import storage from '../../storage'
import { httpGet, httpPost } from '../../api'
import storageMap from '../../storage/storageMap'
import apiEndPoints from '../../api/apiEndPoints'

export async function getPublishingServicesFromServer() {
  const { data } = await httpGet(apiEndPoints.publishing_services, false)
  await storage.bulkUpdate(storageMap.publishing_services, data)
}

export async function loadPublishingServicesFromLocalStorage() {
  const response = await storage.getAll(storageMap.publishing_services)
  return response || []
}

export function publishScoringToService(productId, serviceCode) {
  const path = `products/${productId}/publish`
  return new Promise((resolve, reject) => {
    httpPost(
      path,
      {
        service: serviceCode,
      },
      false
    )
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}
