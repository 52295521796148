import { httpDelete, httpPut } from './index'
import ApiException from './apiException'

export const batchCreateOrUpdate = async (productId, comments) => {
  const response = await httpPut(`products/${productId}/scoring-tree/comments`, { comments }, false)

  if (response.status && response.status >= 200 && response.status < 300) {
    return response.data
  }

  throw new ApiException(response)
}

export const batchRemove = async (productId, comments) => {
  const response = await httpDelete(`products/${productId}/scoring-tree/comments`, { comments }, false)

  if (response.status && response.status >= 200 && response.status < 300) {
    return response.data
  }

  throw new ApiException(response)
}
