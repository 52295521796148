import React from 'react'
import { connect } from 'react-redux'
import { graphql, StaticQuery } from 'gatsby'
import * as actionCreators from '../../../store/actions'
import authPagesMap from './authPagesMap'

export class AuthFormTitleComponent extends React.Component {
  render() {
    const { authProcess, setAuthProcessPage, texts } = this.props
    function handleShowUsernamePage() {
      setAuthProcessPage(authPagesMap.useremail)
    }
    function handleShowPasswordPage() {
      setAuthProcessPage(authPagesMap.password)
    }

    return (
      <StaticQuery
        query={graphql`
          query {
            prevBtn: file(relativePath: { regex: "/assets/svg/30-px-login-prev.svg/" }) {
              publicURL
            }
            confirmIcon: file(relativePath: { regex: "/assets/svg/30px-email-sent.svg/" }) {
              publicURL
            }
          }
        `}
        render={data => (
          <div>
            {(() => {
              switch (authProcess.page) {
                case authPagesMap.useremail:
                  return <div className="login-form-title">{texts.auth_sign_in}</div>
                case authPagesMap.password:
                  return (
                    <div className="login-form-title">
                      <button type="button" onClick={handleShowUsernamePage} className="styleless_btn">
                        <img src={data.prevBtn.publicURL} alt="prev-auth-page" />
                      </button>
                      {texts.auth_sign_in}
                    </div>
                  )
                case authPagesMap.resetpass:
                  return (
                    <div className="login-form-title">
                      <button type="button" onClick={handleShowPasswordPage} className="styleless_btn">
                        <img src={data.prevBtn.publicURL} alt="prev-auth-page" />
                      </button>
                      {texts.auth_password_reset}
                    </div>
                  )
                case authPagesMap.resetnotallowed:
                  return (
                    <div className="login-form-title">
                      <button type="button" onClick={handleShowPasswordPage} className="styleless_btn">
                        <img src={data.confirmIcon.publicURL} alt="prev-auth-page" />
                      </button>
                      {texts.auth_password_reset_not_allowed}
                    </div>
                  )
                case authPagesMap.resetrequestsent:
                  return (
                    <div className="login-form-title">
                      <button type="button" onClick={handleShowPasswordPage} className="styleless_btn">
                        <img src={data.confirmIcon.publicURL} alt="prev-auth-page" />
                      </button>
                      {texts.auth_password_reset_sent}
                    </div>
                  )
                case authPagesMap.newpassword:
                  return <div className="login-form-title">{texts.auth_create_password}</div>
                default:
                  return null
              }
            })()}
          </div>
        )}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    authProcess: state.authentication.authProcess,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(AuthFormTitleComponent)
