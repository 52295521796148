import React from 'react'
import { connect } from 'react-redux'
import { ClickAwayListener, Popper } from '@material-ui/core'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import * as actionCreators from '../../store/actions'
import InfoIcon from '../../assets/svg/info.svg'

const classNames = require('classnames')

export class AccountPopover extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorElement: false,
    }
  }

  render() {
    const { device, authentication, updateLogout } = this.props
    const { user } = authentication
    const role = user.roles[0] ? user.roles[0].name : ''
    const { anchorElement } = this.state
    const open = Boolean(anchorElement)
    const id = open ? 'spring-popper' : undefined
    const rolesInfoPageEnabled = process.env.rolesInfoPageEnabled ? JSON.parse(process.env.rolesInfoPageEnabled) : false
    const userAnonymous = process.env.userAnonymous ? JSON.parse(process.env.userAnonymous) : false

    const handleLogout = async () => {
      if (device.isOnline) {
        updateLogout()
        // await logout()
        // window.location.href = '/'
      }
    }

    const togglePopover = event => {
      this.setState({
        anchorElement: anchorElement ? null : event.currentTarget,
      })
    }

    const handlePopoverClose = () => {
      this.setState({
        anchorElement: null,
      })
    }

    return (
      <div
        className={classNames({
          header_btn: true,
        })}
      >
        <button
          type="button"
          aria-describedby={id}
          className={classNames({
            styleless_btn: true,
            header_btn_user_avatar: true,
          })}
          onClick={togglePopover}
        >
          <PersonOutlineIcon className="account_icon" style={{ fontSize: '29px' }} />
        </button>
        <Popper
          id={id}
          className="account_popover"
          open={open}
          anchorEl={anchorElement}
          // disablePortal
          style={{
            zIndex: 100,
          }}
        >
          <div>
            <ClickAwayListener onClickAway={handlePopoverClose}>
              <div className="account_popver_inner_wrapper">
                <div className="account_popver_user_fullname">{userAnonymous ? user.oidc_id : user.name}</div>
                <div className="account_popver_user_role">
                  {rolesInfoPageEnabled ? (
                    <>
                      <a href="/roles">
                        {role} <InfoIcon style={{ marginLeft: '5px' }} width="15px" height="15px" />
                      </a>
                    </>
                  ) : (
                    <>{role}</>
                  )}
                </div>
                <div className="account_popver_footer">
                  <button
                    type="button"
                    className="sm_btn theme_primary_btn float_right_btn theme_background_color"
                    onClick={handleLogout}
                  >
                    Log out
                  </button>
                </div>
              </div>
            </ClickAwayListener>
          </div>
        </Popper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    cobranding: state.environment.cobranding,
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(AccountPopover)
