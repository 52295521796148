import { batchCreateOrUpdate } from '../../api/comments'
import { SYNC_ACTION_UPDATE_COMMENTS } from '../consts'
import DefaultActionHandler from './defaultActionHandler'

class UpdateCommentHandler extends DefaultActionHandler {
  constructor() {
    super()
    this.setAction(SYNC_ACTION_UPDATE_COMMENTS)
  }

  process = async item => {
    const { data } = item
    const { product_id: productId, comments } = data
    return batchCreateOrUpdate(productId, comments)
  }
}

export default UpdateCommentHandler
