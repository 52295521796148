import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

import NextIcon from '../../../../assets/svg/pagination-next.svg'
import PrevIcon from '../../../../assets/svg/pagination-prev.svg'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

export class ScoringSearchPagination extends React.Component {
  render() {
    const { texts, scoring, updateScoringSearch } = this.props

    function handlePageNumberClicked(pageNumber) {
      updateScoringSearch(scoring.scoringSearch.searchValue, scoring.scoringSearch.pageSize, pageNumber)
    }

    function handlePreviousResultsButtonClick() {
      if (scoring.scoringSearch.currentPage > 0) {
        updateScoringSearch(
          scoring.scoringSearch.searchValue,
          scoring.scoringSearch.pageSize,
          scoring.scoringSearch.currentPage - 1
        )
      }
    }
    function handleNextResultsButtonClick() {
      if (scoring.scoringSearch.currentPage < scoring.scoringSearch.pagesCount - 1) {
        updateScoringSearch(
          scoring.scoringSearch.searchValue,
          scoring.scoringSearch.pageSize,
          scoring.scoringSearch.currentPage + 1
        )
      }
    }

    return (
      <div
        className={classNames({
          search_pagination_container: true,
        })}
      >
        <div
          className={classNames({
            search_pagination_button_prev: true,
            search_pagination_button: true,
          })}
          key={uuidv4()}
          role="button"
          tabIndex={0}
          onClick={handlePreviousResultsButtonClick}
          onKeyPress={handlePreviousResultsButtonClick}
        >
          <div>
            <PrevIcon />
            <div>{texts.previous_results}</div>
          </div>
        </div>

        <div
          className={classNames({
            search_pagination_button_number_container: true,
          })}
        >
          {Array.apply(0, Array(scoring.scoringSearch.pagesCount)).map((x, i) => {
            return (
              <div
                className={classNames({
                  search_pagination_button_number: true,
                  search_pagination_button: true,
                  search_pagination_selected_button_number: scoring.scoringSearch.currentPage === i,
                  search_pagination_button_number_first: i === 0,
                })}
                key={uuidv4()}
                role="button"
                tabIndex={0}
                onClick={() => handlePageNumberClicked(i)}
                onKeyPress={() => handlePageNumberClicked(i)}
              >
                <div>{i + 1}</div>
              </div>
            )
          })}
        </div>

        <div
          className={classNames({
            search_pagination_button_next: true,
            search_pagination_button: true,
          })}
          key={uuidv4()}
          role="button"
          tabIndex={0}
          onClick={handleNextResultsButtonClick}
          onKeyPress={handleNextResultsButtonClick}
        >
          <div>
            <div>{texts.next_results}</div>
            <NextIcon />
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    environment: state.environment,
    templates: state.templates,
    texts: state.texts.values,
    scoring: state.scoring,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringSearchPagination)
