import _ from 'lodash'
import storage from '../../storage'
import storageMap from '../../storage/storageMap'
import { updateDownloadedScoringIntoLocalStorage } from '../downloaded'
import { calculate } from '../../specializations'
import apiEndPoints from '../../api/apiEndPoints'
import { httpPost, httpDelete, httpGet } from '../../api'
import { mongoObjectId, imageResize, readUploadedFileAsBase64, secureAdd, generateOfflineScoringObj } from '../../utils'
import mediaCollectionsMap from '../../utils/mediaCollectionsMap'
import { normalizeComment, normalizeMedia, normalizeScoring } from '../../utils/dataNormalizers'
import normalizeDirectionMap from '../../utils/normalizeDirectionMap'
import nodeDefinitionTypeMap from '../../../src/components/scoring_tree/helper/nodeDefinitionTypeMap'

function handleDeleteMedia(scoring, mediaId) {
  const pictureWasNew = _.find(scoring.media, _media => _media.id === mediaId && _media.is_new)
  if (pictureWasNew) {
    scoring.media = _.filter(scoring.media, _media => _media.id !== mediaId)
  } else {
    if (!scoring.mediaToDelete) scoring.mediaToDelete = []
    scoring.mediaToDelete.push(mediaId)
  }
}

export async function setScoringIntoLocalStorage(scoring) {
  await storage.update(storageMap.scoring, scoring)
  return true
}

export async function getScoringById(id) {
  const scoring = await storage.get(storageMap.scoring, id)
  return scoring
}

export async function createScoringRecord(attributes = {}) {
  const tempId = mongoObjectId()
  const newScoring = {
    ...attributes,
    ...{
      id: tempId,
      template_id: 0,
      status: attributes?.status ?? '',
      nodes: {},
      props: [],
      itemsFatherId: 0,
      activeItemId: 0,
      project_mode: attributes.projectMode ? attributes.projectMode : false,
    },
  }

  await storage.put(storageMap.scoring, newScoring)

  updateDownloadedScoringIntoLocalStorage(tempId)

  return tempId
}

export async function removePictureForScoring(scoringId, collection) {
  const mediaList = await storage.getAll(storageMap.media)
  const picture = mediaList.find(x => x.collection === collection && x.scoring_id === scoringId)
  if (picture) {
    const scoring = await getScoringById(scoringId)

    handleDeleteMedia(scoring, picture.id)

    await setScoringIntoLocalStorage(scoring)

    await storage.remove(storageMap.media, picture.id)
    await storage.remove(storageMap.media_big, picture.id)
  }
  return true
}

export async function removeAllPicturesForScoring(scoringId) {
  const scoring = await getScoringById(scoringId)

  const mediaList = await storage.getAllMedia(scoringId)

  for (let i = 0; i !== mediaList.length; i += 1) {
    const picture = mediaList[i]

    handleDeleteMedia(scoring, picture.id)

    storage.remove(storageMap.media, picture.id)
    storage.remove(storageMap.media_big, picture.id)
  }

  await setScoringIntoLocalStorage(scoring)
}

export async function removePictureForScoringById(scoringId, pictureId) {
  const scoring = await getScoringById(scoringId)

  handleDeleteMedia(scoring, pictureId)

  await setScoringIntoLocalStorage(scoring)

  storage.remove(storageMap.media, pictureId)
  storage.remove(storageMap.media_big, pictureId)
}

export async function getScoringDataByTemplateId(templateId, projectMode) {
  const template = await storage.get(storageMap.template, templateId)
  const { locales } = template
  const scope = await storage.get(storageMap.scope, template.scope.id)
  const mandatoryProps = scope.props
    ? scope.props
        .filter(x => {
          if (projectMode && x.forecast && typeof x.forecast.mandatory !== 'undefined') return x.forecast.mandatory
          return x.mandatory === true
        })
        .map(prop => {
          return prop.slug
        })
    : []
  return {
    locales,
    scope,
    mandatoryProps,
    node_definitions: template.node_definitions,
  }
}

export async function deleteScoringRecord(id) {
  await removeAllPicturesForScoring(id)
  const response = await storage.remove(storageMap.scoring, id)
  return response
}

export async function updateSelectedTemplateForScoring(scoringId, templateId, projectMode) {
  const scoringData = await storage.get(storageMap.scoring, scoringId)
  const templateData = await storage.get(storageMap.template, templateId)

  scoringData.template_id = templateId

  // Create scoring nodes
  scoringData.nodes = {}

  const definitionKeys = Object.keys(templateData.node_definitions)
  for (let i = 0; i !== definitionKeys.length; i += 1) {
    const key = definitionKeys[i]
    const node = templateData.node_definitions[key]
    const newNode = {}
    newNode.id = mongoObjectId()
    newNode.node_definition_id = node.id
    newNode.score = node.bonus_demerit
      ? JSON.parse(global.env.config.demerit_default_score)
      : JSON.parse(global.env.config.default_score)
    newNode.maxScore = 0
    newNode.percentage = 0
    newNode.normalizedScore = 0
    newNode.scored = 0
    newNode.notScored = 0
    newNode.percentageScored = 0
    newNode.is_enabled = JSON.parse(global.env.defaultIsEnabledValue)
    newNode.is_default = JSON.parse(global.env.defaultIsDefaultValue)
    newNode.to_review = false
    newNode.commentsCount = 0
    newNode.mediaCount = 0
    newNode.isExpanded = i < 3

    if (projectMode) {
      // init forecast node scoreset obj
      newNode.forecast = {}
      newNode.forecast.is_enabled = JSON.parse(global.env.defaultIsEnabledValue)
      newNode.forecast.is_default = JSON.parse(global.env.defaultIsDefaultValue)
      newNode.forecast.to_review = false
      newNode.forecast.commentsCount = 0
      newNode.forecast.mediaCount = 0
    }

    scoringData.nodes[node.id] = newNode
  }
  const { nodes } = scoringData
  const duplicatedNodes = { ...nodes }
  await calculate(scoringData, templateData.node_definitions, duplicatedNodes)
  scoringData.nodes = duplicatedNodes
  const response = await storage.update(storageMap.scoring, scoringData)
  return response
}

export async function updateSelectedStatusForScoring(scoringId, status) {
  const scoringData = await storage.get(storageMap.scoring, scoringId)
  scoringData.status = status

  const response = await storage.update(storageMap.scoring, scoringData)
  return response
}

export async function updateProjectModeForScoring(scoringId, projectMode) {
  const scoringData = await storage.get(storageMap.scoring, scoringId)

  scoringData.project_mode = projectMode

  const response = await storage.update(storageMap.scoring, scoringData)
  return response
}

// update optional attribute

export async function updatePublishToForScoring(scoringId, publishTo) {
  const scoringData = await storage.get(storageMap.scoring, scoringId)
  scoringData.publish_to = publishTo

  const response = await storage.update(storageMap.scoring, scoringData)
  return response
}

export async function updatePictureForScoring(scoringId, file, collection, overwrite) {
  const imageObj = await readUploadedFileAsBase64(file)
  const base64Resized = await imageResize(file, global.env.thumbnailsWidth, global.env.thumbnailsHeight)
  const media = {
    id: mongoObjectId(),
    file_name: file.name,
    scoring_id: scoringId,
    node_definition_id: 0,
    collection, // !!! This is important for Server
    type: 'image',
    file_ext: file.name.split('.').pop(),
    file_type: file.type,
    file: base64Resized, // !!! This is important for Server
    input_type: 'base64', // !!! This is important for Server
    is_new: true,
  }
  if (overwrite) {
    await removePictureForScoring(scoringId, collection)
  }
  const mediaBig = { ...media }
  mediaBig.file = imageObj.base64

  // Update media storage
  await storage.update(storageMap.media, media)
  await storage.update(storageMap.media_big, mediaBig)

  // Update scoring storage
  const scoring = await getScoringById(scoringId)
  if (!scoring.media) scoring.media = []
  scoring.media.push(mediaBig)
  setScoringIntoLocalStorage(scoring)

  return mediaBig
}

export async function getScoringPictures(scoringId) {
  const list = await storage.getMedia(scoringId, mediaCollectionsMap.scoring_pictures)
  return list
}

export async function filterTemplatesFromLocalStorage(name, size, number) {
  let templates = await storage.getAll('template')
  templates = templates.filter(template => template.name.en.toLowerCase().includes(name.toLowerCase()))

  if (templates) {
    const pagesCount = Math.ceil(templates.length / size)
    const start = size * number
    const end = start + size
    const hits = templates.slice(start, end)

    return {
      hits,
      pagesCount,
    }
  }
  return false
}

export async function updateScoringPropValue(scoringId, slug, type, label, value, isAggregated) {
  const scoringData = await storage.get(storageMap.scoring, scoringId)
  const { props } = scoringData
  const prop = props.filter(x => x.slug === slug)

  if (prop.length > 0) {
    if (isAggregated) {
      prop[0].props = value
    } else {
      prop[0].value = value
    }
  } else {
    const newProp = isAggregated
      ? {
          slug,
          type,
          label,
          props: value,
        }
      : {
          slug,
          type,
          label,
          value,
        }

    scoringData.props.push(newProp)
  }

  // update indexed db
  await storage.update(storageMap.scoring, scoringData)
  return props
}

export async function updateScoringNodesIntoLocalStorage(scoringId, nodes) {
  const scoringData = await storage.get(storageMap.scoring, scoringId)
  scoringData.nodes = nodes

  const response = await storage.update(storageMap.scoring, scoringData)
  return response
}

export async function duplicateScoring(scoringId, duplicateRequest) {
  const endpoint = apiEndPoints.duplicate_product.replace('{id}', scoringId)
  const response = await httpPost(endpoint, duplicateRequest, false)

  return response
}

export async function deleteScoring(scoringId) {
  const endpoint = apiEndPoints.delete_product.replace('{id}', scoringId)
  const response = await httpDelete(endpoint, '', false)
  return response
}

export async function checkIfScoringExistsOnServer(scoringId) {
  if (!scoringId || scoringId === '') {
    return false
  }
  try {
    const scoringResult = await httpGet(`${apiEndPoints.products}/${scoringId}`)

    // TODO: restore the check scoringResult.data.is_ready
    if (scoringResult.status === 200 || scoringResult.status === 201) {
      return true
    }

    return false
  } catch {
    return false
  }
}

export async function downloadScoringFromServerAtFly(scoringId) {
  if (!scoringId || scoringId === '') {
    return false
  }
  let scoring = {}
  let template = {}

  // Get scoring from server
  const scoringResult = await httpGet(`${apiEndPoints.products}/${scoringId}`)
  if (scoringResult.status === 200 || scoringResult.status === 201) {
    if (scoringResult && scoringResult.data) {
      scoring = scoringResult.data
      template = await storage.get(storageMap.template, scoring.template_id)
      await normalizeScoring(scoring, template, '', 'from_server')
    }
  } else {
    return false
  }

  // Init comments and media
  // Create breadcrumbs
  const keys = Object.keys(scoring.nodes)
  keys.map(key => {
    scoring.nodes[key].comments = []
    scoring.nodes[key].media = []
    return key
  })

  // Get Comments from server
  const comments = await httpGet(`${apiEndPoints.products}/${scoringId}/scoring-tree/comments/all`)
  if (comments && comments.data && comments.data.length > 0) {
    await Promise.all(
      comments.data.map(async comment => {
        normalizeComment(comment, scoring, template, normalizeDirectionMap.from_server)
        scoring.nodes[comment.node_definition_id].comments.push(comment)
      })
    )
  }
  if (scoring.media && scoring.media.length > 0) {
    await Promise.all(
      await scoring.media.map(async picture => {
        if (
          picture.collection === mediaCollectionsMap.cover_pictures ||
          picture.collection === mediaCollectionsMap.full_exterior ||
          picture.collection === mediaCollectionsMap.full_interior
        ) {
          picture.file = picture.file_thumb
        }
        normalizeMedia(picture, scoring, template, normalizeDirectionMap.from_server)
      })
    )
  }
  const media = await httpGet(`${apiEndPoints.products}/${scoringId}/scoring-tree/media/all`)
  if (media && media.data && media.data.length > 0) {
    media.data.map(async picture => {
      normalizeMedia(picture, scoring, template, normalizeDirectionMap.from_server)
      scoring.nodes[picture.node_definition_id].media.push(picture)
    })
  }
  scoring.synced = true
  return scoring
}

export async function invisibleDownloadScoringFromServer(scoringId) {
  if (!scoringId || scoringId === '') {
    return false
  }
  let scoring = {}
  let template = {}
  let memScoring = {}

  // Get scoring from server
  const scoringResult = await httpGet(`${apiEndPoints.products}/${scoringId}`)
  if (scoringResult.status === 200 || scoringResult.status === 201) {
    if (scoringResult && scoringResult.data) {
      scoring = scoringResult.data
      template = await storage.get(storageMap.template, scoring.template_id)
      await normalizeScoring(scoring, template, '', 'from_server')
      memScoring = JSON.parse(JSON.stringify(scoring))
    }
  } else {
    return false
  }

  // Remove all media and comments on IDB for this product
  await storage.removeAllByKey(storageMap.media, 'scoring_id', scoringId)
  await storage.removeAllByKey(storageMap.comment, 'scoring_id', scoringId)

  // Get Comments from server
  const comments = await httpGet(`${apiEndPoints.products}/${scoringId}/scoring-tree/comments/all`)
  if (comments && comments.data && comments.data.length > 0) {
    const bulkComments = []
    comments.data.map(async comment => {
      normalizeComment(comment, scoring, template, normalizeDirectionMap.from_server)

      if (comment.is_forecast) {
        // update forecast comments count
        memScoring.nodes[comment.node_definition_id].forecast.commentsCount = secureAdd(
          memScoring.nodes[comment.node_definition_id].forecast.commentsCount,
          1
        )
      } else {
        // update normal comments count
        memScoring.nodes[comment.node_definition_id].commentsCount = secureAdd(
          memScoring.nodes[comment.node_definition_id].commentsCount,
          1
        )
      }

      const tmpNode = template.node_definitions[comment.node_definition_id]

      if (tmpNode.type === nodeDefinitionTypeMap.criterion) {
        const itemsCommentsCount = memScoring.nodes[tmpNode.parent_id].commentsCount
        memScoring.nodes[tmpNode.parent_id].commentsCount =
          itemsCommentsCount !== undefined ? itemsCommentsCount + 1 : 1
      }

      bulkComments.push(comment)

      // ADD CommentForecastCount?
    })

    await storage.bulkUpdate(storageMap.comment, bulkComments)
  }

  if (scoring.media && scoring.media.length > 0) {
    const offlinePictures = []

    await Promise.all(
      await scoring.media.map(async picture => {
        if (
          picture.collection === mediaCollectionsMap.cover_pictures ||
          picture.collection === mediaCollectionsMap.full_exterior ||
          picture.collection === mediaCollectionsMap.full_interior
        ) {
          picture.file = picture.file_thumb
        }

        normalizeMedia(picture, scoring, template, 'from_server')
        await storage.update(storageMap.media, picture)

        if (
          picture.collection === mediaCollectionsMap.cover_pictures ||
          picture.collection === mediaCollectionsMap.full_exterior ||
          picture.collection === mediaCollectionsMap.full_interior
        ) {
          offlinePictures.push(picture)
        }
      })
    )

    if (offlinePictures.length > 0) {
      const offlineScoring = generateOfflineScoringObj(scoring, template, offlinePictures)
      await storage.update(storageMap.offline_scorings_list, offlineScoring)
    }
  }

  const media = await httpGet(`${apiEndPoints.products}/${scoringId}/scoring-tree/media/all`)
  if (media && media.data && media.data.length > 0) {
    const bulkPictures = []
    media.data.map(async picture => {
      normalizeMedia(picture, scoring, template, 'from_server')

      if (picture.collection === mediaCollectionsMap.forecast_node_pictures) {
        // update forecast media count
        memScoring.nodes[picture.node_definition_id].forecast.mediaCount = secureAdd(
          memScoring.nodes[picture.node_definition_id].forecast.mediaCount,
          1
        )
      } else {
        // update normal media count
        memScoring.nodes[picture.node_definition_id].mediaCount = secureAdd(
          memScoring.nodes[picture.node_definition_id].mediaCount,
          1
        )
      }

      const tmpNode = template.node_definitions[picture.node_definition_id]
      if (tmpNode.type === nodeDefinitionTypeMap.criterion) {
        const itemsMediaCount = memScoring.nodes[tmpNode.parent_id].mediaCount
        memScoring.nodes[tmpNode.parent_id].mediaCount = itemsMediaCount !== undefined ? itemsMediaCount + 1 : 1

        // update forecast parent item media count if node is criterion
        if (picture.collection === mediaCollectionsMap.forecast_node_pictures) {
          const itemsMediaForecastCount = memScoring.nodes[tmpNode.parent_id].forecast.mediaCount
          memScoring.nodes[tmpNode.parent_id].forecast.mediaCount =
            itemsMediaForecastCount !== undefined ? itemsMediaForecastCount + 1 : 1
        }
      }

      bulkPictures.push(picture)
    })
    await storage.bulkUpdate(storageMap.media, bulkPictures)
  }
  memScoring.synced = true

  await storage.update(storageMap.scoring, memScoring)
  return true
}

export async function sendLockUnlockScoringOnServer(scoring) {
  try {
    const singleResponse = await httpPost(
      `${apiEndPoints.products}/${scoring.id}`,
      { is_locked: scoring.is_locked },
      false
    )
    if (singleResponse && singleResponse.status && (singleResponse.status === 200 || singleResponse.status === 201)) {
      return singleResponse.data
    }
    return false
  } catch (e) {
    console.log('Error updating locked / unlocked scoring on Server', e)
    return false
  }
}
