import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../store/actions'

import Thumbs from './thumbs'
import Comments from './comments'
import screenTypesMap from '../../../layout/_parts/screenTypesMap'

import { getProductBrandAndModelLabel, createInterRankingProductsList } from '../../../../va-corejs-v3/utils'

import UnpinIcon from '../../../assets/svg/unpin.svg'
import PinnedIcon from '../../../assets/svg/pinned.svg'
import NoMediaIcon from '../../../assets/svg/no-media.svg'
import { getMediaBlobUrl } from '../../../../va-corejs-v3/api'

const classNames = require('classnames')

class PinnedProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      thumbUrl: null,
    }
  }

  setThumbUrl = async () => {
    const { pin } = this.props
    const thumbUrl = pin.thumbMediaUrl
    this.setState({
      thumbUrl: thumbUrl !== '' ? await getMediaBlobUrl(thumbUrl) : null,
    })
  }

  componentDidMount = async () => {
    await this.setThumbUrl()
  }

  componentDidUpdate = async prevProps => {
    const { pin } = this.props

    if (prevProps.pin !== pin) {
      await this.setThumbUrl()
    }
  }

  handleUnpin = () => {
    const {
      updateInterRankingPin,
      updateInterRankingProductMediaAndComments,
      nodeDefId,
      productsFromServer,
      filteredScore,
      carouselIndex,
      scoringTree,
    } = this.props
    updateInterRankingPin(false)
    const productsList = createInterRankingProductsList(productsFromServer, filteredScore, false)
    const { nodeDefsObj } = scoringTree
    const itemId = nodeDefsObj[nodeDefId].parent_id
    updateInterRankingProductMediaAndComments(productsList[carouselIndex].id, itemId, nodeDefId, 'focused')
  }

  render() {
    const { pin, device, compactMode } = this.props
    const { thumbUrl } = this.state

    const name = getProductBrandAndModelLabel(pin.props)
    return (
      <div className="carousel_pin_container_left_content">
        <div className="pin_title_container">
          <div className="pinned_icon">
            <PinnedIcon />
          </div>
          <div
            className={classNames({
              criterion_score_btn: true,
              criterion_score_btn_selected: true,
              criterion_score_value_btn_0: true,
              pin_title_score: true,
            })}
            role="button"
            tabIndex={0}
          >
            {pin.score}
          </div>
          <div className="pin_title_name">{name}</div>
        </div>
        <div
          className={classNames({
            pin_image_container: true,
            isSafari: device.isSafari,
            isFirefox: device.isFirefox,
            desk: device.screenType === screenTypesMap.desk,
            ipadh: device.screenType === screenTypesMap.iPadH,
            ipadv: device.screenType === screenTypesMap.iPadV,
            iphonexr: device.screenType === screenTypesMap.iPhoneXR,
            iphonese: device.screenType === screenTypesMap.iPhoneSE,
          })}
        >
          <div
            className="unpin"
            role="button"
            tabIndex={0}
            onKeyDown={() => this.handleUnpin()}
            onClick={() => this.handleUnpin()}
          >
            <UnpinIcon /> <span className="unpin_label">Unpin</span>
          </div>
          {thumbUrl !== '' && <div style={{ backgroundImage: `url(${thumbUrl})` }} className="pin_image" />}
          {thumbUrl === '' && (
            <div className="no_media_container">
              <NoMediaIcon />
            </div>
          )}
        </div>
        <Thumbs compactMode={compactMode} type="pinned" scoringId={pin.id} />
        <Comments type="pinned" position="pinned_left" />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoringTree: state.scoringTree,
    pin: state.interRanking.pin,
    pinnedMedia: state.interRanking.pinnedMedia,
    pinnedComments: state.interRanking.pinnedComments,
    nodeDefId: state.interRanking.nodeDefId,
    carouselIndex: state.interRanking.carouselIndex,
    productsFromServer: state.interRanking.productsFromServer,
    filteredScore: state.interRanking.filteredScore,
  }
}

export default connect(mapStateToProps, actionCreators)(PinnedProduct)
