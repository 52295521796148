import _ from 'lodash'
import storageMap from '../../storage/storageMap'
import storage from '../../storage'
import nodeDefinitionTypeMap from '../../../src/components/scoring_tree/helper/nodeDefinitionTypeMap'

export function doCreateScorePanel(scoring, nodes, nodeDefinitions) {
  const treeMap = global.env.treeMap.split(',')
  let scorePanel = []

  _.forEach(nodeDefinitions, (nodeDefinition, key) => {
    const { type, name } = nodeDefinition
    if (type === treeMap[0]) {
      const node = nodes[key]
      const { normalizedScore } = node

      const panel = {
        name: name.en,
        value: Math.round(normalizedScore),
        is_enabled: node.is_enabled,
      }

      // Forecast values
      if (scoring.project_mode) {
        panel.forecast = {
          name: name.en,
          value: node.forecast ? Math.round(node.forecast.normalizedScore) : 0,
          is_enabled: node.forecast ? node.forecast.is_enabled : false,
        }
      }

      scorePanel.push(panel)
    }
  })

  const allItem = { name: 'ALL', value: 0 }
  let scoreSum = 0
  scorePanel.forEach(panel => {
    scoreSum += panel.value
  })
  allItem.value = Math.round(scoreSum / scorePanel.length)

  // Forecast ALL value
  if (scoring.project_mode) {
    allItem.forecast = { name: 'ALL', value: 0 }
    scoreSum = 0
    scorePanel.forEach(panel => {
      scoreSum += panel.forecast.value
    })
    allItem.forecast.value = Math.round(scoreSum / scorePanel.length)
  }

  // VAV3-689: If score panel is > 3 elements, just take the 'ALL' score panel
  scorePanel = scorePanel.length > 2 ? [allItem] : [allItem, ...scorePanel]
  return scorePanel
}

/**
 * Helper function which extract the correct set of attributes from the
 * node object based on the score set type.
 * e.g: forecast
 *
 * @param {Object} node
 * @param {String} scoreSetType
 * @returns
 */
function getScoreSet(node, scoreSetType = null) {
  let scoreSet = {}
  if (scoreSetType !== null) {
    scoreSet = node[scoreSetType] !== undefined ? node[scoreSetType] : {}
  } else {
    scoreSet = node
  }

  return scoreSet
}

function initNode(node, scoreSetType = null) {
  const scoreSet = getScoreSet(node, scoreSetType)

  scoreSet.score = 0
  scoreSet.maxScore = 0
  scoreSet.percentage = 0
  scoreSet.denominator = 0
  scoreSet.normalizedScore = 0
  scoreSet.scored = 0
  scoreSet.notScored = 0
  scoreSet.percentageScored = 0
  scoreSet.is_enabled = scoreSet.is_enabled !== undefined ? scoreSet.is_enabled : true
  scoreSet.is_default = scoreSet.is_default !== undefined ? scoreSet.is_default : true

  if (scoreSetType !== null) {
    node[scoreSetType] = scoreSet
  } else {
    node = scoreSet
  }

  return node
}

/**
 * After all scores are calculated, get the normalized scores for each node
 *
 * @param {Array} productNodes
 */
function calcNormalizedScores(productNodes, scoreSetType = null) {
  const configMaxScore = JSON.parse(global.env.config.max_product_score)

  _.forEach(productNodes, node => {
    const scoreSet = getScoreSet(node, scoreSetType)

    // Calc normal scores
    const { score, maxScore } = scoreSet
    const normalizedScore = score > 0 ? (score * configMaxScore) / maxScore : 0
    scoreSet.normalizedScore = Math.round((normalizedScore + Number.EPSILON) * 100) / 100

    if (scoreSetType !== null) {
      node[scoreSetType] = scoreSet
    } else {
      node = scoreSet
    }
  })
}

/**
 * Determine if a node is eligible (enabled) for the calculation
 *
 * @param {Object} node
 * @param {String} scoreSetType
 * @returns
 */
export function isNodeEligibleForCal(node, nodeDefinition, scoreSetType = null) {
  if (scoreSetType === null && node.is_enabled === true) {
    // Normal score enabled => eligible
    return true
  }

  if (
    scoreSetType !== null &&
    node[scoreSetType] !== undefined &&
    node[scoreSetType].is_enabled === true // Necessary becasue at the moment some nodes doesn't have 'is_enabled' attribute by default
  ) {
    if (nodeDefinition.type !== nodeDefinitionTypeMap.criterion) {
      return true
    }

    if (
      node[scoreSetType].is_default === false // Necessary becasue at the moment some nodes doesn't have 'is_default' attribute by default
    ) {
      // Scoreset enabled and value not default => eligible
      return true
    }

    if (node[scoreSetType].is_default === true && node.is_default === false) {
      // Scoreset enabled with scoreset value default and normal value not default => eligible
      return true
    }

    if (node[scoreSetType].is_default === true && node.is_default === true) {
      // Scoreset enabled and both values default => not eligible
      return false
    }
  }

  return false
}

/**
 * Calculate the score for a node
 *
 * @param {Object} node
 * @param {Object} nodeDefinition
 * @param {int} treeLevel
 * @param {String} scoreSetType
 * @returns
 */
function calcScores(node, nodeDefinition, treeLevel, scoreSetType = null) {
  const treeMap = global.env.treeMap.split(',')
  treeMap.reverse()

  const isNodeEligible = isNodeEligibleForCal(node, nodeDefinition, scoreSetType)
  const scoreSet = getScoreSet(node, scoreSetType)

  if (isNodeEligible) {
    // Node enabled
    const _denominator = parseFloat(scoreSet.denominator)
    const _maxScore = parseFloat(scoreSet.maxScore)

    if (treeLevel === 0) {
      // If it is a criterion
      scoreSet.score =
        scoreSetType !== null && (scoreSet.is_default || scoreSet.score === null)
          ? parseFloat(node.score)
          : parseFloat(scoreSet.score)
      scoreSet.maxScore = JSON.parse(global.env.config.max_score)
    } else {
      // If it is NOT a criterion
      const _score = parseFloat(scoreSet.score)
      scoreSet.score = _denominator === 0 ? 0 : _score / _denominator
      scoreSet.maxScore = _denominator === 0 ? 0 : _maxScore / _denominator
    }

    scoreSet.percentage =
      scoreSet.maxScore === 0 ? 0 : (parseFloat(scoreSet.score) * 100) / parseFloat(scoreSet.maxScore)
  } else if (treeLevel === 0) {
    // Node NOT enabled but it is a criterion
    scoreSet.maxScore = JSON.parse(global.env.config.max_score)
  }

  if (scoreSetType !== null) {
    node[scoreSetType] = scoreSet
  } else {
    node = scoreSet
  }

  return node
}

/**
 * Calculate the scores for the parent of a node
 *
 * @param {Object} parentNode
 * @param {Object} node
 * @param {Object} nodeDefinition
 * @param {int} treeLevel
 * @param {String} scoreSetType
 * @returns
 */
function calcParentScores(parentNode, node, nodeDefinition, treeLevel, scoreSetType = null) {
  const scoreSet = getScoreSet(node, scoreSetType)
  const parentScoreSet = getScoreSet(parentNode, scoreSetType)
  const isNodeEligible = isNodeEligibleForCal(node, nodeDefinition, scoreSetType)

  if (!isNodeEligible) {
    return parentNode
  }

  if (
    (treeLevel === 0 && (!isNodeEligible || scoreSet.is_default)) ||
    (treeLevel !== 0 && (!isNodeEligible || scoreSet.scored === 0))
  ) {
    parentScoreSet.notScored += 1
  } else {
    parentScoreSet.scored += 1
  }

  // Add current node values to parent values
  parentScoreSet.score += parseFloat(scoreSet.score) * parseFloat(nodeDefinition.weight)
  parentScoreSet.maxScore += parseFloat(scoreSet.maxScore) * parseFloat(nodeDefinition.weight)
  parentScoreSet.denominator += parseFloat(nodeDefinition.weight)

  if (scoreSetType !== null) {
    parentNode[scoreSetType] = parentScoreSet
  } else {
    parentNode = parentScoreSet
  }

  return parentNode
}

/**
 * Do the actual calculation
 *
 * @param {Object} scoring
 * @param {Array} nodeDefinitions
 * @param {Object} productNodes
 * @param {Boolean} skipScoringPanel
 * @returns
 */
export async function doCalculation(scoring, nodeDefinitions, productNodes, skipScoringPanel) {
  // We need [perimeter,family,subfamily,item,criterion]
  const configMaxScore = JSON.parse(global.env.config.max_product_score)
  const treeMap = global.env.treeMap.split(',')
  treeMap.reverse()

  const criteria = []
  const demerits = []

  const defKeys = Object.keys(nodeDefinitions)

  // Get Criteria and Demerits
  for (let i = 0; i !== defKeys.length; i += 1) {
    const key = defKeys[i]
    const def = nodeDefinitions[key]
    const { type, id } = def
    const pNode = productNodes[id]

    if (type === nodeDefinitionTypeMap.criterion && pNode.is_enabled === true) {
      if (def.bonus_demerit === false) {
        criteria.push(def)
      } else {
        demerits.push(def)
      }
    }
  }

  const isProjectMode = scoring.project_mode === true

  // Empty all scores but criteria
  Object.keys(productNodes).forEach(nodeDefinitionId => {
    const { type } = nodeDefinitions[nodeDefinitionId]
    if (type !== nodeDefinitionTypeMap.criterion) {
      productNodes[nodeDefinitionId] = initNode(productNodes[nodeDefinitionId])

      // Forecast attributes
      if (isProjectMode) {
        productNodes[nodeDefinitionId] = initNode(productNodes[nodeDefinitionId], 'forecast')
      }
    }
  })

  // STEP 1
  // -----------------------------------------------------------------------------------------
  // Note: Treemap is reversed so [criterion, item, subfamily, family, perimeter]
  for (let t = 0; t < treeMap.length; t += 1) {
    _.forEach(nodeDefinitions, nodeDefinition => {
      // Benchmark scores
      if (nodeDefinition.type === treeMap[t]) {
        let currentNode = calcScores(productNodes[nodeDefinition.id], nodeDefinition, t)
        productNodes[nodeDefinition.id] = currentNode

        if (nodeDefinition.parent_id !== null) {
          productNodes[nodeDefinition.parent_id] = calcParentScores(
            productNodes[nodeDefinition.parent_id],
            currentNode,
            nodeDefinition,
            t
          )
        }

        // Forecast scores
        if (isProjectMode) {
          currentNode = calcScores(productNodes[nodeDefinition.id], nodeDefinition, t, 'forecast')
          productNodes[nodeDefinition.id] = currentNode

          if (nodeDefinition.parent_id !== null) {
            productNodes[nodeDefinition.parent_id] = calcParentScores(
              productNodes[nodeDefinition.parent_id],
              currentNode,
              nodeDefinition,
              t,
              'forecast'
            )
          }
        }
      }
    }) // For each nodeDefinition
  } // For each treeMap level

  // STEP 4
  // -----------------------------------------------------------------------------------------
  // Calculate Normalized scores
  calcNormalizedScores(productNodes)

  if (isProjectMode) {
    calcNormalizedScores(productNodes, 'forecast')
  }

  // STEP 5
  // -----------------------------------------------------------------------------------------
  // For every node but criterion calc scored percentage
  _.forEach(nodeDefinitions, nodeDefinition => {
    if (nodeDefinition.type !== nodeDefinitionTypeMap.criterion) {
      const { scored, notScored } = productNodes[nodeDefinition.id]
      const tot = scored + notScored
      if (tot > 0) {
        productNodes[nodeDefinition.id].percentageScored = (scored / tot) * 100
      }
    }
  })

  // STEP 6
  // -----------------------------------------------------------------------------------------
  // For each criteria, calc score contribution
  const calcGroupWeight = nodeDef => {
    let children = 0
    let groupWeight = 0

    nodeDef.children_ids.forEach(_id => {
      const _node = productNodes[_id]
      if (_node.is_enabled) {
        children += 1
        const _nodeDef = nodeDefinitions[_id]
        groupWeight += Number(_nodeDef.weight)
      }
    })

    return children === 0 ? 0 : groupWeight
  }

  criteria.forEach(criterion => {
    const node = productNodes[criterion.id]
    const item = nodeDefinitions[criterion.parent_id]
    const subfamily = nodeDefinitions[item.parent_id]
    const family = nodeDefinitions[subfamily.parent_id]
    const perimeter = nodeDefinitions[family.parent_id]

    const criteriaWeightInItem = calcGroupWeight(item)
    const itemsWeightInSubfamily = calcGroupWeight(subfamily)
    const subfamiliesWeightInFamily = calcGroupWeight(family)
    const familiesWeightInPerimeter = calcGroupWeight(perimeter)

    if (
      criteriaWeightInItem === 0 ||
      itemsWeightInSubfamily === 0 ||
      subfamiliesWeightInFamily === 0 ||
      familiesWeightInPerimeter === 0
    ) {
      return
    }

    const fullWeight =
      (family.weight / familiesWeightInPerimeter) *
      (subfamily.weight / subfamiliesWeightInFamily) *
      (item.weight / itemsWeightInSubfamily) *
      (criterion.weight / criteriaWeightInItem)

    const normalizedMaxScoreContribution = configMaxScore * fullWeight * (1 / node.maxScore) * node.maxScore
    const normalizedScoreContribution = configMaxScore * fullWeight * (1 / node.maxScore) * node.score

    productNodes[criterion.id].normalizedMaxScoreContribution = normalizedMaxScoreContribution
    productNodes[criterion.id].normalizedScoreContribution = normalizedScoreContribution
  })

  if (skipScoringPanel) {
    return productNodes
  }

  // STEP 7
  // -----------------------------------------------------------------------------------------
  // Update offline_scorings_list to view score panel updated on search
  const scorePanel = doCreateScorePanel(scoring, productNodes, nodeDefinitions)
  const offlineScoring = await storage.get(storageMap.offline_scorings_list, scoring.id)
  if (offlineScoring) {
    offlineScoring._source.score_panel = scorePanel
    await storage.update(storageMap.offline_scorings_list, offlineScoring)
  }

  return productNodes
}

// eslint-disable-next-line no-unused-vars
export async function doCalculationForAnalytics(nodeDefinitions, productNodes) {
  // Not necessary
}
