import _ from 'lodash'
import storage from '../../storage'
import storageMap from '../../storage/storageMap'

import { httpGet, httpPost } from '../../api'
import apiEndPoints from '../../api/apiEndPoints'
import initialState from '../../../src/store/reducers/comparison_board/initialState'

import { downloadScoringFromServerAtFly } from '../scoring'

export async function prepareScoringForComparison(scoringId) {
  const scoring = await downloadScoringFromServerAtFly(scoringId)

  const templatesResponse = await storage.get('template', scoring.template_id)
  const template = { ...templatesResponse }

  const { node_definitions: nodeDefinitions } = template

  // Enrich nodes data with some node definitions data
  _.each(nodeDefinitions, (nodeDefinition, id) => {
    scoring.nodes[id].name = nodeDefinition.name ? nodeDefinition.name : ''
    scoring.nodes[id].children_ids = nodeDefinition.children_ids ? nodeDefinition.children_ids : []
    scoring.nodes[id].parent_id = nodeDefinition.parent_id ? nodeDefinition.parent_id : ''
    scoring.nodes[id].type = nodeDefinition.type ? nodeDefinition.type : ''
    scoring.nodes[id].weight = nodeDefinition.weight ? nodeDefinition.weight : ''
    scoring.nodes[id]._left = nodeDefinition._left
    scoring.nodes[id]._right = nodeDefinition._right

    if (nodeDefinition.parent_id) {
      scoring.nodes[id].breadcrumb = [...scoring.nodes[nodeDefinition.parent_id].breadcrumb, scoring.nodes[id].name.en]
    } else {
      scoring.nodes[id].breadcrumb = []
      scoring.nodes[id].breadcrumb.push(scoring.nodes[id].name.en)
    }
  })

  return scoring
}

export async function loadComparisonBoardFromLocalStorageOrSetInitialState() {
  let comparisonBoard = await storage.get(storageMap.comparison_board, 0)

  if (!comparisonBoard) {
    comparisonBoard = initialState
    comparisonBoard.id = 0
    await storage.update(storageMap.comparison_board, comparisonBoard)
  }
  return comparisonBoard
}

export async function loadComparisonBoardFromLocalStorage() {
  const comparisonBoard = await storage.get(storageMap.comparison_board, 0)
  return comparisonBoard
}

export async function updateComparisonBoardIntoLocalStorage(comparisonBoard) {
  await storage.update(storageMap.comparison_board, comparisonBoard)
  return true
}

export async function comparisonBoardRefreshReferenceTemplate(comparedProducts) {
  // If comparedProducts is empty return empty reference template
  if (comparedProducts.length === 0) {
    return initialState.referenceTemplate
  }

  // referenceProduct decides reference template to draw left panel menu on comparison board
  const referenceProduct = comparedProducts[0]
  const referenceTemplate = await storage.get(storageMap.template, referenceProduct.template.id)

  /*
  // Add is expanded property for template_tree_menu use and set false for all but first 2 nodes (first perimeter & first family)
  const vett = object2Array(referenceTemplate.node_definitions)
  referenceTemplate.nodeDefKeys = []
  let counter = 0
  vett.map(item => referenceTemplate.nodeDefKeys.push(item.id))
  referenceTemplate.nodeDefKeys.map(key => {
    const node = referenceTemplate.node_definitions[key]
    counter += 1
    node.isExpanded = counter <= 2
    node.isSelected = counter === 3
    return key
  })
  */
  return referenceTemplate
}

function extractNodeOfProductFromResponse(pID, nodeID, resp) {
  const key = `${pID}_${nodeID}`
  const { body } = resp[key]
  const { data } = body
  return data[0]
}

export async function createDescendantNodesBatchForIdsList(list, familyId, subfamilyId, dList) {
  const { apiUrl } = global.env

  const requestList = []
  for (let i = 0; i !== list.length; i += 1) {
    const id = list[i]
    const descendantsReqUrl = `${apiUrl}${apiEndPoints.nodes_descendants
      .replace('{pId}', id)
      .replace('{defId}', subfamilyId)}`

    requestList.push({
      method: 'GET',
      'content-type': 'application/json',
      relative_url: descendantsReqUrl,
      name: id,
    })

    const familyDefReqUrl = `${apiUrl}${apiEndPoints.nodes_of_product
      .replace('{pId}', id)
      .replace('{commaSeparatedDefIds}', familyId)}`

    requestList.push({
      method: 'GET',
      'content-type': 'application/json',
      relative_url: familyDefReqUrl,
      name: `${id}_${familyId}`,
    })
  }

  if (requestList.length > 0) {
    const requestBody = { batch: requestList }
    const response = await httpPost(apiEndPoints.batch, requestBody, false)
    const { status, data } = response
    if (status === 200) {
      for (let i = 0; i !== list.length; i += 1) {
        const pID = list[i]
        const { body } = data[pID]

        const parsedBody = {}
        for (let j = 0; j !== body.length; j += 1) {
          const prod = body[j]
          const defId = prod.node_definition_id
          parsedBody[defId] = prod
        }

        const famNode = extractNodeOfProductFromResponse(pID, familyId, data)
        parsedBody[familyId] = famNode

        dList[pID] = parsedBody
      }
    }
  }
}

function findMissingIDs(ids, list) {
  const missingIds = []
  for (let i = 0; i !== ids.length; i += 1) {
    const id = ids[i]
    if (!list[id]) {
      missingIds.push(id)
    }
  }
  return missingIds
}

function removeObsoleteDescendantsFromList(ids, list) {
  const keys = Object.keys(list)
  for (let i = 0; i !== keys.length; i += 1) {
    const key = keys[i]
    const idx = ids.indexOf(key)
    if (idx < 0) {
      delete list[key]
    }
  }
}

async function calcDescendantsBestWorst(descendants, itemsIdList) {
  function compare(a, b) {
    if (a.score < b.score) {
      return -1
    }
    if (a.score > b.score) {
      return 1
    }
    return 0
  }

  const { grandparent, list } = descendants
  const pIDList = Object.keys(list)

  for (let i = 0; i !== itemsIdList.length; i += 1) {
    const itmId = itemsIdList[i]
    const itemBW = []

    for (let j = 0; j !== pIDList.length; j += 1) {
      const pID = pIDList[j]
      const pDesc = list[pID]
      const itm = pDesc[itmId]
      const { score } = itm

      itemBW.push({ pID, score })
    }

    itemBW.sort(compare)
    for (let x = 0; x !== itemBW.length; x += 1) {
      const { pID } = itemBW[x]
      list[pID][itmId].bestWorstOrder = x
    }
  }

  const familyScorePerProduct = []
  for (let i = 0; i !== pIDList.length; i += 1) {
    const pID = pIDList[i]
    const descProd = list[pID]
    const familyNode = descProd[grandparent]
    const { score } = familyNode
    familyScorePerProduct.push({ pID, score })
  }
  familyScorePerProduct.sort(compare)

  for (let i = 0; i !== familyScorePerProduct.length; i += 1) {
    const { pID } = familyScorePerProduct[i]
    list[pID][grandparent].bestWorstOrder = i
  }

  return descendants
}

export async function getNodeOfProduct(pID, nodeDefIdList) {
  const response = await httpGet(
    `${apiEndPoints.nodes_of_product.replace('{pId}', pID).replace('{commaSeparatedDefIds}', nodeDefIdList.join())}`,
    false
  )
  const nodes = {}
  const { data, status } = response
  if (status === 200) {
    for (let i = 0; i !== data.data.length; i += 1) {
      const node = data.data[i]
      const nodeDefId = node.node_definition_id
      nodes[nodeDefId] = node
    }
  }

  return nodes
}

export async function getDescendantNodes(flush) {
  const comparisonBoard = await loadComparisonBoardFromLocalStorage()
  const { comparedProducts, itemsFather, descendants } = comparisonBoard

  if (comparedProducts.length === 0) return descendants

  const subfamilyId = itemsFather.id
  const familyId = itemsFather.parent_id

  const comparedIds = []
  for (let i = 0; i !== comparedProducts.length; i += 1) {
    const prod = comparedProducts[i]
    const { id } = prod
    comparedIds.push(id)
  }

  const differentParentId = subfamilyId !== descendants.parent || flush

  let updatedDescendants = {}
  if (differentParentId) {
    const dList = {}
    await createDescendantNodesBatchForIdsList(comparedIds, familyId, subfamilyId, dList)

    updatedDescendants = {
      grandparent: familyId,
      parent: subfamilyId,
      list: dList,
    }
  } else {
    const { grandparent, parent, list } = descendants

    removeObsoleteDescendantsFromList(comparedIds, list)
    const missingIds = findMissingIDs(comparedIds, list)
    await createDescendantNodesBatchForIdsList(missingIds, familyId, subfamilyId, list)

    updatedDescendants = {
      grandparent,
      parent,
      list,
    }
  }

  const itemsIdList = itemsFather.children_ids
  const descendantsAfterBestWorstCalc = await calcDescendantsBestWorst(updatedDescendants, itemsIdList)

  return descendantsAfterBestWorstCalc
}
