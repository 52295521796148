import React from 'react'
import { connect } from 'react-redux'

import { graphql, StaticQuery } from 'gatsby'

import * as actionCreators from '../../../store/actions'
import authPagesMap from './authPagesMap'

export class ResetPasswordComponent extends React.Component {
  render() {
    const { authProcess } = this.props

    return (
      <StaticQuery
        query={graphql`
          query {
            userIcon: file(relativePath: { regex: "/assets/svg/20px-username.svg/" }) {
              publicURL
            }
          }
        `}
        render={data => (
          <div
            className={
              authProcess.page === authPagesMap.resetpass
                ? 'login_form_inner_container_active slide_left'
                : 'login_form_inner_container_inactive'
            }
          >
            <div className="login_form_username">
              <div>
                <img src={data.userIcon.publicURL} alt="user-icon" />{' '}
                <span className="login-form-user-value">{authProcess.userEmail}</span>
              </div>
            </div>
            <div className="login-form-reset-password-description">
              Nous allons vous envoyer un e-mail avec votre nom d’utilisateur et un lien pour réinitialiser votre mot de
              passe.
            </div>
          </div>
        )}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    authProcess: state.authentication.authProcess,
  }
}

export default connect(mapStateToProps, actionCreators)(ResetPasswordComponent)
