import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../store/actions'

import './styles.scss'
import ExpandIcon from '../../assets/svg/inter-ranking-expand-icon.svg'
import CloseIcon from '../../assets/svg/lightbox-close.svg'
import StarHeader from '../../assets/svg/star-header.svg'
import SearchIcon from '../../assets/svg/search-generic.svg'
import Breadcrumb from '../breadcrumb'
import {
  getProductBrandAndModelLabel,
  createBreadcrumb,
  createInterRankingProductsList,
  interRankingGetFirstIndexWithThisScore,
  interRankingGetFirstIndexWithThisScoringId,
} from '../../../va-corejs-v3/utils'
import Carousel from './_parts/carousel'
import PinnedProduct from './_parts/pinnedProduct'
import Thumbs from './_parts/thumbs'

import screenTypesMap from '../../layout/_parts/screenTypesMap'
import CarouselIpadV from './_parts/carouselIpadV'
import Comments from './_parts/comments'

import LoadingBar from '../loading_bar/index.js'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

class InterRanking extends Component {
  state = {
    showFilter: false,
    showSearch: false,
    searchInputValue: '',
    systemLoaded: false,
  }

  componentDidMount = async () => {
    const {
      updateInterRankingProductsFromServer,
      scoringId,
      filteredScore,
      pin,
      nodeDefId,
      updateInterRankingProductMediaAndComments,
      scoringTree,
    } = this.props

    const { nodeDefsObj } = scoringTree
    const { scoring } = scoringTree
    const itemId = nodeDefsObj[nodeDefId].parent_id

    await updateInterRankingProductsFromServer(scoring, filteredScore, pin, nodeDefId, nodeDefsObj)

    updateInterRankingProductMediaAndComments(scoringId, itemId, nodeDefId, 'focused')
    this.setState({ systemLoaded: true })
  }

  handleClose = () => {
    const { updateShowInterRanking, emptyInterRankingFromRedux, updateScrollDisabledStatus } = this.props
    emptyInterRankingFromRedux()
    updateShowInterRanking('', '', false, '', false)
    updateScrollDisabledStatus(false)
  }

  handleSearchScore = score => {
    const {
      updateInterRankingCarouselIndex,
      updateInterRankingActiveScore,
      productsFromServer,
      filteredScore,
      pin,
    } = this.props
    const newIndex = interRankingGetFirstIndexWithThisScore(productsFromServer, filteredScore, pin, score)
    if (newIndex !== false) {
      updateInterRankingCarouselIndex(newIndex)
      updateInterRankingActiveScore(score)
    }
  }

  handleSearchModel = (e, item) => {
    if (e) {
      e.stopPropagation()
    }
    const {
      updateInterRankingCarouselIndex,
      updateInterRankingActiveScore,
      productsFromServer,
      filteredScore,
      pin,
    } = this.props
    const newIndex = interRankingGetFirstIndexWithThisScoringId(productsFromServer, filteredScore, pin, item.id)
    if (newIndex !== false) {
      updateInterRankingCarouselIndex(newIndex)
      updateInterRankingActiveScore(item.score)
      this.setState({ searchInputValue: getProductBrandAndModelLabel(item.props) }, () => {
        this.handleOpenCloseSearch()
      })
    }
  }

  handleOpenCloseFilter = forceShowFilter => {
    let { showFilter } = this.state
    showFilter = forceShowFilter !== undefined ? forceShowFilter : !showFilter
    this.setState({ showFilter })
  }

  handleOpenCloseSearch = forceShowSearch => {
    let { showSearch } = this.state
    showSearch = forceShowSearch !== undefined ? forceShowSearch : !showSearch
    this.setState({ showSearch })
  }

  productsListFilterdByInput = () => {
    const { searchInputValue } = this.state
    const { productsFromServer, filteredScore, pin } = this.props
    const productsList = createInterRankingProductsList(productsFromServer, filteredScore, pin)
    const productsListFiltered = []
    productsList.map(product => {
      const name = getProductBrandAndModelLabel(product.props)
      if (
        !searchInputValue ||
        searchInputValue === '' ||
        name.toLowerCase().indexOf(searchInputValue.toLowerCase()) > -1
      ) {
        productsListFiltered.push(product)
      }
      return product
    })
    return productsListFiltered
  }

  handleModelInputChange = e => {
    this.setState({ showSearch: true, searchInputValue: e.target.value }, () => {
      const productsListFiltered = this.productsListFilterdByInput()
      if (productsListFiltered.length > 0) {
        const { updateInterRankingCarouselIndex, updateInterRankingActiveScore } = this.props
        const newIndex = interRankingGetFirstIndexWithThisScoringId(productsListFiltered[0].id)
        if (newIndex !== false) {
          updateInterRankingCarouselIndex(newIndex)
          updateInterRankingActiveScore(productsListFiltered[0].score)
        }
      }
    })
  }

  thisScoreNotExists = score => {
    const { productsFromServer, filteredScore, pin } = this.props
    const productsList = createInterRankingProductsList(productsFromServer, filteredScore, pin)
    if (!productsList || productsList.length === 0) {
      return true
    }
    let flag = false
    for (let i = 0; i < productsList.length; i += 1) {
      if (productsList[i].score === score) {
        flag = true
      }
    }
    return !flag
  }

  handleFilteredScore = (e, score) => {
    e.stopPropagation()
    const {
      updateInterRankingFilteredScore,
      updateInterRankingCarouselIndex,
      updateInterRankingActiveScore,
    } = this.props
    if (this.thisScoreNotExists(score)) {
      return
    }
    updateInterRankingCarouselIndex(0)
    updateInterRankingFilteredScore(score)
    updateInterRankingActiveScore(score)
    this.handleOpenCloseFilter()
  }

  handleRemoveFilteredScore = () => {
    const {
      updateInterRankingActiveScore,
      updateInterRankingCarouselIndex,
      updateInterRankingFilteredScore,
      activeScore,
    } = this.props
    updateInterRankingFilteredScore(false)
    updateInterRankingActiveScore(activeScore)
    updateInterRankingCarouselIndex(interRankingGetFirstIndexWithThisScore(activeScore))
    this.handleOpenCloseFilter(false)
  }

  render() {
    const { showFilter, showSearch, searchInputValue, systemLoaded } = this.state
    const {
      scoringTree,
      environment,
      nodeDefId,
      activeScore,
      filteredScore,
      pin,
      device,
      productsFromServer,
      carouselIndex,
      texts,
    } = this.props

    const productsList = createInterRankingProductsList(productsFromServer, filteredScore, pin)
    const focusedScoringId =
      productsList && productsList.length > 0 && carouselIndex !== false && productsList[carouselIndex]
        ? productsList[carouselIndex].id
        : ''

    const scoredArray = []
    productsList.map(product => {
      if (scoredArray.indexOf(product.score) === -1) {
        scoredArray.push(product.score)
      }
      return product
    })

    const breadcrumbList = createBreadcrumb(
      scoringTree.nodeDefsObj,
      nodeDefId,
      environment.lang === '' ? environment.defaultLang : environment.lang
    )

    const Buttons = () => {
      const buttons = []
      const gotoLabel = (
        <div className="inter_ranking_goto_label" key="-1">
          Go to
        </div>
      )
      buttons.push(gotoLabel)

      scoredArray.map(score => {
        buttons.push(
          <div
            className={classNames({
              score_btn_unselected: activeScore !== score,
              criterion_score_value_0_off: activeScore !== score && score === 0,
              criterion_score_value_0_5_off: activeScore !== score && score === 0.5,
              criterion_score_value_1_off: activeScore !== score && score === 1,
              criterion_score_value_1_5_off: activeScore !== score && score === 1.5,
              criterion_score_value_2_off: activeScore !== score && score === 2,
              criterion_score_value_2_5_off: activeScore !== score && score === 2.5,
              criterion_score_value_3_off: activeScore !== score && score === 3,
              criterion_score_value_3_5_off: activeScore !== score && score === 3.5,
              criterion_score_value_4_off: activeScore !== score && score === 4,
              criterion_score_value_4_5_off: activeScore !== score && score === 4.5,
              criterion_score_value_5_off: activeScore !== score && score === 5,
              score_btn_selected: activeScore === score,
              criterion_score_value_0: activeScore === score && score === 0,
              criterion_score_value_0_5: activeScore === score && score === 0.5,
              criterion_score_value_1: activeScore === score && score === 1,
              criterion_score_value_1_5: activeScore === score && score === 1.5,
              criterion_score_value_2: activeScore === score && score === 2,
              criterion_score_value_2_5: activeScore === score && score === 2.5,
              criterion_score_value_3: activeScore === score && score === 3,
              criterion_score_value_3_5: activeScore === score && score === 3.5,
              criterion_score_value_4: activeScore === score && score === 4,
              criterion_score_value_4_5: activeScore === score && score === 4.5,
              halo: activeScore === score,
            })}
            key={score}
            role="button"
            tabIndex={0}
            scorevalue={score}
            onKeyDown={() => this.handleSearchScore(score)}
            onClick={() => this.handleSearchScore(score)}
          >
            {score}
          </div>
        )
        return score
      })

      return buttons
    }

    return (
      <div
        className={classNames({
          inter_ranking_container: true,
          isSafari: device.isSafari,
          isFirefox: device.isFirefox,
          desk: device.screenType === screenTypesMap.desk,
          ipadh: device.screenType === screenTypesMap.iPadH,
          ipadv: device.screenType === screenTypesMap.iPadV,
          iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          iphonese: device.screenType === screenTypesMap.iPhoneSE,
        })}
      >
        <div className="inter_ranking_title">
          <StarHeader className="inter_ranking_star_header" />
          Scoring Inter-ranking
          <div className="inter_ranking_breadcrump_container">
            <Breadcrumb breadcrumbList={breadcrumbList} />
          </div>
          <div
            className="inter_ranking_close_btn"
            role="button"
            tabIndex={0}
            onKeyDown={() => this.handleClose()}
            onClick={() => this.handleClose()}
          >
            <CloseIcon />
          </div>
        </div>

        {systemLoaded && productsList.length === 0 && (
          <div className="inter_ranking_no_items_container">{texts.inter_raking_no_items}</div>
        )}

        {productsList.length > 0 && (
          <>
            <div className="inter_ranking_filters_container">
              <div className="inter_ranking_filters_filter_container">
                {!filteredScore && (
                  <div
                    className="inter_ranking_filter_button"
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => this.handleOpenCloseFilter()}
                    onClick={() => this.handleOpenCloseFilter()}
                  >
                    Filter <ExpandIcon className="inter_ranking_filter_expand_icon" />
                  </div>
                )}
                {filteredScore && (
                  <div
                    className="inter_ranking_filter_button inter_ranking_filter_button_filtered"
                    role="button"
                    tabIndex={0}
                    onKeyDown={e => this.handleRemoveFilteredScore(e)}
                    onClick={e => this.handleRemoveFilteredScore(e)}
                  >
                    Filtered {filteredScore}
                  </div>
                )}
                {showFilter && (
                  <>
                    <div className="inter_ranking_filter_list">
                      {scoredArray.map(item => (
                        <div
                          key={item}
                          className="inter_ranking_filter_list_item"
                          role="button"
                          tabIndex={0}
                          onKeyDown={e => this.handleFilteredScore(e, item)}
                          onClick={e => this.handleFilteredScore(e, item)}
                        >
                          Scored {item}
                        </div>
                      ))}
                    </div>
                    <div className="inter_ranking_filter_list_triangle" />{' '}
                  </>
                )}
              </div>

              <div className="inter_ranking_filters_score_container">{!filteredScore && Buttons()}</div>

              <div className="inter_ranking_filters_search_container">
                <div className="inter_ranking_filter_search_input_container">
                  <SearchIcon className="inter_ranking_filters_search_icon" />
                  <input
                    type="text"
                    className="inter_ranking_filter_search_input"
                    role="button"
                    tabIndex={0}
                    onClick={() => this.handleOpenCloseSearch()}
                    onChange={e => this.handleModelInputChange(e)}
                    placeholder="Search Model"
                    value={searchInputValue}
                  />
                </div>

                {showSearch && (
                  <>
                    <div className="inter_ranking_filter_search_list">
                      {this.productsListFilterdByInput().map(item => (
                        <div
                          key={uuidv4()}
                          className="inter_ranking_filter_search_list_item"
                          role="button"
                          tabIndex={0}
                          onKeyDown={e => this.handleSearchModel(e, item)}
                          onClick={e => this.handleSearchModel(e, item)}
                        >
                          <div className="inter_ranking_filter_search_list_text">
                            {getProductBrandAndModelLabel(item.props)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>

            {!systemLoaded && (
              <div
                className={classNames({
                  init_loading_container_small: true,
                })}
              >
                <LoadingBar color="white" />
              </div>
            )}
            {systemLoaded && (
              <div className={classNames({ inter_ranking_content: true, inter_ranking_content_with_pin: !!pin })}>
                {!pin && (
                  <>
                    <div className="inter_ranking_media_wrapper">
                      <Carousel />
                      <Thumbs compactMode={false} type="focused" scoringId={focusedScoringId} />
                    </div>
                    <Comments type="focused" />
                  </>
                )}
                {pin && (
                  <>
                    <div
                      className={classNames({
                        carousel_pin_container_left: true,
                        ipadVPinned: device.screenType === screenTypesMap.iPadV && pin !== false,
                      })}
                    >
                      <PinnedProduct compactMode />
                    </div>
                    <div
                      className={classNames({
                        carousel_pin_container_right: true,
                        isSafari: device.isSafari,
                        isFirefox: device.isFirefox,
                        desk: device.screenType === screenTypesMap.desk,
                        ipadh: device.screenType === screenTypesMap.iPadH,
                        ipadv: device.screenType === screenTypesMap.iPadV,
                        iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                        iphonese: device.screenType === screenTypesMap.iPhoneSE,
                        ipadVPinned: device.screenType === screenTypesMap.iPadV && pin !== false,
                      })}
                    >
                      {!(device.screenType === screenTypesMap.iPadV) && (
                        <>
                          <div className="inter_ranking_media_wrapper">
                            <Carousel carouselPinned={pin} />
                            <Thumbs compactMode={false} type="focused" scoringId={focusedScoringId} />
                          </div>
                          <Comments type="focused" position="pinned_right" />
                        </>
                      )}
                      {device.screenType === screenTypesMap.iPadV && (
                        <>
                          <CarouselIpadV />
                          <Comments type="focused" position="pinned_right" />
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoringTree: state.scoringTree,
    nodeDefId: state.interRanking.nodeDefId,
    activeScore: state.interRanking.activeScore,
    filteredScore: state.interRanking.filteredScore,
    pin: state.interRanking.pin,
    productsFromServer: state.interRanking.productsFromServer,
    templateId: state.interRanking.templateId,
    carouselIndex: state.interRanking.carouselIndex,
    scoringId: state.interRanking.scoringId,
  }
}

export default connect(mapStateToProps, actionCreators)(InterRanking)
