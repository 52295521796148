import {
  SYNC_ACTION_CREATE_COMMENT,
  SYNC_ACTION_UPDATE_COMMENT,
  SYNC_ACTION_CREATE_PRODUCT,
  SYNC_ACTION_DELETE_COMMENT,
  SYNC_ACTION_DELETE_MEDIA,
  SYNC_ACTION_DELETE_PRODUCT,
  SYNC_ACTION_REORDER_MEDIA,
  SYNC_ACTION_UPDATE_PRODUCT,
  SYNC_ACTION_UPDATE_SCORING_TREE,
  SYNC_ACTION_UPLOAD_MEDIA,
  SYNC_ACTION_CREATE_COMMENTS,
  SYNC_ACTION_UPDATE_COMMENTS,
} from '../consts'
import CreateCommentHandler from './createCommentHandler'
import UpdateCommentHandler from './updateCommentHandler'
import CreateProductHandler from './createProductHandler'
import DeleteCommentHandler from './deleteCommentHandler'
import DeleteMediaHandler from './deleteMediaHandler'
import DeleteProductHandler from './deleteProductHandler'
import ReorderMediaHandler from './reorderMediaHandler'
import UpdateProductHandler from './updateProductHandler'
import UpdateScoringTreeHandler from './updateScoringTreeHandler'
import UploadMediaHandler from './uploadMediaHandler'
import CreateCommentsHandler from './createCommentsHandler'
import UpdateCommentsHandler from './updateCommentsHandler'

class ActionHandlerFactory {
  static make = action => {
    switch (action) {
      case SYNC_ACTION_DELETE_COMMENT:
        return new DeleteCommentHandler()
      case SYNC_ACTION_DELETE_MEDIA:
        return new DeleteMediaHandler()
      case SYNC_ACTION_DELETE_PRODUCT:
        return new DeleteProductHandler()
      case SYNC_ACTION_CREATE_COMMENT:
        return new CreateCommentHandler()
      case SYNC_ACTION_UPDATE_COMMENT:
        return new UpdateCommentHandler()
      case SYNC_ACTION_UPLOAD_MEDIA:
        return new UploadMediaHandler()
      case SYNC_ACTION_REORDER_MEDIA:
        return new ReorderMediaHandler()
      case SYNC_ACTION_UPDATE_PRODUCT:
        return new UpdateProductHandler()
      case SYNC_ACTION_CREATE_PRODUCT:
        return new CreateProductHandler()
      case SYNC_ACTION_UPDATE_SCORING_TREE:
        return new UpdateScoringTreeHandler()
      case SYNC_ACTION_CREATE_COMMENTS:
        return new CreateCommentsHandler()
      case SYNC_ACTION_UPDATE_COMMENTS:
        return new UpdateCommentsHandler()
      default:
        return null
    }
  }
}

export default ActionHandlerFactory
