import {
  filterTemplatesFromLocalStorage,
  createScoringRecord,
  updateSelectedTemplateForScoring,
  updateSelectedStatusForScoring,
  getScoringPictures,
  updatePictureForScoring,
  removePictureForScoring,
  removePictureForScoringById,
  getScoringDataByTemplateId,
  deleteScoringRecord,
  updateScoringPropValue,
  getScoringById,
  updateScoringNodesIntoLocalStorage,
  updatePublishToForScoring,
  updateProjectModeForScoring,
} from '../../../../va-corejs-v3/actions/scoring'
import { getTemplateById } from '../../../../va-corejs-v3/actions/templates'

import mediaCollectionsMap from '../../../../va-corejs-v3/utils/mediaCollectionsMap'
import { calculate } from '../../../../va-corejs-v3/specializations'
import syncQueue from '../../../../va-corejs-v3/sync/syncQueue'
import { SYNC_ACTION_UPDATE_SCORING_TREE } from '../../../../va-corejs-v3/sync/consts'

export function setScoringCreationStep(scoringCreationStep) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_SCORING_CREATION_STEP',
      scoringCreationStep,
    })
  }
}

export function createScoringRecordInStorageAndShowScoringPanel() {
  return async dispatch => {
    const newScoringId = await createScoringRecord()
    const newScoringPanelVisible = true
    dispatch({
      type: 'UPDATE_NEW_SCORING_PANEL_VISIBLE',
      newScoringId,
      newScoringPanelVisible,
    })
  }
}

export function deleteTempScoringFromStorageAndResetPanel(scoringId) {
  return async dispatch => {
    await deleteScoringRecord(scoringId)
    dispatch({
      type: 'RESET_SCORING_PANEL_SETTINGS',
    })
  }
}

export function resetScoringPanelForRedux() {
  return async dispatch => {
    dispatch({
      type: 'RESET_SCORING_PANEL_SETTINGS',
    })
  }
}

export function updateScoringSearch(searchValue, pageSize, currentPage) {
  return async dispatch => {
    const response = await filterTemplatesFromLocalStorage(searchValue, pageSize, currentPage)
    const { hits, pagesCount } = response

    dispatch({
      type: 'UPDATE_SCORING_SEARCH',
      searchValue,
      currentPage,
      pagesCount,
      hits,
    })
  }
}

export function setTemplateForScoringCreationEditing(scoringId, selectedTemplate, projectMode) {
  return async dispatch => {
    await updateSelectedTemplateForScoring(scoringId, selectedTemplate, projectMode)
    const scoring = await getScoringById(scoringId)
    const templateData = await getScoringDataByTemplateId(selectedTemplate, projectMode)
    const template = await getTemplateById(selectedTemplate)

    // VAV3-912 Special rule for Renault. Please keep attention: this is a BAD PRACTICE
    // TO REFACTOR!
    const selectedStatus =
      process.env.client === 'renault' && template.code !== 'car' && template.code !== 'lcv'
        ? 'specific_evaluations'
        : ''
    // End of VAV3-912

    dispatch({
      type: 'UPDATE_SELECTED_TEMPLATE_DATA',
      selectedTemplate,
      selectedTemplateLocales: templateData.locales,
      scoringScope: templateData.scope,
      mandatoryProps: templateData.mandatoryProps,
      nodeDefinitions: templateData.node_definitions,
      scoringNodes: scoring.nodes,
      selectedStatus: scoring.status && scoring.status !== '' ? scoring.status : selectedStatus,
    })
  }
}

export function setTemplateOnlyInReduxForScoringDuplication(selectedTemplate) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_SELECTED_TEMPLATE',
      selectedTemplate,
    })
  }
}

export function initializeScoringForEdit(scoringTree, scoringScope) {
  return async dispatch => {
    let fullExterior = {}
    let fullInterior = {}
    let hmi = {}
    let cargo = {}
    const projectMode = scoringTree.scoring.project_mode || false
    const templateData = await getScoringDataByTemplateId(scoringTree.scoring.template_id, projectMode)

    if (scoringTree.scoring.media) {
      const fullExteriorList = scoringTree.scoring.media.filter(x => x.collection === mediaCollectionsMap.full_exterior)
      const fullInteriorList = scoringTree.scoring.media.filter(x => x.collection === mediaCollectionsMap.full_interior)
      const hmiList = scoringTree.scoring.media.filter(x => x.collection === mediaCollectionsMap.hmi)
      const cargoList = scoringTree.scoring.media.filter(x => x.collection === mediaCollectionsMap.cargo)

      fullExterior = fullExteriorList.length > 0 ? fullExteriorList[0] : fullExterior
      fullInterior = fullInteriorList.length > 0 ? fullInteriorList[0] : fullInterior
      hmi = hmiList.length > 0 ? hmiList[0] : hmi
      cargo = cargoList.length > 0 ? cargoList[0] : cargo
    }

    dispatch({
      type: 'INIT_SCORING_FOR_EDIT',
      newScoringId: scoringTree.scoring.id,
      selectedTemplate: scoringTree.scoring.template_id,
      selectedTemplateLocales: scoringTree.locales,
      scoringScope,
      mandatoryProps: templateData.mandatoryProps,
      scoringProps: scoringTree.scoring.props,
      selectedStatus: scoringTree.scoring.status,
      publishTo: scoringTree.scoring.publish_to,
      coverPicture: scoringTree.header.featuredImg,
      fullExterior,
      fullInterior,
      hmi,
      cargo,
      nodeDefinitions: scoringTree.nodeDefsObj,
      scoringNodes: scoringTree.scoring.nodes,
      project_mode: projectMode,
    })
  }
}

export function setScoringStatus(scoringId, selectedStatus) {
  return async dispatch => {
    await updateSelectedStatusForScoring(scoringId, selectedStatus)
    dispatch({
      type: 'UPDATE_SELECTED_STATUS',
      selectedStatus,
    })
  }
}

export function setScoringPublishTo(scoringId, publishTo) {
  return async dispatch => {
    await updatePublishToForScoring(scoringId, publishTo)
    dispatch({
      type: 'UPDATE_PUBLISH_TO',
      publishTo,
    })
  }
}

export function setScoringPicture(scoringId, file, collection) {
  return async dispatch => {
    const picture = await updatePictureForScoring(scoringId, file, collection, true)
    if (collection === mediaCollectionsMap.cover_pictures) {
      dispatch({
        type: 'UPDATE_COVER_PICTURE',
        coverPicture: picture,
      })
    } else if (collection === mediaCollectionsMap.full_exterior) {
      dispatch({
        type: 'UPDATE_FULL_EXTERIOR',
        fullExterior: picture,
      })
    } else if (collection === mediaCollectionsMap.full_interior) {
      dispatch({
        type: 'UPDATE_FULL_INTERIOR',
        fullInterior: picture,
      })
    } else if (collection === mediaCollectionsMap.hmi) {
      dispatch({
        type: 'UPDATE_HMI',
        hmi: picture,
      })
    } else if (collection === mediaCollectionsMap.cargo) {
      dispatch({
        type: 'UPDATE_CARGO',
        cargo: picture,
      })
    }

    return picture
  }
}

export function getScoringGalleryAndUpdateRedux(scoringId) {
  return async dispatch => {
    const scoringPictures = await getScoringPictures(scoringId)
    dispatch({
      type: 'UPDATE_SCORING_PICTURES',
      scoringPictures,
    })
  }
}

export function updateScoringGallery(scoringId, scoringPictures, files) {
  return async dispatch => {
    const filesToProcess = files.length
    const newScoringPictures = [...scoringPictures]
    let fileProcessed = 0

    async function processFiles() {
      if (fileProcessed === filesToProcess) return

      const file = files[fileProcessed]
      const picture = await updatePictureForScoring(scoringId, file, mediaCollectionsMap.scoring_pictures, false)
      newScoringPictures.push(picture)
      fileProcessed += 1
      await processFiles()
    }

    await processFiles()

    dispatch({
      type: 'UPDATE_SCORING_PICTURES',
      scoringPictures: newScoringPictures,
    })
  }
}

export function removePictureFromScoringGallery(scoringId, scoringPictures, pictureID) {
  return async dispatch => {
    await removePictureForScoringById(scoringId, pictureID)
    let newScoringPictures = [...scoringPictures]
    newScoringPictures = newScoringPictures.filter(image => {
      return image.id !== pictureID
    })

    dispatch({
      type: 'UPDATE_SCORING_PICTURES',
      scoringPictures: newScoringPictures,
    })
  }
}

export function removeScoringPicture(scoringId, collection) {
  return async dispatch => {
    await removePictureForScoring(scoringId, collection)
    const picture = {}
    if (collection === mediaCollectionsMap.cover_pictures) {
      dispatch({
        type: 'UPDATE_COVER_PICTURE',
        coverPicture: picture,
      })
    } else if (collection === mediaCollectionsMap.full_exterior) {
      dispatch({
        type: 'UPDATE_FULL_EXTERIOR',
        fullExterior: picture,
      })
    } else if (collection === mediaCollectionsMap.full_interior) {
      dispatch({
        type: 'UPDATE_FULL_INTERIOR',
        fullInterior: picture,
      })
    } else if (collection === mediaCollectionsMap.hmi) {
      dispatch({
        type: 'UPDATE_HMI',
        hmi: picture,
      })
    } else if (collection === mediaCollectionsMap.cargo) {
      dispatch({
        type: 'UPDATE_CARGO',
        cargo: picture,
      })
    }
  }
}

export function setScoringPropValue(scoringId, slug, type, label, value, isAggregated) {
  return async dispatch => {
    const scoringProps = await updateScoringPropValue(scoringId, slug, type, label, value, isAggregated)
    dispatch({
      type: 'UPDATE_SCORING_PROPS',
      scoringProps,
    })
  }
}

export function setBrandFocused(isBrandFocused) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_BRAND_FOCUSED',
      isBrandFocused,
    })
  }
}

export function setShowDetailedSpecifications(showDetailedSpecifications) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_SHOW_DETAILED_SPECIFICATIONS',
      showDetailedSpecifications,
    })
  }
}

export function doCalculation(scoring, nodeDefinitions, nodes) {
  return async dispatch => {
    const duplicatedNodes = { ...nodes }
    await calculate(scoring, nodeDefinitions, duplicatedNodes)
    if (await updateScoringNodesIntoLocalStorage(scoring.id, duplicatedNodes)) {
      dispatch({
        type: 'UPDATE_NODES',
        nodes: duplicatedNodes,
      })
    }
  }
}

export function doCalculationAndSync(scoring, nodeDefinitions, nodes) {
  return async dispatch => {
    const clonedNodes = { ...nodes }
    await calculate(scoring, nodeDefinitions, clonedNodes)
    if (await updateScoringNodesIntoLocalStorage(scoring.id, clonedNodes)) {
      dispatch({
        type: 'UPDATE_NODES',
        nodes: clonedNodes,
      })
    }

    syncQueue.add(SYNC_ACTION_UPDATE_SCORING_TREE, {
      product_id: scoring.id,
      nodes,
    })
  }
}

export function doCalculationOnNewScoring(scoring) {
  return async dispatch => {
    await calculate(scoring, scoring.nodeDefinitions, scoring.nodes)
    await updateScoringNodesIntoLocalStorage(scoring.newScoringId, scoring.nodes)
    dispatch({
      type: 'UPDATE_NODES',
      nodes: scoring.nodes,
    })
  }
}

export function updateScoringDuplicateProps(
  popupVisible,
  sourceScoringId,
  sourceProductName,
  mainPopupDisabled = false
) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_SCORING_DUPLICATE_PROPS',
      popupVisible,
      sourceScoringId,
      sourceProductName,
      mainPopupDisabled,
    })
  }
}

export function setProjectMode(scoringId, projectMode) {
  return async dispatch => {
    await updateProjectModeForScoring(scoringId, projectMode)
    dispatch({
      type: 'UPDATE_PROJECT_MODE',
      projectMode,
    })
  }
}

// update optional attribute
