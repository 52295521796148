import React from 'react'
import { connect } from 'react-redux'
import scoringPropsTypeMap from '../helper/scoringPropsTypeMap'
import AddPhotoBig from '../../../../assets/svg/add-photo-big.svg'
import Trash from '../../../../assets/svg/trash.svg'

import * as actionCreators from '../../../../store/actions'

const classNames = require('classnames')

export class ScoringTextOrImageProp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      textValue: false,
      imageValue: false,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState !== this.state
  }

  handleInputChange = () => {
    const { slug } = this.props

    const mediaFiles = document.getElementById(`${slug}-input`).files
    if (mediaFiles.length > 0) {
      const file = mediaFiles[0]
      const img = document.createElement('img')
      const reader = new FileReader()
      reader.onload = function(e) {
        img.src = e.target.result

        img.onload = function() {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)

          const MAX_WIDTH = document.querySelector('.ns_text_or_image_prop').offsetWidth
          // const MAX_HEIGHT = 233
          let { width } = img
          let { height } = img

          /*
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
            */

          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }

          canvas.width = width
          canvas.height = height

          ctx.drawImage(img, 0, 0, width, height)

          const imageValue = canvas.toDataURL('image/png')
          document.getElementById(`${slug}-hidden-img`).src = imageValue
        }
      }
      reader.readAsDataURL(file)
    }
  }

  handleImageLoaded = async () => {
    const { slug } = this.props
    const { textValue } = this.state
    const { src } = document.getElementById(`${slug}-hidden-img`)
    this.setState({ imageValue: src })
    await this.createPropValueObject(textValue, src)
  }

  handleTextValueChanged = async textValue => {
    const { imageValue } = this.state
    await this.createPropValueObject(textValue, imageValue)
  }

  handleRemoveMediaButton = e => {
    e.stopPropagation()
    const { textValue } = this.state
    const imageValue = ''
    this.setState({ imageValue })
    this.createPropValueObject(textValue, imageValue)
  }

  createPropValueObject = async (textValue, imageValue) => {
    const { environment, scoring, slug, type, label, props, setScoringPropValue } = this.props

    const { locales } = environment
    const bodyText = {}
    const bodyImage = {}
    locales.forEach(locale => {
      const { code } = locale
      bodyText[code] = textValue
      bodyImage[code] = imageValue
    })

    const value = [
      {
        slug: props[0].slug,
        value: {
          body: bodyText,
        },
      },
      {
        slug: props[1].slug,
        value: {
          body: bodyImage,
        },
      },
    ]
    await setScoringPropValue(scoring.newScoringId, slug, type, label, value, true)
  }

  handleClickMediaButton = e => {
    e.stopPropagation()
    const { slug } = this.props

    const inputFile = document.getElementById(`${slug}-input`)
    inputFile.value = ''
    inputFile.click()
  }

  componentDidMount = async () => {
    const { scoring, environment, slug } = this.props
    const textOrImageValueProp = scoring.scoringProps.filter(x => x.slug === slug)

    const textValue =
      textOrImageValueProp.length > 0 && textOrImageValueProp[0].props[0] !== undefined
        ? textOrImageValueProp[0].props[0].value.body[environment.defaultLang]
        : ''

    const imageValue =
      textOrImageValueProp.length > 0 && textOrImageValueProp[0].props[1] !== undefined
        ? textOrImageValueProp[0].props[1].value.body[environment.defaultLang]
        : ''

    this.setState({ initialized: true, textValue, imageValue })
  }

  render() {
    const { environment, texts, mandatory, label, slug, type } = this.props

    const { initialized, textValue, imageValue } = this.state
    const inputType = type === scoringPropsTypeMap.number ? 'number' : 'text'

    return (
      <>
        {initialized && (
          <>
            <div className={classNames({ ns_prop_label: true })}>{label[environment.lang]}</div>
            <div
              className={classNames({
                ns_prop: true,
                ns_text_or_image_prop: true,
              })}
            >
              <input
                className={`ns_text_input ns_text_input_${slug}`}
                type={inputType}
                placeholder={mandatory ? texts.mandatory : '--'}
                value={textValue}
                onChange={e => {
                  this.setState({ textValue: e.target.value })
                }}
                onBlur={async e => {
                  await this.handleTextValueChanged(e.target.value)
                }}
              />
              <div className={classNames({ ns_text_or_image_label: true })}>{texts.or}</div>
              <div
                className={classNames({
                  ns_text_or_image_btn: true,
                  lg_btn: true,
                  secondary_btn_type_2: true,
                  theme_svg: true,
                })}
                role="button"
                tabIndex={-1}
                onClick={e => {
                  this.handleClickMediaButton(e)
                }}
                onKeyPress={e => {
                  this.handleClickMediaButton(e)
                }}
              >
                <AddPhotoBig />
                <input
                  type="file"
                  accept="image/*"
                  id={`${slug}-input`}
                  name={`${slug}-input`}
                  style={{ display: 'none' }}
                  onChange={() => {
                    this.handleInputChange()
                  }}
                />
                <img
                  id={`${slug}-hidden-img`}
                  alt={`${slug}-hidden-img-container`}
                  style={{ display: 'none' }}
                  onLoad={this.handleImageLoaded.bind(this)}
                />
              </div>
              {imageValue && (
                <div className={`ns_text_or_image_img_container ns_text_or_image_img_${slug}`}>
                  <img src={imageValue} alt={slug} />
                  <div
                    className={classNames({
                      ns_text_or_image_img_trash_btn: true,
                    })}
                    role="button"
                    tabIndex={0}
                    onClick={e => {
                      this.handleRemoveMediaButton(e)
                    }}
                    onKeyPress={e => {
                      this.handleRemoveMediaButton(e)
                    }}
                  >
                    <Trash />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    status: state.status,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringTextOrImageProp)
