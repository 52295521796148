/* eslint-disable no-unused-vars */
import _ from 'lodash'
import slugify from 'slugify'
import { doCalculationForAnalytics } from '../../specializations'
import { reportAnalyticsMappingOverviewQuery } from '../elastic_search'
import nodeDefinitionTypeMap from '../../../src/components/scoring_tree/helper/nodeDefinitionTypeMap'

/**
 * Calculate the performances by criterion families in the subtree of rootNodeDefinition.
 * Consider that "Criterion family" is called "Criterion type" by the API and the Database.
 *
 * @param {object} rootNodeDefinition
 * @param {object} nodeDefinitions
 * @param {object|array} productNodes
 * @returns object
 */
export async function performancePerCriterionFamily(rootNodeDefinition, nodeDefinitions, productNodes) {
  // Default locale to get the right labels
  const locale = 'en'

  // Key nodes by node definition id
  // DEEP COPY is important in order to not override the real nodes scores
  const nodes = _.keyBy(_.cloneDeep(productNodes), 'node_definition_id')

  // Get criterion types
  const types = {}
  _.each(nodeDefinitions, nodeDefinition => {
    if (nodeDefinition.criterion_template) {
      const { type } = nodeDefinition.criterion_template
      types[type.slug] = type
    }
  })

  // Get performances
  const promises = _.map(types, type => {
    return new Promise((resolve, reject) => {
      // Filter the scoring tree in order to keep only the proper criteria
      const filteredNodes = _.filter(nodes, node => {
        const nodeDefinition = nodeDefinitions[node.node_definition_id]
        if (nodeDefinition.bonus_demerit === true) {
          return false
        }

        if (nodeDefinition.criterion_template && nodeDefinition.criterion_template.type.slug !== type.slug) {
          return false
        }

        return true
      })

      doCalculationForAnalytics(nodeDefinitions, _.keyBy(filteredNodes, 'node_definition_id'))
        .then(response => {
          const calculatedNodes = _.keyBy(response, 'node_definition_id')
          const calculatedRoot = calculatedNodes[rootNodeDefinition.id]
          resolve({
            label: type.label[locale],
            family: type.slug,
            // Odd way javascript uses to round a number
            performance: Math.round((calculatedRoot.percentage + Number.EPSILON) * 100) / 100,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  })

  const result = await Promise.all(promises)
  return result
}

/**
 * Calculate the performances by criterion types in the subtree of rootNodeDefinition.
 * Consider that "Criterion type" is called "Criterion template" by the API and the Database.
 *
 * @param {object} rootNodeDefinition
 * @param {object} nodeDefinitions
 * @param {object|array} productNodes
 * @returns object
 */
export async function performancePerCriterionType(rootNodeDefinition, nodeDefinitions, productNodes) {
  // Default locale to get the right labels
  const locale = 'en'

  // Key nodes by node definition id
  // DEEP COPY is important in order to not override the real nodes scores
  const nodes = _.keyBy(_.cloneDeep(productNodes), 'node_definition_id')

  // Get criterion templates
  const templates = {}
  _.each(nodeDefinitions, nodeDefinition => {
    if (nodeDefinition.criterion_template) {
      const { criterion_template: template } = nodeDefinition
      templates[template.slug] = template
    }
  })

  // Get performances
  const promises = _.map(templates, template => {
    return new Promise((resolve, reject) => {
      // Filter the scoring tree in order to keep only the proper criteria
      const filteredNodes = _.filter(nodes, node => {
        const nodeDefinition = nodeDefinitions[node.node_definition_id]
        if (nodeDefinition.bonus_demerit === true) {
          return false
        }

        if (nodeDefinition.criterion_template && nodeDefinition.criterion_template.slug !== template.slug) {
          return false
        }

        return true
      })

      doCalculationForAnalytics(nodeDefinitions, _.keyBy(filteredNodes, 'node_definition_id'))
        .then(response => {
          const calculatedNodes = _.keyBy(response, 'node_definition_id')
          const calculatedRoot = calculatedNodes[rootNodeDefinition.id]
          resolve({
            label: template.name[locale],
            type: template.slug,
            short_label: template.short_label[locale],
            // Odd way javascript uses to round a number
            performance: Math.round((calculatedRoot.percentage + Number.EPSILON) * 100) / 100,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  })

  const result = await Promise.all(promises)
  return result
}

/**
 * Export all the values from the score panel of a specific product, a benchmark product
 * and all the related products that share the same main segment with the main product.
 *
 * @param {object} scoring
 * @param {object} benchmarkScoring
 * @returns array
 */
export async function mappingOverview(scoring, benchmarkScoring) {
  // Default locale to get the right labels
  const locale = 'en'
  const statusesToExclude = ['tests_training', 'car_projects']

  // Result is an elastic search formatted data
  const searchResult =
    benchmarkScoring !== undefined
      ? await reportAnalyticsMappingOverviewQuery(scoring.template_id, statusesToExclude, benchmarkScoring.id)
      : await reportAnalyticsMappingOverviewQuery(scoring.template_id, statusesToExclude)
  const { hits } = searchResult.data.hits

  const result = []

  // Parse the elastic search hits
  _.each(hits, hit => {
    const { _source: data } = hit
    const { props, score_panel: scorePanel } = data
    const mainSegment = props['main-segment'] !== undefined ? props['main-segment'].value : null

    const scoringResult = {
      id: data.id,
      model: props.model.value,
      name: `${props.brand.value} ${props.model.value}`,
      main_segment: mainSegment,
      values: {},
    }

    _.each(scorePanel, score => {
      const { values } = score
      const familyName = score.name
      scoringResult.values[familyName] = {}

      _.each(values, value => {
        const perimeterName = value.name[locale]
        // Odd way javascript uses to round a number
        scoringResult.values[familyName][perimeterName] = Math.round((value.value + Number.EPSILON) * 100) / 100
      })
    })

    result.push(scoringResult)
  })

  return result
}

/**
 * Return the `limit` best and the `limit` worst nodes of a scoring which are children of `rootNodeDefinition`
 *
 * @param {object} rootNodeDefinition
 * @param {object} nodeDefinitions
 * @param {object|array} productNodes
 * @param {int} limit
 */
export async function getTopLowestNodes(rootNodeDefinition, nodeDefinitions, productNodes, limit = 5) {
  // Key nodes by node definition id
  const nodes = _.keyBy(productNodes, 'node_definition_id')

  // Get the children node definitions items
  const itemDefinitions = _.filter(nodeDefinitions, nodeDefinition => {
    return (
      rootNodeDefinition._left < nodeDefinition._left &&
      nodeDefinition._right < rootNodeDefinition._right &&
      nodeDefinition.type === nodeDefinitionTypeMap.item &&
      nodeDefinition.bonus_demerit !== true
    )
  })

  // Get the related product nodes
  const items = []
  _.each(itemDefinitions, itemDefinition => {
    // Get all the children criteria of the current item
    const criterionDefinitions = _.filter(nodeDefinitions, nodeDefinition => {
      return (
        itemDefinition._left < nodeDefinition._left &&
        nodeDefinition._right < itemDefinition._right &&
        nodeDefinition.type === nodeDefinitionTypeMap.criterion &&
        nodeDefinition.bonus_demerit !== true
      )
    })

    // Check if the current item is scored, meaning that at least one of the criterion children is scored.
    let unscored = true
    // eslint-disable-next-line consistent-return
    _.each(criterionDefinitions, criterionDefinition => {
      const node = nodes[criterionDefinition.id]

      if (node && node.is_enabled && node.is_default !== true) {
        unscored = false

        // Exit from the iteration
        return false
      }
    })

    const node = nodes[itemDefinition.id]
    if (node && node.is_enabled === true && unscored === false) {
      items.push(node)
    }
  })

  // Filter the worst items (the items with percentage < 50%)
  let lowest = _.filter(items, item => {
    return item.percentage < 50
  })

  // Sort items by percentage (ascending: the first is the worst)
  lowest = _.sortBy(lowest, 'percentage')

  // Filter the best items (the items with percentage >= 50%)
  let top = _.filter(items, item => {
    return item.percentage >= 50
  })

  // Sort items by percentage (descending: the first is the best)
  top = _.reverse(_.sortBy(top, 'percentage'))

  const result = {
    top: [],
    lowest: [],
  }

  for (let i = 0; i < limit; i += 1) {
    const currentLowest = lowest[i]
    const currentTop = top[i]

    if (currentLowest) {
      result.lowest.push({
        id: currentLowest.id,
        node_definition_id: currentLowest.node_definition_id,
      })
    }

    if (currentTop) {
      result.top.push({
        id: currentTop.id,
        node_definition_id: currentTop.node_definition_id,
      })
    }
  }

  return result
}
