const mediaCollectionsMap = {
  cover_pictures: 'cover_pictures',
  scoring_pictures: 'scoring_pictures',
  full_exterior: 'full_exterior',
  full_interior: 'full_interior',
  hmi: 'hmi',
  cargo: 'cargo',
  node_pictures: 'node_pictures',
  forecast_node_pictures: 'forecast_node_pictures',
}

export default mediaCollectionsMap
