/*-----------------------------------------------------*/
// Report Global Conf
// TO DO:
// add default limit list:50
// add default nodes obj
// ...
/*-----------------------------------------------------*/

const reportConf = {
  api: 'autoreport',
  slug: 'report',

  // Summary Conf
  summaryPageCount: 11,
  detailedBasePageCount: 2,
  commentMaxChar: 480,
  evaluationMaxChar: 680,

  // Summary Index Conf
  reportSummaryIndex: [
    {
      slug: 'exterior',
      picture: 'fullExterior',
      score: 0,
      top_lowest: true,
    },
    {
      slug: 'interior',
      picture: 'fullInterior',
      score: 1,
      top_lowest: true,
    },
    {
      slug: 'digital_achievement',
      picture: 'hmi',
      score: 3,
      top_lowest: false,
    },
    {
      slug: 'cargo',
      picture: 'cargo',
      family: 'cargo interior',
      score: 2,
      top_lowest: true,
      hide_unscored: true,
    },
  ],

  // Top Lowest Conf
  topLowestNum: 6,
  topLowestColumnLimit: 50,

  // Detail Conf
  detailColumnLimit: 50,
}

export default reportConf
