import _ from 'lodash'
import { getItemsFatherMediaFromLocalStorage } from '../../../../va-corejs-v3/actions/media'
import {
  createAutoReport,
  // deleteAutoReport,
  fetchAutoReports,
  updateAutoReport,
} from '../../../../va-corejs-v3/actions/report'
import {
  performancePerCriterionFamily,
  performancePerCriterionType,
  mappingOverview,
  getTopLowestNodes,
} from '../../../../va-corejs-v3/actions/report/analytics'
import { downloadScoringFromServerAtFly } from '../../../../va-corejs-v3/actions/scoring'
import mediaCollectionsMap from '../../../../va-corejs-v3/utils/mediaCollectionsMap'
import reportConf from '../../../components/report/helper/reportConf'
import reportRankingTypeMap from '../../../components/report/helper/reportRankingTypeMap'
import { calcExtraScorePanel, getNodesRankingInFamily } from '../../../components/report/_parts/utils'
import nodeDefinitionTypeMap from '../../../components/scoring_tree/helper/nodeDefinitionTypeMap'
import ScoringModeMap from '../../../components/scoring_tree/helper/scoringModeMap'

/*-----------------------------------------------------*/
// HELPERS
/*-----------------------------------------------------*/
export function nodesObjToArray(nodesObj) {
  let nodesArray = []
  if (nodesObj) nodesArray = Object.values(nodesObj)
  return nodesArray
}

export function nodesArrayToObj(nodesArray) {
  const nodesObj = {}
  /* Normalize from server array Nodes to Obj */
  if (nodesArray && Array.isArray(nodesArray))
    nodesArray.forEach(node => {
      nodesObj[node.node_definition_id] = node
    })

  return nodesObj
}

/*-----------------------------------------------------*/
// Server Helpers
/*-----------------------------------------------------*/

export async function fetchAutoReportsNormalized(userId, productId) {
  const reportResults = await fetchAutoReports(userId, productId)

  if (!reportResults.length) return false

  const updateReportObjOut = { ...reportResults[0] } // Only the first result, no versioning

  const nodesObj = nodesArrayToObj(updateReportObjOut.nodes)
  updateReportObjOut.nodes = nodesObj

  return updateReportObjOut
}

export async function createAutoReportNormalized(reportObj) {
  const updateReportObjIn = { ...reportObj }

  const nodesArray = nodesObjToArray(updateReportObjIn.nodes)
  updateReportObjIn.nodes = nodesArray

  const updateReportObjOut = await createAutoReport(updateReportObjIn)

  const nodesObj = nodesArrayToObj(updateReportObjOut.nodes)
  updateReportObjOut.nodes = nodesObj

  return updateReportObjOut
}

export function updateAutoReportNormalized(reportId, reportObj) {
  const updateReportObjIn = { ...reportObj }

  if (updateReportObjIn.nodes) {
    const nodesArray = nodesObjToArray(updateReportObjIn.nodes)
    updateReportObjIn.nodes = nodesArray
  }

  updateAutoReport(reportId, updateReportObjIn)
}

/*-----------------------------------------------------*/
// INIT REPORT VIEW
/*-----------------------------------------------------*/

export function initReportDetailSection(scoringTree) {
  const { nodeDefsObj } = scoringTree

  const firstIndexNode =
    nodeDefsObj[Object.keys(nodeDefsObj).filter(nodeId => scoringTree.scoring.nodes[nodeId].is_enabled)[0]]

  return async dispatch => {
    dispatch({
      type: 'UPDATE_REPORT_DETAIL_SECTION_SELECTED',
      reportDetailSection: firstIndexNode,
    })
  }
}

export function initReportMedia(scoringTree) {
  const { nodeDefsObj } = scoringTree
  let mediaList = []

  return async dispatch => {
    const reportSubfamilies = Object.keys(nodeDefsObj).filter(
      nodeId => nodeDefsObj[nodeId].type === 'subfamily' && scoringTree.scoring.nodes[nodeId].is_enabled
    )

    await Promise.all(
      reportSubfamilies.map(async subfamilyId => {
        const media = await getItemsFatherMediaFromLocalStorage(scoringTree.scoring.id, subfamilyId)

        // filter only benchmark score media
        media.filter(m => m.collection === mediaCollectionsMap.node_pictures)

        if (media) mediaList = [...mediaList, ...media]
      })
    )
    mediaList = mediaList.filter(media => media.file_type.startsWith('image'))

    dispatch({
      type: 'UPDATE_REPORT_MEDIA',
      media: mediaList,
    })
  }
}

/*-----------------------------------------------------*/
/* ADD/INIT REPORT DATA
/*  Fetch or Create 
/*  ReportObj (id, user_id, product_id, benchmark_id, texts, nodes)
/*-----------------------------------------------------*/

/*-----------------------------------------------------*/
// Default Top Lowest
/*-----------------------------------------------------*/

export function reportDefaultTopLowest(scoringTree, topLowest) {
  let updatedTopLowest = {}

  // update topLowest Obj
  if (topLowest) updatedTopLowest = { ...topLowest }

  // foreach summary section get top lowest
  reportConf.reportSummaryIndex.forEach(summarySection => {
    if (summarySection.top_lowest) {
      // init topLowest Obj if not exists
      if (!updatedTopLowest[summarySection.slug]) updatedTopLowest[summarySection.slug] = {}

      if (typeof updatedTopLowest[summarySection.slug].is_default === 'undefined')
        updatedTopLowest[summarySection.slug].is_default = true

      if (typeof updatedTopLowest[summarySection.slug].saved === 'undefined')
        updatedTopLowest[summarySection.slug].saved = false

      // update new default top lowest values if are not edited by user
      if (updatedTopLowest[summarySection.slug].is_default) {
        const topLowestMaxNum = reportConf && reportConf.topLowestNum ? reportConf.topLowestNum : 6

        const topLowestColumnLimit =
          reportConf && reportConf.topLowestColumnLimit ? reportConf.topLowestColumnLimit : 50

        const topInFamily = getNodesRankingInFamily(
          scoringTree,
          summarySection.slug,
          topLowestColumnLimit,
          reportRankingTypeMap.best
        ).slice(0, topLowestMaxNum)

        const lowestInFamily = getNodesRankingInFamily(
          scoringTree,
          summarySection.slug,
          topLowestColumnLimit,
          reportRankingTypeMap.worst
        ).slice(0, topLowestMaxNum)

        updatedTopLowest[summarySection.slug].top_selected_ids = topInFamily
        updatedTopLowest[summarySection.slug].lowest_selected_ids = lowestInFamily
      }
    }
  })

  return updatedTopLowest
}

/*-----------------------------------------------------*/
// Init Node
/*-----------------------------------------------------*/

export function reportAddNode(scoringTree, nodeDefId, reportObj) {
  const updatedReportObj = { ...reportObj }

  const currNode = {
    id: scoringTree.scoring.nodes[nodeDefId].id,
    node_definition_id: nodeDefId,
    selected: 'disabled',
    media_selected_ids: [],
    text: '',
  }
  if (!updatedReportObj.nodes) updatedReportObj.nodes = {}

  updatedReportObj.nodes[nodeDefId] = currNode

  return updatedReportObj
}

/*-----------------------------------------------------*/
// New Report
/*-----------------------------------------------------*/

export async function createReport(scoringTree, userId) {
  const { nodeDefsObj } = scoringTree
  let newReportObj = {}

  // Init Obj
  newReportObj.product_id = scoringTree.scoring.id
  newReportObj.user_id = userId
  newReportObj.texts = {}
  newReportObj.client_url = `${process.env.siteUrl}/scoring?id=${scoringTree.scoring.id}&view=${ScoringModeMap.clipboard}`

  // Populate nodes with all scoring nodes
  await Promise.all(
    Object.keys(nodeDefsObj)
      .filter(nodeDefId => scoringTree.scoring.nodes[nodeDefId].is_enabled)
      .map(async nodeDefId => {
        newReportObj = reportAddNode(scoringTree, nodeDefId, newReportObj)
      })
  )

  // add default top lowest
  newReportObj.top_lowest = reportDefaultTopLowest(scoringTree)

  // then create Obj in DB
  newReportObj = await createAutoReportNormalized(newReportObj)

  return newReportObj
}

/*-----------------------------------------------------*/
// Init Enabled Node on update
/*-----------------------------------------------------*/
export function reportUpdateEnabledNode(scoringTree, reportObj) {
  const { nodeDefsObj } = scoringTree

  let updatedReportObj = { ...reportObj }

  Object.keys(nodeDefsObj).forEach(nodeDefId => {
    if (scoringTree.scoring.nodes[nodeDefId].is_enabled && !updatedReportObj.nodes[nodeDefId]) {
      updatedReportObj = reportAddNode(scoringTree, nodeDefId, updatedReportObj)
    }
    if (!scoringTree.scoring.nodes[nodeDefId].is_enabled && updatedReportObj.nodes[nodeDefId]) {
      delete updatedReportObj.nodes[nodeDefId]
    }
  })

  return updatedReportObj
}

/*-----------------------------------------------------*/
// Init Report Object Core
/*-----------------------------------------------------*/

export async function initReportFromServer(scoringTree, userId) {
  let reportObj = {}

  const reportResult = await fetchAutoReportsNormalized(userId, scoringTree.scoring.id)

  // If report not found create new repot object
  if (!reportResult) reportObj = await createReport(scoringTree, userId)
  else {
    // Load status from API response
    // for now report is unique for product and user
    reportObj = { ...reportResult }

    // init update enabled node
    reportObj = reportUpdateEnabledNode(scoringTree, reportObj)

    // init update default top lowest
    reportObj.top_lowest = reportDefaultTopLowest(scoringTree, reportObj.top_lowest)

    updateAutoReportNormalized(reportObj.id, reportObj)
  }

  return reportObj
}

/*-----------------------------------------------------*/
// Init Report
/*-----------------------------------------------------*/

export function initReportData(scoringTree, userId) {
  let reportObj = {}

  return async dispatch => {
    reportObj = await initReportFromServer(scoringTree, userId)

    // update redux state
    dispatch({
      type: 'UPDATE_REPORT',
      reportObj,
    })
  }
}

/*-----------------------------------------------------*/
// UPDATE REPORT VIEW DATA
/*-----------------------------------------------------*/

export function updateReportPage(reportPage) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_REPORT_PAGE_SELECTED',
      reportPage,
    })
  }
}

export function updateReportDetailSection(reportDetailSection) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_REPORT_DETAIL_SECTION_SELECTED',
      reportDetailSection,
    })
  }
}

export function updateReportSidebarVisibility(reportSidebarVisible) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_REPORT_SIDEBAR_VISIBILITY',
      reportSidebarVisible,
    })
  }
}

export function updateReportOpened(reportOpened) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_REPORT_OPENED',
      reportOpened,
    })
  }
}

export function updateReportFiltersList(reportFiltersList) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_REPORT_FILTERS_LIST',
      reportFiltersList,
    })
  }
}

export function updateReportFiltersGlobal(reportFiltersGlobal) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_REPORT_FILTERS_GLOBAL',
      reportFiltersGlobal,
    })
  }
}

export function updateReportOrder(reportOrder) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_REPORT_ORDER',
      reportOrder,
    })
  }
}

export function updateReportMessages(reportMessages) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_REPORT_MESSAGES',
      reportMessages,
    })
  }
}

/*-----------------------------------------------------*/
// UPDATE REPORT NODES, TEXTS, TOP/LOWEST
/*-----------------------------------------------------*/

// Update Texts
export function updateReportTexts(text, textId, reportTexts, reportId) {
  if (!reportTexts) return false

  const updateText = text || ''

  // Obj update
  const updatedReportObj = {}
  updatedReportObj.texts = { ...reportTexts }

  if (textId) updatedReportObj.texts[textId] = updateText

  // Update Data
  return async dispatch => {
    // Update DB
    if (reportId) updateAutoReportNormalized(reportId, updatedReportObj)

    // Update Rredux
    dispatch({
      type: 'UPDATE_REPORT',
      reportObj: updatedReportObj,
    })
  }
}

// Update Nodes
export function updateReportScoreNodes(nodeList, reportId) {
  if (!nodeList) return false

  // Obj update
  const updatedReportObj = {}
  updatedReportObj.nodes = nodeList

  // Update Data
  return async dispatch => {
    // Update DB
    if (reportId) updateAutoReportNormalized(reportId, updatedReportObj)

    // Update Rredux
    dispatch({
      type: 'UPDATE_REPORT',
      reportObj: updatedReportObj,
    })
  }
}

// Update Benchmark
export function updateReportBenchmark(benchmarkId, reportId) {
  if (!benchmarkId) benchmarkId = null

  // Obj update
  const updatedReportObj = {}
  updatedReportObj.benchmark_id = benchmarkId

  // Update Data
  return async dispatch => {
    // Update DB
    if (reportId) updateAutoReportNormalized(reportId, updatedReportObj)

    // Update Rredux
    dispatch({
      type: 'UPDATE_REPORT',
      reportObj: updatedReportObj,
    })
  }
}

// Update Top Lowest
export function updateReportTopLowest(topLowest, reportId) {
  if (!topLowest) return false

  // Obj update
  const updatedReportObj = {}
  updatedReportObj.top_lowest = topLowest

  // Update Data
  return async dispatch => {
    // Update DB
    if (reportId) updateAutoReportNormalized(reportId, updatedReportObj)

    // Update Rredux
    dispatch({
      type: 'UPDATE_REPORT',
      reportObj: updatedReportObj,
    })
  }
}

// Update confidential
export function updateReportConfidential(confidential, reportId) {
  // Obj update
  const updatedReportObj = {}
  updatedReportObj.confidential = confidential

  return async dispatch => {
    // Update DB
    if (reportId) updateAutoReportNormalized(reportId, updatedReportObj)

    // Update Rredux
    dispatch({
      type: 'UPDATE_REPORT',
      reportObj: updatedReportObj,
    })
  }
}

// Update report type
export function updateReportType(reportId) {
  // Obj update
  const updatedReportObj = {}

  return async (dispatch, getState) => {
    const { report } = getState()

    switch (report.reportPage) {
      case 2:
        updatedReportObj.type = 'summary'
        break
      default:
        updatedReportObj.type = 'detailed'
    }

    // Update DB
    if (reportId) updateAutoReportNormalized(reportId, updatedReportObj)

    // Update Rredux
    dispatch({
      type: 'UPDATE_REPORT',
      reportObj: updatedReportObj,
    })
  }
}

// Update analytics
async function performancePerCriterionFamilyAnalytic(nodeDefinitions, scoring) {
  // Get only the family nodes
  const families = _.filter(nodeDefinitions, nodeDefinition => {
    return nodeDefinition.type === nodeDefinitionTypeMap.family
  })

  const promises = []

  _.each(families, family => {
    // performance_per_criterion_family promise
    promises.push(
      new Promise((resolve, reject) => {
        performancePerCriterionFamily(family, nodeDefinitions, scoring.nodes)
          .then(result => {
            resolve({
              label: family.trailer ? family.trailer.en : '',
              data: result,
            })
          })
          .catch(error => {
            reject(error)
          })
      })
    )
  })

  // Resolve all promises
  const result = await Promise.all(promises)

  return result
}

async function performancePerCriterionTypeAnalytic(nodeDefinitions, scoring) {
  // Get only the family nodes
  const families = _.filter(nodeDefinitions, nodeDefinition => {
    return nodeDefinition.type === nodeDefinitionTypeMap.family
  })

  const promises = []

  _.each(families, family => {
    // performance_per_criterion_type promise for family nodes
    promises.push(
      new Promise((resolve, reject) => {
        performancePerCriterionType(family, nodeDefinitions, scoring.nodes)
          .then(result => {
            resolve({
              label: family.trailer ? family.trailer.en : '',
              data: result,
            })
          })
          .catch(error => {
            reject(error)
          })
      })
    )
  })

  // Resolve all promises
  const result = await Promise.all(promises)

  return result
}

async function subfamiliesDataAnalytic(nodeDefinitions, scoring, reportObject) {
  // Get only the subfamily nodes
  const subfamilies = _.filter(nodeDefinitions, nodeDefinition => {
    return nodeDefinition.type === nodeDefinitionTypeMap.subfamily && nodeDefinition.bonus_demerit !== true
  })

  const promises = []

  _.each(subfamilies, subfamily => {
    promises.push(
      new Promise((resolve, reject) => {
        const node = _.find(scoring.nodes, _node => {
          return _node.node_definition_id === subfamily.id
        })

        const reportNode = _.find(reportObject.nodes, _node => {
          return _node.node_definition_id === subfamily.id
        })

        const object = {
          label: subfamily.trailer ? subfamily.trailer.en : '',
          id: node.id,
          node_definition_id: subfamily.id,
          general_comment: reportNode ? reportNode.text : null,
        }

        Promise.all([
          performancePerCriterionType(subfamily, nodeDefinitions, scoring.nodes),
          getTopLowestNodes(subfamily, nodeDefinitions, scoring.nodes, 5),
        ])
          .then(response => {
            // eslint-disable-next-line prefer-destructuring
            object.performance_per_criterion_type = response[0]
            // eslint-disable-next-line prefer-destructuring
            object.top_lowest = response[1]
            resolve(object)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    )
  })

  const result = await Promise.all(promises)

  return result
}

function scoresAnalytic(nodeDefinitions, scoring) {
  // Build the score panel
  const { score_panel: scorePanel } = scoring
  let newScorePanel = _.cloneDeep(scorePanel)

  // TO DO: should be replaced with _.find(scoring.score_panel, panel => panel.name === 'Digital')
  // In this way "calcExtraScorePanel" function can be removed
  const digitalScores = calcExtraScorePanel(nodeDefinitions, scoring, 'digital_achievement')

  newScorePanel.push(digitalScores)
  newScorePanel = _.map(newScorePanel, element => {
    element.values = _.map(element.values, value => {
      value.name = value.name.en
      return value
    })
    return element
  })

  return newScorePanel
}

async function primaryScoringReportAnalytics(nodeDefinitions, scoring, reportObject, benchmarkScoring) {
  return {
    scores: scoresAnalytic(nodeDefinitions, scoring),
    performance_per_criterion_family: await performancePerCriterionFamilyAnalytic(nodeDefinitions, scoring),
    performance_per_criterion_type: await performancePerCriterionTypeAnalytic(nodeDefinitions, scoring),
    mapping_overview: await mappingOverview(scoring, benchmarkScoring),
    subfamilies_data: await subfamiliesDataAnalytic(nodeDefinitions, scoring, reportObject),
  }
}

async function benchmarkScoringReportAnalytics(nodeDefinitions, scoring) {
  return {
    scores: scoresAnalytic(nodeDefinitions, scoring),
    performance_per_criterion_family: await performancePerCriterionFamilyAnalytic(nodeDefinitions, scoring),
    performance_per_criterion_type: await performancePerCriterionTypeAnalytic(nodeDefinitions, scoring),
  }
}

export function generateReportAnalytics(reportId) {
  return async (dispatch, getState) => {
    const { scoringTree, report } = getState()
    const { nodeDefsObj: nodeDefinitions, scoring } = scoringTree

    let analytics = {}

    if (report.reportObj.benchmark_id) {
      const promises = []

      // Download benchmark product
      const benchmarkScoring = await downloadScoringFromServerAtFly(report.reportObj.benchmark_id)

      // Push primary scoring promise
      promises.push(
        new Promise((resolve, reject) => {
          primaryScoringReportAnalytics(nodeDefinitions, scoring, report.reportObj, benchmarkScoring)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      )

      // Push benchmark scoring promise
      promises.push(
        new Promise((resolve, reject) => {
          benchmarkScoringReportAnalytics(nodeDefinitions, benchmarkScoring)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      )

      const result = await Promise.all(promises)

      analytics = {
        primary: result[0],
        benchmark: result[1],
      }
    } else {
      analytics = {
        primary: await primaryScoringReportAnalytics(nodeDefinitions, scoring, report.reportObj),
      }
    }

    // Obj update
    const updatedReportObj = {
      analytics,
    }

    // Update DB
    if (reportId) updateAutoReportNormalized(reportId, updatedReportObj)

    // Update Redux
    dispatch({
      type: 'UPDATE_REPORT',
      reportObj: updatedReportObj,
    })
  }
}
