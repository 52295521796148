/* eslint-disable no-unused-vars */
import _ from 'lodash'
import nodeDefinitionTypeMap from '../../../src/components/scoring_tree/helper/nodeDefinitionTypeMap'
import storage from '../../storage'
import storageMap from '../../storage/storageMap'
import { SYNC_ACTION_CREATE_COMMENT, SYNC_ACTION_DELETE_COMMENT, SYNC_ACTION_UPDATE_COMMENT } from '../../sync/consts'
import syncQueue from '../../sync/syncQueue'
import { array2ObjectGroupedByField, mongoObjectId, isoDate, decreaseToZero } from '../../utils'
import { normalizeComment } from '../../utils/dataNormalizers'
import normalizeDirectionMap from '../../utils/normalizeDirectionMap'

function generateEmptyCommentObj(scoringId, itemsFatherId, defaultLocale, author, nDefId) {
  const commentObj = {
    scoring_id: scoringId,
    items_father_id: itemsFatherId,
    node_definition_id: nDefId,
    text: '',
    locale: defaultLocale,
    feedback: '',
    author,
    editing: false,
  }

  return commentObj
}

export async function generateNewCommentsListStructureForRedux(
  scoringId,
  nodeDefsObj,
  itemsFatherId,
  defaultLocale,
  author
) {
  const newComments = {}

  const addCommentObj = nDefId => {
    const commentObj = generateEmptyCommentObj(scoringId, itemsFatherId, defaultLocale, author, nDefId)
    newComments[nDefId] = commentObj
  }
  const children = nodeDefsObj[itemsFatherId].children_ids
  for (let i = 0; i !== children.length; i += 1) {
    addCommentObj(children[i])
    const grandchildren = nodeDefsObj[children[i]].children_ids

    for (let x = 0; x !== grandchildren.length; x += 1) {
      addCommentObj(grandchildren[x])
    }
  }

  return newComments
}

export async function updateCommentIntoLocalStorageAndReturnUpdatedNewCommentsObj(
  scoringTree,
  newCommentsObj,
  nodeDefId
) {
  const nodeComments = await storage.getAllByScoringIdAndByKey(
    storageMap.comment,
    scoringTree.scoring.id,
    'node_definition_id',
    nodeDefId
  )

  const nodeCommentsForecast = _.filter(nodeComments, item => {
    return item.is_forecast
  })

  const nodeCommentsBenchmark = _.filter(nodeComments, item => {
    return !item.is_forecast
  })

  const comment = { ...newCommentsObj[nodeDefId] }
  const isForcastEnabled =
    scoringTree.scoring.project_mode &&
    scoringTree.scoring.nodes[nodeDefId] &&
    scoringTree.scoring.nodes[nodeDefId].forecast

  // Get the higher order of the comments
  const maxOrderComment = comment.is_forecast
    ? _.maxBy(nodeCommentsForecast, 'order')
    : _.maxBy(nodeCommentsBenchmark, 'order')

  if (comment.id === undefined) {
    comment.id = mongoObjectId()
    comment.created_at = isoDate()
    comment.updated_at = isoDate()
    comment.node_id = scoringTree.scoring.nodes[nodeDefId].node_id
    comment.order = maxOrderComment ? maxOrderComment.order + 1 : 1
  }

  comment.node_id = scoringTree.scoring.nodes[nodeDefId].id
  await storage.update(storageMap.comment, comment)

  // Update items comments count
  const template = await storage.get(storageMap.template, scoringTree.scoring.template_id)
  let tmpNode = template.node_definitions[nodeDefId]

  if (tmpNode.type === nodeDefinitionTypeMap.criterion) {
    scoringTree.scoring.nodes[nodeDefId].commentsCount = !comment.is_forecast
      ? nodeCommentsBenchmark.length + 1
      : nodeCommentsBenchmark.length

    // Update Forecast Criterion Comment
    if (isForcastEnabled)
      scoringTree.scoring.nodes[nodeDefId].forecast.commentsCount = comment.is_forecast
        ? nodeCommentsForecast.length + 1
        : nodeCommentsForecast.length

    tmpNode = template.node_definitions[tmpNode.parent_id]
  }

  // Update item comments count
  scoringTree.scoring.nodes[tmpNode.id].commentsCount = scoringTree.scoring.nodes[tmpNode.id].commentsCount
    ? scoringTree.scoring.nodes[tmpNode.id].commentsCount + 1
    : 1

  const scoringComments = await storage.getAllByScoringIdAndByKey(storageMap.comment, scoringTree.scoring.id)
  scoringTree.scoring.commentsCount = scoringComments.length

  await storage.update(storageMap.scoring, scoringTree.scoring)

  const normalizedComment = normalizeComment({ ...comment }, '', '', normalizeDirectionMap.to_server)
  await syncQueue.add(SYNC_ACTION_CREATE_COMMENT, {
    product_id: comment.scoring_id,
    ...normalizedComment,
  })

  const newComments = { ...newCommentsObj }
  newComments[nodeDefId] = generateEmptyCommentObj(
    comment.scoring_id,
    comment.items_father_id,
    comment.locale,
    comment.author,
    nodeDefId
  )

  return {
    comment,
    newComments,
    scoring: scoringTree.scoring,
  }
}

export async function getAllComments(scoringId) {
  try {
    const response = await storage.getAllByKey('comment', 'scoring_id', scoringId)
    return response
  } catch (err) {
    return false
  }
}

export async function generateCommentsForRedux(scoringId, itemsFatherId) {
  const itemComments = await storage.getAllByScoringIdAndByKey(
    storageMap.comment,
    scoringId,
    'items_father_id',
    itemsFatherId,
    'updated_at'
  )
  const comments = array2ObjectGroupedByField(itemComments, 'node_definition_id')

  return comments
}

export async function removeCommentFromStorage(scoringTree, comment) {
  await syncQueue.add(SYNC_ACTION_DELETE_COMMENT, {
    id: comment.id,
    node_id: comment.node_id,
    product_id: comment.scoring_id,
  })

  await storage.remove(storageMap.comment, comment.id)

  // decrase forecast item criterion comment count
  if (comment.is_forecast) {
    decreaseToZero(scoringTree.scoring.nodes[comment.node_definition_id].forecast, 'commentsCount')
  } else {
    decreaseToZero(scoringTree.scoring.nodes[comment.node_definition_id], 'commentsCount')
  }

  const template = await storage.get(storageMap.template, scoringTree.scoring.template_id)
  const tmpNode = template.node_definitions[comment.node_definition_id]
  if (tmpNode.type === nodeDefinitionTypeMap.criterion) {
    // decrase forecast item comment count
    decreaseToZero(scoringTree.scoring.nodes[tmpNode.parent_id], 'commentsCount')
  }

  decreaseToZero(scoringTree.scoring, 'commentsCount')

  await storage.update(storageMap.scoring, scoringTree.scoring)
  const comments = await generateCommentsForRedux(scoringTree.scoring.id, scoringTree.itemsFather.id)
  return { comments, scoring: scoringTree.scoring }
}

export async function updateCommentEditingValue(commentId, editing) {
  const comment = await storage.get(storageMap.comment, commentId)
  comment.editing = editing

  await storage.update(storageMap.comment, comment)
  const comments = await generateCommentsForRedux(comment.scoring_id, comment.items_father_id)
  return comments
}

export async function updateCommentIntoStorage(comment) {
  await storage.update(storageMap.comment, comment)

  const normalizedComment = normalizeComment({ ...comment }, '', '', normalizeDirectionMap.to_server)
  await syncQueue.add(SYNC_ACTION_UPDATE_COMMENT, {
    product_id: comment.scoring_id,
    ...normalizedComment,
  })

  const comments = await generateCommentsForRedux(comment.scoring_id, comment.items_father_id)
  return comments
}
