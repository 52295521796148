import storage from '../../storage'
import { httpGet } from '../../api'
import storageMap from '../../storage/storageMap'
import { array2Object } from '../../utils'
import apiEndPoints from '../../api/apiEndPoints'
import nodeDefinitionTypeMap from '../../../src/components/scoring_tree/helper/nodeDefinitionTypeMap'

export async function getTemplatesFromServer() {
  const templatesResponse = await httpGet(apiEndPoints.templates, false)

  if (templatesResponse) {
    templatesResponse.data.forEach(async template => {
      // template.node_definitions = array2Object(template.node_definitions, 'id')

      const nodeDefs = template.node_definitions
      const parsedDefs = {}
      let cIdx = 1
      for (let i = 0; i !== nodeDefs.length; i += 1) {
        const def = nodeDefs[i]
        const { id, type } = def
        if (type === nodeDefinitionTypeMap.criterion) {
          def.index = cIdx
          cIdx += 1
        }
        parsedDefs[id] = def
      }
      template.node_definitions = parsedDefs

      template.locales = array2Object(template.locales, 'code')
      await storage.update(storageMap.template, template)
    })
  }

  return true
}

export async function loadTemplatesFromLocalStorage() {
  const response = await storage.getAll(storageMap.template)

  if (response) {
    return response
  }
  return false
}

export async function getTemplateById(id) {
  const template = await storage.get(storageMap.template, id)
  return template
}
