import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { useTransition, animated } from '@react-spring/web'
import * as actionCreators from '../../../store/actions'
import ShrinkIcon from '../../../assets/svg/shrink-bold.svg'
import {
  selectAllSyncErrors,
  selectAllSyncItems,
  selectCompletedSyncItems,
  selectSyncStatus,
} from '../../../store/selectors/sync_v2'
import SnackbarBody from './body'
import { SYNC_STATUS_COMPLETED, SYNC_STATUS_IN_PROGRESS } from '../../../consts/sync'

const classNames = require('classnames')

const SNACKBAR_STATUS_IN_PROGRESS = 'in-progress'
const SNACKBAR_STATUS_SUCCESS = 'success'
const SNACKBAR_STATUS_FAILURE = 'failure'

function ExpandedSnackbar({ status, errors, items, completedItems, onShrink, open }) {
  const snackbarStatus = useMemo(() => {
    if (status === SYNC_STATUS_IN_PROGRESS) {
      return 'in-progress'
    }
    if (status === SYNC_STATUS_COMPLETED && errors.length === 0) {
      return 'success'
    }
    if (status === SYNC_STATUS_COMPLETED && errors.length > 0) {
      return 'failure'
    }

    return null
  }, [status])

  const title = useMemo(() => {
    switch (snackbarStatus) {
      case SNACKBAR_STATUS_IN_PROGRESS:
        return 'Transferring'
      case SNACKBAR_STATUS_SUCCESS:
        return 'Transfer completed'
      case SNACKBAR_STATUS_FAILURE:
        return 'Transfer failed'
      default:
        return ''
    }
  }, [snackbarStatus])

  const transitions = useTransition(open, {
    from: { x: 290, opacity: 1 }, // 290px is the width of the sync snackbar
    enter: { x: -15, opacity: 1 },
    leave: { x: 290, opacity: 0.4 },
    config: { duration: 100 },
    delay: open ? 300 : 0,
  })

  return transitions((style, isOpen) => {
    return isOpen ? (
      <animated.div
        className={classNames('sync-snackbar', `sync-snackbar--${snackbarStatus}`, 'sync-snackbar--expanded')}
        style={style}
      >
        <div className="sync-snackbar-inner">
          <div className="sync-snackbar__header">
            <div className="sync-snackbar__header-row">
              <div className="sync-snackbar__title">{title}</div>

              <button
                className="sync-snackbar-icon-wrapper sync-snackbar-icon-wrapper--shrink"
                type="button"
                onClick={onShrink}
              >
                <ShrinkIcon width="20" height="20" />
              </button>
            </div>

            <div className="sync-snackbar__header-row">
              <div className="sync-snackbar__counter">
                {completedItems.length}/{items.length}
              </div>
            </div>
          </div>

          <SnackbarBody />
        </div>
      </animated.div>
    ) : null
  })
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    errors: selectAllSyncErrors(state),
    items: selectAllSyncItems(state),
    completedItems: selectCompletedSyncItems(state),
    status: selectSyncStatus(state),
  }
}

export default connect(mapStateToProps, actionCreators)(ExpandedSnackbar)
