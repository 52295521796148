import { remove } from '../../api/media'
import storage from '../../storage'
import storageMap from '../../storage/storageMap'
import { SYNC_ACTION_DELETE_MEDIA, SYNC_ACTION_UPLOAD_MEDIA } from '../consts'
import DefaultActionHandler from './defaultActionHandler'

class DeleteMediaHandler extends DefaultActionHandler {
  constructor() {
    super()
    this.setAction(SYNC_ACTION_DELETE_MEDIA)
  }

  add = async data => {
    const { id } = data

    const itemsRemovedCount = await storage.removeByFilter(
      storageMap.sync_queue_v2,
      item => item.action === SYNC_ACTION_UPLOAD_MEDIA && item.data.id === id
    )

    if (itemsRemovedCount <= 0) {
      await super.add(data)
    }
  }

  process = async item => {
    const { data } = item
    const { id } = data

    return remove(id)
  }
}

export default DeleteMediaHandler
