import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

const classNames = require('classnames')

export class ScoringSelectDataListProp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      selectValue: false,
    }
  }

  componentDidMount = async () => {
    const { scoring, environment, slug } = this.props
    const DataListValueProp = scoring.scoringProps.filter(x => x.slug === slug)
    const selectValue = DataListValueProp.length > 0 ? DataListValueProp[0].value.body[environment.lang] : 'unselected'

    this.setState({ initialized: true, selectValue })
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { selectValue } = this.state
    return nextState.selectValue !== selectValue
  }

  render() {
    const { environment, texts, label, mandatory, values, slug, type, onChangeFunction } = this.props

    const { initialized, selectValue } = this.state

    return (
      <>
        {initialized && (
          <>
            <div className={classNames({ ns_prop_label: true })}>{label[environment.lang]}</div>
            <div
              className={classNames({
                ns_prop: true,
                ns_datalist_prop: true,
              })}
            >
              <select
                className={`va_select form_item_select_input ns_select_input_${slug}`}
                onChange={e => {
                  const optionSlug = e.target.options[e.target.selectedIndex].id
                  const value = values.find(x => x.slug === optionSlug)
                  onChangeFunction(slug, type, label, value)
                }}
                defaultValue={selectValue}
                style={{
                  textAlign: 'left',
                  fontSize: '14px',
                  paddingLeft: 10,
                  width: 'calc(100% - 32px)',
                  marginLeft: -10,
                }}
              >
                {selectValue === 'unselected' && (
                  <option disabled selected value="unselected">
                    {mandatory ? texts.mandatory : '--'}
                  </option>
                )}

                {values.map(opt => {
                  return (
                    <option key={opt.slug} id={opt.slug}>
                      {opt.body[environment.defaultLang]}
                    </option>
                  )
                })}
              </select>
            </div>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    device: state.device,
    scoring: state.scoring,
    status: state.status,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringSelectDataListProp)
