import _ from 'lodash'
import syncQueue from '../../../../va-corejs-v3/sync/syncQueue'
import { selectFailedSyncItems } from '../../selectors/sync_v2'

const uuidv4 = require('uuid/v4')

let syncTimer = null

export function processSyncQueue(syncKey = null) {
  return async (dispatch, getState) => {
    if (typeof syncTimer === 'number') {
      clearTimeout(syncTimer)
    }

    syncTimer = setTimeout(async () => {
      const idbQueueItems = await syncQueue.all()

      if (idbQueueItems.length <= 0) {
        // If there aren't any items in the sync queue, just return without doing nothing
        return
      }

      // Set a lock on the items in the queue in order to avoid double process of the same item
      // and in order to avoid a batch item to be consider as unlocked while it is still processing
      await syncQueue.lockMany(idbQueueItems)

      // Why using a unique key?
      // It is useful to identify items in the queue added by the current sync process.
      // Only that items will be processed in order to avoid double process of the same item.
      const syncUniqueKey = syncKey || uuidv4()

      dispatch({
        type: 'ADD_QUEUE_ITEMS',
        items: _.map(idbQueueItems, item => ({ ...item, completed_at: null, sync_key: syncUniqueKey })),
      })

      const updatedState = getState()
      const { items } = updatedState.syncV2
      const itemsToBeProcessed = _.sortBy(
        _.filter(items, item => item.sync_key === syncUniqueKey),
        'added_at'
      )

      async function _handleQueueItem(item) {
        await syncQueue
          .process(item.id)
          .then(() => {
            dispatch({
              type: 'QUEUE_ITEM_PROCESSED_SUCCESSFULLY',
              id: item.id,
            })

            // Remove the items from the queue and from the UI after 5s
            setTimeout(() => {
              dispatch({
                type: 'REMOVE_QUEUE_ITEMS',
                ids: [item.id],
              })
            }, 5000)
          })
          .catch(error => {
            dispatch({
              type: 'QUEUE_ITEM_PROCESSED_FAILURE',
              id: item.id,
              error: error.message,
            })
          })
      }

      for (let i = 0; i < itemsToBeProcessed.length; i += 1) {
        const itemToBeProcessed = itemsToBeProcessed[i]

        if (itemToBeProcessed.async) {
          _handleQueueItem(itemToBeProcessed)
        } else {
          // eslint-disable-next-line no-await-in-loop
          await _handleQueueItem(itemToBeProcessed)
        }
      }
    }, 4000)
  }
}

export function retryAllFailedSyncItems() {
  return async (dispatch, getState) => {
    const state = getState()
    const failedItems = selectFailedSyncItems(state)

    dispatch({
      type: 'REMOVE_QUEUE_ITEMS',
      ids: _.map(failedItems, item => item.id),
    })

    dispatch(processSyncQueue())
  }
}

export function clearAllFailedSyncItems() {
  return async (dispatch, getState) => {
    const state = getState()
    const failedItems = selectFailedSyncItems(state)
    const ids = _.map(failedItems, item => item.id)

    await syncQueue.removeMany(ids)

    dispatch({
      type: 'REMOVE_QUEUE_ITEMS',
      ids,
    })
  }
}

export function retrySyncItem(id) {
  return async dispatch => {
    const syncKey = uuidv4()

    dispatch({
      type: 'RESET_QUEUE_ITEM',
      syncKey,
      id,
    })

    dispatch(processSyncQueue(syncKey))
  }
}

export function removeSyncItem(id) {
  return async dispatch => {
    await syncQueue.remove(id)

    dispatch({
      type: 'REMOVE_QUEUE_ITEMS',
      ids: [id],
    })
  }
}
