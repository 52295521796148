import _ from 'lodash'
import {
  comparisonBoardRefreshReferenceTemplate,
  loadComparisonBoardFromLocalStorage,
  loadComparisonBoardFromLocalStorageOrSetInitialState,
  updateComparisonBoardIntoLocalStorage,
  prepareScoringForComparison,
} from '../../../../va-corejs-v3/actions/comparison_board'
import initialState from '../../reducers/comparison_board/initialState'
import nodeDefinitionTypeMap from '../../../components/scoring_tree/helper/nodeDefinitionTypeMap'

export function updateComparisonBoard() {
  return async dispatch => {
    const comparisonBoard = await loadComparisonBoardFromLocalStorageOrSetInitialState()

    dispatch({
      type: 'UPDATE_COMPARISON_BOARD',
      comparisonBoard,
    })
  }
}

export function updateReferenceTemplate(referenceTemplate) {
  return async dispatch => {
    const comparisonBoard = await loadComparisonBoardFromLocalStorage()
    comparisonBoard.referenceTemplate = referenceTemplate
    await updateComparisonBoardIntoLocalStorage(comparisonBoard)

    dispatch({
      type: 'UPDATE_COMPARISON_BOARD',
      comparisonBoard,
    })
  }
}

export async function recursivelyCalcOnProducts(list, output) {
  if (list.length === 0) return
  const itm = list.pop()
  const { id } = itm
  const tree = await prepareScoringForComparison(id)
  itm.score_panel = tree.score_panel // VAV3-533: Fix because data structure is confusing
  itm.tree = tree
  output.push(itm)
  await recursivelyCalcOnProducts(list, output)
}

export function reloadAllComparedProducts() {
  return async dispatch => {
    const comparisonBoard = await loadComparisonBoardFromLocalStorage()
    const { comparedProducts, referenceTemplate } = comparisonBoard
    const productsList = [...comparedProducts]
    const outputList = []
    await recursivelyCalcOnProducts(productsList, outputList)
    comparisonBoard.comparedProducts = outputList

    // Update navigation list
    const orderedItems = []
    const orderedCriterion = []
    if (Object.keys(referenceTemplate).length > 0) {
      const nodeDefs = referenceTemplate.node_definitions
      const nodeKeys = Object.keys(nodeDefs)
      for (let i = 0; i !== nodeKeys.length; i += 1) {
        const key = nodeKeys[i]
        const def = nodeDefs[key]
        const { type } = def
        if (type === nodeDefinitionTypeMap.item) orderedItems.push(def)
        else if (type === nodeDefinitionTypeMap.criterion) orderedCriterion.push(def)
      }
    }
    comparisonBoard.navigationLists = {
      orderedItems,
      orderedCriterion,
    }
    // End of navigation list updating

    updateComparisonBoardIntoLocalStorage(comparisonBoard)
    dispatch({
      type: 'UPDATE_COMPARISON_BOARD',
      comparisonBoard,
    })
  }
}

export function updateComparedProducts(chosen, compared) {
  return async dispatch => {
    const comparisonBoard = await loadComparisonBoardFromLocalStorage()

    const referenceTemplate = await comparisonBoardRefreshReferenceTemplate(chosen)

    let { order } = comparisonBoard
    const productsToCalc = []
    const newProducts = []
    const toBeRemovedIdx = []

    for (let i = 0; i !== chosen.length; i += 1) {
      const itm = chosen[i]
      const { id } = itm
      let alreadyCompared = false

      // Set order
      if (!_.find(order, orderItm => orderItm.id === itm.id)) {
        order.push({
          id: itm.id,
          scoreSet: 'benchmark',
        })

        if (itm.project_mode) {
          order.push({
            id: itm.id,
            scoreSet: 'forecast',
          })
        }
      }

      for (let j = 0; j !== compared.length; j += 1) {
        const comProduct = compared[j]
        if (comProduct.id === id) {
          alreadyCompared = true
          break
        }
      }

      if (alreadyCompared === false) {
        productsToCalc.push(itm)
      }
    }

    await recursivelyCalcOnProducts(productsToCalc, newProducts)
    for (let i = 0; i !== newProducts.length; i += 1) {
      const prod = newProducts[i]
      compared.push(prod)
    }

    for (let i = 0; i !== compared.length; i += 1) {
      const itm = compared[i]
      const { id } = itm
      let toRemove = true
      for (let j = 0; j !== chosen.length; j += 1) {
        const chosenProd = chosen[j]
        if (chosenProd.id === id) {
          toRemove = false
          break
        }
      }
      if (toRemove === true) {
        toBeRemovedIdx.push(i)
      }
    }

    for (let i = toBeRemovedIdx.length - 1; i >= 0; i -= 1) {
      const idx = toBeRemovedIdx[i]
      compared.splice(idx, 1)
    }

    // Remove IDs from order
    order = _.filter(order, orderItem => {
      return _.find(chosen, p => {
        return p.id === orderItem.id
      })
    })

    comparisonBoard.order = order
    comparisonBoard.comparedProducts = compared
    comparisonBoard.referenceTemplate = referenceTemplate

    await updateComparisonBoardIntoLocalStorage(comparisonBoard)
    dispatch({
      type: 'UPDATE_COMPARISON_BOARD',
      comparisonBoard,
    })
  }
}

export function updateSortedElements(comparedProducts) {
  return async dispatch => {
    const comparisonBoard = await loadComparisonBoardFromLocalStorage()

    const order = []
    comparedProducts.forEach(itm => {
      order.push({
        id: itm.element.id,
        scoreSet: itm.scoreSet,
      })
    })
    comparisonBoard.order = order

    await updateComparisonBoardIntoLocalStorage(comparisonBoard)

    dispatch({
      type: 'UPDATE_COMPARISON_BOARD',
      comparisonBoard,
    })
  }
}

export function updateDeltaProduct(value) {
  return async dispatch => {
    const comparisonBoard = await loadComparisonBoardFromLocalStorage()
    comparisonBoard.deltaProduct = value

    dispatch({
      type: 'UPDATE_COMPARISON_BOARD',
      comparisonBoard,
    })

    await updateComparisonBoardIntoLocalStorage(comparisonBoard)
  }
}

export function removeProductFromComparedProducts(productId) {
  return async dispatch => {
    const comparisonBoard = await loadComparisonBoardFromLocalStorage()
    const { chosenProductsForComparison, comparedProducts } = comparisonBoard

    function removeProductWithIdFromList(id, list) {
      let pidIndex = -1
      for (let i = 0; i !== list.length; i += 1) {
        const pID = list[i].id
        if (pID === id) {
          pidIndex = i
          break
        }
      }
      if (pidIndex > -1) {
        list.splice(pidIndex, 1)
      }
    }

    removeProductWithIdFromList(productId, chosenProductsForComparison)
    removeProductWithIdFromList(productId, comparedProducts)
    comparisonBoard.order = _.filter(comparisonBoard.order, item => item.id !== productId)

    comparisonBoard.chosenProductsForComparison = chosenProductsForComparison
    comparisonBoard.comparedProducts = comparedProducts

    if (chosenProductsForComparison.length === comparedProducts.length && comparedProducts.length === 0) {
      comparisonBoard.referenceTemplate = initialState.referenceTemplate
    }

    await updateComparisonBoardIntoLocalStorage(comparisonBoard)

    dispatch({
      type: 'UPDATE_COMPARISON_BOARD',
      comparisonBoard,
    })
  }
}

export function updateDifferencesEnabledForComparison(differencesEnabled) {
  return async dispatch => {
    const comparisonBoard = await loadComparisonBoardFromLocalStorage()
    comparisonBoard.differencesEnabled = differencesEnabled
    await updateComparisonBoardIntoLocalStorage(comparisonBoard)

    dispatch({
      type: 'UPDATE_COMPARISON_BOARD',
      comparisonBoard,
    })
  }
}

export function updateBestWorstEnabledForComparison(bestWorstEnabled) {
  return async dispatch => {
    const comparisonBoard = await loadComparisonBoardFromLocalStorage()
    comparisonBoard.bestWorstEnabled = bestWorstEnabled
    await updateComparisonBoardIntoLocalStorage(comparisonBoard)

    dispatch({
      type: 'UPDATE_COMPARISON_BOARD',
      comparisonBoard,
    })
  }
}

export function updateSyncMediaForComparison(syncMedia) {
  return async dispatch => {
    const comparisonBoard = await loadComparisonBoardFromLocalStorage()
    comparisonBoard.syncMedia = syncMedia
    await updateComparisonBoardIntoLocalStorage(comparisonBoard)

    dispatch({
      type: 'UPDATE_COMPARISON_BOARD',
      comparisonBoard,
    })
  }
}

export function updateChosenProductsForComparison(chosenProductsForComparison) {
  return async dispatch => {
    const comparisonBoard = await loadComparisonBoardFromLocalStorage()
    comparisonBoard.chosenProductsForComparison = chosenProductsForComparison
    updateComparisonBoardIntoLocalStorage(comparisonBoard)

    dispatch({
      type: 'UPDATE_COMPARISON_BOARD',
      comparisonBoard,
    })
  }
}

export function updateAddProductPopup(addProductPopup) {
  return async dispatch => {
    const comparisonBoard = await loadComparisonBoardFromLocalStorage()
    comparisonBoard.addProductPopup = addProductPopup
    await updateComparisonBoardIntoLocalStorage(comparisonBoard)

    dispatch({
      type: 'UPDATE_COMPARISON_BOARD',
      comparisonBoard,
    })
  }
}

export function updateNodePopup(props) {
  return async dispatch => {
    const comparisonBoard = await loadComparisonBoardFromLocalStorage()
    const clonedNodePopup = { ...comparisonBoard.nodePopup }
    comparisonBoard.nodePopup = {
      ...clonedNodePopup,
      ...props,
    }
    updateComparisonBoardIntoLocalStorage(comparisonBoard)

    dispatch({
      type: 'UPDATE_COMPARISON_BOARD',
      comparisonBoard,
    })
  }
}

export function nodePopupIsLoading(value) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_COMPARISON_BOARD_NODE_POPUP_IS_LOADING',
      isLoading: value,
    })
  }
}
