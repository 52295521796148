import screenTypesMap from '../../../layout/_parts/screenTypesMap'

import { updateOnlineOfflineStatusIntoSystemData } from '../../../../va-corejs-v3/actions/system'

function isIpadProResolution() {
  if (typeof window === 'undefined') {
    return false
  }
  const ratio = window.devicePixelRatio || 1
  const screen = {
    width: window.screen.width * ratio,
    height: window.screen.height * ratio,
  }

  // Borderline resolutions valid for many iPad Models
  /* (screen.width === 1668 && screen.height === 2224) ||
  (screen.width === 2224 && screen.height === 1668)
  (screen.width === 1536 && screen.height === 2048) ||
  (screen.width === 2048 && screen.height === 1536) || */

  return (
    (screen.width === 2048 && screen.height === 2732) ||
    (screen.width === 2732 && screen.height === 2048) ||
    (screen.width === 1668 && screen.height === 2388) ||
    (screen.width === 2388 && screen.height === 1668)
  )
}

export function iDevice() {
  const canvasElement = document.createElement('canvas')
  let gpu = null
  try {
    const context = canvasElement.getContext('webgl')
    const extension = context.getExtension('WEBGL_debug_renderer_info')
    gpu = context.getParameter(extension.UNMASKED_RENDERER_WEBGL)
  } catch (e) {
    return e
  }

  const matches = gpu.match(/^Apple (.+) GPU$/)
  const cpu = matches && matches[1]
  const scale = window.devicePixelRatio
  const s = `${Math.floor(window.screen.width * scale)}x${Math.floor(window.screen.availHeight * scale)}`
  const dpr = devicePixelRatio

  if (!cpu) {
    if (gpu === 'PowerVR SGX 535' && s === '768x1024' && dpr === 1) {
      return 'iPad 2/mini 1'
    }
    if (gpu === 'PowerVR SGX 543' && dpr === 2) {
      if (s === '320x480') {
        return 'iPhone 4s'
      }
      if (s === '768x1024') {
        return 'iPad 3'
      }
      if (s === '320x568') {
        if (navigator.userAgent.indexOf('iPod') > -1) {
          return 'iPod Touch 5'
        }
        return 'iPhone 5/5c'
      }
    }
    if (gpu === 'PowerVR SGX 554' && s === '768x1024' && dpr === 2) {
      return 'iPad 4'
    }
    return `Unidentified CPU: ${cpu} / RESOLUTION: ${s}@${dpr}`
  }

  if (cpu === 'A7') {
    if (s === '320x568') {
      return 'iPhone 5s'
    }
    if (s === '768x1024') {
      return 'iPad Air/mini 2/mini 3'
    }
  }

  if (s === '320x568') {
    if (cpu === 'A8' && navigator.userAgent.indexOf('iPod') > -1) {
      return 'iPod Touch 6'
    }
    if (cpu === 'A9') {
      return 'iPhone SE (or 6s with Display Zoom)'
    }
  }

  const models = ['6', '6s', '7', '8']
  const cpuGeneration = parseInt(cpu.substr(1), 0)
  if (cpuGeneration >= 8 && cpuGeneration <= 11) {
    if (dpr === 2 && (s === '375x667' || s === '320x568')) {
      return `iPhone ${models[cpuGeneration - 8]}`
    }
    if (dpr === 3 && (s === '414x736' || s === '375x667')) {
      return `iPhone ${models[cpuGeneration - 8]} Plus`
    }
  }

  if (cpu === 'A8' && s === '768x1024') {
    return 'iPad mini 4'
  }

  if (cpu === 'A8X' && s === '768x1024') {
    return 'iPad Air 2'
  }

  if (cpu === 'A9' && s === '768x1024') {
    return 'iPad (2017)'
  }

  if (cpu === 'A9X') {
    if (s === '768x1024') {
      return 'iPad Pro 9.7" (2016)'
    }
    if (s === '1024x1366') {
      return 'iPad Pro 12.9" (2015)'
    }
  }

  if (cpu === 'A10') {
    if (s === '768x1024') {
      return 'iPad (2018)'
    }
  }

  if (cpu === 'A10X') {
    if (s === '834x1112') {
      return 'iPad Pro 10.5" (2017)'
    }
    if (s === '1024x1366') {
      return 'iPad Pro 12.9" (2017)'
    }
  }

  if (cpu === 'A11') {
    if (s === '375x812') {
      return 'iPhone X'
    }
  }

  if (cpu === 'A12') {
    if (dpr === 2) {
      if (s === '375x812' || s === '414x896') {
        return 'iPhone XR'
      }
      if (s === '834x1112') {
        return 'iPad Air (2019)'
      }
      if (s === '768x1024') {
        return 'iPad mini (2019)'
      }
    }
    if (dpr === 3) {
      if (s === '375x812') {
        return 'iPhone XS (or XS Max with Display Zoom)'
      }
      if (s === '414x896') {
        return 'iPhone XS Max'
      }
    }
  }

  if (cpu === 'A12X') {
    if (s === '834x1194') {
      return 'iPad Pro 11" (2018)'
    }
    if (s === '1024x1366') {
      return 'iPad Pro 12.9" (2018)'
    }
  }

  return `Unidentified CPU: ${cpu} / RESOLUTION: ${s}@${dpr}`
}

export function checkIfDeviceIsIpadPro() {
  const deviceInfo = iDevice()
  const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent : ''
  let result = false
  result = deviceInfo.toUpperCase().indexOf('IPAD PRO') !== -1 || result
  result = !result ? userAgent.toUpperCase().indexOf('MACINTOSH') && isIpadProResolution() : result
  return result
}

export function updateDeviceSettings() {
  return dispatch => {
    const orientation = {
      isPortrait: typeof window === 'object' ? window.matchMedia('(orientation: portrait)').matches : false,
      isLandscape: typeof window === 'object' ? window.matchMedia('(orientation: landscape)').matches : false,
    }
    const size = {
      innerWidth: typeof window === 'object' ? window.innerWidth : 0,
      innerHeight: typeof window === 'object' ? window.innerHeight : 0,
      outerWidth: typeof window === 'object' ? window.outerWidth : 0,
      outerHeight: typeof window === 'object' ? window.outerHeight : 0,
    }

    const platform =
      typeof window !== 'undefined' && typeof window.navigator !== 'undefined' ? window.navigator.platform : null

    const isSafari = typeof window !== 'undefined' && window.safari !== undefined

    const isFirefox = typeof window !== 'undefined' && window.navigator.userAgent.search('Firefox') > -1

    // const iOSMobile = !!(platform !== null && platform === 'iPhone')
    const iOSTablet = !!(platform !== null && platform === 'iPad')
    const androidTablet = !!(platform !== null && platform === 'Linux armv81')
    const iOS = platform === 'iPad' || platform === 'iPhone'

    let screenType = screenTypesMap.desk
    if ((iOSTablet || androidTablet || (size.outerWidth >= 415 && size.outerWidth < 835)) && orientation.isPortrait) {
      screenType = screenTypesMap.iPadV
    } else if (
      (iOSTablet || androidTablet || (size.outerWidth > 415 && size.outerWidth <= 1195)) &&
      orientation.isLandscape
    ) {
      screenType = screenTypesMap.iPadH
    } else if (size.outerWidth > 320 && size.outerWidth <= 414) {
      screenType = screenTypesMap.iPhoneXR
    } else if (size.outerWidth <= 320) {
      screenType = screenTypesMap.iPhoneSE
    }

    dispatch({
      type: 'UPDATE_DEVICE_SETTINGS',
      orientation,
      platform,
      iOS,
      isSafari,
      isFirefox,
      size,
      screenType,
    })
  }
}

export function updateIsOnline(isOnline) {
  return async dispatch => {
    await updateOnlineOfflineStatusIntoSystemData(isOnline)
    dispatch({
      type: 'UPDATE_IS_ONLINE',
      isOnline,
    })
  }
}

export function setStickyHeaderStatus(isStickyHeader) {
  return dispatch => {
    dispatch({
      type: 'SET_STICKY_HEADER_STATUS',
      isStickyHeader,
    })
  }
}

export function updateScrollDisabledStatus(scrollDisabled) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_SCROLL_DISABLED_STATUS',
      scrollDisabled,
    })
  }
}

export function updateBurgerMenuVisible(burgerMenuVisible) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_BURGER_MENU_VISIBLE',
      burgerMenuVisible,
    })
  }
}
