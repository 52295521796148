import { SearchkitManager, TermQuery, BoolMustNot } from 'searchkit'
import apiEndPoints from '../../../../va-corejs-v3/api/apiEndPoints'
import { getAccessToken } from '../../../../va-corejs-v3/actions/authentication'

export function initializeSearchkitManager(index, user, context) {
  return async dispatch => {
    const token = await getAccessToken()
    const searchkit = new SearchkitManager(`${process.env.apiUrl + apiEndPoints.elastic}/${index}`, {
      httpHeaders: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })

    // Exclude is_configuration product
    searchkit.addDefaultQuery(query => {
      return query.addQuery(BoolMustNot([TermQuery(`is_configuration`, true)]))
    })

    // Exclude forbidden products
    const excludedProducts = user.products_excluded
    if (excludedProducts.length > 0) {
      const excludedTermsQuery = []
      for (let i = 0; i !== excludedProducts.length; i += 1) {
        const productId = excludedProducts[i]
        excludedTermsQuery.push(TermQuery('_id', productId))
      }

      searchkit.addDefaultQuery(query => {
        return query.addQuery(BoolMustNot(excludedTermsQuery))
      })
    }

    // Exclude forbidden statuses
    const { excludedStatus } = user
    const excludedStatusQuery = []
    for (let i = 0; i !== excludedStatus.length; i += 1) {
      const status = excludedStatus[i]
      excludedStatusQuery.push(TermQuery('status', status))
    }
    if (excludedStatusQuery.length > 0) {
      searchkit.addDefaultQuery(query => {
        return query.addQuery(BoolMustNot(excludedStatusQuery))
      })
    }

    dispatch({
      type: 'UPDATE_SEARCHKIT_OBJECT',
      searchkit,
      context,
    })
  }
}

export function clearSearchkitManager(context) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_SEARCHKIT_OBJECT',
      searchkit: null,
      context,
    })
  }
}

export function updateHitsView(hitsView) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_HIST_VIEW',
      hitsView,
    })
  }
}

export function updateLastRemovedItem(lastRemovedItem) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_LAST_REMOVED_ITEM',
      lastRemovedItem,
    })
  }
}
