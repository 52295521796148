import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { useTransition, animated } from '@react-spring/web'
import * as actionCreators from '../../../store/actions'
import ErrorIcon from '../../../assets/svg/error.svg'
import SyncIcon from '../../../assets/svg/sync.svg'
import EnlargeIcon from '../../../assets/svg/enlarge.svg'
import SuccessMarkIcon from '../../../assets/svg/success_mark.svg'
import {
  selectAllSyncErrors,
  selectAllSyncItems,
  selectCompletedSyncItems,
  selectSyncStatus,
} from '../../../store/selectors/sync_v2'
import SnackbarBodyFailure from './bodyFailure'
import { SYNC_STATUS_COMPLETED, SYNC_STATUS_IN_PROGRESS } from '../../../consts/sync'
import { SYNC_SNACKBAR_STATUS_FAILURE, SYNC_SNACKBAR_STATUS_IN_PROGRESS, SYNC_SNACKBAR_STATUS_SUCCESS } from '../consts'

const classNames = require('classnames')

function ShrinkedSnackbar({ status, errors, items, completedItems, onExpand, open }) {
  const snackbarStatus = useMemo(() => {
    if (status === SYNC_STATUS_IN_PROGRESS) {
      return 'in-progress'
    }
    if (status === SYNC_STATUS_COMPLETED && errors.length === 0) {
      return 'success'
    }
    if (status === SYNC_STATUS_COMPLETED && errors.length > 0) {
      return 'failure'
    }

    return null
  }, [status])

  const title = useMemo(() => {
    switch (snackbarStatus) {
      case SYNC_SNACKBAR_STATUS_IN_PROGRESS:
        return 'Transferring'
      case SYNC_SNACKBAR_STATUS_SUCCESS:
        return 'Transfer completed'
      case SYNC_SNACKBAR_STATUS_FAILURE:
        return 'Transfer failed'
      default:
        return ''
    }
  }, [snackbarStatus])

  const statusIcon = useMemo(() => {
    const classes = classNames('sync-snackbar-icon', `sync-snackbar-icon--${snackbarStatus}`)

    switch (snackbarStatus) {
      case SYNC_SNACKBAR_STATUS_IN_PROGRESS:
        return <SyncIcon width="21" height="21" className={classes} />
      case SYNC_SNACKBAR_STATUS_SUCCESS:
        return <SuccessMarkIcon width="21" height="21" className={classes} />
      case SYNC_SNACKBAR_STATUS_FAILURE:
        return <ErrorIcon width="21" height="21" className={classes} />
      default:
        return ''
    }
  }, [snackbarStatus])

  const transitions = useTransition(open, {
    from: { x: 290, opacity: 1 }, // 290px is the width of the sync snackbar
    enter: { x: -15, opacity: 1 },
    leave: { x: 290, opacity: 0.4 },
    config: { duration: 100 },
    delay: open ? 300 : 0,
  })

  return transitions((style, isOpen) => {
    return isOpen ? (
      <animated.div
        className={classNames('sync-snackbar', `sync-snackbar--${snackbarStatus}`, 'sync-snackbar--shrinked')}
        style={style}
      >
        <div className="sync-snackbar__border" />

        <div className="sync-snackbar-inner">
          <div className="sync-snackbar__header">
            <div className="sync-snackbar-icon-wrapper sync-snackbar-icon-wrapper--status">{statusIcon}</div>

            <div className="sync-snackbar__title">{title}</div>

            {snackbarStatus === SYNC_SNACKBAR_STATUS_IN_PROGRESS && (
              <div className="sync-snackbar__counter">
                {completedItems.length}/{items.length}
              </div>
            )}

            <button
              className="sync-snackbar-icon-wrapper sync-snackbar-icon-wrapper--expand"
              type="button"
              onClick={onExpand}
            >
              <EnlargeIcon width="20" height="20" />
            </button>
          </div>

          {(snackbarStatus === SYNC_SNACKBAR_STATUS_FAILURE || snackbarStatus === SYNC_SNACKBAR_STATUS_IN_PROGRESS) &&
            errors.length > 0 && <SnackbarBodyFailure errorsCount={errors.length} />}
        </div>
      </animated.div>
    ) : null
  })
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    errors: selectAllSyncErrors(state),
    items: selectAllSyncItems(state),
    completedItems: selectCompletedSyncItems(state),
    status: selectSyncStatus(state),
  }
}

export default connect(mapStateToProps, actionCreators)(ShrinkedSnackbar)
