import storage from '../../storage'
import storageMap from '../../storage/storageMap'
import { readUploadedFileAsBase64, mongoObjectId, isoDate } from '../../utils'
import { setScoringIntoLocalStorage } from '../scoring'
import mediaCollectionsMap from '../../utils/mediaCollectionsMap'
import nodeDefinitionTypeMap from '../../../src/components/scoring_tree/helper/nodeDefinitionTypeMap'
import syncQueue from '../../sync/syncQueue'
import { SYNC_ACTION_DELETE_MEDIA, SYNC_ACTION_UPLOAD_MEDIA } from '../../sync/consts'

export async function updateMediaInLocalStorage(item) {
  if (!(await storage.update(storageMap.media, item))) {
    return false
  }

  return true
}

export async function doSaveMediaIntoLocalStorage(
  file,
  mediaType,
  nodeDefId,
  collection,
  scoringId,
  itemsFatherId,
  mediaIdToReplace,
  order
) {
  const dataToReturn = {}
  const fileExtension = file.name
    .split('.')
    .pop()
    .toLowerCase()
  let fileObj = {}
  fileObj = await readUploadedFileAsBase64(file, fileExtension)
  const scoring = await storage.get(storageMap.scoring, scoringId)
  const mediaObj = {}
  mediaObj.id = mediaIdToReplace || mongoObjectId()
  mediaObj.node_id = scoring.nodes[nodeDefId].id
  mediaObj.scoring_id = scoring.id
  mediaObj.node_definition_id = nodeDefId
  mediaObj.collection = collection // !!! This is important for Server
  mediaObj.type = mediaType
  mediaObj.file_ext = fileExtension
  mediaObj.file_type = file.type
  mediaObj.file_thumb = fileObj.base64
  if (!mediaIdToReplace) {
    mediaObj.created_at = isoDate()
  }
  mediaObj.updated_at = isoDate()
  mediaObj.input_type = 'base64' // !!! This is important for Server
  mediaObj.items_father_id = itemsFatherId
  mediaObj.order = order

  if (!(await storage.update(storageMap.media, mediaObj))) {
    return false
  }

  const mediaObjBig = { ...mediaObj }
  let video = ''
  switch (fileExtension) {
    case 'qt':
    case 'mov':
    case 'mp4':
      video = fileObj.base64
      mediaObjBig.file = ''
      break
    default:
      mediaObjBig.file = fileObj.base64
      break
  }

  if (!(await storage.update(storageMap.media_big, mediaObjBig))) {
    return false
  }

  dataToReturn.media = mediaObj
  dataToReturn.scoring = scoring
  dataToReturn.video = video
  await setScoringIntoLocalStorage(scoring)
  return dataToReturn
}

export async function saveMediaIntoLocalStorage(
  files,
  mediaType,
  nodeDefId,
  collection,
  scoringId,
  itemsFatherId,
  mediaIdToReplace
) {
  const filesArray = []
  for (let i = 0; i < files.length; i += 1) {
    filesArray.push(files[i])
  }

  const results = {
    media: [],
    scoring: {},
  }
  await Promise.all(
    await filesArray.map(async file => {
      // Get the highest order number from the siblings of the media we are storing
      let maxOrder = 0
      const siblings = await storage.getWhere(storageMap.media, {
        node_definition_id: nodeDefId,
      })
      await siblings.each(item => {
        if (item.order !== undefined && item.order !== null && item.order > maxOrder) {
          maxOrder = item.order
        }
      })
      maxOrder += 1

      const singleResult = await doSaveMediaIntoLocalStorage(
        file,
        mediaType,
        nodeDefId,
        collection,
        scoringId,
        itemsFatherId,
        mediaIdToReplace,
        maxOrder
      )
      results.media.push(singleResult)

      await syncQueue.add(SYNC_ACTION_UPLOAD_MEDIA, {
        id: singleResult.media.id,
        model_id: singleResult.media.node_id,
        model_type: 'node',
        collection,
        order: singleResult.media.order,
        created_at: singleResult.media.created_at,
        updated_at: singleResult.media.updated_at,
        file_type: singleResult.media.file_type,
        file: singleResult.media.file_thumb,
        file_name: file.name,
      })
    })
  )

  // UPDATE COUNTERS
  const scoring = await storage.get(storageMap.scoring, scoringId)
  const template = await storage.get(storageMap.template, scoring.template_id)
  let tmpNode = template.node_definitions[nodeDefId]

  const nodeMedia = await storage.getAllByScoringIdAndByKey(
    storageMap.media,
    scoring.id,
    'node_definition_id',
    nodeDefId
  )
  const nodeMediaBenchmark = nodeMedia.filter(m => m.collection === mediaCollectionsMap.node_pictures)
  const nodeMediaForecast = nodeMedia.filter(m => m.collection === mediaCollectionsMap.forecast_node_pictures)

  const isForcastEnabled = scoring.project_mode && scoring.nodes[nodeDefId] && scoring.nodes[nodeDefId].forecast

  if (tmpNode.type === nodeDefinitionTypeMap.criterion) {
    // Update criterion media
    scoring.nodes[nodeDefId].mediaCount = nodeMediaBenchmark.length

    if (isForcastEnabled) {
      scoring.nodes[nodeDefId].forecast.mediaCount = nodeMediaForecast.length
    }

    tmpNode = template.node_definitions[tmpNode.parent_id]
  }

  // Update item media count
  scoring.nodes[tmpNode.id].mediaCount = scoring.nodes[tmpNode.id].mediaCount
    ? scoring.nodes[tmpNode.id].mediaCount + files.length
    : files.length

  // Update scoring media count
  scoring.mediaCount = scoring.mediaCount ? scoring.mediaCount + files.length : files.length

  await setScoringIntoLocalStorage(scoring)

  results.scoring = scoring
  // END UPDATE COUNTERS

  return results
}

export async function getItemsFatherMediaFromLocalStorage(scoringId, itemsFatherId) {
  const media = await storage.getAllByScoringIdAndByKey(
    storageMap.media,
    scoringId,
    'items_father_id',
    itemsFatherId,
    '-created_at'
  )
  return media
}

export async function getScoringTreeMediaFromLocalStorage(scoringId) {
  const media = await storage.getAllByScoringIdAndByKey(storageMap.media, scoringId, 'collection', 'node_pictures')
  return media
}

export async function removeMediaById(mediaId) {
  await storage.remove(storageMap.media, mediaId)
  await storage.remove(storageMap.media_big, mediaId)
  return true
}

export async function deleteMediaFromLocalStorage(id) {
  await storage.remove(storageMap.media, id)
  await syncQueue.add(SYNC_ACTION_DELETE_MEDIA, { id })
  return true
}

export async function getMediaBigFromLocalStorage(id) {
  const media = await storage.get(storageMap.media_big, id)
  return media
}
