import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../store/actions'
import '../styles.scss'

const classNames = require('classnames')

class AppVersionLabel extends Component {
  render() {
    const { githash } = this.props

    if (!githash || githash === '') {
      return <></>
    }

    return <div className={classNames({ app_version_label_container: true })}>App Version: {githash.slice(-6)}</div>
  }
}

const mapStateToProps = state => {
  return {
    githash: state.appVersion.githash,
  }
}

export default connect(mapStateToProps, actionCreators)(AppVersionLabel)
