import { loadPublishingServicesFromLocalStorage } from '../../../../va-corejs-v3/actions/publishing_services'

export function updatePublishingServices() {
  return async dispatch => {
    const list = await loadPublishingServicesFromLocalStorage()
    dispatch({
      type: 'UPDATE_PUBLISHING_SERVICES',
      list,
    })
  }
}
