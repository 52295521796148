import { getPublishingServicesFromServer } from './actions/publishing_services'
import { initializeSystemData } from './actions/system'
import { getTextsFromServer } from './actions/texts'
import { getSettingsFromServer, getLocalesFromServer } from './actions/env'
import { loadUserFromLocalStorage } from './actions/authentication'
import { getTemplatesFromServer } from './actions/templates'
import { getScopesFromServer } from './actions/scopes'
import { getStatusFromServer } from './actions/status'

global.env = {}

export function setGlobalEnvVariables() {
  global.env.title = process.env.title
  global.env.siteUrl = process.env.siteUrl
  global.env.apiUrl = process.env.apiUrl
  global.env.fakeApiUrl = process.env.fakeApiUrl
  global.env.defaultLang = process.env.defaultLang
  global.env.client = process.env.client
  global.env.cobrandingMode = process.env.cobrandingMode
  global.env.storageName = process.env.storageName
  global.env.maxPassResetReqPerHour = process.env.maxPassResetReqPerHour
  global.env.oauthClientId = process.env.oauthClientId
  global.env.oauthClientSecret = process.env.oauthClientSecret
  global.env.defaultIsEnabledValue = process.env.defaultIsEnabledValue
  global.env.defaultIsDefaultValue = process.env.defaultIsDefaultValue
  global.env.treeMap = process.env.treeMap
  global.env.thumbnailsScale = process.env.thumbnailsScale
  global.env.thumbnailsWidth = process.env.thumbnailsWidth
  global.env.thumbnailsHeight = process.env.thumbnailsHeight
  global.env.sortByGlobalScoreEnabled = process.env.sortByGlobalScoreEnabled
  global.env.useWebcamOnMediaButton = process.env.useWebcamOnMediaButton
  global.env.analyticsEnabled = process.env.analyticsEnabled
  global.env.mediaTabEnabled = process.env.mediaTabEnabled
  global.env.interRankingEnabled = process.env.interRankingEnabled
  global.env.showMoreMediaButtonEnabled = process.env.showMoreMediaButtonEnabled
  global.env.comparisonBoardEnabled = process.env.comparisonBoardEnabled
  global.env.perimeterHasScore = process.env.perimeterHasScore
  global.env.destroyIdbThroughVersions = process.env.destroyIdbThroughVersions
  global.env.normalizedScoreDecimals = process.env.normalizedScoreDecimals
  global.env.oidcFlow = process.env.oidcFlow
}

export async function initSystem() {
  // Load text from server and save in storage
  getTextsFromServer()

  // Load environment properties and locales
  await getSettingsFromServer()
  await getLocalesFromServer()

  const user = await loadUserFromLocalStorage()

  const promises = []

  if (user) {
    // Load publishing services
    promises.push(
      new Promise((resolve, reject) => {
        getPublishingServicesFromServer()
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    )

    // Load templates
    promises.push(
      new Promise((resolve, reject) => {
        getTemplatesFromServer()
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    )

    // Load scopes
    promises.push(
      new Promise((resolve, reject) => {
        getScopesFromServer()
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    )

    // Load status
    promises.push(
      new Promise((resolve, reject) => {
        getStatusFromServer()
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    )

    // Initialize system data
    promises.push(
      new Promise((resolve, reject) => {
        initializeSystemData()
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    )
  }

  await Promise.all(promises)

  return true
}
