import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import authPagesMap from './authPagesMap'

export class ResetNotAllowedComponent extends React.Component {
  render() {
    const { authProcess } = this.props

    return (
      <div
        className={
          authProcess.page === authPagesMap.resetnotallowed
            ? 'login_form_inner_container_active slide_left'
            : 'login_form_inner_container_inactive'
        }
      >
        <div className="login-form-reset-not-allowed">
          Nous allons vous envoyer un e-mail avec votre nom d’utilisateur et un lien pour réinitialiser votre mot de
          passe.
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authProcess: state.authentication.authProcess,
  }
}

export default connect(mapStateToProps, actionCreators)(ResetNotAllowedComponent)
