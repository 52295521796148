import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

const classNames = require('classnames')

export class ScoringWheelSizeProp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      whellFront: '',
      whellRear: '',
    }
  }

  componentDidMount = async () => {
    const { scoring, environment, slug } = this.props
    const wheelSizeValueProp = scoring.scoringProps.filter(x => x.slug === slug)
    const whellFront = wheelSizeValueProp.length > 0 ? wheelSizeValueProp[0].props[0].value.body[environment.lang] : ''

    const whellRear = wheelSizeValueProp.length > 0 ? wheelSizeValueProp[0].props[1].value.body[environment.lang] : ''

    this.setState({ initialized: true, whellFront, whellRear })
  }

  render() {
    const { initialized, whellFront, whellRear } = this.state

    const { scoring, environment, texts, mandatory, label, slug, type, props, setScoringPropValue } = this.props

    const wheelSizePropsMapIndex = {
      wheel_size_front: 0,
      wheel_size_rear: 1,
    }

    function createPropValueObject(wFront, wRear) {
      const { locales } = environment
      const bodyFront = {}
      const bodyRear = {}
      locales.forEach(locale => {
        const { code } = locale
        bodyFront[code] = wFront
        bodyRear[code] = wRear
      })

      const value = [
        {
          slug: props[wheelSizePropsMapIndex.wheel_size_front].slug,
          value: {
            body: bodyFront,
          },
        },
        {
          slug: props[wheelSizePropsMapIndex.wheel_size_rear].slug,
          value: {
            body: bodyRear,
          },
        },
      ]
      setScoringPropValue(scoring.newScoringId, slug, type, label, value, true)
    }

    function handleSizeChanged(e) {
      const container = e.target.parentElement
      const wFront = container.querySelector('.ns_front_input').value
      const wRear = container.querySelector('.ns_rear_input').value
      createPropValueObject(wFront, wRear)
    }

    return (
      <div>
        {initialized && (
          <div
            className={classNames({
              ns_prop_variant: true,
              ns_wheel_size_prop: true,
            })}
          >
            <div className={classNames({ ns_prop_label: true })}>{label[environment.lang]}</div>
            <input
              className={classNames({
                ns_text_input: true,
                ns_size_input: true,
                ns_front_input: true,
              })}
              type="text"
              value={whellFront}
              onChange={e => {
                this.setState({ whellFront: e.target.value })
                handleSizeChanged(e)
              }}
              placeholder={
                mandatory ? texts.mandatory : props[wheelSizePropsMapIndex.wheel_size_front].label[environment.lang]
              }
            />
            <input
              className={classNames({
                ns_text_input: true,
                ns_size_input: true,
                ns_rear_input: true,
              })}
              type="text"
              value={whellRear}
              onChange={e => {
                this.setState({ whellRear: e.target.value })
                handleSizeChanged(e)
              }}
              placeholder={
                mandatory ? texts.mandatory : props[wheelSizePropsMapIndex.wheel_size_rear].label[environment.lang]
              }
            />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    status: state.status,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringWheelSizeProp)
