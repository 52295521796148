import { batchCreateOrUpdate } from '../../api/comments'
import { SYNC_ACTION_CREATE_COMMENT } from '../consts'
import DefaultActionHandler from './defaultActionHandler'

class CreateCommentHandler extends DefaultActionHandler {
  constructor() {
    super()
    this.setAction(SYNC_ACTION_CREATE_COMMENT)
  }

  process = async item => {
    const { data } = item
    const { product_id: productId } = data
    return batchCreateOrUpdate(productId, [data])
  }
}

export default CreateCommentHandler
