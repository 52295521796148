import React from 'react'

class PublishingServiceSelect extends React.Component {
  render() {
    const { servicesList, currentValue, onValueChange, disabled, useEmptyValue } = this.props

    return (
      <div className="publisher_service">
        <select
          className="va_select form_item_select_input"
          value={currentValue}
          onChange={e => {
            onValueChange(e.target.value)
          }}
          disabled={disabled}
        >
          {useEmptyValue && <option />}

          {servicesList.map((service, index) => {
            return (
              <option key={index} value={service.code}>
                {service.label}
              </option>
            )
          })}
        </select>
      </div>
    )
  }
}

export default PublishingServiceSelect
