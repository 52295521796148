// import _ from 'lodash'
import storage from '../../storage'
import storageMap from '../../storage/storageMap'
import { SYNC_ACTION_CREATE_COMMENT, SYNC_ACTION_UPDATE_COMMENT, SYNC_ACTION_DELETE_COMMENT } from '../consts'
import DefaultActionHandler from './defaultActionHandler'
import { batchRemove } from '../../api/comments'

class DeleteCommentHandler extends DefaultActionHandler {
  constructor() {
    super()
    this.setAction(SYNC_ACTION_DELETE_COMMENT)
  }

  async add(data) {
    const { id } = data

    const itemsRemovedCount = await storage.removeByFilter(
      storageMap.sync_queue_v2,
      item => (item.action === SYNC_ACTION_CREATE_COMMENT || SYNC_ACTION_UPDATE_COMMENT) && item.data.id === id
    )

    if (itemsRemovedCount <= 0) {
      await super.add(data)
    }

    /*
    const { id: commentId, product_id: productId } = data

    const addCommentsRecord = await storage.find(
      storageMap.sync_queue_v2,
      item => item.action === SYNC_ACTION_CREATE_OR_UPDATE_COMMENT && item.data.product_id === productId
    )

    const alreadyCreated = addCommentsRecord
      ? !!_.find(addCommentsRecord.data.comments, comment => comment.id === commentId)
      : false

    if (alreadyCreated) {
      // If the comment was previously created in the same offline session and now we want to delete it, ignore both actions
      const newAddCommentsRecord = { ...addCommentsRecord }
      newAddCommentsRecord.data.comments = _.filter(
        addCommentsRecord.data.comments,
        comment => comment.id !== commentId
      )

      if (newAddCommentsRecord.data.comments.length === 0) {
        // Remove the entire record
        await storage.remove(storageMap.sync_queue_v2, addCommentsRecord.id)
      } else {
        // Update the record
        await storage.update(storageMap.sync_queue_v2, newAddCommentsRecord)
      }
    } else {
      // If the comment is being only deleted in the current session
      const deleteCommentsRecord = await storage.find(
        storageMap.sync_queue_v2,
        item => item.action === SYNC_ACTION_DELETE_COMMENT && item.data.product_id === productId
      )

      if (deleteCommentsRecord) {
        // If a "delete comments" action for the current product already exists, then update it
        const newDeleteCommentsRecord = { ...deleteCommentsRecord }
        newDeleteCommentsRecord.data.comments.push(data)
        await storage.update(storageMap.sync_queue_v2, newDeleteCommentsRecord)
      } else {
        // Otherwise create a new "delete comments" action for the current product
        await super.add({
          product_id: productId,
          comments: [data],
        })
      }
    }
    */
  }

  process = async item => {
    const { data } = item
    const { product_id: productId } = data
    return batchRemove(productId, [data])
  }
}

export default DeleteCommentHandler
