import { initDb } from './db.js'
import storageMap from './storageMap'

class Storage {
  getDb = () => {
    const db = initDb()
    return db
  }

  deleteDb = async () => {
    const db = this.getDb()

    try {
      return await db.delete()
    } catch (e) {
      console.error('IndexedDB: delete db', e)
      return false
    }
  }

  put = async (collection, data) => {
    const db = this.getDb()

    try {
      return await db[collection].put(data)
    } catch (e) {
      console.error('IndexedDB: put record', e)
      return false
    }
  }

  update = async (collection, data) => {
    const db = this.getDb()

    try {
      const record = await db[collection].get(data.id)

      if (!record) {
        await db[collection].put(data)
      } else {
        await db[collection].update(data.id, data)
      }
    } catch (e) {
      console.error('IndexedDB: update record', e)
      return false
    }

    return true
  }

  bulkUpdate = async (collection, data) => {
    const db = this.getDb()

    try {
      await db[collection].bulkPut(data)
    } catch (e) {
      console.error('IndexedDB: bulk update', e)
      return false
    }

    return true
  }

  get = async (collection, id) => {
    const db = this.getDb()

    try {
      return await db[collection].get(id)
    } catch (e) {
      console.error('IndexedDB: get record', e)
      return false
    }
  }

  getWhere = async (collection, keysObj) => {
    const db = this.getDb()

    try {
      return await db[collection].where(keysObj)
    } catch (e) {
      console.error('IndexedDB: get record where', e)
      return false
    }
  }

  getAllMedia = async scoringId => {
    const db = this.getDb()

    return db[storageMap.media]
      .where('scoring_id')
      .equals(scoringId)
      .toArray()
  }

  getMedia = async (scoringId, mediaCollection) => {
    const db = this.getDb()

    return db[storageMap.media]
      .where('[scoring_id+collection]')
      .equals([scoringId, mediaCollection])
      .toArray()
  }

  filter = async (collection, filter) => {
    const db = this.getDb()

    return db[collection].filter(filter).toArray()
  }

  find = async (collection, filter) => {
    const db = this.getDb()

    const result = await db[collection].filter(filter).toArray()

    return result.length > 0 ? result[0] : null
  }

  remove = async (collection, id) => {
    const db = this.getDb()

    try {
      return await db[collection].delete(id)
    } catch (e) {
      console.error('IndexedDB: remove record', e)
      await this.put('log', {
        id: new Date().getTime(),
        s: `### IDB UPDATE ERROR during removing into ${collection} collection: ${e}`,
      })
      return false
    }
  }

  removeByFilter = async (collection, filter) => {
    const db = this.getDb()

    return db[collection].filter(filter).delete()
  }

  removeAllByKey = async (collection, keyName, keyValue) => {
    const db = this.getDb()

    try {
      return await db[collection]
        .where({
          [keyName]: keyValue,
        })
        .delete()
    } catch (e) {
      console.error('IndexedDB: removeAllRecordsByKey', e)
      return false
    }
  }

  getAll = async collection => {
    const db = this.getDb()

    try {
      return await db[collection].toArray()
    } catch (e) {
      console.error('IndexedDB: get all records', e)
      return false
    }
  }

  getFirstByKey = async (collection, keyName, keyValue) => {
    const db = this.getDb()

    try {
      let records = await db[collection]
        .where({
          [keyName]: keyValue,
        })
        .limit(1)

      await records.toArray(async arr => {
        records = arr
      })

      const [firstRecord] = records

      return firstRecord
    } catch (e) {
      console.error('IndexedDB: get all records by key', e)
      return false
    }
  }

  getByIdsArray = async (collection, idsList) => {
    const db = this.getDb()

    try {
      return await db[collection]
        .where('id')
        .anyOf(idsList)
        .toArray()
    } catch (e) {
      console.error('IndexedDB: bulk get', e)
      return false
    }
  }

  getAllByKey = async (collection, keyName, keyValue) => {
    const db = this.getDb()

    let records = []

    try {
      records = await db[collection].where({
        [keyName]: keyValue,
      })

      await records.toArray(async arr => {
        records = arr
      })
    } catch (e) {
      console.error('IndexedDB: get all records by key', e)
    }

    return records
  }

  getAllByScoringIdAndByKey = async (collection, scoringId, keyName, keyValue, orderByValue) => {
    const db = this.getDb()

    let records = []

    try {
      if (keyName && keyValue) {
        records = await db[collection]
          .where(`[scoring_id+${keyName}]`)
          .equals([scoringId, keyValue])
          .toArray()
      } else {
        records = await db[collection]
          .where('scoring_id')
          .equals(scoringId)
          .toArray()
      }

      if (orderByValue) {
        records = records.sort((a, b) => (a[orderByValue] > b[orderByValue] ? 1 : -1))
      }
    } catch (e) {
      console.error('IndexedDB: get all records by key', e)
    }

    return records
  }

  empty = async collection => {
    const db = this.getDb()

    try {
      return await db[collection].clear()
    } catch (e) {
      console.error('IndexedDB: empty collection', e)
      return false
    }
  }

  count = async (collection, scoringId, keyName, keyValue) => {
    try {
      const records = await this.getAllByScoringIdAndByKey(collection, scoringId, keyName, keyValue)
      return records.length
    } catch (err) {
      return false
    }
  }

  countAll = async collection => {
    const db = this.getDb()

    try {
      return await db[collection].count(collection)
    } catch (err) {
      return false
    }
  }

  getTexts = async lang => {
    const db = this.getDb()

    try {
      let response = await db[storageMap.texts].where({
        language: lang,
      })

      await response.toArray(async arr => {
        response = arr
      })

      return response
    } catch (e) {
      console.error('IndexedDB: get texts by lang', e)
      return false
    }
  }
}

const storage = new Storage()

export default storage
