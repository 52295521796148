import {
  loadDownloadedFromStorage,
  updateDownloadedScoringIntoLocalStorage,
  removeScoringFromLocalStorage,
} from '../../../../va-corejs-v3/actions/downloaded'

export function updateDownloaded() {
  return async dispatch => {
    const downloaded = await loadDownloadedFromStorage()
    const scorings = downloaded && downloaded.scorings ? downloaded.scorings : {}
    dispatch({
      type: 'UPDATE_DOWNLOADED',
      scorings,
    })
  }
}

export function updateDownloadedScorings(scoringId) {
  return async dispatch => {
    const scorings = await updateDownloadedScoringIntoLocalStorage(scoringId)
    dispatch({
      type: 'UPDATE_DOWNLOADED',
      scorings,
    })
  }
}

export function removeDownloadedScorings(scorings, scoringId) {
  return async dispatch => {
    await removeScoringFromLocalStorage(scoringId)
    delete scorings[scoringId]
    dispatch({
      type: 'UPDATE_DOWNLOADED',
      scorings,
    })
  }
}
