import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import authPagesMap from './authPagesMap'

const classNames = require('classnames')

export class NewPassComponent extends React.Component {
  render() {
    const { authProcess, updateNewPasswords, texts } = this.props

    const emptyNewPassword = authProcess.resetPasswordValidation.isNewPasswordEmpty && authProcess.showNewPasswordError

    const emptyRepeatedPassword =
      authProcess.resetPasswordValidation.isRepeatedPasswordEmpty && authProcess.showRepeatedPasswordError

    const notEquals = !authProcess.resetPasswordValidation.areEquals && authProcess.showRepeatedPasswordError

    const serverError = authProcess.resetPasswordValidation.errorFromServer

    const newPasswordErrorVisible = emptyNewPassword || serverError !== ''
    const repeatedPasswordErrorVisible = emptyRepeatedPassword || notEquals

    function handlePasswordChanged() {
      const newPassword = document.querySelector('.login_form_new_pass_input > input').value
      const repeatedPassword = document.querySelector('.login_form_repeated_pass_input > input').value
      updateNewPasswords(newPassword, repeatedPassword)
    }

    return (
      <div
        className={classNames({
          login_form_inner_container_active: authProcess.page === authPagesMap.newpassword,
          slide_left: authProcess.page === authPagesMap.newpassword,
          login_form_inner_container_inactive: authProcess.page !== authPagesMap.newpassword,
        })}
      >
        <div style={{ position: 'relative' }}>
          {emptyNewPassword && (
            <div className={classNames({ login_form_field_error: true })}>{texts.auth_password_empty}</div>
          )}
          {serverError !== '' && <div className={classNames({ login_form_field_error: true })}>{serverError}</div>}

          <div
            className={classNames({
              login_form_input_container: true,
              login_form_new_pass_input: true,
              login_form_pass_input: true,
              input_with_error: newPasswordErrorVisible,
            })}
          >
            <input
              type="password"
              autoComplete="off"
              placeholder=" "
              value={authProcess.newPassword}
              onChange={handlePasswordChanged}
            />
            <span
              className={classNames({
                floating_label: !newPasswordErrorVisible,
                static_label: newPasswordErrorVisible,
              })}
            >
              {texts.auth_new_password}
            </span>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          {emptyRepeatedPassword && (
            <div className={classNames({ login_form_field_error: true })}>{texts.auth_password_empty}</div>
          )}
          {!emptyRepeatedPassword && notEquals && (
            <div className={classNames({ login_form_field_error: true })}>{texts.auth_not_equal_passwords}</div>
          )}

          <div
            className={classNames({
              login_form_input_container: true,
              login_form_repeated_pass_input: true,
              login_form_pass_input: true,
              input_with_error: repeatedPasswordErrorVisible,
            })}
          >
            <input
              type="password"
              placeholder=" "
              autoComplete="off"
              value={authProcess.repeatedPassword}
              onChange={handlePasswordChanged}
            />
            <span
              className={classNames({
                floating_label: !repeatedPasswordErrorVisible,
                static_label: repeatedPasswordErrorVisible,
              })}
            >
              {texts.auth_repeat_password}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authProcess: state.authentication.authProcess,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(NewPassComponent)
