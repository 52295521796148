import storage from '../../storage'
import { httpGet } from '../../api'
import storageMap from '../../storage/storageMap'
import apiEndPoints from '../../api/apiEndPoints'

// const uuidv4 = require('uuid/v4')

export async function getStatusFromServer() {
  const statusResponse = await httpGet(apiEndPoints.statuses)
  if (statusResponse) {
    await storage.empty(storageMap.status)

    statusResponse.data.forEach(async s => {
      const status = s
      // status.id = uuidv4()

      await storage.put(storageMap.status, status)
    })
  }

  return true
}

export async function loadStatusFromLocalStorage() {
  const response = await storage.getAll(storageMap.status)
  if (response) {
    return response
  }
  return false
}
