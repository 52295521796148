// eslint-disable-next-line import/no-webpack-loader-syntax
// import latestGitHash from 'raw-loader!../../../latestGitHash'
import {
  getAppVersionDataFromLocalStorage,
  updateGitHashIntoLocalStorage,
} from '../../../../va-corejs-v3/actions/app_version'

export function verifyAppVersionAndUpdateRedux() {
  return async dispatch => {
    const latestGitHash = process.env.githash

    console.log(`GitHash :${latestGitHash}`)

    const StoredAppVersionData = await getAppVersionDataFromLocalStorage()
    let isLastVersion = false
    let githash = null

    if (!StoredAppVersionData) {
      await updateGitHashIntoLocalStorage(latestGitHash)
      isLastVersion = true
      githash = latestGitHash
    } else {
      const storedGitHash = StoredAppVersionData.githash
      isLastVersion = storedGitHash === latestGitHash
      githash = storedGitHash
    }

    dispatch({
      type: 'UPDATE_APP_VERSION',
      isLastVersion,
      githash,
    })
  }
}
