import React from 'react'
import { connect } from 'react-redux'

import './styles.scss'

import * as actionCreators from '../../store/actions'

import LanguageSelector from './_parts/languageSelector'
import ColumnsExportButton from './_parts/columnsExportButton'
import screenTypesMap from '../../layout/_parts/screenTypesMap'
import AdminSettingsIcon from '../../assets/svg/menu-admin.svg'

const classNames = require('classnames')

export class Burger extends React.Component {
  render() {
    const { device, texts, authentication } = this.props

    return (
      <div
        className={classNames({
          burger_menu: true,
          burger_menu_visible: device.burgerMenuVisible,
          burger_menu_hidden: !device.burgerMenuVisible,
          isSafari: device.isSafari,
          isFirefox: device.isFirefox,
          desk: device.screenType === screenTypesMap.desk,
          ipadh: device.screenType === screenTypesMap.iPadH,
          ipadv: device.screenType === screenTypesMap.iPadV,
          iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          iphonese: device.screenType === screenTypesMap.iPhoneSE,
        })}
      >
        <div className={classNames({ burger_title: true })}>{texts.choose_language}</div>

        <div className={classNames({ burger_item: true, language_switcher: true })}>
          <LanguageSelector />
        </div>

        {process.env.backendUrl && authentication.user.parsedPermissions.administration && (
          <div
            className={classNames({
              burger_item: true,
              burger_item_default: true,
            })}
          >
            <a
              href={`${process.env.backendUrl}`}
              target="_blank"
              rel="noreferrer"
              className={classNames({
                burger_item_inner_container: true,
              })}
            >
              <span
                className={classNames({
                  burger_item_icon: true,
                })}
              >
                <AdminSettingsIcon />
              </span>
              {texts.admin_settings}
            </a>
          </div>
        )}

        {authentication.user.parsedPermissions.administration && <ColumnsExportButton />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    device: state.device,
    authentication: state.authentication,
  }
}

export default connect(mapStateToProps, actionCreators)(Burger)
