import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as DOMPurify from 'dompurify'
import * as actionCreators from '../../../store/actions'

import CommentFeedbackPositive from '../../../assets/svg/comment-feedback-positive.svg'
import CommentFeedbackNeutral from '../../../assets/svg/comment-feedback-neutral.svg'
import CommentFeedbackNegative from '../../../assets/svg/comment-feedback-negative.svg'
import commentFeedbackMap from '../../scoring_tree/helper/commentFeedbackMap'
import { getMediaBlobUrl } from '../../../../va-corejs-v3/api'

class Comment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      avatarUrl: null,
    }
  }

  componentDidMount = async () => {
    const { comment } = this.props

    if (comment.author) {
      this.setState({
        avatarUrl: await getMediaBlobUrl(comment.author.media[0].url),
      })
    }
  }

  FeedbackIcon = comment => {
    switch (comment.feedback) {
      case commentFeedbackMap.positive:
        return <CommentFeedbackPositive />
      case commentFeedbackMap.negative:
        return <CommentFeedbackNegative />

      default:
        return <CommentFeedbackNeutral />
    }
  }

  render() {
    const { comment } = this.props
    const { avatarUrl } = this.state

    const renderCommentText = text => {
      let output

      // remove html tag
      output = text.replace(/(<([^>]+)>)/gi, '')

      // let break space in html tag as before
      output = output.replace(/\n/g, '<br />')
      return output
    }

    return (
      <div className="inter_ranking_comment" key={comment.id}>
        <div className="inter_ranking_comment_avatar">{comment.author && <img src={avatarUrl} alt="avatar" />}</div>
        <div className="inter_ranking_comment_content">
          <div className="inter_ranking_comment_info">
            {this.FeedbackIcon(comment)}
            <span className="inter_ranking_comment_author">{comment.author ? comment.author.name : '-'}</span>
            <div className="inter_ranking_comment_locale">{comment.locale.code}</div>
          </div>
          <div
            className="inter_ranking_comment_text"
            dangerouslySetInnerHTML={{
              __html: comment?.body ? DOMPurify.sanitize(renderCommentText(comment?.body)) : '',
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(Comment)
