import _ from 'lodash'
import {
  generateNewCommentsListStructureForRedux,
  generateCommentsForRedux,
  updateCommentIntoLocalStorageAndReturnUpdatedNewCommentsObj,
  removeCommentFromStorage,
  updateCommentEditingValue,
  updateCommentIntoStorage,
  getAllComments,
} from '../../../../va-corejs-v3/actions/comments'

const arrayMove = require('array-move')

export function initializeNewCommentsListForRedux(scoringId, nodeDefsObj, itemsFatherId, defaultLocale, author) {
  return async dispatch => {
    const newComments = await generateNewCommentsListStructureForRedux(
      scoringId,
      nodeDefsObj,
      itemsFatherId,
      defaultLocale,
      author
    )

    dispatch({
      type: 'UPDATE_NEW_COMMENTS',
      newComments,
    })
  }
}

export function initNewCommentsForecast(scoringId, nodeDefsObj, itemsFatherId, defaultLocale, author) {
  return async dispatch => {
    const newCommentsForecast = await generateNewCommentsListStructureForRedux(
      scoringId,
      nodeDefsObj,
      itemsFatherId,
      defaultLocale,
      author
    )

    Object.keys(newCommentsForecast).forEach(index => {
      newCommentsForecast[index].is_forecast = true
    })

    dispatch({
      type: 'UPDATE_NEW_COMMENTS_FORECAST',
      newCommentsForecast,
    })
  }
}

export function updateNewCommentsObj(newComments) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_NEW_COMMENTS',
      newComments,
    })
  }
}

export function updateNewCommentsForecastObj(newCommentsForecast) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_NEW_COMMENTS_FORECAST',
      newCommentsForecast,
    })
  }
}

export function getCommentsListFromStorage(scoringId, itemsFatherId) {
  return async dispatch => {
    const comments = await generateCommentsForRedux(scoringId, itemsFatherId)
    dispatch({
      type: 'UPDATE_COMMENTS',
      comments,
    })
  }
}

export function getAllCommentsFromStorage(scoringId) {
  return async dispatch => {
    const comments = await getAllComments(scoringId)
    dispatch({
      type: 'UPDATE_SCORING_TREE_COMMENTS',
      comments,
    })
  }
}

export function loadCommentsFromStorageByItemIdAndUpdateRedux(scoringId, itemsFatherId) {
  return async dispatch => {
    const comments = await generateCommentsForRedux(scoringId, itemsFatherId)
    dispatch({
      type: 'UPDATE_COMMENTS',
      comments,
    })
  }
}

export function deleteComment(scoringTree, comment) {
  return async dispatch => {
    const { comments, scoring } = await removeCommentFromStorage(scoringTree, comment)

    dispatch({
      type: 'UPDATE_COMMENTS',
      comments,
    })
    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring,
    })
  }
}

export function moveNewCommentIntoStorageAndUpdateRedux(scoringTree, newCommentsObj, nodeDefId) {
  return async dispatch => {
    const response = await updateCommentIntoLocalStorageAndReturnUpdatedNewCommentsObj(
      scoringTree,
      newCommentsObj,
      nodeDefId
    )

    const { comment, newComments, scoring } = response

    const comments = await generateCommentsForRedux(comment.scoring_id, comment.items_father_id)

    dispatch({
      type: 'UPDATE_COMMENTS_AND_NEW_COMMENTS',
      comments,
      newComments,
    })
    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring,
    })
  }
}

export function updateCommentEditingValueIntoStorageAndUpdateRedux(commentId, editing) {
  return async dispatch => {
    const comments = await updateCommentEditingValue(commentId, editing)
    dispatch({
      type: 'UPDATE_COMMENTS',
      comments,
    })
  }
}

export function moveNewCommentForecastIntoStorageAndUpdateRedux(scoringTree, newCommentsForecastObj, nodeDefId) {
  return async dispatch => {
    const response = await updateCommentIntoLocalStorageAndReturnUpdatedNewCommentsObj(
      scoringTree,
      newCommentsForecastObj,
      nodeDefId
    )

    const { comment, newComments, scoring } = response

    if (newComments[nodeDefId]) newComments[nodeDefId].is_forecast = true

    const comments = await generateCommentsForRedux(comment.scoring_id, comment.items_father_id)

    dispatch({
      type: 'UPDATE_COMMENTS_AND_NEW_COMMENTS_FORECAST',
      comments,
      newCommentsForecast: newComments,
    })
    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring,
    })
  }
}

export function updateCommentIntoStorageAndUpdateRedux(scoringTree, comment) {
  return async dispatch => {
    const comments = await updateCommentIntoStorage(comment)
    dispatch({
      type: 'UPDATE_COMMENTS',
      comments,
    })
    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring: scoringTree.scoring,
    })
  }
}

export function changeCommentsOrder(comments, oldIndex, newIndex) {
  return async dispatch => {
    const newList = arrayMove(comments, oldIndex, newIndex)
    const promises = []

    for (let i = 0; i < newList.length; i += 1) {
      promises.push(
        new Promise((resolve, reject) => {
          newList[i].order = i + 1
          updateCommentIntoStorage(newList[i])
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      )
    }
    const result = await Promise.all(promises)

    dispatch({
      type: 'UPDATE_COMMENTS',
      comments: _.last(result), // Only the latest result is needed because of the result of updateCommentIntoStorage function
    })
  }
}
