import storage from '../../storage'
import storageMap from '../../storage/storageMap'

export async function getSystemData() {
  const result = await storage.get(storageMap.system_data, 0)
  return result
}

export async function initializeSystemData() {
  let systemData = await storage.get(storageMap.system_data, 0)

  if (systemData === undefined) {
    systemData = {
      id: 0,
      initialized: true,
      language: process.env.defaultLang,
    }
  } else {
    systemData.initializeSystemData = true
  }

  await storage.put(storageMap.system_data, systemData)
}

export async function updateSystemDataLang(lang) {
  const systemData = await storage.get(storageMap.system_data, 0)
  systemData.language = lang

  await storage.put(storageMap.system_data, systemData)
}

export async function getOnlineStatusFromIndexedDb() {
  const systemData = await storage.get(storageMap.system_data, 0)

  const isOnline = systemData && systemData.isOnline !== undefined ? systemData.isOnline : true
  return isOnline
}

export async function updateOnlineOfflineStatusIntoSystemData(isOnline) {
  const systemData = await storage.get(storageMap.system_data, 0)
  if (systemData && systemData !== undefined) {
    systemData.isOnline = isOnline
    await storage.put(storageMap.system_data, systemData)
  }
}
