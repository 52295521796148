import React, { Component } from 'react'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'

import './styles.scss'
import PrevIcon from '../../../../assets/svg/lightbox-arrow-big-left.svg'
import NextIcon from '../../../../assets/svg/lightbox-arrow-big-right.svg'
import screenTypesMap from '../../../../layout/_parts/screenTypesMap'
import CarouselItems from './carouselItems'

import { createInterRankingProductsList } from '../../../../../va-corejs-v3/utils'

const classNames = require('classnames')

class Carousel extends Component {
  state = {
    direction: 'left',
  }

  moveLeft = productsList => {
    let { carouselIndex } = this.props
    const {
      activeScore,
      updateInterRankingActiveScore,
      updateInterRankingCarouselIndex,
      updateInterRankingProductMediaAndComments,
      nodeDefId,
      scoringTree,
    } = this.props
    if (carouselIndex === 0) {
      return
    }
    carouselIndex -= 1
    this.setState({
      direction: 'left',
    })
    const newScore = productsList[carouselIndex].score
    if (newScore !== activeScore) {
      updateInterRankingActiveScore(newScore)
    }
    updateInterRankingCarouselIndex(carouselIndex)
    const { nodeDefsObj } = scoringTree
    const itemId = nodeDefsObj[nodeDefId].parent_id
    updateInterRankingProductMediaAndComments(productsList[carouselIndex].id, itemId, nodeDefId, 'focused')
  }

  moveRight = productsList => {
    let { carouselIndex } = this.props
    const {
      activeScore,
      updateInterRankingActiveScore,
      updateInterRankingCarouselIndex,
      updateInterRankingProductMediaAndComments,
      nodeDefId,
      scoringTree,
    } = this.props
    if (carouselIndex === productsList.length - 1) {
      return
    }
    carouselIndex += 1
    this.setState({
      direction: 'right',
    })
    const newScore = productsList[carouselIndex].score
    if (newScore !== activeScore) {
      updateInterRankingActiveScore(newScore)
    }
    updateInterRankingCarouselIndex(carouselIndex)
    const { nodeDefsObj } = scoringTree
    const itemId = nodeDefsObj[nodeDefId].parent_id
    updateInterRankingProductMediaAndComments(productsList[carouselIndex].id, itemId, nodeDefId, 'focused')
  }

  render() {
    const { direction } = this.state
    const { device, activeScore, filteredScore, pin, productsFromServer, carouselPinned } = this.props

    const productsList = createInterRankingProductsList(productsFromServer, filteredScore, pin)

    if (activeScore !== false) {
      return (
        <div
          className={classNames({
            carousel_shell: true,
            isSafari: device.isSafari,
            isFirefox: device.isFirefox,
            desk: device.screenType === screenTypesMap.desk,
            ipadh: device.screenType === screenTypesMap.iPadH && carouselPinned === undefined,
            ipadv: device.screenType === screenTypesMap.iPadV,
            iphonexr: device.screenType === screenTypesMap.iPhoneXR,
            iphonese: device.screenType === screenTypesMap.iPhoneSE,
            desk_pinned: device.screenType === screenTypesMap.desk && carouselPinned,
            ipadh_pinned: device.screenType === screenTypesMap.iPadH && carouselPinned,
          })}
        >
          <div
            className={classNames({
              carousel: true,
              noselect: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH && !carouselPinned,
              ipadv: device.screenType === screenTypesMap.iPadV || carouselPinned,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
              carousel_hidden: device.screenType === screenTypesMap.iPadV && carouselPinned,
            })}
          >
            <div
              className="arrow arrow-left"
              role="button"
              tabIndex={0}
              onKeyDown={() => this.moveLeft(productsList)}
              onClick={() => this.moveLeft(productsList)}
            >
              <PrevIcon className="arrow_icon_left" />
            </div>
            <CSSTransitionGroup
              transitionName={direction}
              transitionEnterTimeout={200}
              transitionLeaveTimeout={100}
              transitionAppear
              transitionAppearTimeout={100}
            >
              <CarouselItems productsList={productsList} carouselPinned={carouselPinned} />
            </CSSTransitionGroup>
            <div
              className="arrow arrow-right"
              role="button"
              tabIndex={0}
              onKeyDown={() => this.moveRight(productsList)}
              onClick={() => this.moveRight(productsList)}
            >
              <NextIcon className="arrow_icon_right" />
            </div>
          </div>
        </div>
      )
    }

    return <></>
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoringTree: state.scoringTree,
    nodeDefId: state.interRanking.nodeDefId,
    activeScore: state.interRanking.activeScore,
    productsFromServer: state.interRanking.productsFromServer,
    carouselIndex: state.interRanking.carouselIndex,
    pin: state.interRanking.pin,
    filteredScore: state.interRanking.filteredScore,
  }
}

export default connect(mapStateToProps, actionCreators)(Carousel)
