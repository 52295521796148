import storage from '../../storage'
import { httpGet } from '../../api'
import storageMap from '../../storage/storageMap'
import apiEndPoints from '../../api/apiEndPoints'

export async function getScopesFromServer() {
  const scopesResponse = await httpGet(apiEndPoints.scopes_all, false)
  // const scopesResponse = await httpGet('scopes')
  if (scopesResponse) {
    scopesResponse.data.forEach(async scope => {
      const updated = await storage.update(storageMap.scope, scope)
      return updated
    })
  }

  return true
}

export async function loadScopesFromLocalStorage() {
  const response = await storage.getAll(storageMap.scope)
  if (response) {
    return response
  }
  return false
}
