import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

import IconDownload from '../../../../assets/svg/icon-download.svg'
import IconPen from '../../../../assets/svg/icon-pen.svg'
import IconTrash from '../../../../assets/svg/icon-trash.svg'
import mediaCollectionsMap from '../../../../../va-corejs-v3/utils/mediaCollectionsMap'
import { isValidURL } from '../../../../../va-corejs-v3/utils'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'

const classNames = require('classnames')

export class ScoringPicture extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pictureUrl: null,
    }
  }

  setPictureUrl = async () => {
    const { scoring, collection } = this.props

    let pictureUrl = null
    if (collection === mediaCollectionsMap.cover_pictures) {
      if (Object.keys(scoring.coverPicture).length > 0) {
        pictureUrl = scoring.coverPicture.file
        if (pictureUrl && !pictureUrl.startsWith('data:')) {
          pictureUrl = await getMediaBlobUrl(pictureUrl)
        }
      }
    } else if (collection === mediaCollectionsMap.full_exterior) {
      if (Object.keys(scoring.fullExterior).length > 0) {
        pictureUrl = scoring.fullExterior.file
        if (pictureUrl && !pictureUrl.startsWith('data:')) {
          pictureUrl = await getMediaBlobUrl(pictureUrl)
        }
      }
    } else if (collection === mediaCollectionsMap.full_interior) {
      if (Object.keys(scoring.fullInterior).length > 0) {
        pictureUrl = scoring.fullInterior.file
        if (pictureUrl && !pictureUrl.startsWith('data:')) {
          pictureUrl = await getMediaBlobUrl(pictureUrl)
        }
      }
    } else if (collection === mediaCollectionsMap.hmi) {
      if (Object.keys(scoring.hmi).length > 0) {
        pictureUrl = scoring.hmi.file
        if (pictureUrl && !pictureUrl.startsWith('data:')) {
          pictureUrl = await getMediaBlobUrl(pictureUrl)
        }
      }
    } else if (collection === mediaCollectionsMap.cargo) {
      if (Object.keys(scoring.cargo).length > 0) {
        pictureUrl = scoring.cargo.file
        if (pictureUrl && !pictureUrl.startsWith('data:')) {
          pictureUrl = await getMediaBlobUrl(pictureUrl)
        }
      }
    }

    this.setState({
      pictureUrl,
    })
  }

  componentDidMount = async () => {
    await this.setPictureUrl()
  }

  componentDidUpdate = async prevProps => {
    const { scoring, collection } = this.props

    if (prevProps.scoring !== scoring || prevProps.collection !== collection) {
      await this.setPictureUrl()
    }
  }

  render() {
    const {
      scoring,
      setScoringPicture,
      removeScoringPicture,
      addMediaToScoring,
      removeMediaFromScoring,
      collection,
      label,
      mandatory,
      alignment,
    } = this.props

    const { pictureUrl } = this.state

    function handleChooseImageClicked() {
      const inputFile = document.querySelector(`input[type=file].input_file_${collection}`)
      inputFile.value = ''
      inputFile.click()
    }

    async function managePicture(files) {
      const file = files[0]
      const picture = await setScoringPicture(scoring.newScoringId, file, collection)
      addMediaToScoring(scoring.newScoringId, picture)
    }

    function handleFileUpload(e) {
      const { files } = e.target
      managePicture(files)
    }

    async function handleDownloaPictureClicked() {
      let pictureDef = null
      if (collection === mediaCollectionsMap.cover_pictures) {
        pictureDef = scoring.coverPicture
      } else if (collection === mediaCollectionsMap.full_exterior) {
        pictureDef = scoring.fullExterior
      } else if (collection === mediaCollectionsMap.full_interior) {
        pictureDef = scoring.fullInterior
      } else if (collection === mediaCollectionsMap.hmi) {
        pictureDef = scoring.hmi
      } else if (collection === mediaCollectionsMap.cargo) {
        pictureDef = scoring.cargo
      }

      if (pictureDef !== null) {
        const url = isValidURL(pictureDef.file) ? await getMediaBlobUrl(`${pictureDef.file}`) : pictureDef.file

        const a = document.createElement('a')
        a.href = url
        a.download = pictureDef.file_name ? pictureDef.file_name : `${collection}_image.${pictureDef.file_ext}`
        a.click()
      }
    }

    function handleDeletePictureClicked() {
      removeScoringPicture(scoring.newScoringId, collection)
      removeMediaFromScoring(scoring.newScoringId, collection)
    }

    return (
      <div
        className={classNames({
          picture_outer_container: true,
        })}
      >
        <input
          type="file"
          className={`scoring_picture_file_selector input_file_${collection}`}
          accept="image/png, image/jpeg"
          onChange={handleFileUpload}
        />
        {pictureUrl && (
          <>
            <div
              className={classNames({
                picture_label: true,
              })}
            >
              {label}
            </div>
            <div
              className={classNames({
                picture_inner_container: true,
              })}
              style={{
                background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 72%, #000000 96%), url("${pictureUrl}")`,
              }}
            >
              <div
                className={classNames({
                  picture_buttons_container: true,
                })}
              >
                <div
                  className={classNames({
                    picture_action_button: true,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={handleDownloaPictureClicked}
                  onKeyPress={handleDownloaPictureClicked}
                >
                  <IconDownload />
                </div>
                <div
                  className={classNames({
                    picture_action_button: true,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={handleChooseImageClicked}
                  onKeyPress={handleChooseImageClicked}
                >
                  <IconPen />
                </div>
                <div
                  className={classNames({
                    picture_action_button: true,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={handleDeletePictureClicked}
                  onKeyPress={handleDeletePictureClicked}
                >
                  <IconTrash />
                </div>
              </div>
            </div>
          </>
        )}
        {pictureUrl === null && (
          <>
            <button
              type="button"
              className={classNames({
                sm_btn: true,
                theme_primary_btn: mandatory === true,
                secondary_btn_type_2: mandatory === false,
                picture_btn_left_aligned: alignment === 'left',
                picture_btn_center_aligned: alignment === 'center',
                picture_btn_right_aligned: alignment === 'right',
                btn_195: true,
                theme_color: true,
              })}
              onClick={handleChooseImageClicked}
            >
              <span>{label}</span>
            </button>
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    status: state.status,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringPicture)
