/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import FsLightbox from 'fslightbox-react'
import * as actionCreators from '../../../../store/actions'
import screenTypesMap from '../../../../layout/_parts/screenTypesMap'
import PinIcon from '../../../../assets/svg/pin.svg'
import NoMediaIcon from '../../../../assets/svg/no-media.svg'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'
import ZoomedItem from './zoomedItem'
import { getProductBrandAndModelLabel, createInterRankingProductsList } from '../../../../../va-corejs-v3/utils'

// https://www.npmjs.com/package/react-addons-css-transition-group

const classNames = require('classnames')

class CarouselItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pinModelEnabled: false,
      toggleLightbox: false,
      thumbUrl: '',
    }
  }

  componentDidMount = async () => {
    // Set pinModelEnabled
    this.setState({
      pinModelEnabled: process.env.interRankingPinModelEnabled
        ? JSON.parse(process.env.interRankingPinModelEnabled)
        : false,
    })
    await this.setMediaBlobUrls()
  }

  setMediaBlobUrls = async () => {
    const { itemData } = this.props
    if (itemData) {
      const { thumbMediaUrl } = itemData

      this.setState({
        thumbUrl: thumbMediaUrl && thumbMediaUrl !== '' ? await getMediaBlobUrl(thumbMediaUrl) : '',
      })
    }
  }

  handlePin = itemData => {
    const {
      updateInterRankingPin,
      updateInterRankingProductMediaAndComments,
      nodeDefId,
      productsFromServer,
      filteredScore,
      updateInterRankingCarouselIndex,
      scoringTree,
    } = this.props
    let { carouselIndex } = this.props
    // Update pin and load pinned media and comment
    const productsList = createInterRankingProductsList(productsFromServer, filteredScore, itemData)
    let itemId = 0
    if (scoringTree) {
      const { nodeDefsObj } = scoringTree
      itemId = nodeDefsObj[nodeDefId].parent_id
    }
    updateInterRankingPin(itemData)
    updateInterRankingProductMediaAndComments(itemData.id, itemId, nodeDefId, 'pinned')

    // Recalc carousleIndex on productList without pinned product
    carouselIndex = carouselIndex >= productsList.length ? carouselIndex - 1 : carouselIndex
    updateInterRankingCarouselIndex(carouselIndex)
    if (scoringTree) {
      const { nodeDefsObj } = scoringTree
      itemId = nodeDefsObj[nodeDefId].parent_id
      updateInterRankingProductMediaAndComments(productsList[carouselIndex].id, itemId, nodeDefId, 'focused')
    }
  }

  handleFocusProduct = picIndex => {
    if (picIndex === undefined) {
      const { toggleLightbox: oldToggleLightbox } = this.state
      this.setState({
        toggleLightbox: !oldToggleLightbox,
      })
      return
    }
    const {
      productsFromServer,
      filteredScore,
      updateInterRankingCarouselIndex,
      updateInterRankingProductMediaAndComments,
      nodeDefId,
      scoringTree,
    } = this.props

    // Update pin and load pinned media and comment
    const productsList = createInterRankingProductsList(productsFromServer, filteredScore, false)
    updateInterRankingCarouselIndex(picIndex)
    const { nodeDefsObj } = scoringTree
    const itemId = nodeDefsObj[nodeDefId].parent_id
    updateInterRankingProductMediaAndComments(productsList[picIndex].id, itemId, nodeDefId, 'focused')
  }

  render() {
    const { device, level, itemData, carouselPinned, picIndex } = this.props
    const { toggleLightbox, thumbUrl, pinModelEnabled } = this.state
    const { zoomedMediaUrl } = itemData

    if (itemData && itemData.score !== false) {
      const { props } = itemData
      const { score } = itemData
      const name = getProductBrandAndModelLabel(props)
      return (
        <>
          <FsLightbox
            toggler={toggleLightbox}
            sources={[
              <div style={{ width: '100vw', height: '100vh', textAlign: 'center' }}>
                <ZoomedItem url={zoomedMediaUrl} />
              </div>,
            ]}
          />
          <div
            style={{ backgroundImage: `url(${thumbUrl})` }}
            className={classNames({
              default_background: thumbUrl === '',
              item: true,
              level_2: level === -2,
              level_1: level === -1,
              level0: level === 0,
              level1: level === 1,
              level2: level === 2,
              pinned: device.screenType === screenTypesMap.desk && carouselPinned !== undefined,
              ipadh_pinned: device.screenType === screenTypesMap.iPadH && carouselPinned !== undefined,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH && carouselPinned !== undefined,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
            role="button"
            tabIndex={0}
            onClick={level === 0 ? () => this.handleFocusProduct() : () => this.handleFocusProduct(picIndex)}
          >
            {thumbUrl === '' && (
              <div className="no_media_icon">
                <NoMediaIcon />
              </div>
            )}
            {level === 0 && pinModelEnabled && (
              <div
                className={classNames({
                  pin: true,
                  isSafari: device.isSafari,
                  isFirefox: device.isFirefox,
                  desk: device.screenType === screenTypesMap.desk,
                  ipadh: device.screenType === screenTypesMap.iPadH,
                  ipadv: device.screenType === screenTypesMap.iPadV,
                  iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                  iphonese: device.screenType === screenTypesMap.iPhoneSE,
                  desk_pinned: device.screenType === screenTypesMap.desk && carouselPinned !== undefined,
                })}
                role="button"
                tabIndex={0}
                onKeyDown={() => this.handlePin(itemData)}
                onClick={() => this.handlePin(itemData)}
              >
                <PinIcon /> <span className="pin_label">Pin this model</span>
              </div>
            )}
            <div className={`score_level${level}`}>
              {level >= 0 && (
                <div
                  className={classNames({
                    criterion_score_btn: true,
                    criterion_score_btn_selected: true,
                    criterion_score_value_0: score === 0,
                    criterion_score_value_0_5: score === 0.5,
                    criterion_score_value_1: score === 1,
                    criterion_score_value_1_5: score === 1.5,
                    criterion_score_value_2: score === 2,
                    criterion_score_value_2_5: score === 2.5,
                    criterion_score_value_3: score === 3,
                    criterion_score_value_3_5: score === 3.5,
                    criterion_score_value_4: score === 4,
                    criterion_score_value_4_5: score === 4.5,
                    criterion_score_value_5: score === 5,
                    criterion_score_value_btn_small:
                      level === 1 || level === -1 || device.screenType === screenTypesMap.iPadV,
                    criterion_score_value_btn_extra_small:
                      level === 2 ||
                      level === -2 ||
                      ((level === 1 || level === -1) && device.screenType === screenTypesMap.iPadV),
                  })}
                  key={score}
                  role="button"
                  tabIndex={0}
                >
                  <div>{score}</div>
                </div>
              )}
              <div
                className={classNames({
                  score_level_title_positive: level >= 0,
                  score_level_title_negative: level < 0,
                  lv1: level === 1 || level === -1,
                  lv2: level === 2 || level === -2,
                })}
              >
                {name}
              </div>
              {level < 0 && (
                <div
                  className={classNames({
                    criterion_score_btn: true,
                    criterion_score_btn_selected: true,
                    criterion_score_value_0: score === 0,
                    criterion_score_value_0_5: score === 0.5,
                    criterion_score_value_1: score === 1,
                    criterion_score_value_1_5: score === 1.5,
                    criterion_score_value_2: score === 2,
                    criterion_score_value_2_5: score === 2.5,
                    criterion_score_value_3: score === 3,
                    criterion_score_value_3_5: score === 3.5,
                    criterion_score_value_4: score === 4,
                    criterion_score_value_4_5: score === 4.5,
                    criterion_score_value_5: score === 5,
                    criterion_score_value_btn_small: level === 1 || level === -1,
                    criterion_score_value_btn_extra_small: level === 2 || level === -2,
                  })}
                  key={score}
                  role="button"
                  tabIndex={0}
                >
                  <div>{score}</div>
                </div>
              )}
            </div>
          </div>
        </>
      )
    }

    return <></>
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    pin: state.interRanking.pin,
    nodeDefId: state.interRanking.nodeDefId,
    productsFromServer: state.interRanking.productsFromServer,
    filteredScore: state.interRanking.filteredScore,
    carouselIndex: state.interRanking.carouselIndex,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(CarouselItem)
