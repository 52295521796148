import { getHomeDataAndUpdateStorage } from '../../../../va-corejs-v3/actions/home'

export function getHomeData() {
  return async dispatch => {
    const homeResponse = await getHomeDataAndUpdateStorage()
    const blocks = homeResponse === undefined ? [] : homeResponse.data

    dispatch({
      type: 'UPDATE_HOME_DATA',
      blocks,
    })
  }
}
