import React from 'react'
import { connect } from 'react-redux'

import { Modal } from '@material-ui/core'
import * as actionCreators from '../../../store/actions'
import DownloadIcon from '../../../assets/svg/download-icon-black.svg'
import apiEndPoints from '../../../../va-corejs-v3/api/apiEndPoints'
import { httpGet } from '../../../../va-corejs-v3/api'

const classNames = require('classnames')

export class ColumnsExportButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      submitted: false,
      templateCodeSelected: null,
    }
  }

  componentDidMount = () => {
    const { templates } = this.props
    if (templates.length > 0) {
      this.setState({
        templateCodeSelected: templates[0].code,
      })
    }
  }

  render() {
    const { texts, templates, environment } = this.props
    const { open, submitted, templateCodeSelected } = this.state
    const currentLocale = environment.lang ?? environment.defaultLang

    const handleOpen = () => {
      this.setState({
        open: true,
        submitted: false,
      })
    }

    const handleClose = () => {
      this.setState({
        open: false,
        submitted: false,
      })
    }

    const handleExport = e => {
      e.stopPropagation()
      httpGet(apiEndPoints.export_columns.replace('{templateCode}', templateCodeSelected))
      this.setState({
        submitted: true,
      })
    }

    const handleChangeTemplate = e => {
      this.setState({
        templateCodeSelected: e.target.value,
      })
    }

    return (
      <>
        <div
          className={classNames({
            burger_item: true,
            burger_item_default: true,
          })}
        >
          <div
            role="button"
            className={classNames({
              burger_item_inner_container: true,
            })}
            onClick={handleOpen}
            onKeyPress={handleOpen}
            tabIndex={0}
          >
            <span
              className={classNames({
                burger_item_icon: true,
              })}
            >
              <DownloadIcon />
            </span>
            {texts.export_columns}
          </div>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="columns_export_modal_title"
          className={classNames({
            columns_export_modal: true,
          })}
        >
          <div
            className={classNames({
              columns_export_modal_inner: true,
            })}
          >
            <div className="columns_export_modal_body">
              <h3 id="columns_export_modal_title">{texts.export_columns}</h3>

              {!submitted && (
                <>
                  <p>{texts.export_columns_template_selection_text}</p>
                  <div className="columns_export_inputs">
                    <label htmlFor="columns_export_template_select" className="columns_export_form_label">
                      {texts.template}
                      <select
                        id="columns_export_template_select"
                        className="columns_export_select"
                        onChange={e => handleChangeTemplate(e)}
                      >
                        {templates.map(item => (
                          <option value={item.code} key={item.id} defaultValue={templateCodeSelected === item.code}>
                            {item.name[currentLocale]}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </>
              )}

              {submitted && <p>{texts.export_columns_template_submitted_text}</p>}
            </div>

            <div className="columns_export_modal_actions">
              {!submitted && (
                <>
                  <button type="button" onClick={handleClose} className="button_cancel">
                    {texts.cancel}
                  </button>
                  <button
                    type="button"
                    onClick={e => handleExport(e)}
                    className="button_export"
                    disabled={templateCodeSelected === null}
                  >
                    {texts.export}
                  </button>
                </>
              )}

              {submitted && (
                <>
                  <button type="button" onClick={handleClose} className="button_cancel">
                    {texts.close}
                  </button>
                </>
              )}
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    templates: state.templates.list,
  }
}

export default connect(mapStateToProps, actionCreators)(ColumnsExportButton)
