import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../store/actions'

import authPagesMap from './authPagesMap'

const classNames = require('classnames')

export class EmailComponent extends React.Component {
  render() {
    const { authProcess, updateUserEmail, texts, handleShowPasswordPage } = this.props

    const emptyEmail = authProcess.showUserEmailError && authProcess.userEmailValidation.isEmpty
    const invalidEmail =
      authProcess.showUserEmailError &&
      !authProcess.userEmailValidation.isEmpty &&
      !authProcess.userEmailValidation.isValid
    const emailNotFound =
      authProcess.showUserEmailError &&
      !authProcess.userEmailValidation.isEmpty &&
      authProcess.userEmailValidation.isValid &&
      authProcess.userEmailValidation.checkedIfExists &&
      !authProcess.userEmailValidation.exists

    const fieldErrorVisible = emptyEmail || invalidEmail || emailNotFound

    return (
      <div
        className={classNames({
          // slide_left: true,
          login_form_inner_container_active: authProcess.page === authPagesMap.useremail,
          login_form_inner_container_inactive: authProcess.page !== authPagesMap.useremail,
        })}
      >
        {emptyEmail && (
          <div
            className={classNames({
              login_form_field_error: true,
            })}
          >
            {texts.auth_user_email_empty}
          </div>
        )}
        {invalidEmail && (
          <div
            className={classNames({
              login_form_field_error: true,
            })}
          >
            {texts.auth_user_email_not_valid}
          </div>
        )}
        {emailNotFound && (
          <div
            className={classNames({
              login_form_field_error: true,
            })}
          >
            {texts.auth_user_email_not_found}
          </div>
        )}
        <div
          className={classNames({
            login_form_input_container: true,
            login_form_user_input: true,
            input_with_error: fieldErrorVisible,
          })}
        >
          <input
            type="text"
            placeholder=" "
            value={authProcess.userEmail}
            autoComplete="off"
            onChange={e => {
              updateUserEmail(e)
            }}
            onKeyPress={e => {
              const code = e.which ? e.which : e.keyCode
              if (code === 13) {
                handleShowPasswordPage()
              }
            }}
          />
          <span
            className={classNames({
              floating_label: !fieldErrorVisible,
              static_label: fieldErrorVisible,
            })}
          >
            E-mail
          </span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authProcess: state.authentication.authProcess,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(EmailComponent)
