import storage from '../../storage'
import storageMap from '../../storage/storageMap'

export async function loadDownloadedFromStorage() {
  const response = await storage.get(storageMap.downloaded, 0)
  return response
}

export async function updateDownloadedScoringIntoLocalStorage(scoringId) {
  const response = await storage.get(storageMap.downloaded, 0)
  const downloaded = response || { id: 0, scorings: {} }
  downloaded.scorings[scoringId] = true
  await storage.update(storageMap.downloaded, downloaded)
  return downloaded.scorings
}

export async function removeScoringFromLocalStorage(scoringId) {
  // Check if this scoring is synced to server
  // TODO

  let response = false

  // first verify if exist
  const recordToRemove = await storage.get(storageMap.scoring, scoringId)
  if (recordToRemove) {
    // Remove scoring
    response = await storage.remove(storageMap.scoring, scoringId)
    response = await storage.remove(storageMap.offline_scorings_list, scoringId)
    response = await storage.removeAllByKey(storageMap.media, 'scoring_id', scoringId)
    response = await storage.removeAllByKey(storageMap.comment, 'scoring_id', scoringId)
    response = await storage.get(storageMap.downloaded, 0)
    const downloaded = response || {}
    if (downloaded.scorings[scoringId]) {
      delete downloaded.scorings[scoringId]
      await storage.update(storageMap.downloaded, downloaded)
    }
  }

  return response
}
