import _ from 'lodash'
import {
  doUploadClipboarFilesOnServer,
  doRemoveClipboardFileOnServer,
} from '../../../../va-corejs-v3/actions/clipboard'
import msgBoxMessageMap from '../../../../va-corejs-v3/utils/msgBoxMessagesMap'

export function removeClipboardFileOnServer(scoringTree, attachment) {
  return async dispatch => {
    await doRemoveClipboardFileOnServer(scoringTree.scoring.id, attachment)
    scoringTree.scoring.media = scoringTree.scoring.media.filter(item => item.id !== attachment.id)
    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring: scoringTree.scoring,
    })
  }
}

export function uploadClipboarFilesOnServer(scoringTree, files, collection) {
  return async (dispatch, getState) => {
    const state = getState()
    const texts = state.texts.values

    dispatch({
      type: 'UPDATE_MSGBOX',
      msgboxMessage: msgBoxMessageMap.preparingUpload,
      data: {
        scoringId: 0,
        par1: 'loading_screen',
      },
    })

    const { filesUploaded, errors } = await doUploadClipboarFilesOnServer(scoringTree.scoring.id, files, collection)

    const uploadedScoring = { ...scoringTree.scoring }

    filesUploaded.map(file => {
      const mediaToUploadIndex = uploadedScoring.media.findIndex(media => media.id === file.id)

      // add media
      if (mediaToUploadIndex < 0) uploadedScoring.media.push(file)
      // overwite Media if id already exists in Media list
      else uploadedScoring.media[mediaToUploadIndex] = file

      return file
    })

    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring: uploadedScoring,
    })

    if (errors.length > 0) {
      // Some files have not been uploaded
      let message =
        errors.length > 1
          ? `${errors.length} ${texts.files_not_uploaded_message}.`
          : `${texts.file_not_uploaded_message}.`

      _.each(errors, error => {
        if (error.status === 415) {
          message += '<br>Possible cause: invalid media type'
        }
      })

      dispatch({
        type: 'UPDATE_MSGBOX',
        msgboxMessage: msgBoxMessageMap.error,
        data: {
          message,
        },
      })
    } else {
      dispatch({
        type: 'UPDATE_MSGBOX',
        msgboxMessage: '',
        data: {},
      })
    }
  }
}
