import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'

const classNames = require('classnames')

export class SplasScreenComponent extends React.Component {
  render() {
    const { texts, cobranding, setSplashScreenUsedStatus } = this.props

    function handleDisableSplashScreen() {
      setSplashScreenUsedStatus(true)
    }

    return (
      <div
        className={classNames({
          login_splash_screen: true,
        })}
      >
        <div>
          <div
            className={classNames({
              login_splash_client_logo: true,
            })}
          >
            <img src={cobranding.big_client_logo_url} alt="client logo" />
          </div>
          <div
            className={classNames({
              login_splash_valueable_logo: true,
            })}
          >
            <img src={cobranding.big_valueable_logo} alt="valueable logo" />
          </div>
        </div>
        <div>
          <button
            type="button"
            className={classNames({
              lg_btn: true,
              theme_primary_btn: true,
              btn_300: true,
              touch_btn: true,
            })}
            onClick={handleDisableSplashScreen}
          >
            <span>{texts.auth_sign_in}</span>
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    cobranding: state.environment.cobranding,
  }
}

export default connect(mapStateToProps, actionCreators)(SplasScreenComponent)
