import * as renault from './renault'
import * as honda from './honda'
import * as va from './va'

function specializer() {
  switch (global.env.client) {
    case 'renault':
      return renault
    case 'honda':
      return honda
    case 'va':
      return va
    default:
      return null
  }
}

export function isNodeEligibleForCal(node, nodeDefinition, scoreSetType = null) {
  const spec = specializer()
  return spec.isNodeEligibleForCal(node, nodeDefinition, scoreSetType)
}

export function calculate(scoring, nodeDefinitions, productNodes, skipScoringPanel) {
  const spec = specializer()
  return spec.doCalculation(scoring, nodeDefinitions, productNodes, skipScoringPanel)
}

export function createScorePanel(scoring, nodes, defs) {
  const spec = specializer()
  return spec.doCreateScorePanel(scoring, nodes, defs)
}

export function doCalculationForAnalytics(nodeDefinitions, productNodes) {
  const spec = specializer()
  return spec.doCalculationForAnalytics(nodeDefinitions, productNodes)
}

export function getRolesInfoData() {
  const spec = specializer()
  return spec.rolesInfo
}
