import storage from '../../storage'
import storageMap from '../../storage/storageMap'

export async function getAppVersionDataFromLocalStorage() {
  const response = await storage.get(storageMap.app_version, 0)
  return response
}

export async function updateGitHashIntoLocalStorage() {
  const { githash } = process.env

  await storage.update(storageMap.app_version, { id: 0, githash })
}

export async function deleteIndexedDb() {
  await storage.deleteDb()
}
