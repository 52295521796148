const msgBoxMessagesMap = {
  confirmDeleteProductAttachment: 'CONFIRM_DELETE_PRODUCT_ATTACHMENT',
  confirmDeleteProduct: 'CONFIRM_DELETE_PRODUCT',
  doubleConfirmDeleteProduct: 'DOUBLE_CONFIRM_DELETE_PRODUCT',
  confirmLockUnlockScoring: 'CONFIRM_LOCK_UNLOCK_SCORING',
  downloadAllMedia: 'DOWNLOAD_ALL_MEDIA',
  duplicateProduct: 'DUPLICATE_PRODUCT',
  idbSizeExeeded: 'IDB_SIZE_EXEEDED',
  logout: 'LOGOUT',
  offlineImageLimitator: 'OFFLINE_IMAGE_LIMITATOR',
  preparingDownload: 'PREPARING_DOWNLOAD',
  syncError: 'SYNC_ERROR',
  preparingUpload: 'PREPARING_UPLOAD',
  publishStarted: 'PUBLISH_STARTED',
  error: 'ERROR',
}

export default msgBoxMessagesMap
