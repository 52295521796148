import React from 'react'
import { connect } from 'react-redux'

import UpdateIcon from '../../assets/svg/update-icon.svg'
import { deleteIndexedDb, updateGitHashIntoLocalStorage } from '../../../va-corejs-v3/actions/app_version'
import screenTypesMap from '../../layout/_parts/screenTypesMap'
import * as actionCreators from '../../store/actions'
import './styles.scss'

const classNames = require('classnames')

export class AppVersion extends React.Component {
  componentDidMount = async () => {
    const { verifyAppVersionAndUpdateRedux } = this.props
    setTimeout(async () => {
      await verifyAppVersionAndUpdateRedux()
    }, 2000)
  }

  handleClearIndexedDbClicked = async () => {
    if (JSON.parse(process.env.destroyIdbThroughVersions) === true) {
      await deleteIndexedDb()
      if (typeof window !== 'undefined') {
        window.location.reload()
      }
    } else {
      await updateGitHashIntoLocalStorage()
    }
  }

  render() {
    const { texts, appVersion, device } = this.props
    return (
      <>
        {!appVersion.isLastVersion && (
          <div
            className={classNames({
              app_version_container: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            <div className={classNames({ app_version_label: true })}>{texts.new_version_available}</div>
            <div
              className={classNames({
                app_version_update_btn_container: true,
                theme_color: true,
                theme_svg: true,
              })}
              role="button"
              tabIndex={0}
              onClick={() => {
                this.handleClearIndexedDbClicked()
              }}
              onKeyPress={() => {
                this.handleClearIndexedDbClicked()
              }}
            >
              <UpdateIcon /> <span>{texts.new_version_available_update}</span>
            </div>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    appVersion: state.appVersion,
    device: state.device,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(AppVersion)
