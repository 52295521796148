import { reorderNodeMedia } from '../../api/media'
import { SYNC_ACTION_REORDER_MEDIA } from '../consts'
import DefaultActionHandler from './defaultActionHandler'

class ReorderMediaHandler extends DefaultActionHandler {
  constructor() {
    super()
    this.setAction(SYNC_ACTION_REORDER_MEDIA)
  }

  process = async item => {
    const { data } = item
    const { node_id: nodeId, media } = data
    return reorderNodeMedia(nodeId, media)
  }
}

export default ReorderMediaHandler
