/* eslint-disable react/jsx-no-bind */
import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import * as actionCreators from '../../../store/actions'

import ScoringDetailedPropsColumn from './scoringDetailedPropsColum'
import ScoringPicture from './props/scoringPicture'
import ScoringBrandSelector from './props/scoringBrandSelector'
import ScoringTextProp from './props/scoringTextProp'
import ScoringTextOrImageProp from './props/scoringTextOrImageProp'
import ScoringSelectDataListProp from './props/scoringSelectDataListProp'
import ScoringPriceProp from './props/scoringPriceProp'
import ScoringDistanceProp from './props/scoringDistanceProp'
import ScoringGallery from './props/scoringGallery'

import scopePropsMap from './helper/scopePropsMap'
import mediaCollectionsMap from '../../../../va-corejs-v3/utils/mediaCollectionsMap'
import { array2Object } from '../../../../va-corejs-v3/utils'
import ScoringPublisherField from './publisher/scoringPublisherField'
import { normalizeProjectModeProp } from '../../scoring_tree/_parts/projectMode/utils'

const classNames = require('classnames')

export class ScoringSpecifyProduct extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scopePropsObj: {},
      scopePropsParseCompleted: false,
      exteriorDetailsList: [],
      interiorDetailsList: [],
      otherProductSpec: undefined,
    }
  }

  componentDidMount = () => {
    const { scoring, scopes, status, setScoringStatus, templates } = this.props

    // Initialize status
    const template = _.find(templates, _t => _t.id === scoring.selectedTemplate)
    const defaultStatus = template.default_status ? template.default_status.slug : null
    const productStatus = scoring.selectedStatus || defaultStatus || status.list[0].slug
    setScoringStatus(scoring.newScoringId, productStatus)

    const actualScope = scopes.list.filter(scope => scope.id === scoring.scoringScope.id)[0]
    const exteriorDetailsList = actualScope.props.filter(
      x =>
        [
          scopePropsMap.exterior_decor,
          scopePropsMap.lights_technology,
          scopePropsMap.wheels_material,
          scopePropsMap.tire_size,
        ].indexOf(x.slug) !== -1
    )
    const interiorDetailsList = actualScope.props.filter(
      x =>
        [
          scopePropsMap.trim,
          scopePropsMap.upholstery,
          scopePropsMap.gearbox,
          scopePropsMap.screen_size,
          scopePropsMap.engine,
        ].indexOf(x.slug) !== -1
    )

    const scopePropsObj = array2Object(actualScope.props, 'slug')

    this.setState({
      exteriorDetailsList,
      interiorDetailsList,
      scopePropsObj,
      scopePropsParseCompleted: true,
    })

    const otherProductSpecSearchResult = actualScope.props.filter(
      x => x.slug === scopePropsMap.other_product_specifications
    )

    if (otherProductSpecSearchResult.length === 1) {
      const otherProductSpec = otherProductSpecSearchResult[0]

      this.setState({ otherProductSpec })
    }
  }

  render() {
    const {
      texts,
      status,
      environment,
      scoring,
      setScoringPropValue,
      setScoringStatus,
      setShowDetailedSpecifications,
      setScoringPublishTo,
    } = this.props

    const {
      scopePropsParseCompleted,
      scopePropsObj,
      exteriorDetailsList,
      interiorDetailsList,
      otherProductSpec,
    } = this.state

    const renderPublicationService = JSON.parse(process.env.publicationServiceEnabled)

    const hmiScoringPictureEnabled = process.env.hmiScoringPicture ? JSON.parse(process.env.hmiScoringPicture) : false

    const cargoScoringPictureEnabled = process.env.cargoScoringPicture
      ? JSON.parse(process.env.cargoScoringPicture)
      : false

    function propIsEnabled(keyProps) {
      if (!scopePropsObj[keyProps]) return false
      return normalizeProjectModeProp(scoring, scopePropsObj[keyProps], 'is_enabled', true)
    }

    function propIsMandatory(keyProps) {
      return normalizeProjectModeProp(scoring, scopePropsObj[keyProps], 'mandatory', true)
    }

    const exteriorDetailsListEnabled = exteriorDetailsList.filter(x => propIsEnabled(x.slug))
    const interiorDetailsListEnabled = interiorDetailsList.filter(x => propIsEnabled(x.slug))

    function retrieveScoringPropFromRedux(slug) {
      const propPosition = scoring.scoringProps.indexOf(scoring.scoringProps.filter(x => x.slug === slug)[0])
      const prop = scoring.scoringProps[propPosition]
      return prop
    }

    function handlePublishToChange(services) {
      setScoringPublishTo(scoring.newScoringId, services)
    }

    function handleStatusSelected(selectedStatus) {
      setScoringStatus(scoring.newScoringId, selectedStatus)
    }

    function handleTextValueChanged(e, slug, type, label) {
      const { locales } = environment
      const body = {}
      locales.forEach(locale => {
        const { code } = locale
        body[code] = e.target.value
      })

      const value = {
        body,
      }
      setScoringPropValue(scoring.newScoringId, slug, type, label, value, false)
    }

    function handleDateValueChanged(e, slug, type, label) {
      const languages = Object.keys(scoring.selectedTemplateLocales)
      const body = {}
      for (let i = 0; i !== languages.length; i += 1) {
        const code = languages[i]
        body[code] = e.target.value
      }
      const value = {
        body,
      }
      setScoringPropValue(scoring.newScoringId, slug, type, label, value, false)
    }

    function handleSelectValueChanged(slug, type, label, value) {
      setScoringPropValue(scoring.newScoringId, slug, type, label, value, false)
    }

    function handleDataListValueChanged(slug, type, label, value) {
      setScoringPropValue(scoring.newScoringId, slug, type, label, value, false)
    }

    function handleShowHideDetailedSpecifications() {
      const gap = scoring.showDetailedSpecifications
        ? 0
        : document.querySelector('.show_hide_details_container').offsetTop - 62

      setShowDetailedSpecifications(!scoring.showDetailedSpecifications)
      setTimeout(() => {
        if (typeof window !== 'undefined') {
          window.document.querySelector('.popup_body_container').scrollTo(0, gap)
        }
      }, 200)
    }

    const statusOptions = () => {
      const html = []
      const { list } = status
      for (let i = 0; i !== list.length; i += 1) {
        const itm = list[i]
        html.push(
          <option key={itm.id} value={itm.slug} id={itm.id}>
            {itm.name[environment.defaultLang]}
          </option>
        )
      }
      return html
    }

    return (
      <div className={classNames({ specify_product_container: true })}>
        <div className={classNames({ form_item_full_width: true })}>
          <div className={classNames({ form_item_big_label: true })}>{texts.status}</div>
          <div
            className={classNames({
              ns_section_value_container: true,
              select_list_container: true,
            })}
          >
            <select
              className={classNames({
                va_select: true,
                form_item_select_input: true,
              })}
              onChange={e => {
                const selectedStatus = e.target.options[e.target.selectedIndex].value
                handleStatusSelected(selectedStatus)
              }}
              value={scoring.selectedStatus}
            >
              {statusOptions()}
            </select>
          </div>
        </div>

        <div className={classNames({ form_item_full_width: true })}>
          <div className={classNames({ form_item_big_label: true })}>{texts.pictures}</div>

          <div className={classNames({ pictures_wrapper: true })}>
            <div
              className={classNames({
                picture_container: true,
              })}
            >
              <ScoringPicture
                collection={mediaCollectionsMap.cover_pictures}
                label={texts.cover_picture}
                mandatory
                alignment="left"
              />
            </div>
            <div
              className={classNames({
                picture_container: true,
              })}
            >
              <ScoringPicture
                collection={mediaCollectionsMap.full_exterior}
                label={texts.full_exter}
                mandatory={false}
                alignment="center"
              />
            </div>
            <div
              className={classNames({
                picture_container: true,
              })}
            >
              <ScoringPicture
                collection={mediaCollectionsMap.full_interior}
                label={texts.full_inter}
                mandatory={false}
                alignment="right"
              />
            </div>
            {hmiScoringPictureEnabled && (
              <div
                className={classNames({
                  picture_container: true,
                })}
              >
                <ScoringPicture
                  collection={mediaCollectionsMap.hmi}
                  label={texts.hmi}
                  mandatory={false}
                  alignment="right"
                />
              </div>
            )}
            {cargoScoringPictureEnabled && (
              <div
                className={classNames({
                  picture_container: true,
                })}
              >
                <ScoringPicture
                  collection={mediaCollectionsMap.cargo}
                  label={texts.cargo_label}
                  mandatory={false}
                  alignment="right"
                />
              </div>
            )}
          </div>
        </div>

        {scopePropsParseCompleted && (
          <div className={classNames({ form_item_full_width: true })}>
            <div className={classNames({ form_item_big_label: true })}>{texts.identity}</div>

            {propIsEnabled(scopePropsMap.brand) && (
              <div
                className={classNames({
                  form_item_half: true,
                  form_item_half_left: true,
                })}
              >
                <ScoringBrandSelector
                  mandatory={propIsMandatory(scopePropsMap.brand)}
                  slug={scopePropsObj[scopePropsMap.brand].slug}
                  type={scopePropsObj[scopePropsMap.brand].type}
                  label={scopePropsObj[scopePropsMap.brand].label}
                  values={scopePropsObj[scopePropsMap.brand].values}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              </div>
            )}

            {propIsEnabled(scopePropsMap.model) && (
              <div
                className={classNames({
                  form_item_half: true,
                  form_item_half_right: true,
                })}
              >
                <ScoringTextProp
                  mandatory={propIsMandatory(scopePropsMap.model)}
                  slug={scopePropsObj[scopePropsMap.model].slug}
                  type={scopePropsObj[scopePropsMap.model].type}
                  label={scopePropsObj[scopePropsMap.model].label}
                  onChangeFunction={handleTextValueChanged}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              </div>
            )}

            {propIsEnabled(scopePropsMap.comm_name_grade) && (
              <div
                className={classNames({
                  form_item_half: true,
                  form_item_half_left: true,
                })}
              >
                <ScoringTextProp
                  mandatory={propIsMandatory(scopePropsMap.comm_name_grade)}
                  slug={scopePropsObj[scopePropsMap.comm_name_grade].slug}
                  type={scopePropsObj[scopePropsMap.comm_name_grade].type}
                  label={scopePropsObj[scopePropsMap.comm_name_grade].label}
                  onChangeFunction={handleTextValueChanged}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              </div>
            )}

            {propIsEnabled(scopePropsMap.level) && (
              <div
                className={classNames({
                  form_item_half: true,
                  form_item_half_right: true,
                })}
              >
                <ScoringSelectDataListProp
                  mandatory={propIsMandatory(scopePropsMap.level)}
                  slug={scopePropsObj[scopePropsMap.level].slug}
                  type={scopePropsObj[scopePropsMap.level].type}
                  label={scopePropsObj[scopePropsMap.level].label}
                  values={scopePropsObj[scopePropsMap.level].values}
                  onChangeFunction={handleDataListValueChanged}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              </div>
            )}

            {propIsEnabled(scopePropsMap.main_segment) && (
              <div
                className={classNames({
                  form_item_half: true,
                  form_item_half_left: true,
                })}
              >
                <ScoringSelectDataListProp
                  mandatory={propIsMandatory(scopePropsMap.main_segment)}
                  slug={scopePropsObj[scopePropsMap.main_segment].slug}
                  type={scopePropsObj[scopePropsMap.main_segment].type}
                  label={scopePropsObj[scopePropsMap.main_segment].label}
                  values={scopePropsObj[scopePropsMap.main_segment].values}
                  onChangeFunction={handleDataListValueChanged}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              </div>
            )}

            {propIsEnabled(scopePropsMap.silhouette) && (
              <div
                className={classNames({
                  form_item_half: true,
                  form_item_half_right: true,
                })}
              >
                <ScoringSelectDataListProp
                  mandatory={propIsMandatory(scopePropsMap.silhouette)}
                  slug={scopePropsObj[scopePropsMap.silhouette].slug}
                  type={scopePropsObj[scopePropsMap.silhouette].type}
                  label={scopePropsObj[scopePropsMap.silhouette].label}
                  values={scopePropsObj[scopePropsMap.silhouette].values}
                  onChangeFunction={handleDataListValueChanged}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              </div>
            )}

            {propIsEnabled(scopePropsMap.price) && (
              <div
                className={classNames({
                  form_item_full_width: true,
                })}
              >
                <ScoringPriceProp
                  mandatory={propIsMandatory(scopePropsMap.price)}
                  slug={scopePropsObj[scopePropsMap.price].slug}
                  type={scopePropsObj[scopePropsMap.price].type}
                  label={scopePropsObj[scopePropsMap.price].label}
                  props={scopePropsObj[scopePropsMap.price].props}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              </div>
            )}

            {propIsEnabled(scopePropsMap.n_vin) && (
              <div
                className={classNames({
                  form_item_half: true,
                  form_item_half_left: true,
                })}
              >
                <ScoringTextOrImageProp
                  mandatory={propIsMandatory(scopePropsMap.n_vin)}
                  slug={scopePropsObj[scopePropsMap.n_vin].slug}
                  type={scopePropsObj[scopePropsMap.n_vin].type}
                  label={scopePropsObj[scopePropsMap.n_vin].label}
                  props={scopePropsObj[scopePropsMap.n_vin].props}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              </div>
            )}

            {propIsEnabled(scopePropsMap.n_vin) && (
              <div
                className={classNames({
                  form_item_half: true,
                  form_item_half_right: true,
                })}
              >
                <ScoringDistanceProp
                  mandatory={propIsMandatory(scopePropsMap.distance)}
                  slug={scopePropsObj[scopePropsMap.distance].slug}
                  type={scopePropsObj[scopePropsMap.distance].type}
                  label={scopePropsObj[scopePropsMap.distance].label}
                  props={scopePropsObj[scopePropsMap.distance].props}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              </div>
            )}

            {propIsEnabled(scopePropsMap.energy) && (
              <div
                className={classNames({
                  form_item_half: true,
                  form_item_half_left: true,
                })}
              >
                <ScoringSelectDataListProp
                  mandatory={propIsMandatory(scopePropsMap.energy)}
                  slug={scopePropsObj[scopePropsMap.energy].slug}
                  type={scopePropsObj[scopePropsMap.energy].type}
                  label={scopePropsObj[scopePropsMap.energy].label}
                  values={scopePropsObj[scopePropsMap.energy].values}
                  onChangeFunction={handleDataListValueChanged}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              </div>
            )}

            {propIsEnabled(scopePropsMap.stage) && (
              <div
                className={classNames({
                  form_item_half: true,
                  form_item_half_right: true,
                })}
              >
                <ScoringTextProp
                  mandatory={propIsMandatory(scopePropsMap.stage)}
                  slug={scopePropsObj[scopePropsMap.stage].slug}
                  type={scopePropsObj[scopePropsMap.stage].type}
                  label={scopePropsObj[scopePropsMap.stage].label}
                  onChangeFunction={handleTextValueChanged}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              </div>
            )}
          </div>
        )}

        {renderPublicationService && (
          <div className={classNames({ form_item_full_width: true })}>
            <div className={classNames({ form_item_big_label: true })}>{texts.publish_scoring_to_services}</div>

            <div
              className={classNames({
                form_item_full_width: true,
              })}
            >
              <ScoringPublisherField onValueChange={handlePublishToChange} />
            </div>
          </div>
        )}

        <div className={classNames({ show_hide_details_container: true })}>
          <button
            type="button"
            className={classNames({
              lg_btn: true,
              btn_195: true,
              secondary_btn_type_2: true,
              theme_color: true,
            })}
            onClick={handleShowHideDetailedSpecifications}
          >
            <span>{scoring.showDetailedSpecifications ? texts.hide_details : texts.more_details}</span>
          </button>
        </div>

        {scoring.showDetailedSpecifications && (
          <div className={classNames({ ns_detailed_specs_section: true })}>
            <div className={classNames({ form_item_full_width: true })}>
              <div
                className={classNames({
                  form_item_half: true,
                  form_item_half_left: true,
                })}
              >
                <ScoringDetailedPropsColumn
                  list={exteriorDetailsListEnabled}
                  handleDateValueChanged={handleDateValueChanged}
                  handleTextValueChanged={handleTextValueChanged}
                  handleSelectValueChanged={handleSelectValueChanged}
                  handleDataListValueChanged={handleDataListValueChanged}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              </div>
              <div
                className={classNames({
                  form_item_half: true,
                  form_item_half_right: true,
                })}
              >
                <ScoringDetailedPropsColumn
                  list={interiorDetailsListEnabled}
                  handleDateValueChanged={handleDateValueChanged}
                  handleTextValueChanged={handleTextValueChanged}
                  handleSelectValueChanged={handleSelectValueChanged}
                  handleDataListValueChanged={handleDataListValueChanged}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              </div>
            </div>

            {otherProductSpec && (
              <div className={classNames({ form_item_full_width: true })}>
                <ScoringTextProp
                  slug={otherProductSpec.slug}
                  type={otherProductSpec.type}
                  label={otherProductSpec.label}
                  onChangeFunction={handleTextValueChanged}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              </div>
            )}

            <ScoringGallery />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    status: state.status,
    scopes: state.scopes,
    templates: state.templates.list,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringSpecifyProduct)
