import { loadScopesFromLocalStorage } from '../../../../va-corejs-v3/actions/scopes'

export function updateScopes() {
  return async dispatch => {
    const list = await loadScopesFromLocalStorage()
    dispatch({
      type: 'UPDATE_SCOPES',
      list,
    })
  }
}
