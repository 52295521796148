import React from 'react'
import { connect } from 'react-redux'

import './styles.scss'

import * as actionCreators from '../../store/actions'

import screenTypesMap from '../../layout/_parts/screenTypesMap'
import BreadcrumbIcon from '../../assets/svg/lightbox-breadcrumb.svg'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

export class Breadcrumb extends React.Component {
  render() {
    const { breadcrumbList, device } = this.props

    const BreadcrumbComponent = () => {
      const html = []
      if (!breadcrumbList || breadcrumbList.length === 0) {
        return <></>
      }
      for (let i = 0; i !== breadcrumbList.length; i += 1) {
        html.push(
          <div
            key={uuidv4()}
            className={classNames({
              breadcrumb_element: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            <span>{breadcrumbList[i]}</span>
            {device.screenType === screenTypesMap.desk && i !== breadcrumbList.length - 1 && <BreadcrumbIcon />}
          </div>
        )
        if (device.screenType === screenTypesMap.desk && i === 1) {
          html.push(<br key={uuidv4()} />)
        }
      }
      return html
    }

    return <BreadcrumbComponent />
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(Breadcrumb)
