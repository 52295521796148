import _ from 'lodash'
import { SYNC_ACTION_CREATE_PRODUCT } from '../consts'
import DefaultActionHandler from './defaultActionHandler'
import { createOrUpdate } from '../../api/products'
import storage from '../../storage'
import storageMap from '../../storage/storageMap'
import { normalizeNode, normalizeScoring } from '../../utils/dataNormalizers'
import normalizeDirectionMap from '../../utils/normalizeDirectionMap'
import { tusUpload } from '../../api/media'
import { setScoringIntoLocalStorage } from '../../actions/scoring'
import { urlToBlob } from '../../utils'

class CreateProductHandler extends DefaultActionHandler {
  constructor() {
    super()
    this.setAction(SYNC_ACTION_CREATE_PRODUCT)
    this.setAsync(false)
  }

  process = async item => {
    const { data, id: itemId } = item
    const { id } = data

    const product = await storage.get(storageMap.scoring, id)

    // Handle product creation
    await this._handleCreateProductBatch(product, itemId)

    // Handle product media uploading
    await this._handleUploadMediaBatches(product, itemId)

    await setScoringIntoLocalStorage(product)
  }

  _handleCreateProductBatch = async (product, itemId) => {
    const batchCode = `create_product_${itemId}`

    const isBatchCompleted = await this.isBatchCompleted(batchCode, itemId)

    if (isBatchCompleted) {
      return
    }

    const users = await storage.getAll(storageMap.user)

    let newProduct = _.cloneDeep(product)

    newProduct = await normalizeScoring(newProduct, {}, users[0], normalizeDirectionMap.to_server)

    const scoreDigital = await storage.get(storageMap.extra_data, `scoring_${product.id}_score_digital`)
    newProduct.score_digital = scoreDigital ? scoreDigital.data : ''

    delete newProduct.media
    if (newProduct.mediaToDelete) delete newProduct.mediaToDelete

    // Handle product nodes (scoring tree)
    const normalizedNodes = _.map(newProduct.nodes, node => {
      normalizeNode(node, normalizeDirectionMap.to_server)
      node.product_id = newProduct.id
      return node
    })
    newProduct.nodes = normalizedNodes

    // Save the product
    await createOrUpdate(product.id, newProduct)

    await this.completeBatch(batchCode, itemId)
  }

  _handleUploadMediaBatches = async (product, itemId) => {
    const batchPrefix = 'upload_media_'

    const media = _.filter(product.media, _media => _media.is_new)

    await Promise.all(
      _.map(
        media,
        _media =>
          new Promise((resolve, reject) => {
            const batchCode = `${batchPrefix}${_media.id}`
            this.isBatchCompleted(batchCode, itemId).then(isBatchCompleted => {
              if (isBatchCompleted) {
                resolve(_media.id)
                return
              }

              const file = urlToBlob(_media.file)
              tusUpload(file, {
                id: _media.id,
                file_name: _media.file_name,
                model_id: product.id,
                model_type: 'product',
                collection: _media.collection,
              })
                .then(() => {
                  // Remove no more useful values
                  product.media = _.map(product.media, _m => {
                    if (_m.id === _media.id) {
                      delete _m.is_new
                    }
                    return _m
                  })

                  this.completeBatch(_media.id, itemId)
                  resolve(_media.id)
                })
                .catch(error => reject(error))
            })
          })
      )
    )
  }
}

export default CreateProductHandler
