import { httpGet, httpPatch, httpPost } from '../../api'
import storage from '../../storage'
import storageMap from '../../storage/storageMap'
import apiEndPoints from '../../api/apiEndPoints'
import userRolesMap from './userRolesMap'
import userPermissionsMap from './userPermissionsMap'
import { loadStatusFromLocalStorage } from '../status'

export async function getAccessToken() {
  const token = await storage.get(storageMap.access_token, 1)
  return token.access_token
}

export function isValidEmail(email) {
  const rexpr = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  return rexpr.test(email)
}

export function verifyIfDateIsExpired(date) {
  const expirationTime = 60 * 60 * 1000
  const isExpired = !(new Date() - date < expirationTime)
  return isExpired
}

export async function checkIfEmailExists(email) {
  let response = false
  if (isValidEmail(email)) {
    response = await httpGet(`${apiEndPoints.auth_check_email}?email=${email}`, false)
  }
  return response.status === 200 && response.data.exists
}

export async function checkPermission(user, permission) {
  const { roles, permissions } = user

  let isSuperUser = false
  let hasRequiredPermission = false

  const rolesKeys = Object.keys(roles)
  for (let i = 0; i !== rolesKeys.length; i += 1) {
    const role = roles[rolesKeys[i]]
    if (role.slug === userRolesMap.super_user || role.slug === userRolesMap.super_admin) {
      isSuperUser = true
      break
    }
  }
  if (isSuperUser) {
    hasRequiredPermission = true
  } else {
    for (let i = 0; i !== permissions.length; i += 1) {
      if (permissions[i].slug === permission) {
        hasRequiredPermission = true
        break
      }
    }
  }
  return hasRequiredPermission
}

export async function generateParsedPermissions(user) {
  user.parsedPermissions = {}
  user.parsedPermissions.administration = await checkPermission(user, userPermissionsMap.administration)
  user.parsedPermissions.canCreateProduct = await checkPermission(user, userPermissionsMap.product_create)
  user.parsedPermissions.canViewProduct = await checkPermission(user, userPermissionsMap.product_view)
  user.parsedPermissions.canViewProductScoring = await checkPermission(user, userPermissionsMap.product_view_scoring)

  user.parsedPermissions.canDeleteProduct = await checkPermission(user, userPermissionsMap.product_delete)
  user.parsedPermissions.canDuplicateProduct = await checkPermission(user, userPermissionsMap.product_duplicate)
  user.parsedPermissions.canExport = await checkPermission(user, userPermissionsMap.product_export)
  user.parsedPermissions.canImport = await checkPermission(user, userPermissionsMap.product_import)
  user.parsedPermissions.canEditProduct = await checkPermission(
    user,
    userPermissionsMap.product_edit || userPermissionsMap.product_update
  )
  user.parsedPermissions.canUpdateProduct = await checkPermission(user, userPermissionsMap.product_update)
  user.parsedPermissions.canLockProduct = await checkPermission(user, userPermissionsMap.product_lock)

  // Auto reports permissions
  user.parsedPermissions.canCreateReport = await checkPermission(user, userPermissionsMap.report_create)
  user.parsedPermissions.canDeleteReport = await checkPermission(user, userPermissionsMap.report_delete)
  user.parsedPermissions.canUpdateReport = await checkPermission(user, userPermissionsMap.report_update)
  user.parsedPermissions.canPublishReport = await checkPermission(user, userPermissionsMap.report_publish)

  // Renault project mode permissions
  user.parsedPermissions.renaultProjectMode = await checkPermission(user, userPermissionsMap.renault_project_mode)
}

export async function calculateExcludedStatus(user) {
  const statusList = await loadStatusFromLocalStorage()
  user.excludedStatus = []
  const { permissions } = user
  const enabledStatusList = []
  for (let i = 0; i !== permissions.length; i += 1) {
    const perm = permissions[i]
    const { slug } = perm
    if (slug.indexOf('status.') === 0) {
      const statusDef = slug.split('.')
      enabledStatusList.push(statusDef[1])
    }
  }
  if (enabledStatusList.length > 0) {
    for (let i = 0; i !== statusList.length; i += 1) {
      const status = statusList[i]
      const { slug } = status
      const isEnabledStatus = enabledStatusList.includes(slug)
      if (!isEnabledStatus) {
        user.excludedStatus.push(slug)
      }
    }
  }
}

export async function login(email, password) {
  try {
    // Get access token from server
    const response = await httpPost(
      apiEndPoints.auth_login,
      {
        username: email,
        password,
        grant_type: 'password',
        client_secret: global.env.oauthClientSecret,
        client_id: global.env.oauthClientId,
        scope: '',
      },
      false
    )

    if (response.status === 200 && response.data.access_token) {
      // Store access_token into local storage (to be reached for next api)
      await storage.update(storageMap.access_token, {
        id: 1,
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
      })

      // Get user from server (token is reached from local storage)
      const { data: user } = await httpGet(apiEndPoints.auth_me, false)

      await generateParsedPermissions(user)
      await calculateExcludedStatus(user)

      await storage.update(storageMap.user, user)
      return { user, token: response.data.access_token }
    }
    return false
  } catch (err) {
    console.log(err)
    return false
  }
}

export async function exchangeOauthCode(code, redirectUri) {
  try {
    // Get access token from server
    const response = await httpPost(
      'oauth/exchange',
      {
        redirect_uri: redirectUri,
        code,
      },
      false
    )

    if (response.status === 200 && response.data.access_token) {
      // Store access_token into local storage (to be reached for next api)
      await storage.update(storageMap.access_token, {
        id: 1,
        access_token: response.data.access_token,
      })

      // Get user from server (token is reached from local storage)
      const { data: user } = await httpGet(apiEndPoints.auth_me, false)

      await generateParsedPermissions(user)
      await calculateExcludedStatus(user)

      await storage.update(storageMap.user, user)
      return { user, token: response.data.access_token, enabled: true }
    }

    if (response.status === 403 && response.data.code === 2000) {
      // Convention: user not enabled error is represented by code 2000
      return {
        userNotEnabled: true,
      }
    }

    return false
  } catch (err) {
    console.log(err)
    return false
  }
}

export async function requestPasswordReset(email, url) {
  try {
    const response = await httpPost(apiEndPoints.auth_forgot_psw, { email, reset_form_url: url }, false)
    if (response.status === 200) {
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

export async function resetPassword(email, token, password, passwordConfirmation) {
  try {
    const response = await httpPost(
      apiEndPoints.auth_reset_psw,
      { email, token, password, password_confirmation: passwordConfirmation },
      false
    )
    return response
  } catch (err) {
    return false
  }
}

export async function loadUserFromLocalStorage() {
  const storedUser = await storage.getAll(storageMap.user)
  const user = storedUser.length > 0 ? storedUser[0] : false
  return user
}

export async function loadTokenFromLocalStorage() {
  const storedToken = await storage.getAll(storageMap.access_token)
  const token = storedToken.length > 0 ? storedToken[0].access_token : false
  return token
}

export async function getUserRequestsObjectFromStorage(email) {
  const userRequestsObj = await storage.get(storageMap.reset_requests, email)
  return userRequestsObj
}

export async function createUserRequestsObjectAndSaveItInStorage(email) {
  let userRequestsObj = await storage.get(storageMap.reset_requests, email)

  if (userRequestsObj) {
    await storage.remove(storageMap.reset_requests, email)
  }
  userRequestsObj = {
    creationDate: new Date(),
    requestsMade: 1,
  }

  userRequestsObj.id = email
  await storage.put(storageMap.reset_requests, userRequestsObj)
  return userRequestsObj
}

export async function updateUserRequestsObjectAndSaveItInStorage(email) {
  const userRequestsObj = await storage.get(storageMap.reset_requests, email)

  userRequestsObj.requestsMade += 1
  await storage.update(storageMap.reset_requests, userRequestsObj)

  return userRequestsObj
}

export async function updateUserRequestsObjectInStorage(email) {
  let userRequestsObj = await getUserRequestsObjectFromStorage(email)

  if (!userRequestsObj) {
    userRequestsObj = await createUserRequestsObjectAndSaveItInStorage(email)
  } else {
    const { creationDate } = userRequestsObj
    const isExpired = verifyIfDateIsExpired(new Date(creationDate))
    if (isExpired) {
      userRequestsObj = await createUserRequestsObjectAndSaveItInStorage(email)
    } else {
      userRequestsObj = await updateUserRequestsObjectAndSaveItInStorage(email)
    }
  }

  return userRequestsObj
}

export async function updateUserAttributes(attributes = {}) {
  const user = await loadUserFromLocalStorage()
  const updatedUser = { ...user, ...attributes }

  // update server
  httpPatch(`${apiEndPoints.user}/${user.id}`, attributes, false)

  // update local storage
  await storage.update(storageMap.user, updatedUser)

  return updatedUser
}
