import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import PublishingServiceSelect from './_parts/publishingServiceSelect'

const classNames = require('classnames')

class ScoringPublisherField extends React.Component {
  constructor(props) {
    super(props)

    const { scoring } = this.props
    const services = scoring.publishTo ?? []

    this.state = {
      isPublishingEnabled: services.length > 0,
      selectedServices: services,
    }

    this.togglePublishing = this.togglePublishing.bind(this)
  }

  onServiceSelected = service => {
    const { onValueChange } = this.props
    const { selectedServices } = this.state
    selectedServices.push(service)
    this.setState({ selectedServices })
    onValueChange(selectedServices)
  }

  togglePublishing = () => {
    const { onValueChange } = this.props
    const { isPublishingEnabled, selectedServices } = this.state
    const newIsPublishingEnabled = !isPublishingEnabled
    const newSelectedServices = newIsPublishingEnabled ? selectedServices : []
    this.setState({
      isPublishingEnabled: newIsPublishingEnabled,
      selectedServices: newSelectedServices,
    })
    onValueChange([])
  }

  getMissingServices = () => {
    const { publishingServices } = this.props
    const onlyServicesCodes = publishingServices.map(item => {
      return item.code
    })
    const { selectedServices } = this.state
    return onlyServicesCodes.filter(x => !selectedServices.includes(x))
  }

  render() {
    const { publishingServices } = this.props
    const { isPublishingEnabled, selectedServices } = this.state
    const missingServices = this.getMissingServices()

    return (
      <div className="product_publisher_field">
        <div className="col_left">
          <label htmlFor="product_publish_to" id="product_publish_to_label">
            <input
              id="product_publish_to"
              type="checkbox"
              defaultChecked={isPublishingEnabled}
              onChange={this.togglePublishing}
            />
            Export to
          </label>
        </div>

        <div className="col_right">
          {isPublishingEnabled && (
            <div>
              {selectedServices.map(service => {
                return (
                  <div
                    key={service}
                    className={classNames({
                      ns_section_value_container: true,
                      select_list_container: true,
                    })}
                  >
                    <PublishingServiceSelect
                      currentValue={service}
                      onValueChange={this.onServiceSelected}
                      servicesList={publishingServices}
                    />
                  </div>
                )
              })}
            </div>
          )}

          {missingServices.length > 0 && (
            <div
              className={classNames({
                ns_section_value_container: true,
                select_list_container: true,
              })}
            >
              <PublishingServiceSelect
                onValueChange={this.onServiceSelected}
                servicesList={publishingServices}
                useEmptyValue
                disabled={!isPublishingEnabled}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoring: state.scoring,
    publishingServices: state.publishingServices.list,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringPublisherField)
