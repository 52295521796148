import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../store/actions'

import screenTypesMap from '../../../layout/_parts/screenTypesMap'

import Thumbs from './thumbs'
import PrevIcon from '../../../assets/svg/lightbox-arrow-big-left.svg'
import NextIcon from '../../../assets/svg/lightbox-arrow-big-right.svg'
import PinIcon from '../../../assets/svg/pin.svg'
import NoMediaIcon from '../../../assets/svg/no-media.svg'

import { createInterRankingProductsList, getProductBrandAndModelLabel } from '../../../../va-corejs-v3/utils'
import { getMediaBlobUrl } from '../../../../va-corejs-v3/api'

const classNames = require('classnames')

class CarouselIpadV extends Component {
  constructor(props) {
    super(props)

    this.state = {
      productsList: [],
      productActive: null,
      thumbUrl: null,
    }
  }

  componentDidMount = async () => {
    const { pin, productsFromServer, filteredScore } = this.props
    let { carouselIndex } = this.props
    const productsList = createInterRankingProductsList(productsFromServer, filteredScore, pin)
    if (carouselIndex === undefined) {
      carouselIndex = 0
    }
    const productActive = productsList[carouselIndex]

    this.setState({
      productsList,
      productActive,
      thumbUrl: productActive.zoomedMediaUrl ? await getMediaBlobUrl(productActive.zoomedMediaUrl) : null,
    })
  }

  handlePin = itemData => {
    const { updateInterRankingPin, updateInterRankingProductMediaAndComments, scoringTree, nodeDefId } = this.props
    updateInterRankingPin(itemData)

    let itemId = 0
    if (scoringTree) {
      const { nodeDefsObj } = scoringTree
      itemId = nodeDefsObj[nodeDefId].parent_id
    }
    updateInterRankingProductMediaAndComments(itemData.id, itemId, nodeDefId, 'pinned')
  }

  moveLeft = productsList => {
    let { carouselIndex } = this.props
    const {
      activeScore,
      updateInterRankingActiveScore,
      updateInterRankingCarouselIndex,
      updateInterRankingProductMediaAndComments,
      nodeDefId,
      scoringTree,
    } = this.props
    if (carouselIndex === 0) {
      return
    }
    carouselIndex -= 1
    carouselIndex = carouselIndex < 0 ? productsList.length - 1 : carouselIndex
    const newScore = productsList[carouselIndex].score
    if (newScore !== activeScore) {
      updateInterRankingActiveScore(newScore)
    }
    updateInterRankingCarouselIndex(carouselIndex)
    const { nodeDefsObj } = scoringTree
    const itemId = nodeDefsObj[nodeDefId].parent_id
    updateInterRankingProductMediaAndComments(productsList[carouselIndex].id, itemId, nodeDefId, 'focused')
  }

  moveRight = productsList => {
    let { carouselIndex } = this.props
    const {
      activeScore,
      updateInterRankingActiveScore,
      updateInterRankingCarouselIndex,
      updateInterRankingProductMediaAndComments,
      nodeDefId,
      scoringTree,
    } = this.props
    if (carouselIndex === productsList.length - 1) {
      return
    }
    carouselIndex += 1
    const newScore = productsList[carouselIndex].score
    if (newScore !== activeScore) {
      updateInterRankingActiveScore(newScore)
    }
    updateInterRankingCarouselIndex(carouselIndex)
    const { nodeDefsObj } = scoringTree
    const itemId = nodeDefsObj[nodeDefId].parent_id
    updateInterRankingProductMediaAndComments(productsList[carouselIndex].id, itemId, nodeDefId, 'focused')
  }

  render() {
    const { device } = this.props
    const { productsList, productActive, thumbUrl } = this.state

    if (!productActive) {
      return <></>
    }

    return (
      <div className="carousel_pin_container_right_content">
        <div
          style={{
            width: '30%',
            float: 'left',
            textAlign: 'left',
            marginBottom: 61,
          }}
        >
          <div className="arrow arrow-left arrowIpadV" style={{ marginLeft: 20 }}>
            <PrevIcon
              className="arrow_icon_left"
              role="button"
              tabIndex={0}
              onKeyDown={() => this.moveLeft(productsList)}
              onClick={() => this.moveLeft(productsList)}
            />
          </div>
        </div>
        <div style={{ width: '40%', float: 'left' }}>
          <div className="pin_title_container">
            <div
              className={classNames({
                criterion_score_btn: true,
                criterion_score_btn_selected: true,
                criterion_score_value_btn_0: productActive.score === 0,
                criterion_score_value_btn_1: productActive.score === 1,
                criterion_score_value_btn_2: productActive.score === 2,
                criterion_score_value_btn_3: productActive.score === 3,
                criterion_score_value_btn_4: productActive.score === 4,
                pin_title_score: true,
              })}
              role="button"
              tabIndex={0}
            >
              {productActive.score}
            </div>
            <div className="pin_title_name">{getProductBrandAndModelLabel(productActive.props)}</div>
          </div>
        </div>
        <div
          style={{
            width: '30%',
            float: 'left',
            textAlign: 'right',
          }}
        >
          <div className="arrow arrow-right arrowIpadV" style={{ marginRight: 20 }}>
            <NextIcon
              className="arrow_icon_right"
              role="button"
              tabIndex={0}
              onKeyDown={() => this.moveRight(productsList)}
              onClick={() => this.moveRight(productsList)}
            />
          </div>
        </div>
        <div
          className={classNames({
            pin_image_container: true,
            isSafari: device.isSafari,
            isFirefox: device.isFirefox,
            desk: device.screenType === screenTypesMap.desk,
            ipadh: device.screenType === screenTypesMap.iPadH,
            ipadv: device.screenType === screenTypesMap.iPadV,
            iphonexr: device.screenType === screenTypesMap.iPhoneXR,
            iphonese: device.screenType === screenTypesMap.iPhoneSE,
          })}
        >
          <div
            className="pin"
            role="button"
            tabIndex={0}
            onKeyDown={() => this.handlePin(productActive)}
            onClick={() => this.handlePin(productActive)}
          >
            <PinIcon /> <span className="pin_label">Pin this model</span>
          </div>
          {thumbUrl !== '' && <img src={thumbUrl} alt="pinned product" className="pin_image" />}
          {thumbUrl === '' && (
            <div className="no_media_container">
              <NoMediaIcon />
            </div>
          )}
        </div>
        <Thumbs type="focused" compactMode scoringId={productActive.id} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoringTree: state.scoringTree,
    pin: state.interRanking.pin,
    carouselIndex: state.interRanking.carouselIndex,
    productsFromServer: state.interRanking.productsFromServer,
    filteredScore: state.interRanking.filteredScore,
    nodeDefId: state.interRanking.nodeDefId,
  }
}

export default connect(mapStateToProps, actionCreators)(CarouselIpadV)
