import React from 'react'
import { connect } from 'react-redux'
import { useStaticQuery, graphql } from 'gatsby'

import _ from 'lodash'
import * as actionCreators from '../../../../store/actions'

import PlusMediaIcon from '../../../../assets/svg/plus-media-icon.svg'
import TrashMediaIcon from '../../../../assets/svg/trash.svg'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'

const uuidv4 = require('uuid/v4')
const classNames = require('classnames')

export class ScoringGallery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      placeholdersCount: 0,
      scoringPictures: [],
    }
  }

  handleAdditionalPicturesClicked = () => {
    const galleryInput = document.querySelector('.scoring_gallery_input_file')
    galleryInput.value = ''
    galleryInput.click()
  }

  handleInputfileChanged = async e => {
    const { scoring, updateScoringGallery } = this.props
    const { files } = e.target
    this.setState({ placeholdersCount: files.length })
    await updateScoringGallery(scoring.newScoringId, scoring.scoringPictures, files)

    this.setState({ placeholdersCount: 0 })
  }

  handleDeletePictureClicked = pictureId => {
    const { scoring, removePictureFromScoringGallery } = this.props
    removePictureFromScoringGallery(scoring.newScoringId, scoring.scoringPictures, pictureId)
  }

  setScoringPictures = async () => {
    const { scoring } = this.props
    this.setState({ placeholdersCount: scoring.scoringPictures.length })

    const promises = []
    _.each(scoring.scoringPictures, picture => {
      if (picture && picture.file) {
        promises.push(
          new Promise((resolve, reject) => {
            if (!picture.file.startsWith('data:')) {
              getMediaBlobUrl(picture.file)
                .then(response => {
                  resolve({
                    id: picture.id,
                    url: response,
                  })
                })
                .catch(error => {
                  reject(error)
                })
            } else {
              resolve({
                id: picture.id,
                url: picture.file,
              })
            }
          })
        )
      }
    })

    const results = await Promise.all(promises)
    this.setState({
      scoringPictures: results,
      placeholdersCount: 0,
    })
  }

  componentDidMount = async () => {
    const { scoring, getScoringGalleryAndUpdateRedux } = this.props
    await getScoringGalleryAndUpdateRedux(scoring.newScoringId)
    await this.setScoringPictures()
  }

  componentDidUpdate = async prevProps => {
    const { scoring } = this.props

    if (prevProps.scoring.scoringPictures !== scoring.scoringPictures) {
      await this.setScoringPictures()
    }
  }

  render() {
    const { scoring, texts } = this.props
    const { placeholdersCount, scoringPictures } = this.state

    const ImagesGallery = () => {
      const data = useStaticQuery(graphql`
        query loaderQuery {
          allFile(filter: { name: {}, relativePath: { eq: "assets/gif/ajax-loader.gif" } }) {
            edges {
              node {
                publicURL
              }
            }
          }
        }
      `)
      const loaderGifUrl = data.allFile.edges[0].node.publicURL

      const html = []

      for (let i = 0; i !== scoringPictures.length; i += 1) {
        const image = scoringPictures[i]

        html.push(
          <div key={image.id} className={classNames({ scoring_gallery_item: true })}>
            <img src={image.url} alt="gallery-img" />
            <div
              className={classNames({ scoring_gallery_item_delete: true })}
              role="button"
              tabIndex={0}
              onClick={() => this.handleDeletePictureClicked(image.id)}
              onKeyPress={() => this.handleDeletePictureClicked(image.id)}
            >
              <TrashMediaIcon />
            </div>
          </div>
        )
      }

      for (let i = 0; i !== placeholdersCount; i += 1) {
        html.push(
          <div
            className={classNames({
              scoring_gallery_item_placeholder: true,
            })}
            key={uuidv4()}
          >
            <img src={loaderGifUrl} alt="loading" />
          </div>
        )
      }

      return html
    }

    return (
      <div className={classNames({ scoring_gallery: true })}>
        <input
          type="file"
          className={classNames({ scoring_gallery_input_file: true })}
          accept="image/*"
          multiple
          onChange={e => this.handleInputfileChanged(e)}
        />

        <div
          className={classNames({
            lg_btn: true,
            theme_primary_btn: true,
            btn_250: true,
            scoring_gallery_btn: true,
          })}
          role="button"
          tabIndex={0}
          onClick={() => {
            this.handleAdditionalPicturesClicked()
          }}
          onKeyPress={() => {
            this.handleAdditionalPicturesClicked()
          }}
        >
          <PlusMediaIcon /> {texts.additional_pictures}
        </div>
        {(scoring.scoringPictures.length > 0 || placeholdersCount > 0) && (
          <div className={classNames({ scoring_gallery_images: true })}>
            <ImagesGallery />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringGallery)
