import apiEndPoints from '../../api/apiEndPoints'
import { httpGet } from '../../api'
import storage from '../../storage'
import { normalizeScoring } from '../../utils/dataNormalizers'
import storageMap from '../../storage/storageMap'
import nodeDefinitionTypeMap from '../../../src/components/scoring_tree/helper/nodeDefinitionTypeMap'
import { doCalculation } from '../../specializations/renault'
import scorePanelNestedPropsMap from '../../../src/components/scoring_tree/helper/scorePanelNestedPropsMap'
import scorePanelPropsMap from '../../../src/components/scoring_tree/helper/scorePanelPropsMap'
import { searchProductsByTemplate, searchProductsByTemplateAndSegment } from '../elastic_search'

export async function updateNodesAfterCalculation(scoring) {
  const { nodeDefsObj, nodes } = scoring
  const newNodes = { ...nodes }
  await doCalculation(scoring, nodeDefsObj, newNodes, true)
  scoring.nodes = newNodes
  return true
}

export async function findCriterionInList(list, nodeDefsObj, criterion) {
  for (let i = 0; i !== list.length; i += 1) {
    const nId = list[i]
    const nDef = nodeDefsObj[nId]
    if (!nDef.bonus_demerit) {
      const { type } = nDef
      if (type === nodeDefinitionTypeMap.criterion) {
        criterion.push(nId)
      } else {
        const children = nDef.children_ids
        findCriterionInList(children, nodeDefsObj, criterion)
      }
    }
  }
}

export async function findBestHitInProducts(products, family) {
  const { data } = products
  const { hits } = data.hits

  const bestHit = {}

  for (let i = 0; i !== hits.length; i += 1) {
    const hit = hits[i]
    const scorePanel = hit._source.score_panel
    const { props } = hit._source
    const name = `${props.brand.value} ${props.model.value}`

    const exteriorInitial = scorePanel[scorePanelPropsMap.exterior].values[scorePanelNestedPropsMap.initial].value
    const exteriorDetailed = scorePanel[scorePanelPropsMap.exterior].values[scorePanelNestedPropsMap.detailed].value
    const interiorInitial = scorePanel[scorePanelPropsMap.interior].values[scorePanelNestedPropsMap.initial].value
    const interiorDetailed = scorePanel[scorePanelPropsMap.interior].values[scorePanelNestedPropsMap.detailed].value
    const cargoInitial = scorePanel[scorePanelPropsMap.cargo].values[scorePanelNestedPropsMap.initial].value
    const cargoDetailed = scorePanel[scorePanelPropsMap.cargo].values[scorePanelNestedPropsMap.detailed].value

    const globalValue =
      exteriorInitial + exteriorDetailed + interiorInitial + interiorDetailed + cargoInitial + cargoDetailed

    const globalExterior = exteriorInitial + exteriorDetailed
    const globalInterior = interiorInitial + interiorDetailed
    const globalCargo = cargoInitial + cargoDetailed
    if (
      (!family && globalValue > bestHit.globalValue) ||
      (family === scorePanelPropsMap.exterior && globalExterior > bestHit.globalExterior) ||
      (family === scorePanelPropsMap.interior && globalInterior > bestHit.globalInterior) ||
      (family === scorePanelPropsMap.cargo && globalCargo > bestHit.globalCargo) ||
      Object.keys(bestHit).length === 0
    ) {
      bestHit.hit = hit
      bestHit.name = name
      bestHit.globalValue = globalValue
      bestHit.globalAverage = globalValue / 6
      bestHit.globalExterior = globalExterior
      bestHit.globalExteriorAverage = globalExterior / 2
      bestHit.globalInterior = globalInterior
      bestHit.globalInteriorAverage = globalInterior / 2
      bestHit.globalCargo = globalCargo
      bestHit.globalCargoAverage = globalCargo / 2
      bestHit.exteriorInitial = exteriorInitial
      bestHit.exteriorDetailed = exteriorDetailed
      bestHit.interiorInitial = interiorInitial
      bestHit.interiorDetailed = interiorDetailed
    }
  }

  return bestHit
}

export async function getNodesForESHits(hits) {
  let productList = []

  const productIds = []
  for (let i = 0; i !== hits.length; i += 1) {
    const hit = hits[i]
    const { id } = hit._source
    productIds.push(id)
  }
  const commaSeparatedIdsList = productIds.join()

  const apiUrl = apiEndPoints.products_filter.replace('{commaSeparatedIdList}', commaSeparatedIdsList)
  const productListResult = await httpGet(apiUrl, false)

  if (productListResult.status === 200) {
    productList = productListResult.data.data
  }

  return productList
}

export async function normalizeAndDoCalculationOfAllProductInList(template, productList, outputList) {
  const scoring = productList.pop()
  if (scoring) {
    scoring.nodeDefsObj = template.node_definitions
    await normalizeScoring(scoring, template, '', 'from_server')

    const propsKeys = Object.keys(scoring.props)
    const props = {}
    for (let i = 0; i !== propsKeys.length; i += 1) {
      const key = propsKeys[i]
      const prop = scoring.props[key]
      props[prop.slug] = prop
    }
    scoring.props = props
    await updateNodesAfterCalculation(scoring)

    outputList.push(scoring)
    await normalizeAndDoCalculationOfAllProductInList(template, productList, outputList)
  }
}

export async function doCalculationOnProductsList(productList, templateId) {
  const template = await storage.get(storageMap.template, templateId)
  const normalizedProducts = []
  await normalizeAndDoCalculationOfAllProductInList(template, productList, normalizedProducts)
  return normalizedProducts
}

export async function getBestValueFromProductListByNodeId(productList, templateId, nodeId) {
  let bestValue = 0
  const normalizedProducts = await doCalculationOnProductsList(productList, templateId)

  for (let i = 0; i !== normalizedProducts.length; i += 1) {
    const prod = normalizedProducts[i]
    const node = prod.nodes[nodeId]
    const { normalizedScore } = node
    bestValue = normalizedScore > bestValue ? normalizedScore : bestValue
  }
  return bestValue
}

export async function getBestValueFromProductListByNodeList(productList, templateId, nodeList) {
  const bestValues = {}

  const normalizedProducts = await doCalculationOnProductsList(productList, templateId)

  for (let i = 0; i !== normalizedProducts.length; i += 1) {
    const prod = normalizedProducts[i]
    for (let j = 0; j !== nodeList.length; j += 1) {
      const nodeId = nodeList[j]
      const node = prod.nodes[nodeId]
      const { percentage } = node

      const currentBestValuePerNode = bestValues[nodeId] || 0
      const newBestValuePerNode = percentage > currentBestValuePerNode ? percentage : currentBestValuePerNode

      bestValues[nodeId] = newBestValuePerNode
    }
  }
  return bestValues
}

export async function getBestOfSegmentPerNode(templateId, segment, nodeId) {
  const products = await searchProductsByTemplateAndSegment(templateId, segment)
  const { hits } = products.data.hits
  const productList = await getNodesForESHits(hits)

  const bestValue = getBestValueFromProductListByNodeId(productList, templateId, nodeId)
  return bestValue
}

export async function getBestOfSegmentPerNodesList(templateId, segment, nodeList) {
  const products = await searchProductsByTemplateAndSegment(templateId, segment)
  const { hits } = products.data.hits
  const productList = await getNodesForESHits(hits)

  const bestValues = getBestValueFromProductListByNodeList(productList, templateId, nodeList)
  return bestValues
}

export async function getBestOfAllPerNodeList(templateId, nodeList) {
  const products = await searchProductsByTemplate(templateId)
  const { hits } = products.data.hits
  const productList = await getNodesForESHits(hits)

  const bestValues = getBestValueFromProductListByNodeList(productList, templateId, nodeList)
  return bestValues
}

export async function getBestOfAllPerNode(templateId, nodeId) {
  const products = await searchProductsByTemplate(templateId)
  const { hits } = products.data.hits
  const productList = await getNodesForESHits(hits)

  const bestValue = getBestValueFromProductListByNodeId(productList, templateId, nodeId)
  return bestValue
}

export async function getBestOfAllPerFamily(templateId, family) {
  const products = await searchProductsByTemplate(templateId)
  const bestHit = findBestHitInProducts(products, family)
  return bestHit
}

export async function getBestOfSegmentPerFamily(templateId, segment, family) {
  const products = await searchProductsByTemplateAndSegment(templateId, segment)
  const bestHit = findBestHitInProducts(products, family)
  return bestHit
}

export async function getBestOfSegment(templateId, segment) {
  const products = await searchProductsByTemplateAndSegment(templateId, segment)
  const bestHit = findBestHitInProducts(products, null)
  return bestHit
}

export async function getBestOfAll(templateId) {
  const products = await searchProductsByTemplate(templateId)
  const bestHit = findBestHitInProducts(products, null)
  return bestHit
}

export async function getDataPerNodeGroupedByCriteriaType(templateId, nodeId, hits) {
  function parseChildren(children, nodes, nodeDefsObj, criterionList) {
    for (let i = 0; i !== children.length; i += 1) {
      const nId = children[i]
      const nDef = nodeDefsObj[nId]
      if (!nDef.bonus_demerit) {
        const { type } = nDef
        if (type === nodeDefinitionTypeMap.criterion) {
          const cNode = nodes[nId]
          const cTemplate = nDef.criterion_template
          const cType = cTemplate.slug

          if (!criterionList[cType]) {
            criterionList[cType] = []
          }
          criterionList[cType].push(cNode)
        } else {
          const childrenList = nDef.children_ids
          parseChildren(childrenList, nodes, nodeDefsObj, criterionList)
        }
      }
    }
  }

  function parseCriterionList(criterionList, averagesByCriterion, bestCriterionValues) {
    const keys = Object.keys(criterionList)
    const parsedCriterionList = {}
    for (let i = 0; i !== keys.length; i += 1) {
      const cKey = keys[i]
      const cArray = criterionList[cKey]

      let cSum = 0

      for (let j = 0; j !== cArray.length; j += 1) {
        const cVal = cArray[j]
        const { score } = cVal
        cSum += score

        if (!bestCriterionValues[cKey] || (bestCriterionValues[cKey] && score > bestCriterionValues[cKey])) {
          bestCriterionValues[cKey] = score
        }
      }
      const cAverage = cSum / cArray.length
      parsedCriterionList[cKey] = {
        cArray,
        cSum,
        cAverage,
      }

      if (!averagesByCriterion[cKey]) {
        averagesByCriterion[cKey] = []
      }
      averagesByCriterion[cKey].push(cAverage)
    }
    return parsedCriterionList
  }

  const productList = await getNodesForESHits(hits)
  const normalizedProducts = await doCalculationOnProductsList(productList, templateId)

  const criterionsPerProducts = {}
  const averagesByCriterion = {}
  const bestCriterionValues = {}
  for (let i = 0; i !== normalizedProducts.length; i += 1) {
    const prod = normalizedProducts[i]
    const { id, nodes, nodeDefsObj } = prod
    const criterionList = {}
    const children = nodeDefsObj[nodeId].children_ids
    parseChildren(children, nodes, nodeDefsObj, criterionList)
    const parsedCriterionList = parseCriterionList(criterionList, averagesByCriterion, bestCriterionValues)
    criterionsPerProducts[id] = parsedCriterionList
  }

  const globalAverageByCriterion = {}
  const criterionKeys = Object.keys(averagesByCriterion)
  for (let i = 0; i !== criterionKeys.length; i += 1) {
    const cKey = criterionKeys[i]
    const cArr = averagesByCriterion[cKey]
    let currentCriSum = 0
    for (let j = 0; j !== cArr.length; j += 1) {
      currentCriSum += cArr[j]
    }
    const currentCriAverage = currentCriSum / cArr.length
    globalAverageByCriterion[cKey] = {
      sum: currentCriSum,
      average: currentCriAverage,
    }
  }

  const retValue = {
    criterionsPerProducts,
    averagesByCriterion,
    bestCriterionValues,
    globalAverageByCriterion,
  }

  return retValue
}

export async function getProductDataByTemplateAndSegmentGroupedByCriteriaType(templateId, segment, nodeId) {
  const products = await searchProductsByTemplateAndSegment(templateId, segment)
  const { hits } = products.data.hits
  const results = await getDataPerNodeGroupedByCriteriaType(templateId, nodeId, hits)
  return results
}

export async function getProductDataByTemplateGroupedByCriteriaType(templateId, nodeId) {
  const products = await searchProductsByTemplate(templateId)
  const { hits } = products.data.hits
  const results = await getDataPerNodeGroupedByCriteriaType(templateId, nodeId, hits)
  return results
}

export async function getAverageOfSegmentPerNodeList(templateId, segment, nodeList) {
  const products = await searchProductsByTemplateAndSegment(templateId, segment)
  const { hits } = products.data.hits
  const productList = await getNodesForESHits(hits)

  const normalizedProducts = await doCalculationOnProductsList(productList, templateId)

  const performanceAveragesList = {}
  for (let i = 0; i !== nodeList.length; i += 1) {
    const nodeId = nodeList[i]
    let performanceAverage = 0
    for (let j = 0; j !== normalizedProducts.length; j += 1) {
      const prod = normalizedProducts[j]
      const node = prod.nodes[nodeId]
      const { percentage } = node
      performanceAverage += percentage
    }
    performanceAverage /= normalizedProducts.length
    performanceAveragesList[nodeId] = performanceAverage
  }

  return performanceAveragesList
}

export async function getAverageOfSegmentPerNode(templateId, segment, nodeId) {
  let averageAllScoreValuesForAllProducts = 0
  const products = await searchProductsByTemplateAndSegment(templateId, segment)
  const { hits } = products.data.hits
  const productList = await getNodesForESHits(hits)

  const normalizedProducts = await doCalculationOnProductsList(productList, templateId)

  let normalizedScoresSum = 0
  for (let i = 0; i !== normalizedProducts.length; i += 1) {
    const prod = normalizedProducts[i]
    const node = prod.nodes[nodeId]
    normalizedScoresSum += node.normalizedScore
  }

  averageAllScoreValuesForAllProducts = normalizedScoresSum / normalizedProducts.length

  return averageAllScoreValuesForAllProducts
}

export async function getGlobalAverageOfSegment(templateId, segment) {
  const products = await searchProductsByTemplateAndSegment(templateId, segment)
  const { data } = products
  const { hits } = data.hits

  let exteriorInitialSum = 0
  let exteriorDetailedSum = 0
  let interiorInitialSum = 0
  let interiorDetailedSum = 0
  let cargoInitialSum = 0
  let cargoDetailedSum = 0

  for (let i = 0; i !== hits.length; i += 1) {
    const hit = hits[i]
    const scorePanel = hit._source.score_panel

    const exteriorInitial = scorePanel[scorePanelPropsMap.exterior].values[scorePanelNestedPropsMap.initial].value

    exteriorInitialSum += exteriorInitial

    const exteriorDetailed = scorePanel[scorePanelPropsMap.exterior].values[scorePanelNestedPropsMap.detailed].value

    exteriorDetailedSum += exteriorDetailed

    const interiorInitial = scorePanel[scorePanelPropsMap.interior].values[scorePanelNestedPropsMap.initial].value

    interiorInitialSum += interiorInitial

    const interiorDetailed = scorePanel[scorePanelPropsMap.interior].values[scorePanelNestedPropsMap.detailed].value

    interiorDetailedSum += interiorDetailed

    const cargoInitial = scorePanel[scorePanelPropsMap.cargo].values[scorePanelNestedPropsMap.initial].value

    cargoInitialSum += cargoInitial

    const cargoDetailed = scorePanel[scorePanelPropsMap.cargo].values[scorePanelNestedPropsMap.detailed].value

    cargoDetailedSum += cargoDetailed
  }

  const exteriorInitial = exteriorInitialSum / hits.length
  const exteriorDetailed = exteriorDetailedSum / hits.length
  const interiorInitial = interiorInitialSum / hits.length
  const interiorDetailed = interiorDetailedSum / hits.length
  const cargoInitial = cargoInitialSum / hits.length
  const cargoDetailed = cargoDetailedSum / hits.length
  const globalAverage =
    (exteriorInitial + exteriorDetailed + interiorInitial + interiorDetailed + cargoInitial + cargoDetailed) / 6

  const exteriorAverage = (exteriorInitial + exteriorDetailed) / 2
  const interiorAverage = (interiorInitial + interiorDetailed) / 2
  const cargoAverage = (cargoInitial + cargoDetailed) / 2

  const averageHit = {
    exteriorInitial,
    exteriorDetailed,
    interiorInitial,
    interiorDetailed,
    cargoInitial,
    cargoDetailed,
    globalAverage,
    exteriorAverage,
    interiorAverage,
    cargoAverage,
  }

  return averageHit
}
