import { httpDelete, httpPut } from './index'
import ApiException from './apiException'

export const createOrUpdate = async (id, data) => {
  const response = await httpPut(`products/${id}`, data, false)
  if (response.status && response.status >= 200 && response.status < 300) {
    return response.data
  }

  throw new ApiException(response)
}

export const remove = async id => {
  const response = await httpDelete(`products/${id}`, {}, false)
  if (response.status && response.status >= 200 && response.status < 300) {
    return response.data
  }

  throw new ApiException(response)
}
