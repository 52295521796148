import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../store/actions'
import { selectSyncStatus } from '../../store/selectors/sync_v2'
import { SYNC_STATUS_IDLE } from '../../consts/sync'
import ShrinkedSnackbar from './shrinked'
import ExpandedSnackbar from './expanded'
import './styles.scss'

function SyncSnackbar({ status }) {
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (status === SYNC_STATUS_IDLE) {
      setExpanded(false)
    }
  }, [status])

  const handleExpand = () => {
    setExpanded(true)
  }

  const handleShrink = () => {
    setExpanded(false)
  }

  return (
    <>
      <ShrinkedSnackbar onExpand={handleExpand} open={status !== SYNC_STATUS_IDLE && expanded === false} />
      <ExpandedSnackbar onShrink={handleShrink} open={status !== SYNC_STATUS_IDLE && expanded === true} />
    </>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    status: selectSyncStatus(state),
  }
}

export default connect(mapStateToProps, actionCreators)(SyncSnackbar)
