import { httpGet, httpPost, httpPatch, httpDelete } from '../../api'

export function fetchAutoReports(userId, productId) {
  const path = `auto-report?filter[product_id]=${productId}&filter[user_id]=${userId}&limit=1000`
  return new Promise((resolve, reject) => {
    httpGet(path)
      .then(response => {
        resolve(response.data.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function findAutoReport(id) {
  const path = `auto-report/${id}`
  return new Promise((resolve, reject) => {
    httpGet(path)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function createAutoReport(data) {
  const path = `auto-report`

  return new Promise((resolve, reject) => {
    httpPost(path, data, false)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function updateAutoReport(id, data) {
  const path = `auto-report/${id}`

  return new Promise((resolve, reject) => {
    httpPatch(path, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function deleteAutoReport(id) {
  const path = `auto-report/${id}`
  return new Promise((resolve, reject) => {
    httpDelete(path, [], false)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function publishAutoReport(id) {
  const path = `auto-report/${id}/publish`
  return new Promise((resolve, reject) => {
    httpPost(path, [], false)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}
