import { httpExport } from '../../../../va-corejs-v3/api'
import msgBoxMessageMap from '../../../../va-corejs-v3/utils/msgBoxMessagesMap'

export function updateExportData(url) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_MSGBOX',
      msgboxMessage: msgBoxMessageMap.preparingDownload,
      data: {
        scoringId: 0,
        par1: 'loading_screen',
      },
    })

    httpExport(url).then(() => {
      dispatch({
        type: 'UPDATE_MSGBOX',
        msgboxMessage: '',
        data: {},
      })
    })
  }
}

export function updateDownloadAllMedia(url, productName) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_MSGBOX',
      msgboxMessage: msgBoxMessageMap.downloadAllMedia,
      data: {
        scoringId: 0,
        productName,
        url,
      },
    })
  }
}
