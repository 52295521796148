import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'

export class UserNotEnabledComponent extends React.Component {
  render() {
    const { texts } = this.props
    const style = {
      marginTop: '10px',
    }
    return <div style={style}>{texts.user_waiting_for_approval}</div>
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(UserNotEnabledComponent)
