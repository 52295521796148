const userPermissionsMap = {
  prodict_list: 'product.list',
  product_view: 'product.view',
  product_view_scoring: 'product.viewScoring',
  product_view_instructions: 'product.viewInstructions',
  product_view_dashboard: 'product.viewDashboard',
  product_create: 'product.create',
  product_update: 'product.update',
  product_duplicate: 'product.duplicate',
  product_export: 'product.export',
  product_import: 'product.import',
  product_list_template: 'list.template',
  product_list_scopes: 'list.scopes',
  product_delete: 'product.delete',
  product_lock: 'product.lock',
  administration: 'administration',
  report_create: 'report.create',
  report_update: 'report.update',
  report_delete: 'report.delete',
  report_publish: 'report.publish',
  renault_project_mode: 'renaultProjectMode',
}
export default userPermissionsMap
