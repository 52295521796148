import React from 'react'
import { connect } from 'react-redux'
import { Scrollbar } from 'react-scrollbars-custom'
import * as actionCreators from '../../../store/actions'
import { selectAllSyncItems } from '../../../store/selectors/sync_v2'
import SnackbarItem from './item'

const uuidv4 = require('uuid/v4')

function SnackbarBody({ items }) {
  return (
    <Scrollbar className="sync-snackbar__body" noScrollX noDefaultStyles>
      {items.map(item => (
        <SnackbarItem item={item} key={uuidv4()} />
      ))}
    </Scrollbar>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    items: selectAllSyncItems(state),
  }
}

export default connect(mapStateToProps, actionCreators)(SnackbarBody)
