/*-----------------------------------------------------*/
// Update selected state of all ancestors checkbox
// and return the new array updated
/*-----------------------------------------------------*/

import _ from 'lodash'
import nodeDefinitionTypeMap from '../../scoring_tree/helper/nodeDefinitionTypeMap'
import reportConf from '../helper/reportConf'
import reportRankingTypeMap from '../helper/reportRankingTypeMap'

export const updateParentsCheckboxSelection = (nodeList, nodeDefsObj, parentCategories) => {
  if (!nodeDefsObj) return false

  parentCategories ??= ['perimeter', 'family', 'subfamily']

  const type = parentCategories.pop()

  if (!type) return nodeList

  Object.keys(nodeList).forEach(nodeId => {
    const prentNode = nodeDefsObj[nodeId]
    if (prentNode.type === type && prentNode.children_ids.filter(id => nodeList[id])) {
      if (
        prentNode.children_ids
          .filter(id => nodeList[id])
          .every(id => nodeList[id] && nodeList[id].selected === 'enabled')
      )
        nodeList[nodeId].selected = 'enabled'
      else if (
        prentNode.children_ids
          .filter(id => nodeList[id])
          .every(id => nodeList[id] && nodeList[id].selected === 'disabled')
      )
        nodeList[nodeId].selected = 'disabled'
      else nodeList[nodeId].selected = 'indeterminate'
    }
  })

  return updateParentsCheckboxSelection(nodeList, nodeDefsObj, parentCategories)
}

/*-----------------------------------------------------*/
// Calc Digital Achievement Score panel
/*-----------------------------------------------------*/

export const calcExtraScorePanel = (nodeDefinitions, scoring, slug) => {
  //
  // DIGITAL ACHIEVEMENT SCORE PANEL CALC

  if (slug === 'digital_achievement') {
    if (!(scoring && scoring.score_panel && scoring.score_panel[0])) return false
    // initial score is calculated on a specific items
    // https://valueablesas.atlassian.net/browse/VAV3-759

    const initialItemId = Object.keys(nodeDefinitions).filter(
      nodeDefId => nodeDefinitions[nodeDefId].name.en.toLowerCase() === 'initial impression of screens and displays'
    )

    const nodes = _.keyBy(scoring.nodes, 'node_definition_id')

    const initial = (initialItemId && nodes[initialItemId] && nodes[initialItemId].normalizedScore) || ''

    // detail score is in score_digital get from local db
    // https://valueablesas.atlassian.net/browse/VAV3-759

    const detail = scoring.score_digital || ''

    // create score data from score panel first row
    const digitalScorePanel = {}

    // name
    digitalScorePanel.name = 'Digital'

    digitalScorePanel.values = []
    digitalScorePanel.values[0] = {}
    digitalScorePanel.values[1] = {}

    digitalScorePanel.values[0].name = { ...scoring.score_panel[0].values[0].name }
    digitalScorePanel.values[0].is_enabled = true
    digitalScorePanel.values[1].name = { ...scoring.score_panel[0].values[1].name }
    digitalScorePanel.values[1].is_enabled = true

    // initial
    if (digitalScorePanel.values && digitalScorePanel.values[0]) digitalScorePanel.values[0].value = initial

    // detail
    if (digitalScorePanel.values && digitalScorePanel.values[1]) digitalScorePanel.values[1].value = detail

    return digitalScorePanel
  }

  return false
}

/*-----------------------------------------------------*/
// Get Family Ranking
/*-----------------------------------------------------*/

const getItemsInFamily = (scoringTree, familySectionName) => {
  const { nodeDefsObj } = scoringTree

  let familyName = familySectionName

  // if section in conf has different family name

  if (
    reportConf.reportSummaryIndex &&
    reportConf.reportSummaryIndex.some(x => x.slug === familySectionName && x.family)
  ) {
    const [familySection] = reportConf.reportSummaryIndex.filter(x => x.slug === familySectionName && x.family)
    familyName = familySection.family
  }

  const selectedFamiliesIdsByName = Object.keys(nodeDefsObj).filter(
    nodeDefId =>
      nodeDefsObj[nodeDefId].type === nodeDefinitionTypeMap.family &&
      nodeDefsObj[nodeDefId].name.en &&
      nodeDefsObj[nodeDefId].name.en.toLowerCase() === familyName.toLowerCase()
  )

  const itemsIdsInFamilyGrouped = selectedFamiliesIdsByName.map(familyId => {
    const familyLeft = nodeDefsObj[familyId]._left
    const familyRight = nodeDefsObj[familyId]._right

    const itemsIdsInCurrFamily = Object.keys(nodeDefsObj).filter(
      nodeDefId =>
        nodeDefsObj[nodeDefId] &&
        nodeDefsObj[nodeDefId].type === nodeDefinitionTypeMap.item &&
        nodeDefsObj[nodeDefId]._left > familyLeft &&
        nodeDefsObj[nodeDefId]._left < familyRight
    )

    return itemsIdsInCurrFamily
  })

  const itemsIdsInFamilyByName = [].concat(...itemsIdsInFamilyGrouped)

  return itemsIdsInFamilyByName
}

// GET ITEMS RANKED LIST
// get node_definition_ids ranked list

export const getItemsRankingInFamily = (scoringTree, family, rankingLimit, rankingType) => {
  const { nodeDefsObj, scoring } = scoringTree

  let itemsRenking = []

  if (!family) return false

  const itemsIds = getItemsInFamily(scoringTree, family)

  // filter best or worst items greater or less than limit value
  // or get all items enabled and scored if limit is null
  // are excluded not scored, disabled, and demerit items

  itemsRenking = itemsIds.filter(
    nodeDefId =>
      nodeDefsObj[nodeDefId] &&
      scoring.nodes[nodeDefId].is_enabled &&
      scoring.nodes[nodeDefId].scored > 0 &&
      !nodeDefsObj[nodeDefId].bonus_demerit &&
      (!rankingLimit ||
        (scoring.nodes[nodeDefId].percentage >= rankingLimit &&
          rankingType &&
          rankingType === reportRankingTypeMap.best) ||
        (scoring.nodes[nodeDefId].percentage < rankingLimit &&
          rankingType &&
          rankingType === reportRankingTypeMap.worst))
  )

  // order list items by score percentage
  itemsRenking = itemsRenking.sort((aId, bId) => {
    const a = scoring.nodes[aId].percentage
    const b = scoring.nodes[bId].percentage
    if (a > b) return -1
    if (a < b) return 1
    return 0
  })

  // reverse for worst ranking
  if (rankingType && rankingType === reportRankingTypeMap.worst) return itemsRenking.reverse()

  return itemsRenking
}

// GET NODE RANKED LIST
// Get node_id ranked list from
// node_definition_ids ranked list

export const getNodesRankingInFamily = (scoringTree, family, rankingLimit, rankingType) => {
  const itemsRankingInFamily = getItemsRankingInFamily(scoringTree, family, rankingLimit, rankingType)

  const nodesRankingInFamily = itemsRankingInFamily.map(itemId => scoringTree.scoring.nodes[itemId].id)

  return nodesRankingInFamily
}

/*-----------------------------------------------------*/
// Check family is scored
/*-----------------------------------------------------*/

export const isFamilyScored = (scoringTree, family) => {
  const { nodeDefsObj, scoring } = scoringTree

  if (!family) return false

  const itemsIds = getItemsInFamily(scoringTree, family)

  // exclude not scored, disabled, and demerit items
  const isScored = itemsIds.some(
    nodeDefId =>
      nodeDefsObj[nodeDefId] &&
      scoring.nodes[nodeDefId].is_enabled &&
      !nodeDefsObj[nodeDefId].bonus_demerit &&
      scoring.nodes[nodeDefId].scored > 0
  )

  return isScored
}
