import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

const classNames = require('classnames')

export class ScoringSelectProp extends React.Component {
  render() {
    const {
      environment,
      texts,
      label,
      mandatory,
      values,
      slug,
      type,
      onChangeFunction,
      retrieveScoringPropFromRedux,
    } = this.props

    const prop = retrieveScoringPropFromRedux(slug)
    const selectedValue = prop ? prop.value.body[environment.defaultLang] : 'unselected'

    return (
      <div
        className={classNames({
          ns_prop: true,
          ns_select_prop: true,
        })}
      >
        {mandatory && <div className={classNames({ ns_prop_label: true })}>{label[environment.lang]}</div>}
        <select
          className={`va_select form_item_select_input ns_select_input_${slug}`}
          onChange={e => {
            const value = values.filter(x => x.slug === e.target.options[e.target.selectedIndex].id)[0]
            onChangeFunction(slug, type, label, value)
          }}
          value={selectedValue}
        >
          {selectedValue === 'unselected' && (
            <option disabled value="unselected">
              {mandatory ? texts.mandatory : '--'}
            </option>
          )}

          {values.map(opt => {
            return (
              <option key={opt.slug} id={opt.slug}>
                {opt.body[environment.defaultLang]}
              </option>
            )
          })}
        </select>

        {!mandatory && <div className={classNames({ ns_prop_label: true })}>{label[environment.lang]}</div>}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    status: state.status,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringSelectProp)
