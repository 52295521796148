import React from 'react'
import { connect } from 'react-redux'
import scoringPropsTypeMap from '../helper/scoringPropsTypeMap'

import * as actionCreators from '../../../../store/actions'

const classNames = require('classnames')

export class ScoringTextProp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      textValue: false,
    }
  }

  componentDidMount = async () => {
    const { scoring, environment, slug } = this.props
    const textValueProp = scoring.scoringProps.filter(x => x.slug === slug)
    const textValue = textValueProp.length > 0 ? textValueProp[0].value.body[environment.lang] : ''

    this.setState({ initialized: true, textValue })
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { textValue } = this.state
    return nextState.textValue !== textValue
  }

  render() {
    const { environment, texts, mandatory, label, slug, type, onChangeFunction } = this.props

    const { initialized, textValue } = this.state
    const inputType = type === scoringPropsTypeMap.number ? 'number' : 'text'
    return (
      <>
        {initialized && (
          <>
            <div className={classNames({ ns_prop_label: true })}>{label[environment.lang]}</div>
            <div
              className={classNames({
                ns_prop: true,
                ns_text_prop: true,
              })}
            >
              <input
                className={`ns_text_input ns_text_input_${slug}`}
                type={inputType}
                placeholder={mandatory ? texts.mandatory : '--'}
                value={textValue}
                onChange={e => {
                  this.setState({ textValue: e.target.value })
                }}
                onBlur={async e => {
                  await onChangeFunction(e, slug, type, label)
                }}
              />
            </div>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    status: state.status,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringTextProp)
