const scoringPropTypesMap = {
  date: 'date',
  datalist: 'datalist',
  distance: 'distance',
  number: 'number',
  price: 'price',
  select: 'select',
  text: 'text',
  text_or_image: 'text-or-image',
  tire_size: 'tire-size',
  wheel_size: 'wheel-size',
}
export default scoringPropTypesMap
