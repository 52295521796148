import _ from 'lodash'
import { httpGet } from '../../../../va-corejs-v3/api'
import { interRankingGetFirstIndexWithThisScoringId, logTimed } from '../../../../va-corejs-v3/utils'
import mediaCollectionsMap from '../../../../va-corejs-v3/utils/mediaCollectionsMap'

export function updateShowInterRanking(scoringId, nodeDefId, activeScore, templateId, visible) {
  return async dispatch => {
    dispatch({
      type: 'SHOW_INTER_RANKING',
      nodeDefId,
      activeScore,
      templateId,
      visible,
      scoringId,
    })
  }
}

export function updateInterRankingActiveScore(activeScore) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_INTER_RANKING_ACTIVE_SCORE',
      activeScore,
    })
  }
}

export function updateInterRankingProductsList(productsList) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_INTER_RANKING_PRODUCTS_LIST',
      productsList,
    })
  }
}

export function updateInterRankingProductsFromServer(scoring, filteredScore, pin, nodeDefId, nodeDefsObj) {
  return async dispatch => {
    const { id: scoringId, template_id: templateId, status } = scoring

    // Get products from server
    const productsObj = await httpGet(`products?filter[template_id]=${templateId}&filter[status]=${status}&limit=1000`)
    let productsFromServer = productsObj.data.data

    const nodesObj = await httpGet(`nodes?filter[node_definition_id]=${nodeDefId}&include=media&limit=1000`, false)
    const nodes = nodesObj.data.data

    // Put score & media from nodes to products
    productsFromServer = await Promise.all(
      productsFromServer.map(product => {
        return new Promise(resolve => {
          const productNodes = nodes.filter(node => node.product_id === product.id)
          const productNode = productNodes && productNodes.length > 0 ? productNodes[0] : null

          if (productNode === null || productNode.is_enabled === false) {
            resolve(null)
          }

          const hasMedia = productNode.media && productNode.media.length > 0

          let zoomedMediaUrl = hasMedia && productNode.media[0].file ? productNode.media[0].file : ''
          let thumbMediaUrl = hasMedia && productNode.media[0].file_thumb ? productNode.media[0].file_thumb : ''

          product.score = productNode.score || ''
          product.zoomedMediaUrl = zoomedMediaUrl
          product.thumbMediaUrl = thumbMediaUrl

          // If criterion has not media i seek it on father
          if (zoomedMediaUrl === '') {
            const itemId = nodeDefsObj[nodeDefId].parent_id
            httpGet(`nodes?filter[product_id]=${product.id}&filter[node_definition_id]=${itemId}&include=media`)
              .then(response => {
                const productObj =
                  response && response.data && response.data.data && response.data.data.length > 0
                    ? response.data.data[0]
                    : null

                if (productObj === null) {
                  resolve(null)
                }

                const parentHasMedia = productObj.media && productObj.media.length > 0

                if (!parentHasMedia) {
                  resolve(null)
                }

                if (parentHasMedia && productObj.media[0].file) {
                  const { file } = productObj.media[0]
                  zoomedMediaUrl = file
                }

                if (parentHasMedia && productObj.media[0].file_thumb) {
                  const { file_thumb: fileThumb } = productObj.media[0]
                  thumbMediaUrl = fileThumb
                }

                product.zoomedMediaUrl = zoomedMediaUrl
                product.thumbMediaUrl = thumbMediaUrl
                resolve(product)
              })
              .catch(() => {
                resolve(null)
              })
          } else {
            resolve(product)
          }
        })
      })
    )

    productsFromServer = productsFromServer.filter(
      product => product !== null && product.score !== undefined && product.score !== null && product.score !== ''
    )

    logTimed('Put score & media from nodes to products')

    // Order products by score ascending
    productsFromServer = productsFromServer.sort((a, b) => (a.score > b.score ? 1 : -1))

    logTimed('Sort')

    // Get first product index with criterion selected score
    const carouselIndex = interRankingGetFirstIndexWithThisScoringId(productsFromServer, filteredScore, pin, scoringId)

    logTimed('Get first index')
    logTimed('stop')
    dispatch({
      type: 'UPDATE_INTER_RANKING_PRODUCTS_FROM_SERVER',
      productsFromServer,
      carouselIndex,
    })

    return productsFromServer
  }
}

export function updateInterRankingCarouselIndex(carouselIndex) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_INTER_RANKING_CAROUSEL_INDEX',
      carouselIndex,
    })
  }
}

export function updateInterRankingFilteredScore(filteredScore) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_INTER_RANKING_FILTERED_SCORE',
      filteredScore,
    })
  }
}

export function updateInterRankingPin(pin) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_INTER_RANKING_PIN',
      pin,
    })
  }
}

export function updateInterRankingProductMediaAndComments(scoringId, itemId, nodeDefId, target) {
  return async (dispatch, getState) => {
    const state = getState()
    const { productsLoaded } = state.interRanking

    let commentsFiltered = []
    let mediaFiltered = []

    if (!productsLoaded.includes(scoringId)) {
      let media = []
      let comments = []

      const result = await Promise.all([
        // Current node
        httpGet(`nodes?filter[product_id]=${scoringId}&filter[node_definition_id]=${nodeDefId}&include=media,comments`),

        // Parent node
        httpGet(`nodes?filter[product_id]=${scoringId}&filter[node_definition_id]=${itemId}&include=media`),
      ])

      for (let i = 0; i < result.length; i += 1) {
        const response = result[i]
        if (response && response.data && response.data.data && response.data.data.length > 0) {
          const node = response.data.data[0]

          // Concat both parent and current nodes media
          media = node.media ? media.concat(node.media) : media

          // Use only current node comments
          comments = node.comments && i === 0 ? comments.concat(node.comments) : comments
        }
      }

      /* remove forecast comment and media */
      commentsFiltered = comments.filter(comment => !comment.is_forecast)
      mediaFiltered = media.filter(singleMedia => singleMedia.collection === mediaCollectionsMap.node_pictures)

      // Set the current scoring as loaded
      dispatch({
        type: 'ADD_PRODUCT_LOADED',
        productId: scoringId,
      })

      // Update media and comments
      dispatch({
        type: 'UPDATE_PRODUCT_MEDIA',
        productId: scoringId,
        media: mediaFiltered,
      })
      dispatch({
        type: 'UPDATE_PRODUCT_COMMENTS',
        productId: scoringId,
        comments: commentsFiltered,
      })
    } else {
      const { comments, media } = state.interRanking

      commentsFiltered = comments[scoringId] ? _.filter(comments[scoringId], comment => !comment.is_forecast) : []
      mediaFiltered = media[scoringId]
        ? _.filter(media[scoringId], singleMedia => singleMedia.collection === mediaCollectionsMap.node_pictures)
        : []
    }

    // Set media pinned or focused
    if (target === 'pinned') {
      dispatch({
        type: 'UPDATE_PINNED_MEDIA_AND_COMMENTS',
        media: mediaFiltered,
        comments: commentsFiltered,
      })
    }
    if (target === 'focused') {
      dispatch({
        type: 'UPDATE_FOCUSED_MEDIA_AND_COMMENTS',
        media: mediaFiltered,
        comments: commentsFiltered,
      })
    }
  }
}

export function updateInterRankingZoomedImage(productId, newZoomedMediaUrl, newThumbMediaUrl, pin) {
  return async dispatch => {
    if (pin && pin.id === productId) {
      pin.zoomedMediaUrl = newZoomedMediaUrl
      pin.thumbMediaUrl = newThumbMediaUrl
    }

    dispatch({
      type: 'UPDATE_INTER_RANKING_ZOOMED_IMAGE',
      productId,
      newZoomedMediaUrl,
      newThumbMediaUrl,
    })

    dispatch({
      type: 'UPDATE_INTER_RANKING_PIN',
      pin,
    })
  }
}

export function emptyInterRankingFromRedux() {
  return async dispatch => {
    dispatch({
      type: 'EMPTY_INTER_RANKING',
    })
  }
}
