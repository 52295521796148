import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import scopePropsMap from '../helper/scopePropsMap'

const classNames = require('classnames')

export class ScoringDistanceProp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      distanceTextValue: '',
    }
  }

  componentDidMount = async () => {
    const { scoring, environment, slug } = this.props
    const distanceValueProp = _.find(scoring.scoringProps, _prop => _prop.slug === slug)
    const distanceTextValue = distanceValueProp
      ? _.get(
          _.find(distanceValueProp.props, _prop => _prop.slug === scopePropsMap.distance_value),
          ['value', 'body', environment.defaultLang],
          ''
        )
      : ''

    this.setState({ initialized: true, distanceTextValue })
  }

  render() {
    const { initialized, distanceTextValue } = this.state

    const {
      texts,
      scoring,
      environment,
      slug,
      type,
      label,
      mandatory,
      props,
      setScoringPropValue,
      retrieveScoringPropFromRedux,
    } = this.props

    const measure = _.find(props, _prop => _prop.slug === scopePropsMap.distance_measure)
    const prop = retrieveScoringPropFromRedux(slug)

    const measureProp =
      prop && prop.props ? _.find(prop.props, _prop => _prop.slug === scopePropsMap.distance_measure) : null

    function createPropValueObject(distanceValue, measureObj) {
      const { locales } = environment
      const body = {}

      locales.forEach(locale => {
        const { code } = locale
        body[code] = distanceValue
      })

      const value = [
        {
          slug: scopePropsMap.distance_value,
          value: {
            body,
          },
        },
        {
          slug: scopePropsMap.distance_measure,
          value: measureObj,
        },
      ]

      setScoringPropValue(scoring.newScoringId, slug, type, label, value, true)
    }

    function getMeasureObjFromSlug(measureSlug) {
      return measure.values.filter(x => x.slug === measureSlug)[0]
    }

    async function handleValueChanged(e) {
      const distanceValue = e.target.value
      const measureSlug = e.target.parentElement
        .querySelector('.ns_distance_types > .ns_selected_distance')
        .getAttribute('slug')
      const measureObj = getMeasureObjFromSlug(measureSlug)
      await createPropValueObject(distanceValue, measureObj)
    }

    async function handleMeasureSelected(e) {
      const measureSlug = e.target.getAttribute('slug')
      const measureObj = getMeasureObjFromSlug(measureSlug)

      const distanceValue = e.target.parentElement.parentElement.querySelector('.ns_distance_input').value
      await createPropValueObject(distanceValue, measureObj)
    }

    const selectedSlug = () => {
      let alreadySelected = false
      let distanceSelected = null

      measure.values.forEach(m => {
        if (measureProp && measureProp.value.slug === m.slug) {
          distanceSelected = m.slug
          alreadySelected = true
        } else if (m.default === true && !alreadySelected) {
          distanceSelected = m.slug
        }
      })

      return distanceSelected
    }

    return (
      <div>
        {initialized && (
          <div
            className={classNames({
              ns_prop_variant: true,
              ns_distance_prop: true,
            })}
          >
            <div className={classNames({ ns_prop_label: true })}>{label[environment.lang]}</div>
            <input
              className={classNames({
                ns_text_input: true,
                ns_distance_input: true,
              })}
              type="number"
              value={distanceTextValue}
              placeholder={mandatory ? texts.mandatory : '--'}
              onChange={e => {
                this.setState({ distanceTextValue: e.target.value })
              }}
              onBlur={async e => {
                await handleValueChanged(e)
              }}
            />
            <div className={classNames({ ns_distance_types: true })}>
              {measure.values.map(m => {
                return (
                  <div
                    key={m.slug}
                    slug={m.slug}
                    className={classNames({
                      ns_distance_btn: true,
                      ns_selected_distance: selectedSlug() === m.slug,
                    })}
                    role="button"
                    tabIndex={0}
                    onClick={handleMeasureSelected}
                    onKeyPress={handleMeasureSelected}
                  >
                    {m.body[environment.lang]}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    status: state.status,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringDistanceProp)
