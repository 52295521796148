import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import './styles.scss'

const classNames = require('classnames')

export class LoadingBar extends React.Component {
  render() {
    const { color } = this.props
    return (
      <div className={classNames({ loading_bar: true })}>
        <div
          className={classNames({
            loading_bar_spinner_1: true,
            loading_bar_spinner_white: color === 'white',
          })}
        />
        <div
          className={classNames({
            loading_bar_spinner_2: true,
            loading_bar_spinner_white: color === 'white',
          })}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(LoadingBar)
