const scopePropsMap = {
  body_color: 'body-color',
  brand: 'brand',
  comm_name_grade: 'commercial-name-grade',
  date_first_registration: 'date-first-registration',
  distance: 'distance',
  distance_measure: 'distance-measure',
  distance_value: 'distance-value',
  doors: 'doors',
  engine: 'engine',
  energy: 'energy',
  exterior_decor: 'exterior-decor',
  gearbox: 'gearbox',
  level: 'level',
  lights_technology: 'lights-technology',
  main_segment: 'main-segment',
  model: 'model',
  n_vin: 'n-vin',
  other_product_specifications: 'other-product-specifications',
  price: 'price',
  price_minimum: 'price-minimum',
  price_maximum: 'price-maximum',
  price_currency: 'price-currency',
  price_of_model: 'price-of-model',
  screen_size: 'screen-size',
  secondary_segment: 'secondary-segment',
  silhouette: 'silhouette',
  tire_size: 'tire-size',
  tire_size_width: 'tire-size-width',
  tire_size_height: 'tire-size-height',
  tire_diameter: 'tire-diameter',
  tire_size_asymmetrical_mount: 'tire-size-asymmetrical-mount',
  trim: 'trim',
  upholstery: 'upholstery',
  wheels_material: 'wheels-material',
  wheel_size: 'wheel-size',
  stage: 'stage',
}
export default scopePropsMap
