import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import ScoringSearchBar from './_parts/search/scoringSearchBar'
import ScoringSearchHits from './_parts/search/scoringSearchHits'
import ScoringSearchPagination from './_parts/search/scoringSearchPagination'
import screenTypesMap from '../../layout/_parts/screenTypesMap'
import CheckWhite from '../../assets/svg/check-white.svg'
import { LoadingBar } from '../loading_bar'

import { duplicateScoring } from '../../../va-corejs-v3/actions/scoring'

const classNames = require('classnames')

export class DuplicateScoringComponent extends React.Component {
  constructor(props) {
    super(props)

    this.duplicateStatusMap = {
      formEditing: 0,
      requestSent: 1,
      completed: 2,
    }

    this.state = {
      duplicateMedia: false,
      duplicateProjectMode: false,
      duplicateComments: false,
      processState: this.duplicateStatusMap.formEditing,
      showLoadingBar: false,
    }
  }

  handleDuplicateMediaClicked = e => {
    e.stopPropagation()
    const { duplicateMedia } = this.state
    this.setState({ duplicateMedia: !duplicateMedia })
  }

  handleDuplicateCommentsClicked = e => {
    e.stopPropagation()
    const { duplicateComments } = this.state
    this.setState({ duplicateComments: !duplicateComments })
  }

  handleDuplicateProjectModeClicked = e => {
    e.stopPropagation()
    const { duplicateProjectMode } = this.state
    this.setState({ duplicateProjectMode: !duplicateProjectMode })
  }

  handleDuplicateProductClicked = async () => {
    const { scoring, onDuplicate } = this.props
    const { duplicateMedia, duplicateComments, duplicateProjectMode } = this.state

    const duplicateRequestObj = {
      new_template_id: scoring.selectedTemplate === 0 ? null : scoring.selectedTemplate,
      duplicate_media: duplicateMedia,
      duplicate_comments: duplicateComments,
      duplicate_project_mode: duplicateProjectMode,
      client_url: `${process.env.siteUrl}/scoring?id=`,
    }

    this.setState({
      processState: this.duplicateStatusMap.requestSent,
    })

    const proceed = true
    if (proceed === true) {
      const scoringId = scoring.duplicate.sourceScoringId

      if (onDuplicate) await onDuplicate(scoringId, duplicateRequestObj)
      else await duplicateScoring(scoringId, duplicateRequestObj)
    }
  }

  handleClosePopup = async () => {
    const { updateScoringDuplicateProps, onClose } = this.props
    updateScoringDuplicateProps(false, 0, '')
    if (onClose) onClose()
  }

  componentDidMount = async () => {
    const { scoring, updateScoringSearch } = this.props
    updateScoringSearch('', scoring.scoringSearch.pageSize, scoring.scoringSearch.currentPage)
  }

  render() {
    const { texts, device, resetScoringPanelForRedux, scoring, onClose, header } = this.props
    const { duplicateMedia, duplicateComments, duplicateProjectMode, processState, showLoadingBar } = this.state

    // const isProjectMode = isProjectModeEnabled(scoringTree.scoring)
    // DISABLE PROJECT MODE DUPLICATE
    const isProjectMode = false

    function handleCancelButtonClicked() {
      resetScoringPanelForRedux()
      if (onClose) onClose()
    }

    return (
      <div>
        <div className={classNames({ popup_background_layer: true })} />
        <div className={classNames({ popup_outer_container: true })}>
          <div className={classNames({ popup_inner_container: true })}>
            <div className={classNames({ popup_head: true })}>
              {!header ? (
                <span className={classNames({ popup_title: true })}>
                  {`${texts.duplicate} ${scoring.duplicate.sourceProductName}`}
                </span>
              ) : (
                header
              )}
              <span
                role="button"
                tabIndex={0}
                className={classNames({
                  popup_cancel_btn: true,
                  theme_color_hover: true,
                })}
                onClick={handleCancelButtonClicked}
                onKeyPress={handleCancelButtonClicked}
              >
                {texts.cancel}
              </span>
              <div
                className={classNames({
                  duplicate_scoring_fields_container: true,
                })}
              >
                <div
                  className={classNames({
                    duplicate_scoring_field: true,
                    duplicate_scoring_field_media: true,
                  })}
                >
                  <div
                    className={classNames({
                      duplicate_scoring_field_label: true,
                    })}
                  >
                    {texts.duplicate_with_media}
                  </div>
                  <div
                    className={classNames({
                      duplicate_scoring_field_enable_disable_btn_container: true,
                      isSafari: device.isSafari,
                      isFirefox: device.isFirefox,
                      desk: device.screenType === screenTypesMap.desk,
                      ipadh: device.screenType === screenTypesMap.iPadH,
                      ipadv: device.screenType === screenTypesMap.iPadV,
                      iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                      iphonese: device.screenType === screenTypesMap.iPhoneSE,
                    })}
                  >
                    <label
                      htmlFor={`${scoring.duplicate.sourceScoringId}_media`}
                      className={classNames({ ios_toggle: true })}
                    >
                      <input
                        id={`${scoring.duplicate.sourceScoringId}_media`}
                        type="checkbox"
                        checked={duplicateMedia}
                        onChange={e => {
                          this.handleDuplicateMediaClicked(e)
                        }}
                      />
                      <span className={classNames({ slider: true })} />
                    </label>
                  </div>
                </div>
                {isProjectMode && (
                  <div
                    className={classNames({
                      duplicate_scoring_field: true,
                      duplicate_scoring_field_media: true,
                    })}
                  >
                    <div
                      className={classNames({
                        duplicate_scoring_field_label: true,
                      })}
                    >
                      {texts.duplicate_with_forecast}
                    </div>
                    <div
                      className={classNames({
                        duplicate_scoring_field_enable_disable_btn_container: true,
                        isSafari: device.isSafari,
                        isFirefox: device.isFirefox,
                        desk: device.screenType === screenTypesMap.desk,
                        ipadh: device.screenType === screenTypesMap.iPadH,
                        ipadv: device.screenType === screenTypesMap.iPadV,
                        iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                        iphonese: device.screenType === screenTypesMap.iPhoneSE,
                      })}
                    >
                      <label
                        htmlFor={`${scoring.duplicate.sourceScoringId}_project`}
                        className={classNames({ ios_toggle: true })}
                      >
                        <input
                          id={`${scoring.duplicate.sourceScoringId}_project`}
                          type="checkbox"
                          checked={duplicateProjectMode}
                          onChange={e => {
                            this.handleDuplicateProjectModeClicked(e)
                          }}
                        />
                        <span className={classNames({ slider: true })} />
                      </label>
                    </div>
                  </div>
                )}

                <div
                  className={classNames({
                    duplicate_scoring_field: true,
                    duplicate_scoring_field_comment: true,
                  })}
                >
                  <div
                    className={classNames({
                      duplicate_scoring_field_label: true,
                    })}
                  >
                    {texts.duplicate_with_comments}
                  </div>
                  <div
                    className={classNames({
                      duplicate_scoring_field_enable_disable_btn_container: true,
                      isSafari: device.isSafari,
                      isFirefox: device.isFirefox,
                      desk: device.screenType === screenTypesMap.desk,
                      ipadh: device.screenType === screenTypesMap.iPadH,
                      ipadv: device.screenType === screenTypesMap.iPadV,
                      iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                      iphonese: device.screenType === screenTypesMap.iPhoneSE,
                    })}
                  >
                    <label
                      htmlFor={`${scoring.duplicate.sourceScoringId}_comments`}
                      className={classNames({ ios_toggle: true })}
                    >
                      <input
                        id={`${scoring.duplicate.sourceScoringId}_comments`}
                        type="checkbox"
                        checked={duplicateComments}
                        onChange={e => {
                          this.handleDuplicateCommentsClicked(e)
                        }}
                      />
                      <span className={classNames({ slider: true })} />
                    </label>
                  </div>
                </div>
              </div>
              <ScoringSearchBar />
            </div>
            <div
              className={classNames({
                popup_body_container: true,
                popup_body_container_duplicate_scoring: true,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
            >
              <div>
                <ScoringSearchHits />
                <ScoringSearchPagination />
              </div>
            </div>
            <div
              className={classNames({
                popup_footer: true,
                request_sent_container: processState === this.duplicateStatusMap.requestSent,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
            >
              <div>
                <button
                  type="button"
                  className={classNames({
                    sm_btn: true,
                    theme_primary_btn: true,
                    float_right_btn: true,
                    btn_200: true,
                    request_sent: processState === this.duplicateStatusMap.requestSent,
                  })}
                  onClick={
                    processState === this.duplicateStatusMap.requestSent
                      ? () => {
                          this.handleClosePopup()
                        }
                      : () => {
                          this.handleDuplicateProductClicked()
                        }
                  }
                >
                  <span
                    className={classNames({
                      btn_label_with_icon: processState !== this.duplicateStatusMap.formEditing,
                      btn_label_with_working_icon: processState === this.duplicateStatusMap.requestSent,
                      btn_label_with_check_icon: processState === this.duplicateStatusMap.completed,
                    })}
                  >
                    {processState === this.duplicateStatusMap.requestSent ? 'OK' : texts.duplicate_scoring}
                  </span>
                  {/* processState === this.duplicateStatusMap.requestSent && (
                      <div className={classNames({ btn_spinner_container: true })}>
                        <span className={classNames({ btn_spinner: true })} />
                      </div>
                    ) */}
                  {processState === this.duplicateStatusMap.completed && <CheckWhite />}
                </button>
                {processState === this.duplicateStatusMap.requestSent && (
                  <div className="request_sent_text" style={{}}>
                    {texts.duplicate_request_sent}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {processState === this.duplicateStatusMap.requestSent && showLoadingBar && (
          <div
            className={classNames({
              full_width_loading_outer_container: true,
            })}
          >
            <div
              className={classNames({
                full_width_loading_inner_container: true,
              })}
            >
              <LoadingBar />
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(DuplicateScoringComponent)
