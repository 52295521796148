// Media
export const SYNC_ACTION_UPLOAD_MEDIA = 'uploadMedia'
export const SYNC_ACTION_DELETE_MEDIA = 'deleteMedia'
export const SYNC_ACTION_REORDER_MEDIA = 'reorderMedia'

// Products
export const SYNC_ACTION_CREATE_PRODUCT = 'createProduct'
export const SYNC_ACTION_UPDATE_PRODUCT = 'updateProduct'
export const SYNC_ACTION_DELETE_PRODUCT = 'deleteProduct'

// Scoring tree update
// It happens after you have scored a node, enabled/disabled it...
export const SYNC_ACTION_UPDATE_SCORING_TREE = 'updateScoringTree'

// Comments
export const SYNC_ACTION_CREATE_COMMENT = 'createComment'
export const SYNC_ACTION_UPDATE_COMMENT = 'updateComment'
export const SYNC_ACTION_DELETE_COMMENT = 'deleteComment'
export const SYNC_ACTION_CREATE_COMMENTS = 'createComments'
export const SYNC_ACTION_UPDATE_COMMENTS = 'updateComments'
