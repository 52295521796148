import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import * as actionCreators from '../../../../store/actions'
import scopePropsMap from '../helper/scopePropsMap'

const classNames = require('classnames')

export class ScoringTireSizeProp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      tireWidth: '',
      tireHeight: '',
    }
  }

  componentDidMount = async () => {
    const { scoring, environment, slug } = this.props
    const tireSizeValueProp = scoring.scoringProps.filter(x => x.slug === slug)
    const tireWidth = tireSizeValueProp.length > 0 ? tireSizeValueProp[0].props[0].value.body[environment.lang] : ''

    const tireHeight = tireSizeValueProp.length > 0 ? tireSizeValueProp[0].props[1].value.body[environment.lang] : ''

    this.setState({ initialized: true, tireWidth, tireHeight })
  }

  render() {
    const { initialized, tireWidth, tireHeight } = this.state

    const {
      scoring,
      environment,
      texts,
      mandatory,
      label,
      slug,
      type,
      props,
      retrieveScoringPropFromRedux,
      setScoringPropValue,
    } = this.props

    const tireDiameterProp = _.find(props, _prop => _prop.slug === scopePropsMap.tire_diameter)
    const tireSizeHeightProp = _.find(props, _prop => _prop.slug === scopePropsMap.tire_size_height)
    const tireSizeWidthProp = _.find(props, _prop => _prop.slug === scopePropsMap.tire_size_width)
    const tireSizeAsymmetricalMountProp = _.find(
      props,
      _prop => _prop.slug === scopePropsMap.tire_size_asymmetrical_mount
    )

    const prop = retrieveScoringPropFromRedux(slug)
    const diameterValues = tireDiameterProp.values

    const selectedTireDiameterProp = prop && _.find(prop.props, _prop => _prop.slug === scopePropsMap.tire_diameter)

    const selectedTireSizeAsymmetricalMountProp =
      prop && _.find(prop.props, _prop => _prop.slug === scopePropsMap.tire_size_asymmetrical_mount)

    const selectedDiameterValue =
      selectedTireDiameterProp && selectedTireDiameterProp.value !== undefined
        ? selectedTireDiameterProp.value.body[environment.lang]
        : 'unselected'

    const selectedAsymmetricalMountValue =
      selectedTireSizeAsymmetricalMountProp && selectedTireSizeAsymmetricalMountProp.value !== undefined
        ? selectedTireSizeAsymmetricalMountProp.value.body[environment.lang]
        : 'unselected'

    function getDiameterObjFromSlug(currencySlug) {
      return diameterValues.filter(x => x.slug === currencySlug)[0]
    }

    function createPropValueObject(tWidth, tHeight, diamObj, aMountVal) {
      const { locales } = environment
      const bodyWidth = {}
      const bodyHeight = {}
      const bodyAsymMount = {}
      locales.forEach(locale => {
        const { code } = locale
        bodyWidth[code] = tWidth
        bodyHeight[code] = tHeight
        bodyAsymMount[code] = aMountVal
      })

      const value = [
        {
          slug: tireSizeWidthProp.slug,
          value: {
            body: bodyWidth,
          },
        },
        {
          slug: tireSizeHeightProp.slug,
          value: {
            body: bodyHeight,
          },
        },
        {
          slug: tireDiameterProp.slug,
          value: diamObj,
        },
        {
          slug: tireSizeAsymmetricalMountProp.slug,
          value: {
            body: bodyAsymMount,
          },
        },
      ]
      setScoringPropValue(scoring.newScoringId, slug, type, label, value, true)
    }

    function handleSizeChanged(e) {
      const container = e.target.parentElement
      const tWidth = container.querySelector('.ns_width_input').value
      const tHeight = container.querySelector('.ns_height_input').value
      const dSelect = container.querySelector('.ns_diamenter_select')
      const dSlug = dSelect.options[dSelect.selectedIndex].id
      const diamObj = getDiameterObjFromSlug(dSlug)
      const aMountVal = container.querySelector('.ns_boolean_btn input').checked
      createPropValueObject(tWidth, tHeight, diamObj, aMountVal)
    }

    function handleDiameterChanged(e) {
      const container = e.target.parentElement
      const tWidth = container.querySelector('.ns_width_input').value
      const tHeight = container.querySelector('.ns_height_input').value
      const dSlug = e.target.options[e.target.selectedIndex].id
      const diamObj = getDiameterObjFromSlug(dSlug)
      const aMountVal = container.querySelector('.ns_boolean_btn input').checked
      createPropValueObject(tWidth, tHeight, diamObj, aMountVal)
    }

    function handleAsymmetricalMouthChanged(e) {
      const container = e.target.parentElement.parentElement.parentElement.parentElement
      const tWidth = container.querySelector('.ns_width_input').value
      const tHeight = container.querySelector('.ns_height_input').value
      const dSelect = container.querySelector('.ns_diamenter_select')
      const dSlug = dSelect.options[dSelect.selectedIndex].id
      const diamObj = getDiameterObjFromSlug(dSlug)
      const aMountVal = e.target.checked
      createPropValueObject(tWidth, tHeight, diamObj, aMountVal)
    }

    return (
      <div>
        {initialized && (
          <div
            className={classNames({
              ns_prop_variant: true,
              ns_tire_size_prop: true,
            })}
          >
            <div className={classNames({ ns_prop_label: true })}>{label[environment.lang]}</div>
            <input
              className={classNames({
                ns_text_input: true,
                ns_size_input: true,
                ns_width_input: true,
              })}
              type="text"
              value={tireWidth}
              onChange={e => {
                this.setState({ tireWidth: e.target.value })
                handleSizeChanged(e)
              }}
              placeholder={mandatory ? texts.mandatory : tireSizeWidthProp.label[environment.lang]}
            />
            <input
              className={classNames({
                ns_text_input: true,
                ns_size_input: true,
                ns_height_input: true,
              })}
              type="text"
              value={tireHeight}
              onChange={e => {
                this.setState({ tireHeight: e.target.value })
                handleSizeChanged(e)
              }}
              placeholder={mandatory ? texts.mandatory : tireSizeHeightProp.label[environment.lang]}
            />
            <select
              className={classNames({
                va_select: true,
                form_item_select_input: true,
                ns_diamenter_select: true,
              })}
              onChange={handleDiameterChanged}
              value={selectedDiameterValue}
            >
              {selectedDiameterValue === 'unselected' && (
                <option disabled value="unselected">
                  {mandatory ? texts.mandatory : tireDiameterProp.label[environment.defaultLang]}
                </option>
              )}

              {diameterValues.map(opt => {
                return (
                  <option key={opt.slug} id={opt.slug}>
                    {opt.body[environment.lang]}
                  </option>
                )
              })}
            </select>
            <div className={classNames({ ns_boolean_input: true })}>
              <div className={classNames({ ns_boolean_lable: true })}>
                {tireSizeAsymmetricalMountProp.label[environment.lang]}
              </div>
              <div className={classNames({ ns_boolean_btn: true })}>
                <label htmlFor={tireSizeAsymmetricalMountProp.slug} className={classNames({ ios_toggle: true })}>
                  <input
                    id={tireSizeAsymmetricalMountProp.slug}
                    type="checkbox"
                    onChange={handleAsymmetricalMouthChanged}
                    checked={!!(selectedAsymmetricalMountValue === true || selectedAsymmetricalMountValue === 'true')}
                  />
                  <span className={classNames({ slider: true })} />
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    status: state.status,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringTireSizeProp)
