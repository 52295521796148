/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'

class ZoomedItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      blobUrl: '',
    }
  }

  componentDidMount = async () => {
    await this.setMediaBlobUrl()
  }

  componentDidUpdate = async prevProps => {
    const { url } = this.props
    if (url !== prevProps.url) {
      await this.setMediaBlobUrl()
    }
  }

  setMediaBlobUrl = async () => {
    const { url } = this.props

    if (url) {
      this.setState({
        blobUrl: url && url !== '' ? await getMediaBlobUrl(url) : '',
      })
    }
  }

  render() {
    const { blobUrl } = this.state

    return <img src={blobUrl} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ZoomedItem)
