const apiEndPoints = {
  auth_check_email: 'auth/check-email',
  auth_login: 'oauth/token',
  auth_refresh: 'oauth/token',
  auth_me: 'auth/me',
  auth_forgot_psw: 'auth/forgot-password',
  auth_reset_psw: 'auth/reset-password',
  auth_oidc_refresh_token: 'oauth/refresh',
  user: 'users',
  batch: 'batch',
  clipboard: 'products/{scoringId}/media/{mediaId}',
  delete_product: 'products/{id}',
  duplicate_product: 'products/{id}/duplicate',
  elastic: 'elastic',
  elastic_valueable_search: 'elastic/valueable/_search',
  export_comments: 'export/products/{scoringId}/comments',
  export_full_product: 'export/products/{scoringId}/full',
  export_v2_full_product: 'export/products/v2/{scoringId}/full',
  export_media: 'export/products/{scoringId}/scoring-tree/media',
  export_scoring: 'export/products/{scoringId}/scoring-tree',
  export_columns: 'export/products/columns?template={templateCode}',
  home_blocks: 'home-blocks',
  import_comments: 'import/products/{scoringId}/comments',
  import_media: 'import/products/{scoringId}/scoring-tree/media',
  import_scoring: 'import/products/{scoringId}/scoring-tree',
  locales: 'locales',
  nodes: 'nodes',
  nodes_of_product: 'nodes?filter[product_id]={pId}&filter[node_definition_id]={commaSeparatedDefIds}',
  nodes_descendants:
    'nodes/descendants?product_id={pId}&node_definition_id={defId}&include=mediaCount,commentsCount,media,comments',
  products: 'products',
  products_filter: 'products?filter[_id]={commaSeparatedIdList}&include=nodes',
  settings: 'settings',
  scopes_all: 'scopes/all',
  scopes: 'scopes',
  statuses: 'statuses/all',
  templates: 'templates/all',
  sync_products: 'products',
  sync_nodes: 'nodes',
  sync_new_product_nodes: 'products/{id}/scoring-tree',
  sync_cover_picture: 'products/{scoring_id}/media/{media_id}',
  sync_media: 'nodes/{node_id}/media/{media_id}',
  publishing_services: 'publisher/services',
  view_media: 'media/{id}/{conversion}',
}
export default apiEndPoints
