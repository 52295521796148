import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'

const classNames = require('classnames')

export class LanguageSelector extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      langList: [],
    }
  }

  componentDidMount = async () => {
    const { languages } = process.env
    const langList = languages.split(',')
    this.setState({ langList })
  }

  render() {
    const { environment, updateSystemLangIntoStorageAndReloadTextsIntoRedux } = this.props
    const { langList } = this.state

    function handleLanguageSelected(lang) {
      updateSystemLangIntoStorageAndReloadTextsIntoRedux(lang)
    }

    const Languages = () => {
      const html = []
      const langCount = langList.length
      const itmWidht = `${274 / langCount - 2}px`
      for (let i = 0; i !== langCount; i += 1) {
        const lang = langList[i]
        html.push(
          <div
            key={i}
            className={classNames({
              lang_item: true,
              lang_item_selected: environment.lang === lang,
            })}
            style={{ width: itmWidht }}
            role="button"
            tabIndex={0}
            onClick={() => {
              handleLanguageSelected(lang)
            }}
            onKeyDown={() => {
              handleLanguageSelected(lang)
            }}
          >
            {lang}
          </div>
        )
      }
      return html
    }

    return (
      <div className={classNames({ language_selector: true })}>
        <Languages />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(LanguageSelector)
