import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../store/actions'
import RetryIcon from '../../../assets/svg/reset-icon.svg'

function SnackbarBodyFailure({ device, errorsCount, retryAllFailedSyncItems, clearAllFailedSyncItems }) {
  const handleRetry = async e => {
    e.preventDefault()

    if (device.isOnline) {
      retryAllFailedSyncItems()
    }
  }

  const handleClearAll = async e => {
    e.preventDefault()

    await clearAllFailedSyncItems()
  }

  return (
    <div className="sync-snackbar__body">
      <span className="sync-snackbar__error-counter">{errorsCount} errors</span>

      <div className="sync-snackbar__buttons-container">
        <button
          type="button"
          className="sync-snackbar__button sync-snackbar__button--error"
          disabled={!device.isOnline}
          onClick={handleRetry}
        >
          <span className="sync-snackbar__button-icon">
            <RetryIcon width="11" />
          </span>
          Retry
        </button>

        <button type="button" className="sync-snackbar__button sync-snackbar__button--grey" onClick={handleClearAll}>
          Clear all
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(SnackbarBodyFailure)
