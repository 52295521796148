import axios from 'axios'
import storage from '../storage'
import storageMap from '../storage/storageMap'
import apiEndPoints from './apiEndPoints'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Content-Type'] = 'application/json'

let isRefreshing = false
let failedQueue = []

function isRefreshTokenRequest(error) {
  if (
    error.config.url.includes('oauth/token/refresh') ||
    (error.config.data && error.config.data.includes('refresh_token'))
  ) {
    return true
  }

  return false
}

async function resetUserData() {
  await storage.remove(storageMap.access_token, 1)
  await storage.empty(storageMap.user)

  // Redirect if logged out
  if (typeof window !== 'undefined') {
    window.location.replace('/')
  }
}

async function refreshToken() {
  const oidcFlow = global.env.oidcFlow !== undefined ? JSON.parse(global.env.oidcFlow) : false
  const tokenData = await storage.get(storageMap.access_token, 1)
  return new Promise((resolve, reject) => {
    if (oidcFlow) {
      axios
        .post(global.env.apiUrl + apiEndPoints.auth_oidc_refresh_token, {
          token: tokenData.access_token,
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    } else {
      axios
        .post(global.env.apiUrl + apiEndPoints.auth_refresh, {
          grant_type: 'refresh_token',
          refresh_token: tokenData.refresh_token,
          client_id: global.env.oauthClientId,
          client_secret: global.env.oauthClientSecret,
          scope: '',
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    }
  })
}

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

axios.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const originalRequest = error.config

    if (error.response.status === 401 && isRefreshTokenRequest(error)) {
      return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject })
        })
          .then(token => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            return axios(originalRequest)
          })
          .catch(err => {
            return Promise.reject(err)
          })
      }

      originalRequest._retry = true
      isRefreshing = true

      return new Promise((resolve, reject) => {
        refreshToken()
          .then(({ data }) => {
            storage.update(storageMap.access_token, {
              id: 1,
              access_token: data.access_token,
              refresh_token: data.refresh_token,
            })
            axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`
            originalRequest.headers.Authorization = `Bearer ${data.access_token}`
            processQueue(null, data.access_token)
            resolve(axios(originalRequest))
          })
          .catch(err => {
            processQueue(err, null)
            resetUserData()
            reject(err)
          })
          .finally(() => {
            isRefreshing = false
          })
      })
    }

    return Promise.reject(error)
  }
)

// Methods
export function setJwt(jwt) {
  axios.defaults.headers.common['x-auth-token'] = jwt
}

export async function httpHeaders(path, form = false) {
  let obj = {}
  obj.headers = {}

  // If request involve authentications access_token is not needed
  if (path.indexOf('auth/login') > -1 || path.indexOf('auth/check-email') > -1) {
    return null
  }

  // Bearer
  const tokenRecord = await storage.get(storageMap.access_token, 1)
  if (tokenRecord) {
    obj.headers.Authorization = `Bearer ${tokenRecord.access_token}`
  }

  // Form
  if (form) {
    obj.headers['Content-Type'] = 'multipart/form-data'
  } else {
    obj.headers['Content-Type'] = 'application/json'
  }

  if (obj.headers === {}) {
    obj = null
  }

  return obj
}

export async function httpGet(path) {
  try {
    // const apiUrl = isFake ? global.env.fakeApiUrl : global.env.apiUrl
    const response = await axios.get(global.env.apiUrl + path, await httpHeaders(path))
    return response
  } catch (err) {
    return false
  }
}

export async function httpPost(path, dataToSend) {
  try {
    const { apiUrl } = global.env
    const response = await axios.post(apiUrl + path, dataToSend, await httpHeaders(path))
    return response
  } catch (err) {
    console.log('httpPost ERROR', err)
    return err.response
  }
}

export async function httpPut(path, dataToSend) {
  try {
    const { apiUrl } = global.env
    const response = await axios.put(apiUrl + path, dataToSend, await httpHeaders(path))
    return response
  } catch (err) {
    console.log('httpPust ERROR', err)
    return err.response
  }
}

export async function httpPatch(path, dataToSend) {
  try {
    const { apiUrl } = global.env
    const response = await axios.patch(apiUrl + path, dataToSend, await httpHeaders(path))
    return response
  } catch (err) {
    console.log('httpPatch ERROR', err)
    return err.response
  }
}

export async function httpDelete(path, data) {
  try {
    const { apiUrl } = global.env
    const headers = await httpHeaders(path)
    headers.data = data
    const response = await axios.delete(apiUrl + path, headers)
    return response
  } catch (err) {
    console.log('httpDelete ERROR', err)
    return err.response
  }
}

export async function httpPostAll(path, list, method) {
  const { apiUrl } = global.env
  const promises = []
  for (let i = 0; i < list.length; i += 1) {
    const element = list[i]
    if (element) {
      switch (method) {
        case 'get':
          promises.push({
            method: 'GET',
            relative_url: `${apiUrl}${path}`,
          })
          break
        case 'post':
          promises.push({
            method: 'POST',
            'content-type': 'application/json',
            relative_url: `${apiUrl}${path}/${element.id}`,
            name: element.id,
            body: element,
          })
          break

        default:
          break
      }
    }
  }
  const response = await axios.post(`${apiUrl}batch`, { batch: promises }, await httpHeaders(''))
  return response
}

export async function httpExport(path) {
  try {
    const url = new URL(global.env.apiUrl + path)

    let mediaCallbackUrlQueryData = `${process.env.siteUrl}/media`
    mediaCallbackUrlQueryData = mediaCallbackUrlQueryData.replace(/([^:]\/)\/+/g, '$1') // Remove duplicated slashes

    url.searchParams.append('media_callback_url', mediaCallbackUrlQueryData)

    const result = await axios.get(url, await httpHeaders(path))
    if (result && result.data) {
      window.open(result.data)
    }
    return true
  } catch (err) {
    console.log(err)
    return false
  }
}

/**
 * Return the blob URL of a media
 *
 * @param {string} url The URL must be absolute
 * @returns
 */
export async function getMediaBlobUrl(url) {
  const tokenRecord = await storage.get(storageMap.access_token, 1)
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${tokenRecord.access_token}`,
      },
      responseType: 'blob',
    })

    const urlCreator = window.URL || window.webkitURL
    return urlCreator.createObjectURL(response.data)
  } catch (err) {
    return false
  }
}
