import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import {
  SYNC_ACTION_CREATE_COMMENT,
  SYNC_ACTION_CREATE_COMMENTS,
  SYNC_ACTION_CREATE_PRODUCT,
  SYNC_ACTION_DELETE_COMMENT,
  SYNC_ACTION_DELETE_MEDIA,
  SYNC_ACTION_DELETE_PRODUCT,
  SYNC_ACTION_REORDER_MEDIA,
  SYNC_ACTION_UPDATE_COMMENT,
  SYNC_ACTION_UPDATE_COMMENTS,
  SYNC_ACTION_UPDATE_PRODUCT,
  SYNC_ACTION_UPDATE_SCORING_TREE,
  SYNC_ACTION_UPLOAD_MEDIA,
} from '../../../../va-corejs-v3/sync/consts'
import * as actionCreators from '../../../store/actions'
import { selectSyncError } from '../../../store/selectors/sync_v2'
import RetryIcon from '../../../assets/svg/reset-icon.svg'
import SyncIcon from '../../../assets/svg/sync.svg'
import SuccessMarkIcon from '../../../assets/svg/success_mark.svg'
import { SYNC_SNACKBAR_STATUS_IN_PROGRESS, SYNC_SNACKBAR_STATUS_SUCCESS } from '../consts'

const classNames = require('classnames')

function SnackbarItem({ device, item, error, removeSyncItem, retrySyncItem }) {
  const [isLoading, setIsLoading] = useState(false)
  const { action, completed_at: completedAt } = item

  const actionText = useMemo(() => {
    switch (action) {
      case SYNC_ACTION_UPLOAD_MEDIA:
        return 'Upload media'
      case SYNC_ACTION_DELETE_MEDIA:
        return 'Delete media'
      case SYNC_ACTION_REORDER_MEDIA:
        return 'Reorder media'
      case SYNC_ACTION_CREATE_PRODUCT:
        return 'Create scoring'
      case SYNC_ACTION_UPDATE_PRODUCT:
        return 'Update scoring'
      case SYNC_ACTION_DELETE_PRODUCT:
        return 'Delete scoring'
      case SYNC_ACTION_UPDATE_SCORING_TREE:
        return 'Update scoring tree'
      case SYNC_ACTION_CREATE_COMMENT:
        return 'Create comment'
      case SYNC_ACTION_CREATE_COMMENTS:
        return 'Create comments'
      case SYNC_ACTION_UPDATE_COMMENT:
        return 'Update comment'
      case SYNC_ACTION_UPDATE_COMMENTS:
        return 'Update comments'
      case SYNC_ACTION_DELETE_COMMENT:
        return 'Delete comment'

      default:
        return ''
    }
  }, [action])

  const handleRetry = async e => {
    e.preventDefault()

    if (device.isOnline) {
      setIsLoading(true)
      retrySyncItem(item.id).finally(() => {
        setIsLoading(false)
      })
    }
  }

  const handleDiscard = async e => {
    e.preventDefault()

    await setIsLoading(true)
    await removeSyncItem(item.id)
    // setIsLoading(false)
  }

  return (
    <div className="sync-snackbar-item">
      <div className="sync-snackbar-item__column">
        <div className="sync-snackbar-item__action">{actionText}</div>
        {error && <div className="sync-snackbar-item__error">{error.message}</div>}
      </div>

      {!completedAt && (
        <div className="sync-snackbar-item__column">
          <SyncIcon
            width="21"
            height="21"
            className={classNames('sync-snackbar-icon', `sync-snackbar-icon--${SYNC_SNACKBAR_STATUS_IN_PROGRESS}`)}
          />
        </div>
      )}

      {completedAt && !error && (
        <div className="sync-snackbar-item__column">
          <SuccessMarkIcon
            width="21"
            height="21"
            className={classNames('sync-snackbar-icon', `sync-snackbar-icon--${SYNC_SNACKBAR_STATUS_SUCCESS}`)}
          />
        </div>
      )}

      {error && (
        <div className="sync-snackbar-item__column sync-snackbar-item__buttons-container">
          <button
            type="button"
            className="sync-snackbar__button sync-snackbar__button--error"
            disabled={!device.isOnline || isLoading}
            onClick={handleRetry}
          >
            <span className="sync-snackbar__button-icon">
              <RetryIcon width="11" />
            </span>
            Retry
          </button>

          <button
            type="button"
            className="sync-snackbar__button sync-snackbar__button--grey"
            disabled={isLoading}
            onClick={handleDiscard}
          >
            Discard
          </button>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    device: state.device,
    texts: state.texts.values,
    error: ownProps.item.id ? selectSyncError(state, ownProps.item.id) : null,
  }
}

export default connect(mapStateToProps, actionCreators)(SnackbarItem)
