import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import scopePropsMap from '../helper/scopePropsMap'

const classNames = require('classnames')

export class ScoringPriceProp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      priceMin: '',
      priceMax: '',
      priceModel: '',
      priceCurrency: '',
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState !== this.state
  }

  componentDidMount = async () => {
    const { scoring, environment } = this.props
    const { scoringProps } = scoring

    const priceValueProp = _.find(scoringProps, _prop => _prop.slug === scopePropsMap.price)

    let priceMin = ''
    let priceMax = ''
    let priceModel = ''
    let priceCurrency = ''

    if (priceValueProp !== undefined && priceValueProp !== null) {
      const { props } = priceValueProp
      const lang = environment.defaultLang

      const propMinValue = _.find(props, _prop => _prop.slug === scopePropsMap.price_minimum)?.value?.body[lang]
      const propMaxValue = _.find(props, _prop => _prop.slug === scopePropsMap.price_maximum)?.value?.body[lang]
      const propModelValue = _.find(props, _prop => _prop.slug === scopePropsMap.price_of_model)?.value?.body[lang]
      const propCurrencyValue = _.find(props, _prop => _prop.slug === scopePropsMap.price_maximum)?.value?.slug

      priceMin = propMinValue ?? priceMin
      priceMax = propMaxValue ?? priceMax
      priceModel = propModelValue ?? priceModel
      priceCurrency = propCurrencyValue ?? priceCurrency
    }

    this.setState({
      initialized: true,
      priceMin,
      priceMax,
      priceModel,
      priceCurrency,
    })
  }

  render() {
    const { initialized, priceMin, priceMax, priceModel, priceCurrency } = this.state

    const { texts, scoring, environment, slug, type, label, mandatory, props, setScoringPropValue } = this.props

    const currencies = _.find(props, _prop => _prop.slug === 'price-currency')

    const priceModelProp = _.find(props, _prop => _prop.slug === scopePropsMap.price_of_model)
    const priceMinProp = _.find(props, _prop => _prop.slug === scopePropsMap.price_minimum)
    const priceMaxProp = _.find(props, _prop => _prop.slug === scopePropsMap.price_maximum)

    async function createPropValueObject(modelPrice, min, max, currencyObj) {
      const { locales } = environment
      const bodyMin = {}
      const bodyMax = {}
      const bodyModelPrice = {}

      locales.forEach(locale => {
        const { code } = locale
        bodyMin[code] = min
        bodyMax[code] = max
        bodyModelPrice[code] = modelPrice
      })

      const modelValue = {
        slug: scopePropsMap.price_of_model,
        value: {
          body: bodyModelPrice,
        },
      }

      const minimunValue = {
        slug: scopePropsMap.price_minimum,
        value: {
          body: bodyMin,
        },
      }

      const maximumValue = {
        slug: scopePropsMap.price_maximum,
        value: {
          body: bodyMax,
        },
      }

      const currValue = {
        slug: scopePropsMap.price_currency,
        value: currencyObj,
      }

      const value = [modelValue, minimunValue, maximumValue, currValue]
      await setScoringPropValue(scoring.newScoringId, slug, type, label, value, true)
    }

    function getCurrencyObjFromSlug(currencySlug) {
      return _.find(currencies.values, _value => _value.slug === currencySlug)
    }

    async function handlePriceChanged() {
      const currencySlug = document.querySelector('.price_curr_select').value
      const currencyObj = getCurrencyObjFromSlug(currencySlug)
      await createPropValueObject(priceModel, priceMin, priceMax, currencyObj)
    }

    async function handleCurrencySelected(e) {
      const container = e.target.parentElement.parentElement.parentElement

      const min = container.querySelector('.ns_minumum_input').value
      const max = container.querySelector('.ns_maximum_input').value
      const currencySlug = e.target.options[e.target.selectedIndex].value
      const currencyObj = getCurrencyObjFromSlug(currencySlug)
      const modelPrice = container.querySelector('.ns_model_price_input').value

      await createPropValueObject(modelPrice, min, max, currencyObj)
    }

    return (
      <div>
        {initialized && (
          <div className={classNames({ ns_price_prop: true })}>
            <div className={classNames({ ns_price_left_column: true })}>
              <div className={classNames({ ns_prop_label: true })}>{texts.price_of_model}</div>
              <div>
                <input
                  className={classNames({
                    ns_text_input: true,
                    ns_price_input: true,
                    ns_model_price_input: true,
                  })}
                  type="number"
                  value={priceModel}
                  onChange={e => {
                    this.setState({ priceModel: e.target.value })
                  }}
                  onBlur={async e => {
                    await handlePriceChanged(e)
                  }}
                  placeholder={mandatory ? texts.mandatory : priceModelProp.label[environment.lang]}
                />
                <select
                  className={classNames({
                    va_select: true,
                    form_item_select_input: true,
                    price_curr_select: true,
                  })}
                  value={priceCurrency}
                  onChange={e => {
                    this.setState({ priceCurrency: e.target.value })
                  }}
                  onBlur={async e => {
                    await handleCurrencySelected(e)
                  }}
                >
                  {currencies.values.map(curr => {
                    return (
                      <option key={curr.slug} value={curr.slug} id={curr.slug}>
                        {curr.body[environment.defaultLang]}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className={classNames({ ns_price_or_lable: true })}>{texts.or}</div>
            <div className={classNames({ ns_price_right_column: true })}>
              <div className={classNames({ ns_prop_label: true })}>{texts.price_range}</div>
              <div>
                <input
                  className={classNames({
                    ns_text_input: true,
                    ns_price_input: true,
                    ns_minumum_input: true,
                  })}
                  type="number"
                  value={priceMin}
                  onChange={async e => {
                    this.setState({ priceMin: e.target.value })
                  }}
                  onBlur={async e => {
                    await handlePriceChanged(e)
                  }}
                  placeholder={mandatory ? texts.mandatory : priceMinProp.label[environment.lang]}
                />
                <input
                  className={classNames({
                    ns_text_input: true,
                    ns_price_input: true,
                    ns_maximum_input: true,
                  })}
                  type="number"
                  value={priceMax}
                  onChange={e => {
                    this.setState({ priceMax: e.target.value })
                  }}
                  onBlur={async e => {
                    await handlePriceChanged(e)
                  }}
                  placeholder={mandatory ? texts.mandatory : priceMaxProp.label[environment.lang]}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    status: state.status,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringPriceProp)
