import { httpPatch, httpPost } from './index'
import ApiException from './apiException'

export const batchCreate = async (productId, nodes) => {
  const response = await httpPost(`products/${productId}/scoring-tree`, { nodes }, false)

  if (response.status && response.status >= 200 && response.status < 300) {
    return response.data
  }

  throw new ApiException(response)
}

export const batchUpdate = async (productId, nodes) => {
  const response = await httpPatch(`products/${productId}/scoring-tree`, { nodes }, false)

  if (response.status && response.status >= 200 && response.status < 300) {
    return response.data
  }

  throw new ApiException(response)
}
