import _ from 'lodash'
import storage from '../storage'
import storageMap from '../storage/storageMap'

export default class IdbTusUrlStorage {
  findAllUploads = () => {
    const results = this._findEntries('tus::')
    return Promise.resolve(results)
  }

  findUploadsByFingerprint = fingerprint => {
    const results = this._findEntries(`tus::${fingerprint}::`)
    return Promise.resolve(results)
  }

  removeUpload = urlStorageKey => {
    return storage.removeByFilter(storageMap.tus_urls, item => item.key === urlStorageKey)
  }

  addUpload = async (fingerprint, upload) => {
    const id = Math.round(Math.random() * 1e12)
    const key = `tus::${fingerprint}::${id}`

    await storage.put(storageMap.tus_urls, {
      key,
      upload: JSON.stringify(upload),
    })

    return Promise.resolve(key)
  }

  _findEntries = async prefix => {
    const items = await storage.filter(storageMap.tus_urls, item => {
      return item.key.indexOf(prefix) === 0
    })

    return _.map(items, item => JSON.parse(item.upload))
  }
}
