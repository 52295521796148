import apiEndPoints from '../../api/apiEndPoints'
import { httpPut, httpDelete } from '../../api'
import { readUploadedAttachmentAsBase64, mongoObjectId, isoDate } from '../../utils'

export async function sendAttachmentToServer(scoringId, obj) {
  const url = apiEndPoints.clipboard.replace('{scoringId}', scoringId).replace('{mediaId}', obj.id)
  try {
    const singleResponse = await httpPut(url, obj, false)
    return singleResponse || false
  } catch (e) {
    console.log('Error sending Attachment to Server', e)
    return false
  }
}

export async function removeAttachmentOnServer(scoringId, attachment) {
  const url = apiEndPoints.clipboard.replace('{scoringId}', scoringId).replace('{mediaId}', attachment.id)
  try {
    const singleResponse = await httpDelete(url, {}, false)
    if (singleResponse && singleResponse.status && (singleResponse.status === 200 || singleResponse.status === 201)) {
      return singleResponse.data
    }
    return false
  } catch (e) {
    console.log('Error sending Attachment to Server', e)
    return false
  }
}

export async function doRemoveClipboardFileOnServer(scoringId, attachment) {
  await removeAttachmentOnServer(scoringId, attachment)
}

export async function doUploadClipboarFilesOnServer(scoringId, files, collection) {
  const filesArray = []
  for (let i = 0; i < files.length; i += 1) {
    filesArray.push(files[i])
  }

  const fileCollection = collection || 'clipboard'

  const filesUploaded = []
  const errors = []
  await Promise.all(
    await filesArray.map(async file => {
      // const fileExtension = file.name.split('.').pop()
      const fileObj = await readUploadedAttachmentAsBase64(file)
      if (file.id) fileObj.id = file.id
      else fileObj.id = mongoObjectId()
      fileObj.file_name = file.name
      fileObj.input_type = 'base64'
      fileObj.collection = fileCollection
      fileObj.default_conversion = 'clipboard_thumb'
      if (file.order) fileObj.order = file.order
      fileObj.created_at = isoDate()
      fileObj.updated_at = isoDate()
      const response = await sendAttachmentToServer(scoringId, fileObj)

      if (response && response.status && (response.status === 200 || response.status === 201)) {
        filesUploaded.push(response.data)
      } else {
        errors.push(response)
      }
    })
  )

  return { filesUploaded, errors }
}
