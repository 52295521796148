import _ from 'lodash'
import { httpGet, getMediaBlobUrl } from '../../api'
import storage from '../../storage'
import storageMap from '../../storage/storageMap'
import apiEndPoints from '../../api/apiEndPoints'

export async function getHomeDataAndUpdateStorage() {
  let homeData = {}
  if (navigator.onLine) {
    const { data } = await httpGet(apiEndPoints.home_blocks, false)

    // Get media blob urls
    const promises = []
    _.each(data, item => {
      if (item.type === 'image') {
        promises.push(
          new Promise((resolve, reject) => {
            getMediaBlobUrl(item.url)
              .then(response => {
                resolve({
                  blobUrl: response,
                  id: item.id,
                })
              })
              .catch(error => {
                reject(error)
              })
          })
        )
      }
    })

    _.each(await Promise.all(promises), result => {
      const homeBlock = _.find(data, item => {
        return item.id === result.id
      })
      homeBlock.url_blob = result.blobUrl
    })
    // End get media blob urls

    homeData.data = data
    homeData.id = 0
    await storage.update(storageMap.home, homeData)
  } else {
    homeData = await storage.get(storageMap.home, 0)
  }
  return homeData
}
