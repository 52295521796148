import {
  loadCobrandingFromLocalStorage,
  loadConfigFromLocalStorage,
  loadSystemLangFromLocalStorage,
  loadLocalesFromLocalStorage,
} from '../../../../va-corejs-v3/actions/env'

import { getOnlineStatusFromIndexedDb, updateSystemDataLang } from '../../../../va-corejs-v3/actions/system'

import { loadTextsFromLocalStorage } from '../../../../va-corejs-v3/actions/texts'

export function updateSystemLoadStatus(systemLoaded) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_SYSTEM_LOAD_STATUS',
      systemLoaded,
    })
  }
}

export function loadLangOnlineStatusFromStorageAndUpdateTextsIntoRedux() {
  return async dispatch => {
    const lang = await loadSystemLangFromLocalStorage()

    const locales = await loadLocalesFromLocalStorage()

    const values = await loadTextsFromLocalStorage(lang)
    const isOnline = await getOnlineStatusFromIndexedDb()

    dispatch({
      type: 'UPDATE_LOCALES',
      locales,
    })
    dispatch({
      type: 'UPDATE_SYSTEM_LANG',
      lang,
    })
    dispatch({
      type: 'UPDATE_TEXTS',
      values,
    })
    dispatch({
      type: 'UPDATE_IS_ONLINE',
      isOnline,
    })
  }
}

export function updateSystemLangIntoStorageAndReloadTextsIntoRedux(lang) {
  return async dispatch => {
    await updateSystemDataLang(lang)
    const values = await loadTextsFromLocalStorage(lang)

    dispatch({
      type: 'UPDATE_SYSTEM_LANG',
      lang,
    })
    dispatch({
      type: 'UPDATE_TEXTS',
      values,
    })
  }
}

export function updateEnv() {
  return async dispatch => {
    const config = await loadConfigFromLocalStorage()
    const cobranding = await loadCobrandingFromLocalStorage()
    dispatch({
      type: 'UPDATE_ENV',
      config,
      cobranding,
    })
  }
}
