import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actionCreators from '../../../store/actions'

import PrevThumbsIcon from '../../../assets/svg/lightbox-arrow-small-left.svg'
import NextThumbsIcon from '../../../assets/svg/lightbox-arrow-small-right.svg'
import NoMediaIcon from '../../../assets/svg/no-media.svg'
import { getMediaBlobUrl } from '../../../../va-corejs-v3/api'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

const thumbWidth = 121
const thumbWidthCompact = 84

class Thumbs extends Component {
  state = {
    thumbsPage: 0,
    thumbsShift: 0,
    parsedMedia: {},
  }

  componentDidMount = async () => {
    const { focusedMedia, pinnedMedia, type } = this.props
    const { parsedMedia } = this.state

    this.setState({
      parsedMedia: {
        ...parsedMedia,
        ...(await this.setMediaBlobUrls(type === 'focused' ? focusedMedia : pinnedMedia, parsedMedia)),
      },
    })
  }

  componentDidUpdate = async prevProps => {
    const { focusedMedia, pinnedMedia, type } = this.props

    if (prevProps.focusedMedia !== focusedMedia || prevProps.pinnedMedia !== pinnedMedia || prevProps.type !== type) {
      const { parsedMedia } = this.state
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        parsedMedia: {
          ...parsedMedia,
          ...(await this.setMediaBlobUrls(type === 'focused' ? focusedMedia : pinnedMedia, parsedMedia)),
        },
      })
    }
  }

  setMediaBlobUrls = async (media, alreadyParsedMedia) => {
    const promises = []
    _.each(media, item => {
      if (!item.file_thumb.startsWith('data:') && !alreadyParsedMedia[item.id]) {
        promises.push(
          new Promise((resolve, reject) => {
            getMediaBlobUrl(item.file_thumb)
              .then(response => {
                resolve({
                  blobUrlThumb: response,
                  mediaId: item.id,
                })
              })
              .catch(error => {
                reject(error)
              })
          })
        )
      }
    })

    const urlResults = {}
    _.map(await Promise.all(promises), item => {
      if (!_.has(urlResults, item.mediaId)) {
        urlResults[item.mediaId] = {}
      }
      if (_.has(item, 'blobUrlThumb')) {
        urlResults[item.mediaId].blobUrlThumb = item.blobUrlThumb
      }
    })

    media = _.map(media, item => {
      const urlThumb = item.file_thumb
      if (!urlThumb.startsWith('data:') && _.has(urlResults, item.id) && _.has(urlResults[item.id], 'blobUrlThumb')) {
        const { blobUrlThumb } = urlResults[item.id]
        item.blobUrlThumb = blobUrlThumb
      }

      return item
    })

    return _.keyBy(media, 'id')
  }

  handleThumbsPageChanged = (e, picsToShow, thumbsPage) => {
    e.stopPropagation()
    const { compactMode } = this.props
    const thumbWidthToUse = compactMode ? thumbWidthCompact : thumbWidth
    const thumbsShift = `translateX(-${thumbsPage * (picsToShow * (thumbWidthToUse + 10))}px)`
    this.setState({ thumbsPage, thumbsShift })
  }

  handleZoomPic = (e, media) => {
    e.stopPropagation()
    const { scoringId, updateInterRankingZoomedImage, pin } = this.props

    updateInterRankingZoomedImage(scoringId, media.file, media.file_thumb, pin)
  }

  render() {
    const { thumbsShift, thumbsPage, parsedMedia } = this.state
    const { compactMode, focusedMedia } = this.props

    if (!parsedMedia) {
      return <></>
    }

    const focusedMediaLength = focusedMedia.length > 0 ? focusedMedia.length : 1
    const totalThumbsWidth = compactMode
      ? focusedMediaLength * (thumbWidthCompact + 10)
      : focusedMediaLength * (thumbWidth + 10)

    let picsToShow = focusedMediaLength >= 5 ? 5 : focusedMediaLength
    if (compactMode) {
      picsToShow = compactMode && focusedMediaLength >= 3 ? 3 : focusedMediaLength
    }

    const thumbsPagesCount = Math.ceil(focusedMediaLength / picsToShow)

    const thumbs = []
    if (parsedMedia.length === 0) {
      thumbs.push(
        <div
          key={uuidv4()}
          className={classNames({
            media_inter_ranking_thumb: true,
            compactMode,
            picsToShow_0: true,
            no_media_thumb: true,
          })}
        >
          <NoMediaIcon style={{ width: 40, fill: 'rgba(124,240,10,0.1)' }} />
        </div>
      )
    } else {
      for (let i = 0; i !== focusedMedia.length; i += 1) {
        const currentFocusedMedia = focusedMedia[i]
        const media = parsedMedia[currentFocusedMedia.id]

        if (media) {
          // Bad thing: the same media model could have file_type attribute or mime_type sometimes
          const mediaType = media.file_type ? media.file_type.split('/')[0] : media.mime_type.split('/')[0]

          let urlThumb = media.file_thumb
          if (urlThumb && !urlThumb.startsWith('data:') && _.has(media, 'blobUrlThumb')) {
            urlThumb = media.blobUrlThumb
          }

          const videoPlaceholderThumb = '/play-button.jpg'

          thumbs.push(
            <div
              key={uuidv4()}
              className={classNames({
                media_inter_ranking_thumb: true,
                compactMode,
              })}
              style={{
                backgroundImage: mediaType === 'video' ? `url(${videoPlaceholderThumb})` : `url(${urlThumb})`,
              }}
              role="button"
              tabIndex={0}
              onClick={e => {
                this.handleZoomPic(e, media)
              }}
              onKeyPress={e => {
                this.handleZoomPic(e, media)
              }}
            />
          )
        }
      }
    }

    return (
      <div
        className={classNames({
          media_inter_ranking_thumbnails_container: true,
          compactMode,
        })}
      >
        <div
          className={classNames({
            media_inter_ranking_disabled_btn: thumbsPage === 0,
            media_inter_ranking_thumb_btn: true,
            media_inter_ranking_thumb_btn_prev: true,
            compactMode,
            picsToShow_0: picsToShow === 0,
            picsToShow_1: picsToShow === 1,
            picsToShow_2: picsToShow === 2,
            picsToShow_3: picsToShow === 3,
            picsToShow_4: picsToShow === 4,
          })}
          role="button"
          tabIndex={0}
          onClick={e => {
            if (thumbsPage > 0) {
              this.handleThumbsPageChanged(e, picsToShow, thumbsPage - 1)
            }
          }}
          onKeyPress={e => {
            if (thumbsPage > 0) {
              this.handleThumbsPageChanged(e, picsToShow, thumbsPage - 1)
            }
          }}
        >
          <PrevThumbsIcon />
        </div>

        <div
          className={classNames({
            media_inter_ranking_disabled_btn: thumbsPage === thumbsPagesCount - 1,
            media_inter_ranking_thumb_btn: true,
            media_inter_ranking_thumb_btn_next: true,
            compactMode,
            picsToShow_0: picsToShow === 0,
            picsToShow_1: picsToShow === 1,
            picsToShow_2: picsToShow === 2,
            picsToShow_3: picsToShow === 3,
            picsToShow_4: picsToShow === 4,
          })}
          role="button"
          tabIndex={0}
          onClick={e => {
            if (thumbsPage < thumbsPagesCount - 1) {
              this.handleThumbsPageChanged(e, picsToShow, thumbsPage + 1)
            }
          }}
          onKeyPress={e => {
            if (thumbsPage < thumbsPagesCount - 1) {
              this.handleThumbsPageChanged(e, picsToShow, thumbsPage + 1)
            }
          }}
        >
          <NextThumbsIcon />
        </div>

        <div
          className={classNames({
            media_inter_ranking_thumbnails: true,
            picsToShow_0: picsToShow === 0,
            picsToShow_1: picsToShow === 1,
            picsToShow_2: picsToShow === 2,
            picsToShow_3: picsToShow === 3,
            picsToShow_4: picsToShow === 4,
            compactMode,
          })}
        >
          <div
            className={classNames({
              media_inter_ranking_thumbnails_inner: true,
              compactMode,
            })}
            style={{ width: totalThumbsWidth, transform: thumbsShift }}
          >
            {thumbs}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    authentication: state.authentication,
    device: state.device,
    texts: state.texts.values,
    productsFromServer: state.interRanking.productsFromServer,
    carouselIndex: state.interRanking.carouselIndex,
    focusedMedia: state.interRanking.focusedMedia,
    pinnedMedia: state.interRanking.pinnedMedia,
    filteredScore: state.interRanking.filteredScore,
    pin: state.interRanking.pin,
  }
}

export default connect(mapStateToProps, actionCreators)(Thumbs)
