import storage from '../../storage'
import { httpGet } from '../../api'
import storageMap from '../../storage/storageMap'
import apiEndPoints from '../../api/apiEndPoints'

export async function getSettingsFromServer() {
  // const envResponse = await httpGet('env?id=0')
  const { data } = await httpGet(apiEndPoints.settings, false)
  await storage.update(storageMap.config, { id: 0, ...data.config })
  await storage.update(storageMap.cobranding, { id: 0, ...data.cobranding })
}

export async function getLocalesFromServer() {
  const { data } = await httpGet(apiEndPoints.locales, false)
  data.forEach(value => {
    storage.update(storageMap.locales, value)
  })
}

export async function loadConfigFromLocalStorage() {
  const response = await storage.get(storageMap.config, 0)
  global.env.config = response || {}
  return response
}

export async function loadLocalesFromLocalStorage() {
  const response = await storage.getAll(storageMap.locales)
  return response
}

export async function loadSystemLangFromLocalStorage() {
  const response = await storage.get(storageMap.system_data, 0)
  const lang = response ? response.language : process.env.defaultLang
  return lang
}

export async function loadCobrandingFromLocalStorage() {
  const response = await storage.get(storageMap.cobranding, 0)
  return response
}
