import _ from 'lodash'
import * as tus from '../tus-js-client'
import { httpDelete, httpPost } from './index'
import storage from '../storage'
import storageMap from '../storage/storageMap'
import IdbTusUrlStorage from '../sync/idbTusUrlStorage'
import ApiException from './apiException'

export const remove = async id => {
  const response = await httpDelete(`media/${id}`, {}, false)
  if (response.status && response.status >= 200 && response.status < 300) {
    return response.data
  }

  throw new ApiException(response)
}

export const reorderNodeMedia = async (nodeId, data) => {
  const url = `nodes/${nodeId}/media/reorder`
  const response = await httpPost(url, { media: data }, false)
  if (response.status && response.status >= 200 && response.status < 300) {
    return response.data
  }

  throw new ApiException(response)
}

export const tusUpload = async (file, data) => {
  const metadata = {
    filename: data.file_name,
    filetype: file.type,
  }

  if (data.collection) {
    metadata.collection = data.collection
  }

  if (data.model_type) {
    metadata.model_type = data.model_type
  }

  if (data.model_id) {
    metadata.model_id = data.model_id
  }

  if (data.created_at) {
    metadata.created_at = data.created_at
  }

  if (data.updated_at) {
    metadata.updated_at = data.updated_at
  }

  if (data.order) {
    metadata.order = data.order
  }

  if (data.id) {
    metadata.id = data.id
  }

  const tokenRecord = await storage.get(storageMap.access_token, 1)

  return new Promise((resolve, reject) => {
    const upload = new tus.Upload(file, {
      urlStorage: new IdbTusUrlStorage(),
      endpoint: `${global.env.apiUrl}media/tus/`,
      retryDelays: [1000, 3000, 5000, 10000],
      metadata,
      headers: {
        Authorization: `Bearer ${tokenRecord.access_token}`,
        'X-Requested-With': 'XMLHttpRequest',
      },
      onError(error) {
        const message =
          error.originalResponse && error.originalResponse._xhr ? error.originalResponse._xhr.responseText : null
        const status = error.originalResponse && error.originalResponse._xhr ? error.originalResponse._xhr.status : null
        reject(new ApiException({ status, message }))
      },
      /*
      onProgress(bytesUploaded, bytesTotal) {
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
        console.log(bytesUploaded, bytesTotal, `${percentage}%`)
      },
      */
      onSuccess() {
        resolve()
      },
    })

    upload.findPreviousUploads().then(previousUploads => {
      // Found previous uploads so we select the first one.
      const previousUpload = previousUploads.length > 0 ? previousUploads[0] : null

      if (previousUpload && _.isEqual(previousUpload.metadata, metadata)) {
        upload.resumeFromPreviousUpload(previousUpload)
      }

      // Start the upload
      upload.start()
    })
  })
}
