import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actionCreators from '../../../store/actions'

import screenTypesMap from '../../../layout/_parts/screenTypesMap'
import Comment from './comment'

const classNames = require('classnames')

class Comments extends Component {
  render() {
    const { device, pin, focusedComments, pinnedComments, type, position } = this.props
    const parsedComments = type === 'focused' ? focusedComments : pinnedComments
    const sortedComments = _.orderBy(parsedComments, 'order')
    return (
      <div
        className={classNames({
          inter_ranking_comments: parsedComments.length > 0,
          pinned: pin,
          there_are_comments: parsedComments.length > 0,
          isSafari: device.isSafari,
          isFirefox: device.isFirefox,
          desk: device.screenType === screenTypesMap.desk,
          ipadh: device.screenType === screenTypesMap.iPadH,
          ipadv: device.screenType === screenTypesMap.iPadV,
          iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          iphonese: device.screenType === screenTypesMap.iPhoneSE,
          desk_pinned_left: position === 'pinned_left',
          desk_pinned_right: position === 'pinned_right',
        })}
      >
        {sortedComments.map(comment => (
          <Comment comment={comment} key={comment.id} />
        ))}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    pin: state.interRanking.pin,
    focusedComments: state.interRanking.focusedComments,
    pinnedComments: state.interRanking.pinnedComments,
  }
}

export default connect(mapStateToProps, actionCreators)(Comments)
