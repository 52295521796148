import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

const classNames = require('classnames')

export class ScoringDateProp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dateValue: '',
    }
  }

  componentDidMount = async () => {
    const { scoring, environment, slug } = this.props
    const dateValueProp = scoring.scoringProps.filter(x => x.slug === slug)
    const dateValue = dateValueProp.length > 0 ? dateValueProp[0].value.body[environment.lang] : ''

    this.setState({ dateValue })
  }

  render() {
    const { dateValue } = this.state

    const {
      texts,
      environment,
      label,
      slug,
      type,
      mandatory,
      onChangeFunction,
      retrieveScoringPropFromRedux,
    } = this.props

    const prop = retrieveScoringPropFromRedux(slug)

    return (
      <div
        className={classNames({
          ns_prop: true,
          ns_date_prop: true,
        })}
      >
        <div className={classNames({ ns_prop_label: true })}>{label[environment.lang]}</div>
        <input
          className={classNames({
            ns_date_input: true,
            empty_date: prop === undefined || prop.value.body[environment.lang] === '',
          })}
          placeholder={mandatory ? texts.mandatory : '--'}
          value={dateValue}
          onChange={e => {
            this.setState({ dateValue: e.target.value })
            onChangeFunction(e, slug, type, label)
          }}
          type="date"
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    status: state.status,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringDateProp)
