import { loadStatusFromLocalStorage } from '../../../../va-corejs-v3/actions/status'

export function updateStatus() {
  return async dispatch => {
    const list = await loadStatusFromLocalStorage()
    dispatch({
      type: 'UPDATE_STATUS',
      list,
    })
  }
}
