import _ from 'lodash'
import { batchUpdate } from '../../api/nodes'
import storage from '../../storage'
import storageMap from '../../storage/storageMap'
import { normalizeNode } from '../../utils/dataNormalizers'
import normalizeDirectionMap from '../../utils/normalizeDirectionMap'
import { SYNC_ACTION_UPDATE_SCORING_TREE } from '../consts'
import DefaultActionHandler from './defaultActionHandler'

class UpdateScoringTreeHandler extends DefaultActionHandler {
  constructor() {
    super()
    this.setAction(SYNC_ACTION_UPDATE_SCORING_TREE)
  }

  async add(data) {
    const { product_id: productId, nodes } = data

    const existingItems = await storage.filter(
      storageMap.sync_queue_v2,
      item => item.action === SYNC_ACTION_UPDATE_SCORING_TREE && item.data.product_id === productId && !item.locked
    )

    if (existingItems.length <= 0) {
      // Create
      await super.add(data)
    } else {
      // Update
      const existingItem = existingItems[0]
      const { data: existingItemData } = existingItem

      _.each(nodes, node => {
        const existingNode = _.find(existingItemData.nodes, _existingNode => _existingNode.id === node.id)

        if (existingNode) {
          const index = _.findIndex(existingItemData.nodes, _existingNode => _existingNode.id === node.id)
          existingItemData.nodes[index] = { ...existingNode, ...node } // The updated node
        } else {
          existingItemData.nodes.push(node)
        }
      })

      existingItem.added_at = Date.now()
      await storage.update(storageMap.sync_queue_v2, existingItem)
    }
  }

  process = async item => {
    const { data } = item
    const { product_id: productId, nodes } = data

    const normalizedNodes = _.map(nodes, node => {
      normalizeNode(node, normalizeDirectionMap.to_server)
      node.product_id = productId
      return node
    })

    return batchUpdate(productId, normalizedNodes)
  }
}

export default UpdateScoringTreeHandler
