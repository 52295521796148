const authPagesMap = {
  useremail: 0,
  password: 1,
  resetpass: 2,
  resetnotallowed: 3,
  resetrequestsent: 4,
  newpassword: 5,
  userNotEnabled: 6,
}
export default authPagesMap
