import _ from 'lodash'
import apiEndPoints from '../../api/apiEndPoints'
import { httpPost } from '../../api'

export async function searchProductsByTemplateAndSegmentOrProductId(templateId, segmentSlug, productId) {
  const queryObject = {
    _source: {
      // include: ['id', 'props', 'score_panel'],
    },
    query: {
      bool: {
        should: [
          {
            bool: {
              must: [{ term: { 'template.id': templateId } }, { term: { 'props.main-segment.slug': segmentSlug } }],
            },
          },
          {
            bool: {
              must: [{ term: { id: productId } }],
            },
          },
        ],
      },
    },
    size: 1000,
  }

  const result = await httpPost(apiEndPoints.elastic_valueable_search, queryObject, false)

  return result
}

export async function searchProductsByTemplateAndSegment(templateId, segmentSlug) {
  const queryObject = {
    _source: {
      // include: ['id', 'props', 'score_panel'],
    },
    query: {
      bool: {
        must: [{ term: { 'template.id': templateId } }, { term: { 'props.main-segment.slug': segmentSlug } }],
      },
    },
    size: 1000,
  }

  const result = await httpPost(apiEndPoints.elastic_valueable_search, queryObject, false)

  return result
}

export async function searchProductsByTemplate(templateId) {
  const queryObject = {
    _source: {
      include: ['id', 'props', 'score_panel'],
    },
    query: {
      bool: {
        must: [{ term: { 'template.id': templateId } }],
      },
    },
    size: 1000,
  }

  const result = await httpPost(apiEndPoints.elastic_valueable_search, queryObject, false)

  return result
}

export async function reportAnalyticsMappingOverviewQuery(templateId, statusesToExclude, benchmarkProductId = null) {
  // Build should query
  const shouldQuery = [
    {
      bool: {
        must: [{ term: { 'template.id': templateId } }],
      },
    },
  ]

  // Statues to exclude from the results
  if (statusesToExclude && statusesToExclude.length > 0) {
    shouldQuery[0].bool.must_not = []

    _.each(statusesToExclude, status => {
      shouldQuery[0].bool.must_not.push({ term: { status } })
    })
  }

  if (benchmarkProductId !== null) {
    shouldQuery.push({
      bool: {
        must: [{ term: { id: benchmarkProductId } }],
      },
    })
  }

  // Prepare the whole query
  const queryObject = {
    _source: {
      include: ['id', 'props', 'score_panel', 'status'],
    },
    query: {
      bool: {
        should: shouldQuery,
      },
    },
    size: 1000,
  }

  const result = await httpPost(apiEndPoints.elastic_valueable_search, queryObject, false)

  return result
}
