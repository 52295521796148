import React from 'react'
import { connect } from 'react-redux'
import { StaticQuery, graphql } from 'gatsby'
import * as actionCreators from '../../../../store/actions'

import screenTypesMap from '../../../../layout/_parts/screenTypesMap'

const classNames = require('classnames')

export class ScoringSearchBar extends React.Component {
  render() {
    const { device, texts, scoring, updateScoringSearch } = this.props

    function handleSearchValueChange(e) {
      const searchValue = e.target.value
      updateScoringSearch(searchValue, scoring.scoringSearch.pageSize, 0)
    }

    async function handleResetSearchValue() {
      await updateScoringSearch('', scoring.scoringSearch.pageSize, 0)
      document.activeElement.blur()
    }

    return (
      <StaticQuery
        query={graphql`
          query {
            searchIconDesk: file(name: { in: "search-field-desk" }) {
              publicURL
            }
            searchIconTouch: file(name: { in: "search-field-touch" }) {
              publicURL
            }
          }
        `}
        render={data => (
          <div className="scoring_search_box_container">
            <input
              style={{
                backgroundImage: `url(${`${data.searchIconDesk.publicURL}`})`,
              }}
              type="search"
              className={classNames({
                scoring_search_box: true,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
              placeholder={texts.search}
              onChange={handleSearchValueChange}
              value={scoring.scoringSearch.searchValue}
            />

            <span
              className={classNames({
                scoring_search_box_cancel_search_label: true,
              })}
              onClick={handleResetSearchValue}
              onKeyPress={handleResetSearchValue}
              role="button"
              tabIndex={0}
            >
              {texts.cancel_search}
            </span>
          </div>
        )}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
    scoring: state.scoring,
    templates: state.templates,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringSearchBar)
