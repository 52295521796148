import _ from 'lodash'
import {
  getScoringMediaById,
  generateScoringTreeDataStructureForRedux,
  updateScoringIntoLocalStorage,
  updateEnableDisableNodesIntoLocalStorage,
  applyExpandedStatusToItemsAndCriteriaOnStorage,
  updateToReviewValueOnLocalStorage,
  updateNodeScoreValueOnLocalStorage,
  updateActiveItemOnLocalStorage,
  updateScoringStatusAndProps,
  updateItemDefaultValueOnLocalStorage,
  getScoringById,
  getRelatedItemsFatherNodeDefFromStorageByCurrentNodeType,
  updateScoringTreeAnalyticsIntoLocalStorage,
  getScoringTreeAnalyticsFromLocalStorage,
} from '../../../../va-corejs-v3/actions/scoring_tree'
import { getItemsFatherMediaFromLocalStorage, removeMediaById } from '../../../../va-corejs-v3/actions/media'
import { calculate } from '../../../../va-corejs-v3/specializations'
import storage from '../../../../va-corejs-v3/storage'
import storageMap from '../../../../va-corejs-v3/storage/storageMap'
import { sendLockUnlockScoringOnServer } from '../../../../va-corejs-v3/actions/scoring'
import { normalizeScoring } from '../../../../va-corejs-v3/utils/dataNormalizers'
import { updateNodesAfterCalculation } from '../../../../va-corejs-v3/actions/charts'
import apiEndPoints from '../../../../va-corejs-v3/api/apiEndPoints'
import { httpGet } from '../../../../va-corejs-v3/api'

export function updateLockNodeCliksOnCommentFocus(commentFocused) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_COMMENT_FOCUSED',
      commentFocused,
    })
  }
}

export function getScoringTreeById(id) {
  return async dispatch => {
    const response = await generateScoringTreeDataStructureForRedux(id)
    if (response) {
      const { scoring, nodeDefsObj, localesList, defKeys, scopeProps, itemsFather, completedPercentage } = response
      dispatch({
        type: 'UPDATE_COMPLETED_PERCENTAGE_INTO_SCORINGTREE',
        completedPercentage,
      })
      dispatch({
        type: 'INIT_SCORING_TREE',
        scoring,
        nodeDefsObj,
        locales: localesList,
        defKeys,
        scopeProps,
        itemsFather,
      })
      const media = await getItemsFatherMediaFromLocalStorage(scoring.id, itemsFather.id)
      dispatch({
        type: 'UPDATE_MEDIA',
        media,
      })
    }
  }
}

export function getScoringTreeFeaturedImage(id, collection) {
  return async dispatch => {
    const response = await getScoringMediaById(id, collection)
    const featuredImg = response.length > 0 ? response[0] : {}

    dispatch({
      type: 'UPDATE_SCORING_TREE_FEATURE_IMAGE',
      featuredImg,
    })
  }
}

export function updateActionMenuVisible(actionsMenuVisible) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_ACTION_MENU_VISIBLE',
      actionsMenuVisible,
    })
  }
}

export function updateScoringSelectedMode(scoringMode) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_SCORING_MODE_SELECTED',
      scoringMode,
    })
  }
}

export function updateCalculationMode(scoreSet) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_CALCULATION_MODE',
      calculationMode: scoreSet,
    })
  }
}

export function updateScoringTreeNodeDefsObj(nodeDefsObj) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_SCORING_TREE_SCORING_NODES',
      nodeDefsObj,
    })
  }
}

export function updateScoringTreeItemsFather(scoring, node) {
  return async dispatch => {
    await updateScoringIntoLocalStorage(scoring.id, node.id, true)
    dispatch({
      type: 'UPDATE_SCORING_TREE_ITEMS_FATHER',
      node,
    })
  }
}

export function updateScoringHeaderCalculationTree(calculationTree) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_SCORING_HEADER_CALCULATION_TREE',
      calculationTree,
    })
  }
}

export function updateExpandedNodeIntoScoring(scoringId, nodeId, itemView, scoreSet) {
  return async dispatch => {
    const scoring = await updateScoringIntoLocalStorage(scoringId, nodeId, false, scoreSet)
    const itemsFather = await getRelatedItemsFatherNodeDefFromStorageByCurrentNodeType(scoringId, nodeId)

    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE_AND_ITEMS_FATHER',
      scoring,
      itemsFather,
      itemView,
    })
  }
}

export function updateSidebarVisibile(sidebarVisible) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_SIDEBAR_VISIBLE',
      sidebarVisible,
    })
  }
}

export function updateEnableDisableNodes(scoringTree, node, checked, scoreSet) {
  return async dispatch => {
    const { completedPercentage, scorePanel } = await updateEnableDisableNodesIntoLocalStorage(
      scoringTree,
      node,
      checked,
      scoreSet
    )
    scoringTree.scoring.score_panel = scorePanel // check score Panel
    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring: scoringTree.scoring,
    })
    dispatch({
      type: 'UPDATE_COMPLETED_PERCENTAGE_INTO_SCORINGTREE',
      completedPercentage,
    })
  }
}

export function applyExpandedStatusOnItemsAndCriteria(scoringObj, itemView, itemsFather) {
  return async dispatch => {
    const response = await applyExpandedStatusToItemsAndCriteriaOnStorage(scoringObj, itemView, itemsFather.id)
    const { scoring } = response
    dispatch({
      type: 'UPDATE_SCORING_TREE_ITEM_AND_CRITERIA_VIEW_PROPS',
      scoring,
      itemView,
      itemsFather,
    })
  }
}

export function updateDefaultValue(scoringTree, nodeDefId) {
  return async dispatch => {
    const scoring = await updateItemDefaultValueOnLocalStorage(scoringTree, nodeDefId)
    const { nodes } = scoring
    const duplicateNodes = { ...nodes }
    await calculate(scoring, scoringTree.nodeDefsObj, duplicateNodes, false)
    scoring.nodes = duplicateNodes

    const tmpScoring = await storage.get(storageMap.offline_scorings_list, scoring.id) // This contains new score panel
    scoring.score_panel = [...tmpScoring._source.score_panel]
    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring,
    })
  }
}

export function updateToReviewValue(scoringId, nodeId, toReview, scoreSet) {
  return async dispatch => {
    const scoring = await updateToReviewValueOnLocalStorage(scoringId, nodeId, toReview, scoreSet)
    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring,
    })
  }
}

export function updateScoringIntoScoringTree(scoring) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring,
    })
  }
}

export function removeMediaFromScoring(scoringId, mediaCollection) {
  return async (dispatch, getState) => {
    const state = getState()
    const isNewScoring = state.scoring.newScoringPanelVisible ?? false

    const scoring = await getScoringById(scoringId)

    _.remove(scoring.media, item => {
      return item.collection === mediaCollection
    })

    // Dont' need the action below; it is performed by updatePictureForScoring() function va-corejs-v3
    // setScoringIntoLocalStorage(scoring)

    if (!isNewScoring) {
      // This is doable only when we are editing a scoring, otherwise there would be
      // issues wehn you trying to create a product while you have a scoring page opened
      dispatch({
        type: 'UPDATE_SCORING_INTO_SCORINGTREE',
        scoring,
      })
    }
  }
}

export function addMediaToScoring(scoringId, media) {
  return async (dispatch, getState) => {
    const state = getState()
    const isNewScoring = state.scoring.newScoringPanelVisible ?? false

    const scoring = await getScoringById(scoringId)

    if (!scoring.media) {
      scoring.media = []
    }

    scoring.media.push(media)

    // Dont' need the action below; it is performed by updatePictureForScoring() function va-corejs-v3
    // setScoringIntoLocalStorage(scoring)

    if (!isNewScoring) {
      // This is doable only when we are editing a scoring, otherwise there would be
      // issues wehn you trying to create a product while you have a scoring page opened
      dispatch({
        type: 'UPDATE_SCORING_INTO_SCORINGTREE',
        scoring,
      })
    }
  }
}

export function updateScoreValue(scoringTree, nodeDefId, scoreValue, isDefault, scoreSet) {
  return async dispatch => {
    const { scoring, completedPercentage } = await updateNodeScoreValueOnLocalStorage(
      scoringTree,
      nodeDefId,
      scoreValue,
      isDefault,
      scoreSet
    )

    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring,
    })
    dispatch({
      type: 'UPDATE_COMPLETED_PERCENTAGE_INTO_SCORINGTREE',
      completedPercentage,
    })
  }
}

export function updateActiveItem(scoringId, subFamilyId, familyId, perimeterId, itemsFather, activeItemId, itemView) {
  return async dispatch => {
    const scoring = await updateActiveItemOnLocalStorage(
      scoringId,
      subFamilyId,
      familyId,
      perimeterId,
      itemsFather.id,
      activeItemId
    )

    dispatch({
      type: 'UPDATE_SCORING_TREE_AFTER_ACTIVE_ITEM_UPDATED',
      scoring,
      itemView,
    })
  }
}

export function restoreScoringInitialProps(scoringId, status, props) {
  return async dispatch => {
    const scoring = await updateScoringStatusAndProps(scoringId, status, props)
    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring,
    })
  }
}

export function reloadScoringAfterEdit(scoringId) {
  return async dispatch => {
    const scoring = await getScoringById(scoringId)
    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring,
    })
  }
}

export function updateActiveItemIdInReduxOnly(scoringId, activeItemId) {
  return async dispatch => {
    const scoring = await getScoringById(scoringId)
    scoring.activeItemId = activeItemId
    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring,
    })
  }
}

export function updateScoringFeaturedImageAfterEdit(scoringId, previousImageId, collection, modified) {
  return async dispatch => {
    const response = await getScoringMediaById(scoringId, collection)
    const { length } = response

    if (length > 1) {
      response.forEach(async img => {
        if ((modified && img.id === previousImageId) || (!modified && img.id !== previousImageId)) {
          await removeMediaById(img.id)
        }
      })
    }

    const media = await getScoringMediaById(scoringId, collection)
    const featuredImg = media.length > 0 ? media[0] : {}

    dispatch({
      type: 'UPDATE_SCORING_TREE_FEATURE_IMAGE',
      featuredImg,
    })
  }
}

export function getAnalyticsFromLocalStorage(scoringId, defaultAnalytics) {
  return async dispatch => {
    let analytics = await getScoringTreeAnalyticsFromLocalStorage(scoringId)
    if (!analytics) {
      analytics = await updateScoringTreeAnalyticsIntoLocalStorage(scoringId, defaultAnalytics)
    }

    dispatch({
      type: 'UPDATE_ANALYTICS',
      analytics,
    })
  }
}

export function updateAnalyticsLastClickedNode(scoringId, lastClickedNode) {
  return async dispatch => {
    const analytics = await getScoringTreeAnalyticsFromLocalStorage(scoringId)
    if (analytics) {
      analytics.lastClickedNode = lastClickedNode
      await updateScoringTreeAnalyticsIntoLocalStorage(scoringId, analytics)

      dispatch({
        type: 'UPDATE_ANALYTICS',
        analytics,
      })
    }
  }
}

export function updateAnalyticsLastClickedNodeAndRefType(scoringId, lastClickedNode, selectedReferenceType) {
  return async dispatch => {
    const analytics = await getScoringTreeAnalyticsFromLocalStorage(scoringId)
    if (analytics) {
      analytics.lastClickedNode = lastClickedNode
      analytics.selectedReferenceType = selectedReferenceType
      await updateScoringTreeAnalyticsIntoLocalStorage(scoringId, analytics)

      dispatch({
        type: 'UPDATE_ANALYTICS',
        analytics,
      })
    }
  }
}

export function setAnalyticsRefTypeIntoStorageAndUpdateRedux(scoringId, selectedReferenceType) {
  return async dispatch => {
    const analytics = await getScoringTreeAnalyticsFromLocalStorage(scoringId)
    if (analytics) {
      analytics.selectedReferenceType = selectedReferenceType
      await updateScoringTreeAnalyticsIntoLocalStorage(scoringId, analytics)
      dispatch({
        type: 'UPDATE_ANALYTICS',
        analytics,
      })
    }
  }
}

export function updateAnalyticsBenchmarkSelectedId(scoringId, benchmarkId) {
  return async dispatch => {
    const analytics = await getScoringTreeAnalyticsFromLocalStorage(scoringId)
    if (analytics) {
      analytics.benchmarkId = benchmarkId
      await updateScoringTreeAnalyticsIntoLocalStorage(scoringId, analytics)

      dispatch({
        type: 'UPDATE_ANALYTICS',
        analytics,
      })
    }
  }
}

export function lockUnlockScoringOnServer(scoring) {
  scoring.is_locked = !scoring.is_locked
  return async () => {
    await sendLockUnlockScoringOnServer(scoring)
  }
}

// Calculate Benchmark Nodes by product Id
export async function getProductDataById(productId) {
  const apiUrl = apiEndPoints.products_filter.replace('{commaSeparatedIdList}', productId)

  const productListResult = await httpGet(apiUrl, false)

  if (productListResult.status === 200) return productListResult.data.data.shift()

  return false
}

export async function normalizeAndDoCalculationProductData(template, product) {
  if (!template || !product) return false

  product.nodeDefsObj = template.node_definitions

  // adapt clean data from server snake to camelcase
  await normalizeScoring(product, template, '', 'from_server')
  const propsKeys = Object.keys(product.props)
  const props = {}
  for (let i = 0; i !== propsKeys.length; i += 1) {
    const key = propsKeys[i]
    const prop = product.props[key]
    props[prop.slug] = prop
  }

  product.props = props

  await updateNodesAfterCalculation(product)
  return true
}

export async function getNormalizedNodesById(productId, templateId) {
  const productData = await getProductDataById(productId)
  const template = await storage.get(storageMap.template, templateId)
  if (!productData || !template) return false
  await normalizeAndDoCalculationProductData(template, productData)
  return productData.nodes
}

// Update Benchmark Nodes
export function updateAnalyticsBenchmarkSelectedNodes(scoringId, banchmarkId, templateId) {
  return async dispatch => {
    const analytics = await getScoringTreeAnalyticsFromLocalStorage(scoringId)

    if (analytics) {
      const benchmarkNodes = await getNormalizedNodesById(banchmarkId, templateId)
      analytics.benchmarkNodes = benchmarkNodes
      await updateScoringTreeAnalyticsIntoLocalStorage(scoringId, analytics)

      dispatch({
        type: 'UPDATE_ANALYTICS',
        analytics,
      })
    }
  }
}

// Update Benchmark Name
export function updateAnalyticsBenchmarkSelectedName(scoringId, benchmarkName) {
  return async dispatch => {
    const analytics = await getScoringTreeAnalyticsFromLocalStorage(scoringId)
    if (analytics) {
      analytics.benchmarkName = benchmarkName
      await updateScoringTreeAnalyticsIntoLocalStorage(scoringId, analytics)

      dispatch({
        type: 'UPDATE_ANALYTICS',
        analytics,
      })
    }
  }
}

// Update Analytics Breadcrumb
export function updateAnalyticsBreadcrumb(scoringId, breadcrumb) {
  return async dispatch => {
    const analytics = await getScoringTreeAnalyticsFromLocalStorage(scoringId)

    if (analytics) {
      analytics.breadcrumb = breadcrumb
      await updateScoringTreeAnalyticsIntoLocalStorage(scoringId, analytics)

      dispatch({
        type: 'UPDATE_ANALYTICS',
        analytics,
      })
    }
  }
}
