import _ from 'lodash'

export const selectAllSyncItems = state => _.values(state.syncV2.items)

export const selectCompletedSyncItems = state => _.filter(state.syncV2.items, item => item.completed_at !== null)

export const selectFailedSyncItems = state => {
  const failedItems = _.filter(state.syncV2.items, item => {
    const itemError = _.find(state.syncV2.errors, error => error.itemId === item.id)
    return !!(item.completed_at !== null && itemError)
  })

  return failedItems
}

export const selectAllSyncErrors = state => state.syncV2.errors

export const selectSyncError = (state, id) => _.find(state.syncV2.errors, item => item.itemId === id)

export const selectSyncStatus = state => state.syncV2.status
