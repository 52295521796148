import { loadTemplatesFromLocalStorage } from '../../../../va-corejs-v3/actions/templates'

export function updateTemplates() {
  return async dispatch => {
    const list = await loadTemplatesFromLocalStorage()
    dispatch({
      type: 'UPDATE_TEMPLATES',
      list,
    })
  }
}
